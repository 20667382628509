import * as React from 'react'
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert'

type AlertColors = 'succes' | 'info' | 'error' | 'warning'
export type AlertSettings = {
    open: boolean,
    message: string,
    color: Color
}
export function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}