import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { AppWithTheme } from 'AppWithTheme'

// import i18n (needs to be bundled ;)) 
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <AppWithTheme>
      <App />
    </AppWithTheme>
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
