import { createStyles, makeStyles, useTheme } from "@material-ui/core";

export const useDrawerStyleX = makeStyles((theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(5)
		},
		listItem: {
			// opacity: 0.6,
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: '24px',
			'&:hover': {
				backgroundColor: 'rgb(255 255 255 / 48%)',
			}
		},
		listItemHeader: {
			fontSize: '12px',
			fontWeight: 400,
			lineHeight: 1.8,
			paddingTop: 0,
			paddingBottom: 0,
			opacity: 0.7,
		},
		listItemText: {
			fontSize: 5,
			color: 'white',
			opacity: 1,
			height: '44px !important',
			margin: 'auto',
			//Ask Juraj
			// verticalAlign: 'middle',
			alignItems: 'center',
			display: 'inline-flex',
			textAlign: 'left',
		},
		itemIcon: {
			minWidth: '46px',
		},
		toolbar: {
			...theme.mixins.toolbar,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		listItemWrapper: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			flexDirection: 'row',
			width: '100%'
		},
		active: {
			opacity: 1,
		},
		selected: {
			backgroundColor: 'rgb(255 255 255 / 48%) !important'
		},
		section: {
			height: '100%',
			padding: '0px 0 20px'
		},
		listItemTreeNode: {
			borderLeft: '2px solid #000',
			'&:last-child': {
				borderLeft: '2px solid transparent'
			}
		},
		listItemTreeText: {
			position: 'relative',
			"&:before": {
				content: '',
				position: 'absolute',
				top: 0,
				left: '-2px',
				bottom: '50%',
				width: '0.75em',
				border: '2px solid #000',
				borderTop: '0 none transparent',
				borderRight: '0 none transparent'
			}
		},
	})
)

export function useDrawerStyle() {
	const theme = useTheme()
	return useDrawerStyleX(theme)
}
