import { CircularProgress, createStyles, fade, IconButton, makeStyles, TableCell, TableRow, Theme, withStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { DepartmentType, JobRole, Member, DocTypesEnum, Item, Client, InvoicerDoc, CompanyInfo } from 'api/api-types';
import { getClients, getForms, getMembers, resendVerifiyEmail } from 'api/ApiServices';
import { handleApiResponse, useApi } from 'api/useApi';
import * as _ from 'lodash';
import * as React from 'react';
import { AuthContext } from 'utils/Auth';
// import { MemberModal } from './MemberModal';
import { useModalStyles } from 'styles/useModalStyle'
import 'styles/colors.css'
// import { DepartmentsModal } from './DepartmentsModal';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import { defaultCellStyle, defaultHeaderStyle } from 'elements/ItemTable/ItemTableColumns';
import { parseValue, RowValue } from 'elements/ItemTable/ItemTableRows';
import { useItemTableStyles } from 'elements/ItemTable/useItemTableStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import GroupIcon from '@material-ui/icons/Group';
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ActionName, ItemActionPopover } from 'feed/ItemActionPopover';
import { InvoicerModal } from './InvoicerModal';
import moment from 'moment';
import { UserContext } from 'main/AppWithUser';


const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			minHeight: 835,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		},
		flexRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 20,
		},
		topFlexRow: {
			borderBottom: '1px solid #ededed',
			paddingBottom: 20
		},
		bottomFlexRow: {
			paddingTop: 5
		},
		//change to succesButton
		addMemberButton: {
			marginLeft: 'auto',
			marginRight: 15,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.1),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.01),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			border: '1px solid #e1e3e1'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		table: {

		},
		userIcon: {
			width: 22,
			height: 22,
		},
		manageIcons: {
			height: 20,
			cursor: 'pointer'
		},
		removeIcon: {
			// marginLeft: 15
		},
		userIconCell: {
			padding: 0
		},
		usernameCell: {
			paddingLeft: 0
		}
	}))



type Params = {
	type: DocTypesEnum
}
export function Invoicer(params: Params) {
	console.log("Params type", params.type)
	const classes = useStyles()
	const tableClasses = useItemTableStyles()

	// const [formsData, refetchForms] = useApi({
	// 	apiRequest: async () => await getForms(params.type, '10', '0'),
	// 	// pollingInterval: 5000
	// })
	const [formsData, setFormsData] = React.useState<Item[] | null>(null)
	const [clientsData, refetchClients] = useApi({
		apiRequest: async () => await getClients(),
	})
	const organisation = React.useContext(UserContext).organisation

	async function getFormsData(limit?: string, offset?: string) {
		console.log('Will fetch formsData and set')
		const response = await getForms(params.type, '10', '0')
		if (response._type === 'data') {
			console.log('data', response.data)
			setFormsData(response.data)
		} else {
			console.log('failed fetching items')
			return null
		}
	}


	const prepareDataForEdit = (client: Client, item: Item, companyInfo: CompanyInfo): InvoicerDoc => {

		let tmpInvoicerDoc: InvoicerDoc = {
			docType: params.type,
			invoiceNumber: item.trackingNumber?.toString() || '',
			orderNumber: item.labels.documentationGroup.orderNumber ? item.labels.documentationGroup.orderNumber[0].value : '',
			units: item.values.units?.value || -1,
			amountExclVAT: item.values.amountExclVAT?.value || -1,
			amountInclVAT: item.values.amountInclVAT?.value || -1,
			VATPercentage: item.values.VAT?.value || -1,
			VAT: item.values.VAT?.value || -1, //
			suppliedGoods: item.labels.documentationGroup.suppliedGoods?.value,
			textField: item.labels.documentationGroup.textField?.value,
			suppliedServices: item.labels.documentationGroup.suppliedServices?.value,
			contractNumber: item.labels.documentationGroup.contractNumber ? parseInt(item.labels.documentationGroup.contractNumber?.value) : undefined,
			dateOfIssue: item.labels.datesGroup.dateOfIssue.value ? item.labels.datesGroup.dateOfIssue.value : undefined,
			dueDate: item.labels.datesGroup.dueDate.value ? item.labels.datesGroup.dueDate.value : undefined,
			deliveryDate: item.labels.datesGroup.deliveryDate.value ? item.labels.datesGroup.deliveryDate.value : undefined,
			...client,
			clientNumber: client.clientNumber || '',
			specificSymbol: item.labels.documentationGroup.specificSymbol?.value,
			variableSymbol: item.labels.documentationGroup.variableSymbol?.value,


			...companyInfo,

		}
		console.log('DATA BEOFRE ENTER EDIT', tmpInvoicerDoc)
		return tmpInvoicerDoc
	}

	// console.log(() => getForms(params.type, '10', '0'))


	React.useEffect(() => {
		getFormsData()
	}, [params.type])

	//after creating departmentsModal
	const invoicerModalRef = React.useRef<InvoicerModal>(null)
	// const departmentModalRef = React.useRef<DepartmentsModal>(null)
	const popoverRef = React.useRef<ItemActionPopover>(null)


	const { t } = useTranslation();


	type Column = '' | 'Tracking #' | 'Client' | 'Invoice #' | 'Amount' | 'Date of Issue' | 'Delivery Date' | 'Due Date' | "INO"
	type Row = string[] | string | number

	function constructColumn(
		// section: Section,
		column: Column,
		options: MUIDataTableColumnOptions
	): MUIDataTableColumn {
		return {
			name: column,
			label: column,
			// options: { ...columnOptions(section, column, options) },
			options: { ...options }
		}
	}

	function renderColumns() {
		const columns: MUIDataTableColumn[] = [
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',
						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',

						}
					})
				}
			),
			constructColumn(
				'Tracking #',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'Tracking #'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Client',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'Client'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Amount',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t(t('Feed.Amount'))
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Date of Issue',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Feed.Date_of_Issue')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							fontWeight: '700',

						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0
						}
					})
				}
			),
			constructColumn(
				'Delivery Date',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Feed.Delivery_Date')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Due Date',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Modal.Date_Due')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'INO',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'INO'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
		]
		return columns
	}

	function renderInvoice(
		items: Item[],
		clients: Client[],
		actionButton: (item: Item, index: number) => JSX.Element
	) {
		console.log('items before reduce', items)
		return items.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// Check with Jorge how to propperly use reduce and why is first showing []
			if (i === 0)
				p.shift()
			let tmpClient = clients.find((e) => e.clientINO === c.labels.inputGroup.clientINO?.value)
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					['', <GroupIcon className={classes.userIcon} />],
					['Tracking #', parseValue(c.trackingNumber ? c.trackingNumber : null)],
					['Client', parseValue(tmpClient?.clientName)],
					['Amount', parseValue(c.values?.amountInclVAT ? c.values.amountInclVAT.value : null)],
					['Date of Issue', parseValue(c.labels.datesGroup.dateOfIssue ? moment(c.labels.datesGroup.dateOfIssue.value).format('DD/MM/YYYY') : null)],
					['Delivery Date', parseValue(c.labels.datesGroup.deliveryDate ? moment(c.labels.datesGroup.deliveryDate.value).format('DD/MM/YYYY') : null)],
					['Due Date', parseValue(c.labels.datesGroup.dueDate ? moment(c.labels.datesGroup.dueDate.value).format('DD/MM/YYYY') : null)],
					['INO', parseValue(tmpClient?.clientINO)],
					['', actionButton(c, i)]
					// ['', <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

					// 	{/* Check if verification is needed and how to display AddDepartmentsModal and EditDepartm...  */}
					// 	<EditIcon className={classes.manageIcons} onClick={() => {
					// 		console.log('onClick in Departments, row: ')
					// 		console.log(row)
					// 		departmentModalRef.current?.show({ department: c, departments: departments, members: members, refetchDepartments })
					// 	}} />
					// </div>

					// ],

				]
			// const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			// const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]
			const tmp = row.map(e => e[1])
			p.push(tmp)
			return p

		}, [[]])

	}


	if (formsData == null || formsData === null || clientsData === 'loading' || clientsData == null) {
		return <CircularProgress />
	} else {
		return (
			<>
				<div className={tableClasses.tableWrapper}>
					<MUIDataTable
						//   This should be an if to decide title
						title='Invoice'
						data={renderInvoice(
							formsData!,
							clientsData!,
							(item, index) => {
								return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', zIndex: 999 }} onClick={e => {
									e.stopPropagation()
									popoverRef.current?.show({
										anchorElement: e.currentTarget,
										actions: [
											{
												actionName: ActionName.EDIT,
												action: () => {
													let tmpClient = clientsData.find((e) => e.clientINO === item.labels.inputGroup.clientINO!.value)
													if (tmpClient)
														invoicerModalRef.current?.show({ docType: params.type, invoicedDoc: prepareDataForEdit(tmpClient, item, organisation.information), itemId: item.itemId, refetchItems: () => getFormsData, isEdit: true })
												}
											},
											// {
											// 	actionName: ActionName.REMOVE,
											// 	action: () => {
											// 		removeMember(member.username)
											// 	}
											// }
										]
									})
									// setSelectedItem(item.itemId, index)
								}} />
							}
						)}
						columns={renderColumns()}
						options={{
							elevation: 0,
							selectableRows: 'none',
							customToolbar: () => {
								return <div className={tableClasses.toolbarButtonsContainer}>
									{/* <div style={{ display: 'flex' }}> */}
									<IconButton onClick={() => {
										console.log("ButtonCliecked")
										console.log(invoicerModalRef)
										invoicerModalRef.current?.show({
											docType: params.type, refetchItems: () => getFormsData
										})
									}}>
										<AddCircleIcon className={`${tableClasses.addIcon}`} />
									</IconButton>
									{/* </div> */}
								</div>
							}
						}}

					/>

				</div>
				<InvoicerModal ref={invoicerModalRef} />
				<ItemActionPopover ref={popoverRef} />

			</>
		)
		// } else {
		// 	return null
		// }
	}
}
