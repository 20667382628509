import HelpIcon from '@material-ui/icons/Help'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import RemoveIcon from '@material-ui/icons/Remove'
import { ApproversType, DocStatusEnum } from "api/api-types"
import * as React from 'react'
import '../../styles/colors.css'
import { ApprovalStatus, ExtractionStatus } from "api/api-types"
import { Tooltip } from '@material-ui/core'

export const ItemTable_Render = {
	value<T>(value: T | "Missing") {
		if (value === "Missing") {
			return <HelpIcon className='bgRed' />
		} else {
			return value
		}
	},
	extractionStatus: (status: ExtractionStatus) => {
		switch (status) {
			case ExtractionStatus.DUPLICATE: return <span className='bgOrange status-bgOrange'>Duplicate</span>
			case ExtractionStatus.ATTENTION: return <span className='bgBlue status-bgBlue'>Attention</span>
			case ExtractionStatus.CORRECT: return <span className='bgGreen status-bgGreen'>Correct</span>
			case ExtractionStatus.CLASSIFIED: return <span className='bgGreen status-bgGreen'>Classified</span>
			case ExtractionStatus.AI_ERROR: return <span className='bgGreen status-bgGreen'>Conflicting</span>

		}
	},
	approvalStatus: (status: ApprovalStatus, approvers?: ApproversType[]) => {
		let content: string = ''
		if (approvers) {
			approvers.forEach(e => {
				content += '\n' + e.username
			})
		}
		// console.log(status, ApprovalStatus.MISSING_DOCUMENTATION)
		switch (status) {
			case ApprovalStatus.READY_FOR_SUMBISION: return <span className='bgGreen status-bgGreen'>Ready for submision</span>
			case ApprovalStatus.APPROVED: return <span className='bgGreen status-bgGreen'>Approved</span>
			case ApprovalStatus.REJECTED: return <span className='bgRed status-bgRed'>Rejected</span>
			case ApprovalStatus.RETURNED: return <span className='bgRed status-bgRed'>Returned</span>
			case ApprovalStatus.REVERTED: return <span className='bgRed status-bgRed'>Reverted</span>
			case ApprovalStatus.ON_HOLD: return <span className='bgOrange status-bgOrange'>On hold</span>
			case ApprovalStatus.PENDING_APPROVAL: {
				if (content !== '') {
					return <Tooltip title={content}><span className='bgOrange status-bgOrange'>Pending</span></Tooltip>
				}
				else
					return <span className='bgOrange status-bgOrange'>Pending</span>
			}
			case ApprovalStatus.MISSING_DOCUMENTATION: return <span className='bgGrey status-bgGrey'>Missing Documentation</span>
			case ApprovalStatus.OPEN: return <span className='bgGrey status-bgGrey'>Open</span>
		}
	},
	documentationStatus: (documentationStatus: DocStatusEnum | string | null) => {
		switch (documentationStatus) {
			case DocStatusEnum.PENDING: return <span className='bgOrange status-bgOrange'>Pending</span>
			case DocStatusEnum.REJECTED: return <span className='bgRed status-bgRed'>Rejected</span>
			case DocStatusEnum.UNAVAILABLE: return <RemoveIcon className='bgRed' />
			case DocStatusEnum.NOT_APPLICABLE: return <span className='bgGreen status-bgGreen'>Not Applicable</span>
			default: {
				return ItemTable_Render.value(documentationStatus)
			}
		}
	},
	// Not implemented just placeholder
	billingBookingArchive: () => {
		return <HourglassEmptyIcon className='bgOrange' />
	},

}