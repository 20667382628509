import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useItemModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			height: '100%'
		},
		documentContainer: {
			minWidth: '740px',
			padding: '10px',
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch'
		},
		close: {
			position: 'absolute',
			top: 0,
			right: 0,
			margin: 20,
			cursor: 'pointer'
		},
		form: {
			width: '540px',
			paddingRight: 10,
			display: 'inline-block',
			overflowY: 'auto',
		},
		pagination: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			padding: 20,
			backgroundColor: 'white',
			minHeight: '64px'
		},
		section: {
			height: '100%',
			minWidth: 200
		},
		submitButton: {
			borderRadius: 0,
			boxShadow: 'none',
			padding: 20,
			justifySelf: 'flex-end'
		},
		paginationButton: {
			color: 'blue',
			textAlign: 'center',
			cursor: 'pointer'
		},
		expandableSection: {
			backgroundColor: 'white',
			padding: '10px 10px 10px 10px',
			marginTop: 10,
			marginBottom: 10
		},
		datesContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			'& > *': {
				marginLeft: 10
			},
			'& :first-child': {
				marginLeft: 0
			},
			'& :last-child': {
				marginRight: 0
			}
		},
		checkboxLabel: {
			marginLeft: '16px',
			marginRight: '16px',
			whiteSpace: 'nowrap',
			alignSelf: 'flex-start',
			paddingTop: '1px'
		},
		headerLabels: {
			display: 'flex',
			alignItems: 'center'
		},
		headerText: {
			marginRight: 10
		},
		labelButton: {
			width: '100%',
			backgroundColor: '#ececec',
			borderRadius: 5,
			padding: 7,
			fontSize: 12,
			marginBottom: 5,
			'&:focus': {
				outline: "none"
			}
		},
		iconCell: {
			paddingRight: 0,
		},
		userIcon: {
			width: 22,
			height: 22,
			color: 'rgba(0, 0, 0, 0.54)'
		},
	})
)
