import * as React from 'react'
import { verifyRegistration } from 'api/ApiServices'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { Link, Redirect } from 'react-router-dom'
import { useModalStyles } from 'styles/useModalStyle'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		registrationSuccess: {
			padding: 40
		}
	})
)

type Props = {
	token: string
}

export function VerifyRegistration(props: Props) {
	const [isVerified, setIsVerified] = React.useState<boolean | null>(null)
	const classes = useStyles()
	const modalClasses = useModalStyles()

	const { t } = useTranslation();

	React.useEffect(() => {
		async function init() {
			const response = await verifyRegistration(props.token)
			if (response._type === 'data') {
				if (response.data === true) {
					setIsVerified(true)
				} else {
					setIsVerified(false)
				}
			}
		}
		init()
	}, [props.token])

	if (isVerified === null) {
		return null
	} else if (!isVerified) {
		return <div className={`${modalClasses.paper} ${modalClasses.paper}`}>
			<div className={classes.registrationSuccess}>{t('Login.Registration_Fail')} <a href="/">{t('Login.Resend')}</a> {t('Login.Email_Low')}</div>
		</div>
	} else {
		return <Redirect to="/login" />
	}
}