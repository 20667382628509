import { makeStyles, Theme } from '@material-ui/core'
import { login } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useModalStyles } from 'styles/useModalStyle'
import { AuthContext } from 'utils/Auth'
import { regexPatterns } from 'utils/Validation'
import { ErrorContext } from './AppWithErrors'
import Select, { components, IndicatorProps } from 'react-select'
import LanguageIcon from '@material-ui/icons/Language';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: 500,
		minHeight: 450,
		padding: 30,
		justifyContent: 'center',
		alignItems: 'center'
	},
	registerContainer: {
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	registerLabel: {
		opacity: 0.7,
		fontSize: 12
	},
	formGroup: {
		marginTop: 40,
		paddingLeft: 40,
		paddingRight: 40
	},
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	select: {
		// border: '2px solid rgb(237, 237, 237)',
		borderRadius: 0,
		minWidth: 118,
		maxWidth: 118,
		marginLeft: 'auto'
	},
	selectIcon: {
		color: 'rgba(0, 0, 0, 0.54)'
	}
}))

enum Language {
	ENGLISH = 'English',
	SLOVAK = 'Slovak',
	SPANISH = 'Spanish',
	GERMAN = 'German'
}

export function Login() {
	const classes = useStyles()
	const modalClasses = useModalStyles({
		modalHeight: 500,
		modalWidth: 450
	})
	const authContext = React.useContext(AuthContext)
	const errorContext = React.useContext(ErrorContext)

	const emailInputRef = React.useRef<CustomInput>(null)
	const passwordInputRef = React.useRef<CustomInput>(null)

	const { t, i18n } = useTranslation();
	const [lang, setLang] = React.useState<{ value: Language, label: string } | null>({ value: codeToLan(i18n.language), label: `${codeToLan(i18n.language)}` })

	function languageOptions() {
		return _.toArray(Language).map(e => ({ value: e, label: e }))
	}

	const changeLanguage = (lng: string) => {
		let code
		if (lng === "Slovak") {
			code = "sk"
		} else if (lng === 'Spanish') {
			code = "es"
		} else {
			code = "en"
		}
		i18n.changeLanguage(code);
	};

	async function onSubmit() {
		const emailError = emailInputRef.current?.checkErrors()
		const passwordError = passwordInputRef.current?.checkErrors()
		if (!emailError && !passwordError) {
			const response = await login({
				username: emailInputRef.current!.value,
				password: passwordInputRef.current!.value
			})
			if (response._type === "data") {
				authContext.login(response.data)
			} else {
				// Handle errors
			}
		}
	}

	function codeToLan(code: string): Language {
		let lan
		if (code === "sk") {
			lan = "Slovak"
		} else if (code === 'de') {
			lan = "German"
		} else if (code === 'es') {
			lan = "Spanish"
		} else {
			lan = "English"
		}
		return lan as Language
	}

	const DropdownIndicator = (props: any) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					{props.selectProps.menuIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}

					{/* <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"} /> */}
				</components.DropdownIndicator>
			)
		);
	};
	// @ts-ignore
	const ValueContainer = ({ children, ...props }) => {
		return (
			components.ValueContainer && (
				// @ts-ignore
				<components.ValueContainer {...props}>
					{!!children && (
						<LanguageIcon className={classes.selectIcon} style={{ position: 'absolute', left: 6 }} />

					)}
					<div style={{ left: 100 }}>
						{children}
					</div>
				</components.ValueContainer>
			)
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Select
				components={{ ValueContainer: ValueContainer, DropdownIndicator: DropdownIndicator }}
				// placeholder={<span>tst</span>}
				key="tst4"
				name="Manager"
				options={languageOptions()}
				value={lang}
				onChange={selected => {
					if (selected) {
						const lan = selected as { value: Language, label: string }
						changeLanguage(lan.value)
					}
					setLang(selected as { value: Language, label: string })
				}}
				className={classes.select}
				styles={{
					container: (base, props) => ({
						...base,
						border: 'none',
						boxShadow: 'none',
						display: 'flex'
					}),
					control: (base, props) => ({
						...base,
						border: 'none',
						boxShadow: 'none',
						minWidth: 118

					}),
					singleValue: (base, props) => ({
						...base,
						marginLeft: '24px'
					}),
					menuList: (base, props) => ({
						...base,
						color: 'black'
					}),
				}}
			/>
			<div className={`${modalClasses.paper} ${modalClasses.paperCentered}`}>
				<div className={classes.content}>
					<a href="https://mygide.com/">
						<img src="/AiBERT_purple2.png" alt="logo" id="logo" />
					</a>
					<Form className={classes.formGroup} onSubmit={onSubmit}>
						<CustomInput
							ref={emailInputRef}
							errorText="Please type in correct email format example@example.example"
							label={t('Login.Email')}
							placeholder="Type in your email"
							required={true}
							type="email"
							regex={regexPatterns.email}
						/>
						<CustomInput
							ref={passwordInputRef}
							errorText="Password must contain one capital letter, one number and at least 8 characters"
							label={t('Login.Password')}
							placeholder="Type in your password"
							required={true}
							type="password"
							regex={regexPatterns.password}
						/>
						<SubmitButton>{t('Login.Login')}</SubmitButton>
					</Form>
					<div className={classes.registerContainer}>
						<p className={classes.registerLabel}>{t('Login.Create_Account_1')} <Link to="/register">{t('Login.Create_Account_2')}</Link></p>
					</div>
					<div className={classes.registerContainer}>
						<p className={classes.registerLabel}>Try us <Link to="/demo">Demo</Link></p>
					</div>
				</div>
			</div>
		</div>
	)
}