import { Checkbox, createStyles, FormControl, FormControlLabel, FormHelperText, makeStyles, Theme } from '@material-ui/core'
import { Client, DepartmentType, Supplier } from 'api/api-types'
import { createClient, getDepartments, updateClient, } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX, ModalXHeader } from 'elements/ModalX'
import * as _ from 'lodash'
import { invert } from 'lodash'
import { LOG } from 'logger/Log'
import * as React from 'react'
import Select, { OptionsType } from 'react-select'
import { ModalBody } from 'reactstrap'
import { regexPatterns } from 'utils/Validation'
// import { jobRoleLabel } from './Members'

// Change type of membersOption to {value: member, label: member}[], it will be cleaner, having less to typecast and less code, maybe even faster
//Then for then when setting setMemberOption() i will need to create {value: member, label: member}[] from array<member>
//Introduce selectFormatType
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			padding: 30,
			maxWidth: 600
		},
		formControl: {
			marginBottom: 10,
		},
		selectLabel: {
			color: 'rgba(0, 0, 0, 0.54)'
		},
		selectError: {
			color: 'red'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
		}
	}))

type Params = {
	// suppliers: Client[]
	client?: Client,
	refetch?: () => void,
}

export type ClientModal = {
	show: (params: Params) => void
}

export const ClientModal = React.forwardRef((props: {}, ref: React.Ref<ClientModal>) => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | null>(null)


	// const [jobRoleError, setJobRoleError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })
	// const [jobRoles, setJobRoles] = React.useState<{ value: JobRole, label: string }[]>([])
	// const [departments, setDepartments] = React.useState<{ value: DepartmentType, label: string }[]>([])
	// const [department, setDepartment] = React.useState<{ value: DepartmentType, label: string }>()


	// const [departmentError, setDepartmentError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })
	// const [suppliers, setSuppliers] = React.useState<{ value: Supplier, label: string }[]>([])
	// const [client, setClient] = React.useState<{ value: Supplier, label: string } | null>(null)
	const numberRef = React.useRef<CustomInput>(null)
	const nameRef = React.useRef<CustomInput>(null)
	const addressRef = React.useRef<CustomInput>(null)
	const contactNameRef = React.useRef<CustomInput>(null)
	const iNORef = React.useRef<CustomInput>(null)
	const tINORef = React.useRef<CustomInput>(null)
	const iNOVATRef = React.useRef<CustomInput>(null)
	const deliveryAddressRef = React.useRef<CustomInput>(null)








	// const nameRef = React.useRef<CustomInput>(null)
	// const aliasRef = React.useRef<CustomInput>(null)

	// function findMemberByEmail(manageUsername: string) {
	// 	//check for this if it can be undefined or null at any time
	// 	return membersOptions.find(member => member.value.username === manageUsername)!
	// }
	// function findMembersByEmail(manageUsernames: string[]) {
	// 	//check for this if it can be undefined or null at any time
	// 	return membersOptions.filter(member => !manageUsernames.includes(member.value.username))
	// }

	// function toSelectFormat(members: Member[]) {
	// 	const selectFormat = [] as { value: Member, label: string }[]
	// 	members.forEach(member => selectFormat.push({ value: member, label: `${member.firstName} ${member.lastName}` }))
	// 	return selectFormat
	// }

	// const fetchDepartments = async () => {
	// 	const data = await getDepartments()
	// 	if (data._type === 'data') {
	// 		setDepartments(data.data.map(e => {
	// 			return {
	// 				value: e,
	// 				label: e.name
	// 			}
	// 		}))
	// 	}
	// }
	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			if (params) {
				setParams(params)
			}
			setIsOpen(true)
		}
	}))

	//pitati stefani da li ovo treba u  show mozda


	async function onSubmit() {
		// const companyError = companyRef.current?.checkErrors()
		// const firstNameError = firstNameRef.current?.checkErrors()
		// const lastNameError = lastNameRef.current?.checkErrors()
		// const titleError = titleRef.current?.checkErrors()
		// const usernameError = userNameRef.current?.checkErrors()
		console.log('OnSubmit')

		if (nameRef.current && addressRef.current && contactNameRef.current && iNOVATRef.current && iNORef.current && tINORef.current && deliveryAddressRef.current && numberRef.current) {
			if (params?.client) {
				console.log('will update')
				const response = await updateClient({
					clientNumber: numberRef.current.value,
					clientName: nameRef.current.value,
					clientAddress: addressRef.current.value,
					clientContactName: contactNameRef.current.value,
					clientINO: iNORef.current.value,
					clientTINO: tINORef.current.value,
					clientINOVAT: iNOVATRef.current.value,
					clientdeliveryAddress: deliveryAddressRef.current.value
				})
				if (response._type === 'generic_success') {
					params?.refetch?.()
					handleClose()

				} else {
					console.log('faidles')
				}
			} else {
				const response = await createClient({
					clientNumber: numberRef.current.value,
					clientName: nameRef.current.value,
					clientAddress: addressRef.current.value,
					clientContactName: contactNameRef.current.value,
					clientINO: iNORef.current.value,
					clientTINO: tINORef.current.value,
					clientINOVAT: iNOVATRef.current.value,
					clientdeliveryAddress: deliveryAddressRef.current.value
				})
				if (response._type === 'generic_success') {
					params?.refetch?.()
					handleClose()

				} else {
					console.log('faidles')
				}
			}
		}



	}
	// }
	// else {
	// console.log('Entered u CreateDepartment')
	// console.log('code', codeRef.current!.value)
	// const response = await postDepartments([{
	// 	code: codeRef.current!.value,
	// 	alias: aliasRef.current?.value || null,
	// 	name: nameRef.current!.value,
	// 	//manager and parent state doesn't have to be array
	// 	manager: manager ? manager?.value.username : null,
	// 	hasEmployees: employees.map((e) => e.value.username),
	// 	parent: parent ? parent.value : null
	// }])
	// if (response._type === 'data' || response._type === 'generic_success') {
	// 	handleClose()
	// } else {
	// 	// handle errors
	// 	console.error(response)
	// }

	// // }
	// }

	// function departmentOptions() {
	// 	return _.toArray(OrgDepartment).map(e => ({ value: e, label: e }))
	// }

	function handleClose() {
		if (params) {
			setParams(null)
		}
		//Hanndle arrors later
		// setJobRoleError({
		// 	error: false,
		// 	errorMessage: ""
		// })
		// setJobRoles([])
		// setDepartments([])
		// setDepartmentError({
		// 	error: false,
		// 	errorMessage: ""
		// })

		setIsOpen(false)
		// setIsMasterApprover(false)
		// setIsMasterApproverError({
		// 	error: false,
		// 	errorMessage: ""
		// })
		// setIsDepartmentOwner(false)
		// setIsDepartmentOwnerError({
		// 	error: false,
		// 	errorMessage: ""
		// })
	}

	function renderCheckBox(
		checked: boolean,
		label: string,
		error: boolean,
		errorText: String,
		setValue: () => void,
	) {
		return (
			<FormControl>
				<FormControlLabel
					control={<Checkbox
						checked={checked}
						onChange={setValue}
						name={label}
					/>}
					label={label}
				/>
				<FormControl error={error}>
					<FormHelperText>{errorText}</FormHelperText>
				</FormControl>
			</FormControl>
		)
	}

	return (
		<ModalX isOpen={isOpen} onClose={handleClose}>
			<ModalXHeader>{params ? "Update Supplier" : "Create Supplier"}</ModalXHeader>
			<ModalBody>
				<Form onSubmit={onSubmit} className={classes.form}>

					<CustomInput
						key="cNumber"
						ref={numberRef}
						label="Number"
						errorText=""
						placeholder="Number"
						required={true}
						type="text"
						startingValue={params?.client?.clientNumber}
					/>
					<CustomInput
						key="cName"
						ref={nameRef}
						label="Name"
						errorText=""
						placeholder="Name"
						required={true}
						type="text"
						startingValue={params?.client?.clientName}
					/>
					<CustomInput
						key="tst1"
						ref={addressRef}
						label="Addresss"
						errorText=""
						placeholder=""
						required={true}
						type="text"
						startingValue={params?.client?.clientAddress}
					/>
					<CustomInput
						key="tst2"
						ref={contactNameRef}
						label="Contact Name"
						errorText=""
						placeholder=""
						required={true}
						type="text"
						startingValue={params?.client?.clientContactName}
					/>
					<CustomInput
						key="ino"
						ref={iNORef}
						label="INO"
						errorText=""
						placeholder="INO"
						required={true}
						type="text"
						startingValue={params?.client?.clientINO}
					/>
					<CustomInput
						key="tst3"
						ref={tINORef}
						label="TINO"
						errorText=""
						placeholder=""
						required={true}
						type="text"
						startingValue={params?.client?.clientTINO}
					/>
					<CustomInput
						key="tst4"
						ref={iNOVATRef}
						label="INOVAT"
						errorText=""
						placeholder="INOVAT"
						required={true}
						type="text"
						startingValue={params?.client?.clientINOVAT}
					/>
					<CustomInput
						key="tst5"
						ref={deliveryAddressRef}
						label="Delivery Address"
						errorText=""
						placeholder=""
						required={true}
						type="text"
						startingValue={params?.client?.clientdeliveryAddress}
					/>
					<SubmitButton>{params?.client ? "Update" : "Create"}</SubmitButton>
				</Form>
			</ModalBody>
		</ModalX>
	)

})