import { Button, CircularProgress, TableFooter, TablePagination, TableRow } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ApprovalStatus, Counters, ExtractionStatus, Item, UiViews } from "api/api-types"
import { getItem, setItemIsRead } from 'api/ApiServices'
import { DuplicateModalParams } from 'feed/DuplicateModal'
import { ItemState, ItemStatus } from 'feed/Feed'
import { Action, ItemActionPopover } from 'feed/ItemActionPopover'
import { ItemLabels } from 'feed/ItemLabels'
import { ItemModalParams } from 'feed/ItemModalCommon'
import { UploadDocumentationModalParams } from 'feed/UploadDocumentationModal'
import { PaginationParams, ViewParams } from 'feed/useFetchItems'
// import { FetchItemsParams } from 'feed/useFetchItems'
import * as _ from 'lodash'
//To be deleted
import { FilterContext } from 'main/AppWithFiltersNew'
import { HEADER_HEIGHT } from 'main/Header'
import MUIDataTable from "mui-datatables"
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'utils/Auth'
import { columnOrdinal, getItemTableColumns } from './ItemTableColumns'
import { getItemTableRows } from './ItemTableRows'
import { ItemTableTitle } from './ItemTableTitle'
import { ItemTableToolbar } from './ItemTableToolbar'
import { ItemTable_Render } from './ItemTable_Render'
import { useItemTableStyles } from './useItemTableStyles'

export type ItemTableActions = (
	item: Item,
	setIsLoading: (isLoading: boolean) => void
) => Action[]


export type Section = "extraction" | "approvals" | "archive" | "submision" | "main" | "extraction errors" | "booking submision" | "booking"

type ForSection = {
	section: "main" | "extraction errors"
	showUploadDocumentation?: (params: UploadDocumentationModalParams) => void
} | {
	section: "extraction",
	actions: ItemTableActions
	showUploadDocumentation?: (params: UploadDocumentationModalParams) => void
} | {
	section: "submision"
	submit: (itemIds: string[]) => void
	revert: (itemIds: string[]) => void
} | {
	section: "approvals"
	actions: ItemTableActions
	showApprovalsModal?: (params: ItemModalParams) => void
	showApprovalInfo?: boolean
} | {
	section: "extraction errors"
	showUploadDocumentation?: (params: UploadDocumentationModalParams) => void
} | {
	section: "booking submision"
	// showUploadDocumentation?: (params: UploadDocumentationModalParams) => void
	submitBooking: (itemIds: string[]) => void
} | {
	section: "archive"
	// showUploadDocumentation?: (params: UploadDocumentationModalParams) => void
	// submitBooking: (itemIds: string[]) => void
}

export type ItemTableProps = {
	items: Item[]
	currentCount: number,
	showExtractionErrorModal?: (params: ItemModalParams) => void
	showEditItem?: (params: ItemModalParams) => void
	showDuplicateItem?: (params: DuplicateModalParams) => void
	fetchItems: (viewP?: ViewParams, paginationP?: PaginationParams) => void
	refetchItems: (viewP?: ViewParams, paginationP?: PaginationParams) => void
	pagination: { offset: number; limit: number; }
	// setPagination: React.Dispatch<React.SetStateAction<{ offset: number; limit: number; }>>,
	itemsCount?: Counters,
	fetchArchiveItems?: (range?: { month: number, year: number }) => void
} & ForSection

export function ItemTable(props: ItemTableProps) {
	const classes = useItemTableStyles()

	const [selectedRow, setSelectedRow] = React.useState({
		index: -1,
		id: ""
	})

	const { t } = useTranslation();

	const [loading, setIsLoading] = React.useState(false)
	const currentPageNumber = React.useRef(0)
	const currentRowsPerPage = React.useRef(10)
	const selectedRows = React.useRef<number[]>([])

	const popoverRef = React.useRef<ItemActionPopover>(null)
	const tableRef = React.useRef<HTMLDivElement>(null)
	const authContext = React.useContext(AuthContext)
	const filterContext = React.useContext(FilterContext)
	const setSelectedItem = (itemId: string, index: number) => {
		const item = _.find(props.items, e => e.itemId === itemId)
		if (item) {
			setSelectedRow({
				index: index,
				id: item.itemId
			})
		}
	}

	const resetSelectedRow = () => {
		setSelectedRow({
			id: "",
			index: -1
		})
	}

	const isSelected = (row: (string | JSX.Element | JSX.Element[])[], index: number) => {
		const selectedIndex = selectedRow.index + (currentRowsPerPage.current * currentPageNumber.current)
		const isSlectedId = row[row.length - 1] === selectedRow.id
		return selectedIndex === index && isSlectedId
	}
	//What to do after deletion of FilterContext
	// Reset selected row on filter change
	// Otherwise show selected row
	React.useEffect(() => {
		resetSelectedRow()
	}, [])

	// Used in sumbission page
	React.useEffect(() => {
		selectedRows.current = []
	}, [props.section])

	const rows = getItemTableRows(
		props.items,
		(item, index) => {
			// This thing with lenght is patch
			if (props.section === "extraction" || (props.section === "approvals" && (props.actions.length !== 2 || item.approvalStatus === ApprovalStatus.REJECTED))) {
				return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', marginRight: 16 }} onClick={e => {
					e.stopPropagation()

					popoverRef.current?.show({
						anchorElement: e.currentTarget,
						actions: props.actions(
							item,
							setIsLoading
						)
					})
					setSelectedItem(item.itemId, index)
				}} />
			} else {
				return <></>
			}
		}
	)


	const columnTranslation = {
		"Supplier": t('Procurement.Supplier'),
		"Invoice ID": t('Feed.Invoice_Id'),
		"Invoice #": t('Feed.Invoice_Num'),
		"Purchase Order #": t('Feed.Purshase_Order_Num'),
		"Requisition #": t('Feed.Requisition_Num'),
		"Name": t('Feed.File'),
		"Document": t('Feed.Document'),
		"Amount": t('Feed.Amount'),
		"Delivery Date": t('Feed.Delivery_Date'),
		"Date of Issue": t('Feed.Date_of_Issue'),
		"Due Date": t('Modal.Date_Due'),
		// Should this be ICO in english?????
		"ICO": t('Feed.ICO'),
		// Should action be translated????
		"Actions": t('Supplier'),
		"Billing": t('Feed.Billing'),
		"Booking": t('Modal.Booking'),
		"Archive": t('Feed.Archive'),
		"Approval": t('Feed.Approvals'),
		"Extraction": t('Feed.Extraction'),
		"Extraction Time": t('Feed.Extraction_Time'),
		"Sender Email": t('Feed.Sender_Email'),
		"Document Format": t('Document_Format'),
		"Created At": t('Created_At'),
		"Tracking #": t('Feed.Tracking_Num'),
		"IBAN": t('Modal.Iban'),
		"Order #": t('Order #')


	}

	const columns = getItemTableColumns(
		props.section,
		(rowIndex, columnIndex) => {
			if (props.section === "extraction") {
				const itemRow = rows![rowIndex]
				const itemId = itemRow[itemRow.length - 1]
				const item = _.find(props.items, e => e.itemId === itemId)!
				if (columnOrdinal("Requisition #") === columnIndex) {
					// props.showUploadDocumentation && props.showUploadDocumentation({
					// 	columnName: "Requisition #"
					// })
				} else if (columnOrdinal("Purchase Order #") === columnIndex) {
					// props.showUploadDocumentation && props.showUploadDocumentation({
					// 	columnName: "Purchase Order #"
					// })
				}
			}
		},
		(approvalStatus, rowIndex) => {
			const itemRow = rows![rowIndex]
			const item = _.find(props.items, e => e.itemId === itemRow[itemRow.length - 1])
			const approvers = item?.approvers
			// Add approvers
			if (approvers) {
				// console.log('ItemTable_Render.approvalStatus(approvalStatus, approvers)', approvalStatus)
				return ItemTable_Render.approvalStatus(approvalStatus, approvers)
			} else {
				// console.log('ItemTable_Render.approvalStatus(approvalStatus), approvalS: ', approvalStatus)
				return ItemTable_Render.approvalStatus(approvalStatus)
			}
		},
		columnTranslation
	)

	function itemStatusesToCount() {

	}


	// This is actually default
	// we can override this
	const renderCustomFooter = (
		count: number,
		page: number,
		rowsPerPage: number,
		changeRowsPerPage: (page: React.ReactText) => void,
		changePage: (newPage: number) => void
	) => {
		return (
			<TableFooter>
				<TableRow>
					<TablePagination
						count={props.currentCount}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={(_, page) => changePage(page)}
						onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
						rowsPerPageOptions={[10, 15, 30]}
					/>
				</TableRow>
			</TableFooter>
		)
	}
	// props.items[0].
	return (
		<>
			<div className={classes.tableWrapper} ref={tableRef}>
				{loading && <div className={classes.loadingContainer}>
					<CircularProgress size={90} />
				</div>}
				<MUIDataTable
					// count={11}
					// offset={pagination.offset} limit={pagination.limit}
					title={<ItemTableTitle forPage={props.section} fetchItems={props.fetchItems} itemsCount={props.itemsCount} />}
					data={rows!}
					columns={columns}
					options={{
						filter: 'disabled',
						search: 'disabled',
						serverSide: true,
						elevation: 0,
						tableBodyMaxHeight: `calc(100vh - 246px)`,
						responsive: 'vertical',
						selectableRows: (props.section === 'submision' || props.section === 'booking submision') ? 'multiple' : 'none',
						onRowSelectionChange: (_f, _s, index) => {
							if (index != null) {
								selectedRows.current = [...index]
							}
						},
						sort: false,
						customFooter: renderCustomFooter,
						rowsSelected: selectedRows.current,
						customToolbar: (data) => {
							if (props.section === 'submision') {
								return <ItemTableToolbar filter="submision" items={props.items} submit={props.submit} />
							} else if (props.section === 'booking submision') {
								//Maybe here filter needs to be separated but prob not.
								return <ItemTableToolbar filter="submision" items={props.items} submit={props.submitBooking} />
							} else if (props.section === 'archive') {
								//Maybe here filter needs to be separated but prob not.
								return <ItemTableToolbar filter="archive" refetchArchive={props.fetchArchiveItems} />
							} else {
								return <ItemTableToolbar filter="" items={props.items} />
							}
						},
						//Maybe add this buttons for Ready for booking(booking submison)
						// this is defined directly here since the typings are not very nice
						customToolbarSelect: ({ data }) => {
							if (props.section === 'submision') {
								return (
									<div className={classes.toolbarContainer}>
										<Button
											disabled={props.items.length === 0}
											className={`${classes.submitButton} ${classes.revertButton}`}
											onClick={async () => {
												const selected = data
													.map(e => rows[e.index])
													.map(e => e[e.length - 1]) as string[]

												props.revert(selected)
												selectedRows.current = []
											}}
										>
											Revert
										</Button>
										<Button
											disabled={props.items.length === 0}
											className={classes.submitButton}
											onClick={async () => {
												const selected = data
													.map(e => rows[e.index])
													.map(e => e[e.length - 1]) as string[]

												props.submit(selected)
												selectedRows.current = []
											}}
										>
											Submit
										</Button>
									</div>
								)
							} else {
								return null
							}
						},
						onChangeRowsPerPage: (numberOfRows) => {
							// currentRowsPerPage.current = numberOfRows
							// console.log('LOOOOOOOOK')

							// console.log("will set pagination", { ...props.pagination, limit: numberOfRows })
							// props.setPagination({ ...props.pagination, limit: numberOfRows })
							// Uiview
							const paginationP = { offset: props.pagination.offset, limit: numberOfRows }
							props.refetchItems(undefined, paginationP)

						},
						onRowClick: (row, { rowIndex }) => {
							resetSelectedRow()
						},
						onChangePage: (pageNumber) => {
							// console.log("will set pagination", {...props.pagination, offset: pageNumber })
							// props.setPagination({ ...props.pagination, offset: pageNumber * 10 })
							props.refetchItems(undefined, { offset: pageNumber * 10, limit: props.pagination.limit })
						},
						setRowProps: (row, rowIndex) => ({
							onClick: () => {
								const item = _.find(props.items, e => e.itemId === row[row.length - 1])!
								if (!item.isRead) {

								}
							},
							onDoubleClick: async () => {

								const item = _.find(props.items, e => e.itemId === row[row.length - 1])!
								const index = _.findIndex(props.items, item)
								// const itemsRead = await setItemIsRead([item.itemId])
								// if (itemsRead._type === 'data') {
								// 	console.log('Item is read succes')
								// } else {
								// 	console.log('Item is read failed')
								// 	console.log('ODJE', itemsRead)
								// }
								if (item.extractionStatus === ExtractionStatus.DUPLICATE && item.duplicateReference) {
									const original = await getItem(item.duplicateReference)
									// const duplicates = _.filter(props.items, e => e.duplicateReference === duplicateRef)
									if (original._type === 'data') {
										const dates = [original.data.createdAt, item.createdAt]
										props.showDuplicateItem && props.showDuplicateItem({
											itemId: original.data.itemId,
											duplicates: item.itemId,
											setLoading: (isLoading) => setIsLoading(isLoading),
											duplicatesCreatedAt: dates
										})
									} else {
										console.log('Cannot find original')
									}
								} else if (props.section === "approvals") {
									const approver = item.approvers?.find((e) => e.username == authContext.getUser()!!.iss)

									if (approver || filterContext.activeFilter === 'on_hold') {

										props.showApprovalsModal && props.showApprovalsModal({
											currentIndex: index,
											item,
											size: props.items.length
										})
									} else {

										props.showEditItem && props.showEditItem({
											currentIndex: index,
											item,
											size: props.items.length,
											// showApprovalInfo: true
										})
									}
									// This should be changed a bit we shouldnt check for main here and pass it down
								} else if (props.section === 'main') {
									props.showEditItem && props.showEditItem({
										currentIndex: index,
										item,
										size: props.items.length,
										forSection: 'main'
									})
								} else if (props.section === 'extraction errors') {
									props.showExtractionErrorModal && props.showExtractionErrorModal({
										currentIndex: index,
										item,
										size: props.items.length,
									})
								}
								else {
									props.showEditItem && props.showEditItem({
										currentIndex: index,
										item,
										size: props.items.length,
									})
								}
								setSelectedItem(row[row.length - 1], rowIndex)
							},
							style: {
								// backgroundColor: isSelected(row, rowIndex) ? "#ededed" : !props.items[rowIndex].isRead || props.items[rowIndex].approvedByMe ? '#ededed' : '',

								backgroundColor: isSelected(row, rowIndex) ? "#ededed" : !props.items[rowIndex].isRead ? '#ededed' : '',
							}
						})
					}}
				/>
				<ItemActionPopover ref={popoverRef} />
			</div>
		</>
	)
}