import { Button, createStyles, Divider, Drawer, IconButton, List, Snackbar, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropzoneArea } from 'material-ui-dropzone';
import { getStatus, getUploadUrl, uploadFile } from 'api/ApiServices';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertSettings } from 'elements/Alerts'
import { StatusLight } from '../StatusLight';
import { useApi } from 'api/useApi';
import { DocucmentUpload } from './DocumentUpload';
import { DownloadReport } from './DownloadReport';
import { Help } from './Help';




const DRAWER_WIDTH = 265

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			flexGrow: 1,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			// width: DRAWER_WIDTH,
			flexShrink: 0,
		},
		drawerPaper: {
			width: DRAWER_WIDTH,
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
			justifyContent: 'flex-end',
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginRight: -DRAWER_WIDTH,
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: 0,
		},
		// To be splited in separate component
		MuiDropzonePreviewList: {
			image: {

			}
		},
		dropZoneText: {
			fontSize: '1rem',
			padding: '0px 16px'
		},

		buttonSection: {
			display: 'flex',
			height: '32%',
			width: '100%',
			maxWidth: '200px',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			padding: '0px 10px',
			alignSelf: 'center',


		},
		approveButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#08ad6c',
			border: '2px solid #08ad6c',
		},
		rejectButton: {
			justifySelf: 'flex-end',
			marginBottom: 16,
			padding: '10px 0px',
			color: '#FF3D57',
			border: '2px solid #FF3D57',

		},
		dropZoneGirdContainer: {

		},
		dropZoneGirdItem: {

		},
		dropZoneGirdImage: {
			color: 'rgba(0, 0, 0, 0.87)',
			width: 'initial',
			height: '30px',
			opacity: 1,
			zIndex: 5,
			maxWidth: '100%',
			boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;',
			boxSizing: 'border-box',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
			borderRadius: '4px',
		},
		list: {
			overflowY: 'auto',
			height: '100%'
		},
		listItem: {
			paddingBottom: '2px',
			paddingTop: '2px',
		},
		itemIcon: {
			minWidth: '0px',
			paddingRight: '10px'
		},
		icon: {
			color: 'rgba(34, 42, 69, 0.96)',
		}

	}),
)

export type OpenParams = {
	open: boolean, for?: 'upload' | 'download' | 'help'
}
type Props = {
	rightDrawerOpen: OpenParams,
	setRightDrawerOpen: (openParams: OpenParams) => void;
	// routeProps: RouteComponentProps
}

export function RightDrawer(props: Props) {
	const classes = useStyles()
	// const [filesUpload, setFilesUpload] = React.useState<File[]>([])
	// const [alert, setAlert] = React.useState<AlertSettings>({ open: false, message: '', color: 'success' });
	// const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
	// 	if (reason === 'clickaway') {
	// 		return;
	// 	}
	// 	setAlert({ open: false, message: '', color: 'success' })
	// 	// setOpenAlert(false)
	// }

	const [status] = useApi({
		apiRequest: getStatus,
		pollingInterval: 15000

	})
	const [start, setStart] = React.useState(false);



	if (status === 'loading' || status == null) {
		return null
	}
	return (<Drawer
		className={classes.drawer}
		variant="persistent"
		anchor="right"
		open={props.rightDrawerOpen.open}
		classes={{
			paper: classes.drawerPaper,
		}}
	>
		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			{props.rightDrawerOpen.for === 'upload' && <StatusLight status={status} start={start} setStart={setStart}></StatusLight>}
			<div> </div>
			<div className={classes.drawerHeader}>

				<IconButton onClick={() => props.setRightDrawerOpen({ open: false })}>
					<CloseIcon />
				</IconButton>
			</div>
		</div>

		<Divider />
		{props.rightDrawerOpen.for === 'upload' && <DocucmentUpload setStart={setStart} />}
		{props.rightDrawerOpen.for === 'download' && < DownloadReport />}
		{props.rightDrawerOpen.for === 'help' && <Help />}
		{/* <DropzoneArea
			acceptedFiles={['application/pdf']}
			dropzoneText={"Drag and drop pdf documents here or click to browse"}
			maxFileSize={5000000}
			filesLimit={25}
			showAlerts={false}
			showPreviewsInDropzone={false}
			showFileNamesInPreview={true}
			showPreviews={false}
			clearOnUnmount={true}
			onDrop={(files) => setFilesUpload(files.concat(filesUpload!))}
			dropzoneParagraphClass={classes.dropZoneText}
			previewGridClasses={{ image: classes.dropZoneGirdImage }}
		// Icon={renderDropZoneIcon()}
		/>
		<List className={classes.list}>
			{renderPreviewList()}
		</List>
		<div className={classes.buttonSection}>
			<Button className={classes.approveButton} onClick={() => {
				if (filesUpload) {
					handleSave(filesUpload)
				}
				setFilesUpload([])
			}
			}>
				UPLOAD
			</Button>
			<Button className={classes.rejectButton} onClick={() => setFilesUpload([])}>
				CANCEL
			</Button>
			<Snackbar open={alert.open} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} style={{ marginBottom: '122px', marginRight: '14px' }}>
				<Alert onClose={handleCloseAlert} severity={alert.color}>
					<Typography variant='body2' style={{ color: 'white' }}>{alert.message}</Typography>
				</Alert>
			</Snackbar>
		</div> */}
	</Drawer>)
}