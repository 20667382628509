import jwtDecode from 'jwt-decode'
import * as React from 'react'
import memoize from 'memoizee'
import { JobRole } from 'api/api-types'
import { LOG } from 'logger/Log'

if (!process.env.REACT_APP_ENVIRONMENT) {
	throw Error("Please provide REACT_APP_ENVIRONMENT inside .env")
}

export const TOKEN_KEY = 'ais-mygide-auth-token'
export const DEV_TOKEN_KEY = 'ais-mygide-auth-token-dev'

export type JWTPayload = {
	org: string // organisation id
	jr: string // job role
}

export type JWTDecodedToken = {
	iss: string, // Receive username
	aud: string,
	exp: number,
	iat: number,
	sub: string
} & JWTPayload

export type JWTDecodedTokenSerialized = JWTDecodedToken & { roles: JobRole[] }

const decodeToken = memoize((raw: string): JWTDecodedTokenSerialized => {
	const decoded = jwtDecode<JWTDecodedToken>(raw)
	const roles = decoded.jr.split(",").map(e => {
		switch (e) {
			case "admin": return JobRole.ADMIN
			case "procurement_administrator": return JobRole.PROCUREMENT_ADMIN
			case "approver": return JobRole.APPROVER
			case "analyst": return JobRole.ANALYST
			default:
				throw Error("Cannot serialize job role from token")
		}
	})
	return {
		...decoded,
		roles
	}
})

function getToken() {
	let tokenKey
	if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
		tokenKey = DEV_TOKEN_KEY
	} else {
		tokenKey = TOKEN_KEY
	}
	return localStorage.getItem(tokenKey)
}

function login(raw: string): AuthState {
	let tokenKey
	if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
		tokenKey = DEV_TOKEN_KEY
	} else {
		tokenKey = TOKEN_KEY
	}
	localStorage.setItem(tokenKey, raw)
	LOG.dev("loged in, token - ", raw)
	return { step: 'logedIn', token: decodeToken(raw) }
}

function logout(): AuthState {
	let tokenKey
	if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
		tokenKey = DEV_TOKEN_KEY
	} else {
		tokenKey = TOKEN_KEY
	}
	localStorage.removeItem(tokenKey)
	return { step: 'logedOut' }
}

export const Auth = {
	getToken,
	login,
	logout,
	decodeToken
}

export type AuthState = {
	step: "logedIn"
	token: JWTDecodedTokenSerialized
} | {
	step: "logedOut"
} | {
	step: "loading"
}

export function useAuth() {
	const [authState, setAuthState] = React.useState<AuthState>({
		step: 'loading'
	})

	const [authInterval, setAuthInterval] = React.useState<NodeJS.Timeout | null>(null)

	React.useEffect(() => {
		const token = Auth.getToken()
		if (token) {
			const decoded = Auth.decodeToken(token)
			const isExpired = decoded.exp - (Date.now() / 1000) < 30
			if (isExpired) {
				setAuthState(logout())
			} else {
				setAuthState({ step: 'logedIn', token: decoded })
			}
		} else {
			setAuthState({ step: "logedOut" })
		}
	}, [])

	React.useEffect(() => {
		if (authState.step === 'logedIn') {
			if (!authInterval) {
				const interval = setInterval(() => {
					const token = Auth.getToken()
					const { exp } = Auth.decodeToken(token!)
					const isExpired = exp - (Date.now() / 1000) < 5
					if (isExpired) {
						setAuthState(Auth.logout())
						clearInterval(interval)
						setAuthInterval(null)
					}
				}, 2000)
				setAuthInterval(interval)
			}
		} else {
			if (authInterval) {
				clearInterval(authInterval)
				setAuthInterval(null)
			}
		}
		// Check this out, maybe this is executing every time....
		return () => {
			if (authInterval) {
				clearInterval(authInterval)
				setAuthInterval(null)
			}
		}
	}, [authInterval, authState.step])

	return {
		authState,
		setAuthState
	}
}

export interface AuthContext {
	logout: () => void
	login: (token: string) => void
	getUser: () => JWTDecodedTokenSerialized | null
}

export const AuthContext = React.createContext<AuthContext>(undefined as any)