import { CompanyContract } from "procurement/companyContract/CompanyContract"
import { Suppliers } from "procurement/suppliers/Suppliers"
import { DrawerContext } from "drawer/AppWithDrawer"
import { Container, Page } from "elements/Container"
import { ClientPage } from './clients/Client'
import * as React from "react"
import "styles/feed.css"
// import { Page } from "react-pdf"
import { RouteComponentProps } from "react-router-dom"
import { ProcurementDrawer } from "./ProcurementDrawer"

type RouteParams = {
	sub: "suppliers" | "contractual-suppliers" | "product-catalog" | "clients"
}

export function ProcurementPage(props: RouteComponentProps<RouteParams>) {
	const drawerContext = React.useContext(DrawerContext)
	React.useEffect(() => {
		drawerContext.setRenderContent(() => (
			<ProcurementDrawer {...props} />
		))
	}, [props.location])
	let content
	if (props.match.params.sub === "suppliers") {
		content = <Suppliers />
	} else if (props.match.params.sub === "product-catalog") {
		content = null
	} else if (props.match.params.sub === "contractual-suppliers") {
		content = <CompanyContract />
	} else if (props.match.params.sub === "clients") {
		content = <ClientPage />
	}
	else {
		content = <Suppliers />
	}

	return (
		<Container page={Page.PROCUREMENT} title="Procurement">
			{content}
		</Container>
	)

}
function ProcurementX() {

}
