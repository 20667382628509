import { makeStyles } from '@material-ui/core'
import { AccountingType, Label } from 'api/api-types'
import * as React from 'react'
import * as _ from 'lodash'
import { ItemModalCommon } from './ItemModalCommon'
const useSyles = makeStyles({
	itemLabels: {
		marginTop: 10,
		display: 'flex',
		alignItems: 'center'
	}
})

type Props = {
	labels: AccountingType
	removeLabel: (labels: AccountingType) => void
}

type MapKey = keyof AccountingType

export function ItemLabels(props: Props) {
	const classes = useSyles()

	const [map, setMap] = React.useState<Map<MapKey, (Label<string | null>) | undefined>>(new Map())

	React.useEffect(() => {
		setMap(new Map([
			["groupCode", props.labels.groupCode],
			["groupAlias", props.labels.groupAlias],
			["departmentCode", props.labels.departmentCode],
			["departmentAlias", props.labels.departmentAlias],
			["segmentCode", props.labels.segmentCode],
			["segmentAlias", props.labels.segmentAlias],
			["subsegmentCode", props.labels.subsegmentCode],
			["subsegmentAlias", props.labels.subsegmentAlias],
			["majorCode", props.labels.majorCode],
			["majorAlias", props.labels.majorAlias],
			["minorCode", props.labels.minorCode],
			["minorAlias", props.labels.minorAlias]
		]))
	}, [
		props.labels.departmentAlias,
		props.labels.departmentCode,
		props.labels.groupAlias,
		props.labels.groupCode,
		props.labels.majorAlias,
		props.labels.majorCode,
		props.labels.minorCode,
		props.labels.minorAlias,
		props.labels.segmentCode,
		props.labels.segmentAlias,
		props.labels.subsegmentCode,
		props.labels.subsegmentAlias
	])


	const removeLabel = (toRemoveCode: MapKey, toRemoveAlias: MapKey) => {
		map.delete(toRemoveCode)
		map.delete(toRemoveAlias)

		props.removeLabel({
			groupCode: map.get("groupCode"),
			groupAlias: map.get("groupAlias"),
			departmentAlias: map.get("departmentAlias"),
			departmentCode: map.get("departmentCode"),
			segmentCode: map.get("segmentCode"),
			segmentAlias: map.get("segmentAlias"),
			subsegmentCode: map.get("subsegmentCode"),
			subsegmentAlias: map.get("subsegmentAlias"),
			majorCode: map.get("majorCode"),
			majorAlias: map.get("majorAlias"),
			minorCode: map.get("minorCode"),
			minorAlias: map.get("minorAlias"),
		})
	}
	const getLabel = (label: Label<string | null> | undefined, removeLabel: () => void) => {
		if (!label) return null
		const labelColor = ItemModalCommon.getLabelColor(label)
		return <span
			className={`${labelColor} removable`}
			onClick={() => { removeLabel() }}>
			{label.value}
		</span>

	}
	return <div className={classes.itemLabels}>
		{getLabel(map.get("departmentAlias"), () => removeLabel("departmentCode", "departmentAlias"))}
		{getLabel(map.get("groupAlias"), () => removeLabel("groupCode", "groupAlias"))}
		{getLabel(map.get("majorAlias"), () => removeLabel("majorCode", "majorAlias"))}
		{getLabel(map.get("minorAlias"), () => removeLabel("minorCode", "minorAlias"))}
		{getLabel(map.get("segmentAlias"), () => removeLabel("segmentCode", "segmentAlias"))}
		{getLabel(map.get("subsegmentAlias"), () => removeLabel("subsegmentCode", "subsegmentAlias"))}
	</div>
}