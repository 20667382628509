import { Button } from "@material-ui/core"
import { Item } from "api/api-types"
import * as React from "react"
import Select from 'react-select'
import { archivingPeriod } from "utils/DateTime"

import { useItemTableStyles } from "./useItemTableStyles"
export type InvoiceToolbarProps = {
	filter: "submision" | ""
	items: Item[]
	submit?: (invoiceIds: string[]) => void
} | {
	filter: 'archive'
	refetchArchive?: (range: { month: number, year: number }) => void
}

export function ItemTableToolbar(props: InvoiceToolbarProps) {
	const classes = useItemTableStyles()

	//This state should be in Feed and passed down
	const current = archivingPeriod()
	// if (props.filter === 'archive')
	// props.refetchArchive?.({ month: current.month, year: current.year })
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	const [month, setMonth] = React.useState<{ value: { month: number, year: number }, label: string }>({
		value: { month: current.month, year: current.year },
		label: `${current.year} ${months[current.month - 1]}`
	})
	// if (props.filter === 'archive') {
	// 	console.log('Before fetch here is', { month: current.month, year: current.year })
	// 	props.refetchArchive?.({ month: current.month, year: current.year })
	// 	// setMonth({ value: { month: current.month, year: current.year }, label: `${current.year} ${months[current.month - 1]}` })
	// }

	// Generate past period
	// React.useEffect(() => {
	// 	{
	// 		// console.log('Before fetch here is', { month: current.month, year: current.year })
	// 		// setMonth({ value: { month: current.month, year: current.year }, label: `${current.year} ${months[current.month - 1]}` })
	// 	}
	// }, [])
	// React.useEffect(() => {
	// 	const current = archivingPeriod()
	// 	if (props.filter === 'archive') {
	// 		// props.refetchArchive?.(current)
	// 		setMonth({ value: { month: current.month, year: current.year }, label: `${current.year} ${months[current.month - 1]}` })
	// 	}
	// })
	// Generate rolling year for filters
	function generateMonthFilters(): { month: number, year: number }[] {


		let filters: { month: number, year: number }[] = []
		for (let i = current.month; i > current.month - 12; i--) {
			filters.push({
				month: i > 0 ? i : 12 + i,
				year: i > 0 ? current.year : current.year - 1
			})
		}
		return filters
	}

	// Return options for filter menu
	function monthsOptions() {
		return generateMonthFilters().map((e) => {
			return {
				value: { month: e.month, year: e.year },
				label: `${e.year} ${months[e.month - 1]}`
			}
		})
	}

	if (props.filter === "submision") {
		return <div className={classes.toolbarButtonsContainer}>
			<div style={{ display: 'flex' }}>
				<Button
					disabled={props.items.length === 0}
					className={classes.submitButton}
					onClick={() => {
						props?.submit?.(props.items.map(e => e.itemId))
					}}
				>
					Submit All
				</Button>
			</div>
		</div>
	} else if (props.filter === 'archive') {
		return <div className={classes.toolbarButtonsContainer}>
			<div style={{ display: 'flex' }}>
				{/* {generateMonthFilters()} */}
				<Select
					key="tst4"
					name="Manager"
					options={monthsOptions()}
					value={month}
					onChange={selected => {
						// if (selected != null) {
						// 	if (departmentError.error) {
						// 		setDepartmentError({ error: false, errorMessage: "" })
						// 	}
						// 	setDepartments(selected as { value: OrgDepartment, label: string }[])
						// } else {
						// setDepartments([])
						const tmpSelected = selected as { value: { month: number, year: number }, label: string }
						props.refetchArchive?.(tmpSelected.value)

						setMonth(tmpSelected)
						// }
					}}
					// className={classes.select}

					styles={{
						control: (base, props) => ({
							...base,
							border: 'none',
							boxShadow: 'none'
						}),
						container: (base, props) => ({
							...base,
							width: '112px',
							zIndex: 999
						})
					}}
				/>
			</div>
		</div>
	} else {
		return null
	}
}