import { DateTimeFormatter, Instant, LocalDate, ZoneId } from '@js-joda/core'
import { ApprovalStatus, DocStatusEnum, OriginEnum, } from 'api/api-types'
// import { addTag, postItem, removeTag } from 'api/ApiServices'
// import { ExpandableSection } from 'elements/ExpandableSection'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { ModalX, ModalXBody } from 'elements/ModalX'
import * as _ from 'lodash'
import { UserContext } from 'main/AppWithUser'
import * as React from 'react'
import { useModalStyles } from 'styles/useModalStyle'

import { ArrayInputs, ItemModal, ItemModalCommon, ItemModalParams, Props, State } from './ItemModalCommon'
import { TagsPopover } from './TagsPopover'
import { useItemModalStyles } from './useItemModalStyle'
import { useTranslation } from 'react-i18next';

const DTF = DateTimeFormatter.ofPattern("yyyy-MM-dd")

export const ExtractionErrorModal = React.forwardRef((props: Props, ref: React.Ref<ItemModal>) => {
	const classes = useItemModalStyles()
	const modalClasses = useModalStyles()

	const user = React.useContext(UserContext)
	const [isOpen, setIsOpen] = React.useState(false)
	const [state, setState] = React.useState<State | null>(null)
	const [orderNumberNotApplicable, setOrderNumberNotApplicable] = React.useState(false)
	const [rerender, forceRerender] = React.useState(0)

	const companyNameRef = React.useRef<CustomInput>(null)
	const dateOfIssueRef = React.useRef<CustomInput>(null)
	const deliveryDateRef = React.useRef<CustomInput>(null)
	const dueDateRef = React.useRef<CustomInput>(null)
	const icoRef = React.useRef<CustomInput>(null)
	const invoiceAmountRef = React.useRef<CustomInput>(null)
	const documentNumberRef = React.useRef<CustomInput>(null)
	const ibanRefs = React.useRef<ArrayInputs<string | null>>([])
	const orderNumRefs = React.useRef<ArrayInputs<string | null>>([])

	const { t } = useTranslation();

	const popoverRef = React.useRef<TagsPopover>(null)

	const show = (params: ItemModalParams) => {
		const { labels, docName, itemId, rejectReason, approvalStatus, values, extractionStatus, confidenceLevel, approvers } = params.item
		const iban = labels.supplierGroup.iban
		const orderNumber = labels.documentationGroup.orderNumber
		if (iban.length > 0) {
			ibanRefs.current = iban.map(e => ({
				label: e,
				ref: React.createRef()
			}))
		} else {
			ibanRefs.current = [{
				label: {
					origin: OriginEnum.USER_INPUT,
					value: ""
				},
				ref: React.createRef()
			}]
		}
		if (orderNumber && orderNumber.length > 0) {
			orderNumRefs.current = orderNumber.map(e => ({
				label: e,
				ref: React.createRef()
			}))
		} else {
			orderNumRefs.current = [{
				label: {
					origin: OriginEnum.USER_INPUT,
					value: ""
				},
				ref: React.createRef()
			}]
		}

		const isNotEditable = approvalStatus === ApprovalStatus.APPROVED
			|| approvalStatus === ApprovalStatus.REJECTED || approvalStatus === ApprovalStatus.PENDING_APPROVAL || approvalStatus === ApprovalStatus.RETURNED || params.forSection === 'main'

		setState({
			docName: docName,
			// isNotEditable: isNotEditable,
			itemId: itemId,
			labels: labels,
			values: values,
			rejectedReason: rejectReason,
			approvalStatus: approvalStatus,
			extractionStatus: extractionStatus,
			// Ask about confidence lvl
			confidenceLevel: confidenceLevel ? confidenceLevel : 100,
			approvers: params.showApprovalInfo && approvalStatus !== ApprovalStatus.RETURNED ? approvers : undefined,
			pagination: {
				hasNext: (params.currentIndex + 1) < params.size,
				hasPrev: params.currentIndex > 0
			}
		})

		if (labels.documentationGroup.requestIdStatus && labels.documentationGroup.requestIdStatus.value === DocStatusEnum.NOT_APPLICABLE) {
			setOrderNumberNotApplicable(true)
		} else {
			setOrderNumberNotApplicable(false)
		}
		checkInputErrors()
		setIsOpen(true)
	}

	function checkInputErrors() {
		companyNameRef.current?.checkErrors()
		dateOfIssueRef.current?.checkErrors()
		deliveryDateRef.current?.checkErrors()
		dueDateRef.current?.checkErrors()
		icoRef.current?.checkErrors()
		invoiceAmountRef.current?.checkErrors()
		documentNumberRef.current?.checkErrors()
		ibanRefs.current.forEach(e => e.ref.current?.checkErrors())
		orderNumRefs.current.forEach(e => e.ref.current?.checkErrors())
	}

	async function handleClose() {
		setState(null)
		setOrderNumberNotApplicable(false)
		setIsOpen(false)
		props.refetchInvoices?.()
		props.refetchCounters()
	}

	React.useImperativeHandle(ref, () => ({ show }))

	const paginateNext = ItemModalCommon.paginateNext(show, handleClose)
	const paginatePrev = ItemModalCommon.paginatePrev(show, handleClose)

	// function updateLabel<T>(label: Label<T>, value: T): Label<T> {
	// 	if (label.value === value) {
	// 		return label
	// 	} else {
	// 		return {
	// 			...label,
	// 			value: value,
	// 			origin: OriginEnum.USER_INPUT
	// 		}
	// 	}
	// }

	// const onSubmit = async () => {
	// 	if (!state) {
	// 		return
	// 	}

	// 	const companyNameErrors = companyNameRef.current?.checkErrors()
	// 	const icoErrors = icoRef.current?.checkErrors()
	// 	const deliveryDateErrors = deliveryDateRef.current?.checkErrors()
	// 	const dueDateErrors = dueDateRef.current?.checkErrors()
	// 	const dateOfIssueErrors = dateOfIssueRef.current?.checkErrors()
	// 	const documentNumberErrors = documentNumberRef.current?.checkErrors()
	// 	const invoiceAmountErrors = invoiceAmountRef.current?.checkErrors()

	// 	const ibansErrors = _.some(ibanRefs.current, e => e.ref.current?.checkErrors())
	// 	const orderNumberErrors = !orderNumberNotApplicable && _.some(orderNumRefs.current, e => e.ref.current?.checkErrors())

	// 	if (ibansErrors || orderNumberErrors || companyNameErrors ||
	// 		icoErrors || dateOfIssueErrors || deliveryDateErrors ||
	// 		dueDateErrors || documentNumberErrors || invoiceAmountErrors) {
	// 		return
	// 	} else {
	// 		// Dates
	// 		const dateOfIssueLabel = updateLabel(
	// 			state.labels.datesGroup.dateOfIssue,
	// 			Instant.from(LocalDate.parse(dateOfIssueRef.current!.value, DTF).atStartOfDay().atZone(ZoneId.UTC)).toEpochMilli()
	// 		)
	// 		const deliveryDateLabel = updateLabel(
	// 			state.labels.datesGroup.deliveryDate,
	// 			Instant.from(LocalDate.parse(deliveryDateRef.current!.value, DTF).atStartOfDay().atZone(ZoneId.UTC)).toEpochMilli()
	// 		)
	// 		const dueDateLabel = updateLabel(
	// 			state.labels.datesGroup.dueDate,
	// 			Instant.from(LocalDate.parse(dueDateRef.current!.value, DTF).atStartOfDay().atZone(ZoneId.UTC)).toEpochMilli()
	// 		)

	// 		// Supplier
	// 		const companyNameLabel = updateLabel(
	// 			state.labels.supplierGroup.companyName,
	// 			companyNameRef.current!.value
	// 		)
	// 		const ibanLabel = ibanRefs.current.map((e, i) => {
	// 			const value = e.ref.current!.value
	// 			const label = e.label
	// 			return updateLabel(label, value)
	// 		})
	// 		const icoLabel = updateLabel(
	// 			state.labels.supplierGroup.ico,
	// 			icoRef.current!.value
	// 		)

	// 		// Documentation
	// 		const orderNumberLabel = orderNumRefs.current.map(e => {
	// 			const value = e.ref.current!.value
	// 			const label = e.label
	// 			return updateLabel(label, value)
	// 		})
	// 		// Check only if orderNumberNotApplicable is selected
	// 		// In other case we don't care, it will check orderNumber value
	// 		const requestIdStatusLabel: () => Label<DocStatusEnum> = () => {
	// 			const requestIdStatus = state.labels.documentationGroup.purchaseOrderIdStatus
	// 			if (orderNumberNotApplicable) {
	// 				return updateLabel(requestIdStatus, DocStatusEnum.NOT_APPLICABLE)
	// 			} else {
	// 				return requestIdStatus
	// 			}
	// 		}
	// 		const documentNumberLabel = updateLabel(
	// 			state.labels.documentationGroup.documentNumber,
	// 			documentNumberRef.current!.value
	// 		)

	// 		// Values
	// 		const invoiceAmountLabel = updateLabel(
	// 			state.values.invoiceAmount,
	// 			parseFloat(invoiceAmountRef.current!.value)
	// 		)
	// 		console.log("PARAAAAMS", {
	// 			...state.labels,
	// 			datesGroup: {
	// 				...state.labels.datesGroup,
	// 				dateOfIssue: dateOfIssueLabel,
	// 				deliveryDate: deliveryDateLabel,
	// 				dueDate: dueDateLabel
	// 			},
	// 			supplierGroup: {
	// 				...state.labels.supplierGroup,
	// 				companyName: companyNameLabel,
	// 				iban: ibanLabel,
	// 				ico: icoLabel
	// 			},
	// 			documentationGroup: {
	// 				...state.labels.documentationGroup,
	// 				orderNumber: orderNumberLabel,
	// 				requestIdStatus: requestIdStatusLabel(),
	// 				documentNumber: documentNumberLabel
	// 			},
	// 			accountingGroup: state.labels.accountingGroup
	// 		}, {
	// 			invoiceAmount: invoiceAmountLabel
	// 		})
	// 		const response = await postItem(state.itemId, {
	// 			...state.labels,
	// 			datesGroup: {
	// 				...state.labels.datesGroup,
	// 				dateOfIssue: dateOfIssueLabel,
	// 				deliveryDate: deliveryDateLabel,
	// 				dueDate: dueDateLabel
	// 			},
	// 			supplierGroup: {
	// 				...state.labels.supplierGroup,
	// 				companyName: companyNameLabel,
	// 				iban: ibanLabel,
	// 				ico: icoLabel
	// 			},
	// 			documentationGroup: {
	// 				...state.labels.documentationGroup,
	// 				orderNumber: orderNumberLabel,
	// 				requestIdStatus: requestIdStatusLabel(),
	// 				documentNumber: documentNumberLabel
	// 			},
	// 			accountingGroup: state.labels.accountingGroup
	// 		}, {
	// 			invoiceAmount: invoiceAmountLabel
	// 		})

	// 		if (response._type === 'generic_success') {
	// 			props.paginate(state.itemId, paginateNext)
	// 		} else {
	// 			// Hndle errors
	// 			console.log(response)
	// 		}
	// 	}
	// }

	// function renderCustomInputs<T>(
	// 	inputs: ArrayInputs<T>,
	// 	placeholder: string,
	// 	label: string,
	// 	key: string,
	// 	disabled: boolean,
	// 	renderDisableCheckbox?: () => React.ReactNode
	// ) {
	// 	return inputs.map((e, i) => {
	// 		const addIcon = !disabled && <AddIcon style={{ cursor: 'pointer' }} onClick={() => {
	// 			inputs.push({
	// 				label: {
	// 					origin: OriginEnum.USER_INPUT,
	// 					value: undefined as any
	// 				},
	// 				ref: React.createRef()
	// 			})
	// 			// Forcing rerender
	// 			forceRerender(rerender + 1)
	// 		}} />

	// 		let removeIcon
	// 		if (i !== 0) {
	// 			removeIcon = <RemoveIcon
	// 				style={{
	// 					cursor: 'pointer'
	// 				}}
	// 				onClick={() => {
	// 					inputs.pop()
	// 					forceRerender(rerender + 1)
	// 				}} />
	// 		} else {
	// 			removeIcon = null
	// 		}

	// 		return (
	// 			<div key={`${key}-input-${i}`} style={{ display: 'flex', alignItems: 'center' }}>
	// 				<CustomInput
	// 					ref={e.ref}
	// 					label={`${inputs.length > 1 ? `#${i + 1}` : ""} ${label}`}
	// 					errorText=""
	// 					placeholder={placeholder}
	// 					required={!disabled}
	// 					type="text"
	// 					showRequiredOnMount={true}
	// 					disabled={disabled}
	// 					startingValue={`${e.label.value}`}
	// 					shrink={true}
	// 					endAndorment={<div style={{
	// 						display: 'flex',
	// 						flexDirection: 'row',
	// 						padding: 5
	// 					}}>
	// 						{removeIcon}
	// 						{addIcon}
	// 					</div>}
	// 				/>
	// 				{renderDisableCheckbox && renderDisableCheckbox()}
	// 			</div>
	// 		)
	// 	})
	// }

	// function formatDateFromEpoch(epochMilis: number | null) {
	// 	if (epochMilis != null) {
	// 		return LocalDate.ofInstant(Instant.ofEpochMilli(epochMilis)).format(DTF)
	// 	} else {
	// 		return null
	// 	}
	// }


	// function expandableSectionIsOpen() {

	// 	switch (state?.approvalStatus) {
	// 		case ApprovalStatus.REJECTED:
	// 			return false
	// 		case ApprovalStatus.PENDING_APPROVAL:
	// 			return false
	// 		default:
	// 			if (state?.approvers)
	// 				return false
	// 			return true
	// 	}
	// }


	// const renderButtonContent = () => {
	// 	let content
	// 	if (state!.pagination.hasNext) {
	// 		content = <>SAVE & NEXT <ArrowForwardIcon /></>
	// 	} else {
	// 		content = "SAVE & CLOSE"
	// 	}
	// 	return content
	// }

	if (!state) {
		return null
	}

	return <ModalX
		isOpen={isOpen}
		onClose={handleClose}
	>
		{/* <ModalXHeader style={{ backgroundColor: 'white' }}>
			<div className={classes.headerLabels}>
				<span className={classes.headerText}>{state.labels.inputGroup.documentType.value}</span>
				{ItemModalCommon.renderHeaderLabels(
					state.approvalStatus,
					state.extractionStatus,
					state.confidenceLevel,
					state.labels.documentationGroup.requestIdStatus.value,
					state.labels.documentationGroup.purchaseOrderIdStatus.value
				)}
			</div>
		</ModalXHeader> */}
		{/* Key is used to trigger rerender on paginate, pagination is making inputs go wild */}
		<Form key={state.itemId} onSubmit={() => { }} pure={true}>
			<ModalXBody style={{
				flexDirection: 'row',
				backgroundColor: '#ededed',
			}}>
				<div className={classes.section}>
					<div className={classes.content}>
						{ItemModalCommon.renderDocument(state, classes.documentContainer)}
						{/* {renderForm(state)} */}
					</div>
					{/* {ItemModalCommon.renderPagination(
						state,
						props,
						paginatePrev,
						paginateNext,
						classes.pagination,
						classes.paginationButton,
					)} */}
				</div>

			</ModalXBody>
		</Form>
		<TagsPopover ref={popoverRef} />
	</ModalX>
})