import { CustomError } from "main/AppWithErrors"

export enum UiViews {
	CONTROL_BOARD = 'CONTROL_BOARD', // ALL-ALL
	EXTRACTION_ALL = 'EXTRACTION_ALL', // ALL-OPEN/REVERTED/MISSING_DOC
	ATTENTION_REVERTED = 'ATTENTION_REVERTED', // ATTENTION-ALL READY-REVERTED
	MISSING_DOCUMENTATION = 'MISSING_DOCUMENTATION', // ALL-MISSING_DOCUMENTATION
	DUPLICATE = 'DUPLICATE', // DUPLICATE-ALL
	CLASSIFIED = 'CLASSIFIED', // CLASSIFIED
	CONFLICTING = 'CONFLICTING', // ERRORED
	READY_FOR_SUBMISION = 'READY_FOR_SUBMISION', // READY-READY_FOR_SUBMISION
	APPROVALS_ALL = 'APPROVALS_ALL',
	APPROVED = 'APPROVED', // READY-APPROVED
	REJECTED = 'REJECTED', // READY-REJECTED
	ON_HOLD = 'ON_HOLD', // ALL-ON_HOLD
	RETURNED = 'RETURNED', // ALL-RETURNED
	PENDING_APPROVAL = 'PENDING_APPROVAL', // READY-PENDING_APPROVAL
	READY_FOR_BOOKING = 'READY_FOR_BOOKING' // READY-PENDING_APPROVAL

}

export type DemoRecord = {
    companyName: string,
    iban?: string[],
    ico: string,
    currency: string,
    deliveryDate: string,
    dateOfIssue: string,
    dueDate: string,
    orderNumber?: string[],
    documentNumber: string,
    invoiceAmount: string
}

export type Supplier = {
	compIco: string
	dic: string
	companyName: string
	city: string
	zipCode: string
}

export enum TagsEnum {
	GROUPS = 'Groups',
	DEPARTMENTS = 'Departments',
	MAJORS = 'Majors',
	MINORS = 'Minors',
	SEGMENTS = 'Segments',
	SUBSEGMENTS = 'Subsegments'
}

export type TagType = {
	code: string,
	alias: string,
	// hasUsers: string[] // IDEA: Users that belong to certain tag (Build approval chain)
}

export type TagsType = {
	Groups: TagType[],
	Departments: TagType[],
	Majors: TagType[],
	Minors: TagType[],
	Segments: TagType[],
	Subsegments: TagType[]
}

export type ProcessType = {
	active: boolean,
	expiration: number
}

export type AppProcessesType = {
	EXTRACTION?: ProcessType, // Extraction
	BASIC_STORAGE?: ProcessType, // Basic storage
	OTHER_INPUT_STORAGE?: ProcessType, // Extended storage',
	DOCUMENTATION_PAIRING?: ProcessType, // Documentation pairing',
	FORMS?: ProcessType, // Forms
	DATA_EXPORT?: ProcessType, // Data exports to 3rd parties
	LABELLING?: ProcessType, // Labelling
	APPROVAL?: ProcessType,// Approval process
	BOOKING?: ProcessType, // Booking process
	PAYMENT?: ProcessType, // Payment process
	ANALYTICS?: ProcessType, // Analytics view
	INVENTORY_MGMT?: ProcessType, // Inventory management
	ARCHIVE?: ProcessType, // Inventory management
	SYSTEM_MAINTENANCE?: ProcessType // System maintenance
}

export enum DupSettingsOpts {
	DELETE = 'DELETE',
	ON_HOLD = 'ON HOLD'
}

export enum TrackNumSettingsOpts {
	IMMEDIATE = 'ACCEPTED',
	ON_SEND_FOR_APPROVAL = 'VERIFIED'
}

export enum ApprovalModes {
	BASIC = 'BASIC',
	APPROVAL_GROUPS = 'APPROVAL GROUPS'
}

export type SettingsBody = {
	whenAssignTrackingNum: TrackNumSettingsOpts,
	duplicateItemAction: DupSettingsOpts,
	maxAmountNoItemCheck: number,
	approvalPeriodLimit: number,
	whichApprovalMode: ApprovalModes,
	toAccountDate: number
}

export type DepartmentType = {
	code: string
	name: string
	alias: string | null
	manager?: string | null  // id
	hasEmployees: string[] // usernames
	parent: string | null // Code of parent department
}

export type DepartmentPostType = Omit<DepartmentType, 'hasEmployees'>

export type Organisation = {
	name: string
	orgid: string
	users: [string]
	departments?: [DepartmentType]
	settings: SettingsBody
	tags: TagsType
	createdAt: number
	customerId?: string,
	paymentMethodId?: string,
	// Company Info
	information: CompanyInfo,

}

export enum ExtractionStatus {
	// Not used currently
	//Is not ready an incomplete set? Q for Jorge
	// NOT_READY = 'Not ready',
	// // One step of AI
	// CLASSIFYING = 'Classifying',
	// // Step extracting data
	// EXTRACTING = 'Extracting',
	// // VALIDATING = 'Validating',
	// AI_ERROR = 'AI error',
	// DUPLICATE = 'Duplicate',
	// READY = 'Ready',
	// // Note these are not comming from api
	// // we add them as confinience
	// ATTENTION = 'Attention',
	// CORRECT = 'Correct',
	NOT_READY = 'Not ready', // !isProcess !isError
	CLASSIFIED = 'Classified', // !isProcess !isError
	AI_ERROR = 'AI error', // !isProcess isError
	DUPLICATE = 'Duplicate', // isProcess !isError
	ATTENTION = 'Attention', // isProcess !isError
	READY = 'Ready', // isProcess !isError
	CORRECT = 'Correct'
}


export enum ApprovalStatus {
	MISSING_DOCUMENTATION = "Missing documentation",
	OPEN = 'Open', //
	READY_FOR_SUMBISION = 'Ready for submision', //READY_FOR_SUBMISION 
	PENDING_APPROVAL = 'Pending Approval', //PENDING_APPROVAL
	APPROVED = 'Approved',  //ARCHIVED??????????????
	REJECTED = 'Rejected', //REJECTED
	REVERTED = 'Reverted',
	RETURNED = 'Returned',
	ON_HOLD = 'On hold',
	DELETED = 'Deleted'
}

// Origin of the document to be extracted
export enum sourceEnum {
	MAIL = 'mail',
	SCAN = 'scan',
	PHOTO = 'photo'
}

// Types of document
export enum DocTypesEnum {
	INVOICE = 'Invoice',
	RECEIPT = 'Receipt',
	PROFORMA_INVOICE = 'Proforma Invoice',
	CREDIT_NOTE = 'Credit Note',
	ORDER = 'Order',
	DELIVERY_LIST = 'Delivery List'
}

export enum InvoicerTypesEnum {
	INVOICE = 'Invoice',
	PROFORMA_INVOICE = 'Proforma Invoice',
	CREDIT_NOTE = 'Credit Note',
	ORDER = 'Order',
	DELIVERY_LIST = 'Delivery List',
	// RECEIPT='receipt'
}

export type Client = {
	clientName: string,
	clientAddress: string,
	clientContactName: string,
	clientINO: string,
	clientTINO: string,
	clientINOVAT: string,
	clientNumber?: string,
	clientdeliveryAddress: string,
}

export type InvoicerDoc = {
	docType: DocTypesEnum,
	// My Org info
	companyName: string,
	companyAddress: string,
	companyContactName: string,
	companyINO: string,
	companyTINO: string,
	companyINOVAT: string,
	companyPhone: string,
	companyEmail: string,
	companyRegistar: string,
	companyLogo: string,
	companyWeb: string,
	companyBankAcc: string, // Not present in ORDER and DELIVERY_LIST
	companyBankCode: string, // Not present in ORDER and DELIVERY_LIST
	companyBankSWIFT: string, // Not present in ORDER and DELIVERY_LIST
	companyIBAN: string, // Not present in ORDER and DELIVERY_LIST
	// Client info
	clientName: string,
	clientAddress: string,
	clientContactName: string,
	clientINO: string,
	clientTINO: string,
	clientINOVAT: string,
	clientNumber: string,
	clientdeliveryAddress: string,
	// Documentation
	invoiceNumber: string // Document number
	orderNumber: string | null,
	contractNumber?: number, // Not present in ORDER and DELIVERY_LIST
	specificSymbol?: string, // Not present in ORDER and DELIVERY_LIST
	variableSymbol?: string, // Not present in ORDER and DELIVERY_LIST
	// Dates
	dateOfIssue?: number, // Not present in ORDER and DELIVERY_LIST
	dueDate?: number, // Not present in ORDER and DELIVERY_LIST
	deliveryDate?: number,
	invoicedPeriod?: number, // Not present in ORDER and DELIVERY_LIST
	// Values
	units: number,
	amountExclVAT: number,
	VAT: number,
	amountInclVAT: number,
	// Contents Description
	suppliedGoods?: string, // Major&Minor
	suppliedServices?: string, // Major&Minor
	textField?: string
	VATPercentage: number
}

// Documentation status (Label that defines required infor to process and invoice/document)
export enum DocStatusEnum {
	// Status when invoice does not need documentation
	NOT_APPLICABLE = 'Not applicable',
	// Request sent for finding documentation from third party system
	PENDING = 'Pending',
	// Request failed, not found matching documentation
	REJECTED = 'Rejected',
	// Status on ARRIVAL, Not found or needs to be provided
	UNAVAILABLE = 'Unavailable',
	// This refers to purchaseOrderId and requestOrderId
	SUCCESS = 'Success'
}

// Based on origin UI assigns colors
// AI=BLUE, SYSTEM=BLACK, USER=RED, CONSOLIDATED=GREEN
export enum OriginEnum {
	AI = 'AI',
	USER_MODIFIED = '',
	SYSTEM = 'System', // System data or labels added by the AIS process (i.e. createdAt, input, ...)
	USER_INPUT = 'User', // User modified
	CONSOLIDATED = 'Consolidated', // Cross-checked with source of authority (i.e. gov db, hospital db ...)
	RECOMMENDATION = 'Recommendation'
}

export type Label<T> = ({
	value: T
	origin: OriginEnum
	ocrValue?: string
	accuracy?: boolean
	comments?: string
})

export type InputType = {
	source: Label<sourceEnum>,
	senderEmail?: Label<string>,
	destinationEmail?: Label<string>,
	documentType?: Label<DocTypesEnum>, // Invoice, Receipt, ...
	documentFormat: Label<string>, // PDF, JPG, ...
	companyINO?: Label<string>, // ICO of company (Mandatory on items created in tool!)
	clientINO?: Label<string> // ICO of client (Mandatory on items created in tool!)
}

export type DateType = {
	// Processing
	receivedAt: Label<number>,
	classifiedAt?: Label<number>,
	extractedAt: Label<number>,
	verifiedAt?: Label<number>,
	// processDuring?: Label<number>,
	// queuedDuring?: Label<number>,
	// Extraction 
	deliveryDate: Label<number | null>,
	dateOfIssue: Label<number | null>
	dueDate: Label<number | null>
}

export type SupplierType = {
	companyName: Label<string | null>,
	iban: Label<string | null>[],
	ico: Label<string | null>
}

export type LocalizationType = {
	language: Label<string>,
	currency: Label<string>,
	countryOrigin?: Label<string>,
	countryDestination?: Label<string>
}

export type DocumentationType = {
	orderNumber?: Label<string | null>[], // To remove and use only PurchaseOrderId
	documentNumber?: Label<string> | null, // Or invoiceNumber
	contractNumber?: Label<string>,
	specificSymbol?: Label<string>,
	variableSymbol?: Label<string>,
	requestId?: Label<string>[],
	requestIdStatus?: Label<DocStatusEnum>,
	purchaseOrderId?: Label<string>[],
	purchaseOrderIdStatus?: Label<DocStatusEnum>,
	suppliedGoods?: Label<string>,
	suppliedServices?: Label<string>,
	textField?: Label<string>
}

export type AccountingType = {
	groupCode?: Label<string | null>
	groupAlias?: Label<string | null>
	departmentCode?: Label<string | null>
	departmentAlias?: Label<string | null>
	segmentCode?: Label<string | null>
	segmentAlias?: Label<string | null>
	subsegmentCode?: Label<string | null>
	subsegmentAlias?: Label<string | null>
	majorCode?: Label<string | null>
	majorAlias?: Label<string | null>
	minorCode?: Label<string | null>
	minorAlias?: Label<string | null>
}

export type LabelGroupsType = {
	inputGroup: InputType
	datesGroup: DateType
	supplierGroup: SupplierType
	localizationGroup: LocalizationType
	documentationGroup: DocumentationType
	accountingGroup: AccountingType
}

export type ValuesType = {
	invoiceAmount?: Label<number>
	units?: Label<number>
	amountExclVAT?: Label<number>
	VAT?: Label<number>
	amountInclVAT?: Label<number>
}

export type Counters = {
	CONTROL_BOARD: number
	EXTRACTION_ALL: number
	ATTENTION_REVERTED: number // ATTENTION-ALL READY-REVERTED
	MISSING_DOCUMENTATION: number // ALL-MISSING_DOCUMENTATION
	DUPLICATE: number // DUPLICATE-ALL
	CLASSIFIED: number // ERRORED
	CONFLICTING: number // ERRORED
	READY_FOR_SUBMISION: number // READY-READY_FOR_SUBMISION
	APPROVALS_ALL: number
	APPROVED: number // READY-APPROVED
	REJECTED: number // READY-REJECTED
	ON_HOLD: number // ALL-ON_HOLD
	RETURNED: number // ALL-RETURNED
	PENDING_APPROVAL: number // READY-PENDING_APPROVAL
}

export type Item = {
	inputId?: string, // DEPRECATED USE, TO BE REMOVED
	itemId: string,
	orgid: string,
	docName: string,
	originalName: string,
	// departmentId: string | null,
	confidenceLevel?: number,
	trackingNumber?: number,
	labels: LabelGroupsType
	values: ValuesType,
	extractionStatus: ExtractionStatus,
	approvalStatus: ApprovalStatus,
	rejectReason?: string,
	returnedReason?: string,
	archived?: boolean,
	archivedCode?: number,
	isRead?: boolean,
	isError?: boolean,
	isProcessed?: boolean,
	approvers?: ApproversType[],
	duplicateReference?: string,
	createdAt: number,
	logo?: string
	approvedByMe?: boolean
}


//  Approvers
export type ApproversType = {
	username: string // TODO String[] for conditional approving !!!!!
	firstName: string
	lastName: string
	approved: boolean
	createdAt: number
	closedAt: number | null // When was approved/rejected
	conditional: boolean
	// standIn: string[] // Array of usernames that are conditional to this one
}

export enum JobRole {
	ADMIN = "admin",
	PROCUREMENT_ADMIN = "procurement_administrator",
	APPROVER = "approver",
	ANALYST = "analyst"
}

export enum DocumentationEnum {
	PURCHASE_ORDER = 'Purchase order',
	PURCHASE_REQUEST = 'Purchase request'
}

export type ApprovalGroupType = {
	createdAt: number
	documentationRequired: DocumentationEnum[] // What documents are needed before sending for approval
	// requestOriginatorsApprove: reqOriginatorsApproveEnum // Whether to add to approvers based on who created request: Originator and originator manager
	conditionalApprovers: ApprovaGroupApprover[] // Add procurement users of the department
	masterApprovers: ApprovaGroupApprover[], // Load organisation users with approvers role
	departmentCode: string,
	departmentName: string,
	departmentAlias: string
	suppliers: Omit<CompanyContract, 'updatedAt'>[]
}

export type Member = {
	company: string
	username: string
	firstName: string
	lastName: string
	title: string
	status: boolean | undefined
	jobRoles: JobRole[]
	verified: boolean
	masterApprover: boolean
	departmentOwner: boolean
	departmentCode?: string,
	departmentName?: string,
	departmentManager?: boolean,
	serialNumber?: string
}


export type UpdateMemberType = {
	username: string,
	firstName: string,
	lastName: string,
	contactMail?: string,
	jobRoles: string[],
	title: string,
	company: string,
	businessUnit?: string,
	serialNumber: string,
	departmentCode?: string,
	departmentName?: string,
}

// If we have error, we expect not to have data, and the oter way around
export type ApiResponse<T> = {
	error: string | null
	data?: T
}

export type ResponseType<T> = {
	_type: 'data',
	data: T
} | {
	_type: 'error',
	error: CustomError
} | {
	_type: 'generic_success'
} | {
	_type: 'generic_error'
	error: CustomError
}

// Data lake

export type Aggregation = {
	value_list_id: number
	aggregation_name: string
	databases_of_aggregation: DatabaseInfoType[]
}

export type DatabaseInfoType = {
	id: number
	name: string
	columns: DbLabelType[]
}

export type DbLabelType = {
	id: number
	name: string
	// Actual type of the label 
	type: string
}

export type AggregationTreeReturnType = {
	request_id: number,
	value: AggregationTree
}

export type AggregationTree = {
	// Reference to Aggregation
	value_list_id: number
	// Name of the referenced Aggregation
	name: string
	nodes: AggregationNode
}

export type AggregationNode = {
	node_id: number
	database_id: number
	row_id: string
	node_caption: string
	parent_node_id: number
	children: AggregationNode[]
}

export type AggregationValue = {
	id: number
	reference_date: string
	value: number
	assigned_tags: number[]
}

export type AggregationMap = {
	index: number
	label: string
}

export type AggregationValues = {
	requestId: number
	value: {
		header: (string | null)[]
		map: AggregationMap[]
		values: [(number | null)[]]
		total_chunks_available: number
	}
}

export type AggregationParams = {
	value_list_id: number
	chunk_offset: number
	chunk_size: number
	// format 1-1-1
	date_from?: string
	date_to?: string
	node_id: number
}
export type VerifiedSuppliers = {
	ico: string
	dic: string
	name: string
	city: string
	zipCode: string
	contracts: string[]
}

export type approvalGroupPostType = {
	documentationRequired: string[],
	conditionalApprovers: ApprovaGroupApprover[],
	masterApprovers: ApprovaGroupApprover[],
	departmentCode: string
	suppliers: Omit<CompanyContract, 'updatedAt'>[]
}

export type CompanyContract = {
	updatedAt: number,
	contractNumber: string,
	ico: string,
	companyName: string,
}

export type ApprovaGroupApprover = {
	username: string,
	firstName: string,
	lastName: string
}

export type Card = {
	number: string,
	exp_month: number,
	exp_year: number,
	cvc: string
}
export type PaymentDetails = {
	address: StripeAddress,
	email: string,
	name: string,
	phone?: string
}

export type StripeAddress = {
	city: string
	country: string
	line1: string
	line2?: string
	postal_code: string
	state: string
}

export type BillingDetails = {
	billing: BillingType,
	invoiceCount: number
}

export type BillingType = {
	fullName: string | null
	stripeCustomerId: string | null
	stripeSubscriptionId: string | null
	stripePaymentMethodId: string | null
	stripePaymentMethodList: string[]
	stripePriceId: string[] | null
	billingEmail: string | null
	paymentStatus: PaymentStatusEnum
	subscription: StripeSubscriptionEnum // Initialize with free plan
	activeServices: ServEnum[]
	address: StripeAddress
	metadata: {
		companyName: string,
		companyId: string,
		companyVAT: string,
		companyPhone: string
	}
	// subscriptionEnds: number | null
	// subscriptionEnds: number | null
} | null

export enum ServEnum {
	EXTRACTION = 'Extraction', // IMPORTANT
	// BASIC_STORAGE = 'Basic storage', // IMPORTANT
	// OTHER_INPUT_STORAGE = 'Extended storage',
	DOCUMENTATION_PAIRING = 'Documentation pairing', // IMPORTANT
	// FORMS = 'Forms',
	// DATA_EXPORT = 'Data exports to 3rd parties',
	// LABELLING = 'Labelling',
	APPROVAL = 'Approval process', // IMPORTANT
	BOOKING = 'Booking process',
	// SUPPLIERS = 'Suppliers',
	// PAYMENT = 'Payment process',
	// ANALYTICS = 'Analytics view',// IMPORTANT
	ARCHIVE = 'Archive',
	// INVENTORY_MGMT = 'Inventory management',
	// SYSTEM_MAINTENANCE = 'System maintenance'
}

export enum StripePlansEnum {
	MONTHLY = 'Monthly',
	QUATERLY = 'Quaterly',
	ANNUALY = 'Annualy'
}

export enum StripeProductEnum {
	// Prod Name in our app
	FREEPLAN = 'Free plan',
	TESTPLAN = 'Test plan',
	AISS = 'SME plan',
	AISM = 'Company plan',
}

export enum PaymentStatusEnum {
	FREE = 'Free',
	ACTIVE = 'Active',
	FAILED = 'Failed',
}

export enum InvoiceMonthlyAllowance {
	INVOICES50 = 50,
	INVOICES200 = 200,
	INVOICES500 = 500,
	INVOICES1000 = 1000
}

export type StripePaymentDetails = {
	id: string,
	billingDetails: {
		address: StripeAddress,
		email: string | null,
		name: string | null,
		phone: string | null
	},
	card: {
		last4: string
		brand: string
		country: string,
		exp_month: number,
		exp_year: number,
	},
	metadata: {
		companyName: string | null,
		companyId: string | null,
		companyVAT: string | null
	}
}

export type StripeProduct = {
	name: StripeProductName
	service: ServEnum
	stripeProductId: string
	stripePriceId: string
	currency: string
}

export type stripeInvoice = {
	billing_reason: string | null,
	amount_paid: number | null,
	amount_due: number | null,
	currency: string | null,
	created: number | null,
	invoice_pdf: string | null,
	invoiceNr: string | null
}

export type InvoiceHistory = {
	month: number,
	count: number
}

export type Status = {
	processing: number | null,
	new: boolean
}
export enum StripeSubscriptionEnum {
	// Prod Name in our app
	FREEPLAN = 'Free plan',
	ACTIVE = 'Active subscription'
}

export enum StripePriceScale {
	LAYER_50 = 0.25,
	LAYER_250 = 0.225,
	LAYER_500 = 0.2,
	LAYER_750 = 0.175,
	LAYER_1000 = 0.15,
	LAYER_1250 = 0.125,
	LAYER_1500 = 0.1,
	LAYER_1750 = 0.075,
	LAYER_2000 = 0.05
}
export enum StripeProductName {
	AIS_ARCHIVE = 'AIS Archive',
	AIS_BOOKING = 'AIS Booking',
	AIS_APPROVAL = 'AIS Approval',
	AIS_CONNECTOR = 'AIS Connector',
	AIS_EXTRACTOR = 'AIS Extractor'
}

export type CompanyInfo = {
	companyName: string,
	companyAddress: string,
	companyContactName: string,
	companyINO: string,
	companyTINO: string,
	companyINOVAT: string,
	companyPhone: string,
	companyEmail: string,
	companyRegistar: string,
	companyLogo: string,
	companyWeb: string,
	companyBankAcc: string,
	companyBankCode: string,
	companyBankSWIFT: string,
	companyIBAN: string
}