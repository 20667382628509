import { ListItem, ListItemIcon, ListItemText, } from '@material-ui/core'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import WorkIcon from '@material-ui/icons/Work';
import { useTranslation } from 'react-i18next';




export function ProcurementDrawer(props: RouteComponentProps) {
	const drawerClasses = useDrawerStyle()
	const { t } = useTranslation();

	return (
		<>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/procurement/suppliers")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/procurement/suppliers'}

			>
				<ListItemIcon className={drawerClasses.itemIcon}><LibraryAddCheckIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/procurement/suppliers' && drawerClasses.active}`} primary={t('Procurement.Ver_Suppliers')} />
			</ListItem>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/procurement/contractual-suppliers")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/procurement/contractual-suppliers'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><LibraryBooksIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/procurement/contractual-suppliers' && drawerClasses.active}`} primary={t('Procurement.Contr_Suppliers')} />
			</ListItem>


			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/procurement/clients")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/procurement/clients'}

			>
				<ListItemIcon className={drawerClasses.itemIcon}><LibraryAddCheckIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/procurement/suppliers' && drawerClasses.active}`} primary={'Clients'} />
			</ListItem>

			<ListItem button disabled={true} className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/procurement/product-catalog")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/procurement/product-catalog'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><WorkIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/procurement/product-catalog' && drawerClasses.active}`} primary={t('Procurement.Prod_Catalog')} />
			</ListItem>




		</>
	)
}