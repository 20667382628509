import { Container, Page } from 'elements/Container'
//To be deleted if filters get out
import { AppWithFilters } from 'main/AppWithFiltersNew'
import { AppWithItems } from 'main/AppWithItems'
import * as React from 'react'
import 'styles/feed.css'
import { Feed } from './Feed'

export function FeedPage() {
	return (
		<Container page={Page.FEED} title="Feed">
			{/* <AppWithItems>
				
			</AppWithItems> */}
			<AppWithFilters>
				<Feed />
			</AppWithFilters>
		</Container>
	)
}
