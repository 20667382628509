import { Checkbox, makeStyles, Typography, Theme, createStyles, Paper, Box, Button, Divider, Snackbar } from '@material-ui/core'
import Slider from '@material-ui/core/Slider';
import { ServEnum, StripePriceScale, StripeProduct, StripeProductName } from 'api/api-types'
import { createSubscription, deleteSubscription, getBillingDetails } from 'api/ApiServices';
import { useApi } from 'api/useApi';
import { Alert, AlertSettings } from 'elements/Alerts';
import * as React from 'react'
import { useTranslation } from 'react-i18next';
import { stripePricing, stripeProducts } from './payment/products';

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		row: {
			display: 'flex',
		},
		column: {
			display: 'flex',
			flexDirection: 'column'
		},
		item: {
			width: '35%',
			flexGrow: 1
		},
		serviceTitle: {
			width: '80%'
		},
		checkBox: {
			// transform: 'scale(0.75)'
		},
		sliderRoot: {
			color: "#08ad6c",

		},
		track: {
			height: 8,
			borderRadius: 8
		},
		rail: {
			height: 8,
			borderRadius: 8
		},
		thumb: {
			width: '18px',
			height: '18px',
			display: 'flex',
			outline: 0,
			position: 'absolute',
			boxSizing: 'border-box',
			marginTop: '-5px',
			transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			alignItems: 'center',
			marginLeft: '-6px',
			borderRadius: '50%',
			justifyContent: 'center',
			backgroundColor: 'currentColor',
		},
		valueLabel: {
			left: 'calc(-50% - -2px)'
		},
		mark: {
			height: 8,
		},
		textGrey: {
			color: 'rgba(0, 0, 0, 0.24)'
		},
		grey: {
			color: 'rgba(0, 0, 0, 0.87)'
		}
	})
)

export function Pricing() {
	const classes = useStyle()
	const [services, setServices] = React.useState<ServEnum[]>([ServEnum.EXTRACTION])
	const [numOfDocs, setNumOfDocs] = React.useState<number>(500)
	const [perDoc, setPerDoc] = React.useState<number>(0.25)
	const [pricingPreview, setPricingPreview] = React.useState<{ discount: number, chargePerDoc: number, monthlyCharge: number }>({ discount: 0, chargePerDoc: 50, monthlyCharge: 0 })
	const [price, setPrice] = React.useState<number>(0)
	const [currentProduct, setCurrentProduct] = React.useState<StripeProduct[]>([{
		name: StripeProductName.AIS_EXTRACTOR,
		stripePriceId: 'price_1JMTt8KgM6Q7jDnM91hpI2S5',
		stripeProductId: 'prod_K0UsdBuSctnfY7',
		service: ServEnum.EXTRACTION,
		currency: 'eur'
	}])
	const [billingDetails, refetch] = useApi({
		apiRequest: getBillingDetails
	})
	const [alert, setAlert] = React.useState<AlertSettings>({ message: '', open: false, color: 'success' })
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ ...alert, open: false })
	}

	React.useEffect(() => {
		if (billingDetails !== 'loading') {
			if (billingDetails?.billing?.subscription != null) {

				const currentProducts = stripeProducts.filter(e => billingDetails?.billing?.stripePriceId?.includes(e.stripePriceId))
				if (currentProduct) {
					setCurrentProduct(currentProducts)
				}
				const chargePerDoc = (0.25 * billingDetails?.billing?.activeServices.length) - ((0.25 * billingDetails?.billing?.activeServices.length) * 0.14)
				setServices([...billingDetails?.billing?.activeServices])
				setPricingPreview({ discount: 14, chargePerDoc: chargePerDoc, monthlyCharge: chargePerDoc * 500 })
			}

		}

	}, [billingDetails])

	const { t } = useTranslation();

	type Key = {
		key: "DOCUMENTATION_PAIRING" | "APPROVAL" | "BOOKING" | "ARCHIVE"
	}
	function renderService(service: ServEnum, description: string, disabled?: boolean) {
		return <><div className={classes.row} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
			<Checkbox name="checkbox1" className={classes.checkBox} checked={services.includes(service)} disabled={disabled} onChange={(e) => {
				if (services.includes(service)) {

					const tmpPricing = stripePricing.find(e => e.numberOfDocs === numOfDocs)
					if (tmpPricing) {
						// const index = services.indexOf(service)
						// console.log('INDEX OF ', index)
						const tmpServices = [...services].filter(e => e !== service)
						setPricingPreview({ ...pricingPreview, chargePerDoc: (0.25 * tmpServices.length) - ((0.25 * tmpServices.length) * (tmpPricing.discount / 100)), monthlyCharge: ((0.25 * tmpServices.length) - ((0.25 * tmpServices.length) * (tmpPricing.discount / 100))) * tmpPricing.numberOfDocs })
						setServices(tmpServices)
						// console.log('This is Charge Per doc', pricingPreview.chargePerDoc)
					}

				} else {
					const tmpPricing = stripePricing.find(e => e.numberOfDocs === numOfDocs)

					if (tmpPricing) {
						// console.log('This is Charge Per doc', pricingPreview.chargePerDoc)
						// console.log('INCLUDES DID NOT PASS')
						const tmpServices = [...services, service]
						setPricingPreview({ ...pricingPreview, chargePerDoc: (0.25 * tmpServices.length) - ((0.25 * tmpServices.length) * (tmpPricing.discount / 100)), monthlyCharge: ((0.25 * tmpServices.length) - ((0.25 * tmpServices.length) * (tmpPricing.discount / 100))) * tmpPricing.numberOfDocs })
						setServices([...services, service])
					}

				}
				// let count = 0
				// for (const [key, value] of Object.entries(processes!)) {
				// 	console.log(`${key}: ${value}`);
				// 	if (value?.active === true) {
				// 		count++
				// 	}
				// }
				// console.log('Procceses num: ', count);
				// setProccesNum(count)
				// setProcesses({ ...processes!, [key.key]: { expiration: 2, active: e.target.checked } })
				// if (processes?.[key.key]?.active) {
				// 	console.log('Active')
				// 	setSelectedProduct(stripeProducts.find(e => (e.recurringInterval === paymentPlan && e.code === `${plan}${count + 1}`))!)
				// }
				// else {
				// 	setSelectedProduct(stripeProducts.find(e => (e.recurringInterval === paymentPlan && e.code === `${plan}${count - 1}`))!)

				// 	console.log('Not Active')
				// }
			}} />
			<div className={classes.column} style={{ width: '76%' }}>
				<Typography variant='subtitle1' className={services.includes(service) ? classes.serviceTitle : `${classes.textGrey} ${classes.serviceTitle}`}>{service === ServEnum.DOCUMENTATION_PAIRING ? t('Connector') : t(service)}</Typography>
				<Typography variant='body2' style={{ width: '80%', marginBottom: '16px' }} className={services.includes(service) ? '' : `${classes.textGrey}`} >{description}</Typography>
			</div>

			<div style={{ paddingRight: '32px' }}>
				<div className={classes.row}>
					<Typography variant='body2' className={services.includes(service) ? '' : `${classes.textGrey}`}>€</Typography>
					<Typography variant='h5' style={{ paddingRight: '16px' }} className={services.includes(service) ? '' : `${classes.textGrey}`}>0,25</Typography>
				</div>
				<Typography variant='body2' style={{ paddingLeft: '40px', lineHeight: 0 }} className={services.includes(service) ? '' : `${classes.textGrey}`}>per doc</Typography>
			</div>
		</div>

		</>

	}
	const marks = [
		{
			value: 50,
			// label: '50',
		},
		{
			value: 250,
			// label: '250',

		},
		{
			value: 500,
			// label: '500',


		},
		{
			value: 750,
			// label: '750',


		},
		{
			value: 1000,
			// label: '1,000',


		},
		{
			value: 1250,
			// label: '1,250',


		},
		{
			value: 1500,
			// label: '1,500',

		},
		{
			value: 1750,
			// label: '1,750',


		},
		{
			value: 2000,
			// label: '2,000',

		},
	]
	function valuetext(value: number) {
		return `${value * 10}`;
	}

	async function subscribe() {
		if (billingDetails !== 'loading' && billingDetails != null) {
			const priceIds = stripeProducts.filter((e) => {
				if (services.includes(e.service))
					return e.stripePriceId
			})
			if (billingDetails?.billing?.stripeCustomerId == null) {
				setAlert({ open: true, message: t("Alert.Missing_Billing"), color: 'warning' })
				return
			}
			if (billingDetails?.billing?.stripeSubscriptionId != null) {

				const response = await createSubscription(billingDetails?.billing?.stripeCustomerId!, priceIds.map(e => e.stripePriceId), billingDetails?.billing?.stripeSubscriptionId)
				if (response._type === 'generic_success') {
					// console.log(response)
					setAlert({ open: true, message: t("Alert.Subscription_Updated"), color: 'success' })
				} else {
					setAlert({ open: true, message: t("Alert.Subscription_Failed"), color: 'warning' })
					// console.log('Error creating subscription')
				}
			} else {
				const response = await createSubscription(billingDetails?.billing?.stripeCustomerId!, priceIds.map(e => e.stripePriceId))
				if (response._type === 'generic_success') {
					setAlert({ open: true, message: t("Alert.Subscription_Created"), color: 'success' })
					// console.log(response)
				} else {
					setAlert({ open: true, message: t("Alert.Subscription_Cre_Failed"), color: 'warning' })
				}
			}

		}
	}
	async function deleteSubscrioption() {
		if (billingDetails !== 'loading' && billingDetails != null && billingDetails?.billing?.stripeSubscriptionId != null) {
			const response = await deleteSubscription(billingDetails.billing.stripeSubscriptionId)
			if (response._type === 'generic_success') {
				refetch()
				setAlert({ open: true, message: t("Alert.Subscription_Del"), color: 'success' })
				// console.log('', response)
			} else {
				refetch()
				setAlert({ open: true, message: t("Alert.Subscription_del_Fail"), color: 'warning' })
			}
		}

	}

	return (<div className={classes.row} style={{ width: '100%' }}>
		<Paper className={`${classes.column} ${classes.item}`} style={{ marginRight: 16, padding: '16px', minHeight: '450px', width: '50%' }}>
			<Typography variant='h6' style={{ paddingBottom: '16px' }}>Services</Typography>
			{renderService(ServEnum.EXTRACTION, t("Subscription_Extraction_Desc"))}
			{renderService(ServEnum.APPROVAL, t("Subscription_Approval_Desc"))}
			{renderService(ServEnum.DOCUMENTATION_PAIRING, t("Subscription_Connector_Desc"), true)}
			{renderService(ServEnum.BOOKING, t("Subscription_Booking_Desc"), true)}
			{renderService(ServEnum.ARCHIVE, t("Subscription_Archive_Desc"), true)}
			<Divider style={{ marginTop: 16 }}></Divider>
			<div className={classes.row} style={{ justifyContent: 'space-between', alignItems: 'center', marginLeft: '64px', paddingTop: '32px' }}>
				<Typography variant='h6'>Total per processed document</Typography>
				<div style={{ paddingRight: '32px' }}>
					<div className={classes.row}>
						<Typography variant='body2'>€</Typography>
						{/* <Typography variant='h5' style={{ paddingRight: '16px' }}>{(services.length * 0.25)}</Typography> */}
						<Typography variant='h5' style={{ paddingRight: '16px' }}>{(services.length * 0.25).toFixed(2)}</Typography>
					</div>
					<Typography variant='body2' style={{ paddingLeft: '40px', lineHeight: 0 }}>per doc</Typography>
				</div>

			</div>
			<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
				<Button variant='contained' color='primary' onClick={subscribe} style={{ margin: 'auto', minWidth: 200, marginTop: '32px', marginBottom: '32px' }}>Subscribe</Button>
				<Button variant='contained' color='secondary' onClick={deleteSubscrioption} style={{ margin: 'auto', minWidth: 200, marginTop: '32px', marginBottom: '32px' }}>UnSubscribe</Button>
			</div>
		</Paper>
		<Paper className={`${classes.column} ${classes.item}`} style={{ marginRight: 16, padding: '16px', width: '50%', justifyContent: 'space-between' }}>
			<Typography variant="h6" id="discrete-slider-restrict" gutterBottom>
				{t("Subscription_Pay_Go")}
			</Typography>
			<Typography variant='body2' style={{ maxWidth: '600px' }}>{t("Subscription_Pay_Go_Desc")}</Typography>
			{/* <Typography variant='body2' style={{ maxWidth: '600px' }}>{t("Subscription_Pay_Go_Desc_Second")}</Typography> */}
			<Typography variant="h6" style={{ marginTop: 'auto' }} id="discrete-slider-restrict" gutterBottom>
				Estimated number of processed documents
			</Typography>
			<div className={classes.row} style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 'auto', }}>

				<Box style={{ width: '600px', paddingTop: '56px', alignSelf: 'flex-start', paddingRight: '38px', }}   >
					<Slider
						min={50}
						max={2000}
						defaultValue={numOfDocs}
						aria-labelledby="discrete-slider-restrict"
						step={null}
						marks={marks}
						classes={{ root: classes.sliderRoot, track: classes.track, rail: classes.rail, thumb: classes.thumb, valueLabel: classes.valueLabel, mark: classes.mark }}
						getAriaValueText={valuetext}
						valueLabelDisplay="on"
						onChange={(e, value) => {
							const v = value as number
							const tmp = stripePricing.find(e => e.numberOfDocs === v)
							// !00 - (Original Price / currentPrice) - 100 
							let chargePerDoc
							let incrementalCharge
							let monthCharge
							if (tmp) {
								const tmpPricingIndex = stripePricing.indexOf(tmp)
								if (tmpPricingIndex === 0) {
									chargePerDoc = (tmp.price * services.length)
									monthCharge = 12.6 * services.length
									incrementalCharge = 12.6 * services.length

								} else {
									incrementalCharge = (tmp.price * services.length) * (stripePricing[tmpPricingIndex].numberOfDocs - stripePricing[tmpPricingIndex - 1].numberOfDocs - 1)
									// if (numOfDocs < value) {
									// 	monthCharge = pricingPreview.monthlyCharge + incrementalCharge

									// } else {
									// 	monthCharge = pricingPreview.monthlyCharge - incrementalCharge
									// }

									chargePerDoc = parseFloat(((0.25 * services.length) - ((0.25 * services.length) * (tmp.discount / 100))).toFixed(2))
									monthCharge = parseFloat((chargePerDoc * tmp.numberOfDocs).toFixed(2))
									// if (numOfDocs < value) {
									// 	chargePerDoc = (pricingPreview.chargePerDoc + ((stripePricing[tmpPricingIndex].numberOfDocs - stripePricing[tmpPricingIndex - 1].numberOfDocs - 1) * (stripePricing[tmpPricingIndex].price * services.length)) / stripePricing[tmpPricingIndex].numberOfDocs)

									// } else {
									// 	chargePerDoc = (((stripePricing[tmpPricingIndex].numberOfDocs - stripePricing[tmpPricingIndex - 1].numberOfDocs - 1) * (stripePricing[tmpPricingIndex].price * services.length) - pricingPreview.chargePerDoc) / stripePricing[tmpPricingIndex].numberOfDocs)

									// }
									// setPreviousChargePerDoc(chargePerDoc)

								}
								setNumOfDocs(tmp.numberOfDocs)




								setPrice(tmp.price)
								// console.log('this is PricingPreview: ', { chargePerDoc: services.length * tmpPricingIndex.price, discount: tmpPricingIndex.discount, monthlyCharge: services.length * tmpPricingIndex.price })
								setPricingPreview({ chargePerDoc: chargePerDoc, discount: tmp.discount, monthlyCharge: monthCharge })
							}

						}
						}
					//Here also for selected product
					/>

				</Box>
				<div style={{ justifySelf: 'flex-end', width: '20%', textAlign: 'center', alignSelf: 'flex-end' }}>
					<Typography variant="h4">{numOfDocs}</Typography>
					<Typography variant="subtitle1">docs per month</Typography>
				</div>
			</div>
			<div style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '16px', justifySelf: 'flex-end', paddingBottom: '64px', marginBottom: 32 }}>
				<Typography variant="h6" style={{ paddingBottom: '16px' }}>Based on your selection</Typography>
				<div className={classes.row} style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ width: '20%', textAlign: 'center' }}>
						<Typography variant="h4">{pricingPreview.discount} %</Typography>
						<Typography variant="subtitle1" className={classes.grey}>discount</Typography>
					</div>
					{/* Number(213245).toLocaleString("es-ES", {minimumFractionDigits: 2}); */}

					<div style={{ width: '20%', textAlign: 'center' }}>
						<Typography variant="h4">€ {Number(pricingPreview.chargePerDoc).toLocaleString("es-ES", { minimumFractionDigits: 2 })}</Typography>
						<Typography variant="subtitle1" className={classes.grey}>per doc</Typography>
					</div>
					<div style={{ width: '20%', textAlign: 'center' }}>
						<Typography variant="h4">€ {Number(pricingPreview.monthlyCharge).toLocaleString("es-ES", { minimumFractionDigits: 2 })}</Typography>
						<Typography variant="subtitle1" className={classes.grey} >monthly charge</Typography>
					</div>
				</div>
			</div>
			<Snackbar open={alert.open} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				<Alert onClose={handleCloseAlert} severity={alert.color}>
					{alert.message}
				</Alert>
			</Snackbar>
			{/* <Button variant='pr'></Button> */}
			{/* <div className={classes.row} style={{ justifyContent: 'space-between' }}>
				<Typography variant='body2' style={{ paddingTop: 60, paddingLeft: '16px', maxWidth: '40%', fontSize: '1.4rem', color: 'rgba(0, 0, 0, 0.04)' }}>Move slider to expected number of documents processed per month</Typography>
				<div style={{ marginTop: 60, alignSelf: 'flex-end', paddingRight: '32px', marginRight: '38px', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
					<div className={classes.row} style={{ justifyContent: 'space-between', width: '360px', alignItems: 'center', marginBottom: '16px', paddingTop: '32px', paddingLeft: '32px' }}>
						<Typography variant='subtitle1'>Estimated number of documents per month</Typography>
						<Typography variant='body2'>t</Typography>

					</div>
					<div className={classes.row} style={{ justifyContent: 'space-between', width: '360px', alignItems: 'center', marginBottom: '16px', paddingTop: '0px', paddingLeft: '32px' }}>
						<Typography variant='subtitle1'>Base price per document</Typography>
						<Typography variant='body2'>t</Typography>

					</div>
					<div className={classes.row} style={{ justifyContent: 'space-between', width: '360px', alignItems: 'center', marginBottom: '16px', paddingTop: '0px', paddingLeft: '32px' }}>
						<Typography variant='subtitle1'>Discount</Typography>
						<Typography variant='body2'>t</Typography>

					</div>
					<div className={classes.row} style={{ justifyContent: 'space-between', width: '360px', alignItems: 'center', marginBottom: '16px', paddingTop: '0px', paddingLeft: '32px' }}>
						<Typography variant='subtitle1'>Net price per document</Typography>
						<Typography variant='body2'>t</Typography>

					</div>
					<div className={classes.row} style={{ justifyContent: 'space-between', width: '360px', alignItems: 'center', paddingTop: '0px', paddingLeft: '32px', paddingBottom: '32px' }}>
						<Typography variant='subtitle1'>Estimated amount per month*</Typography>
						<Typography variant='body2'>t</Typography>
					</div>
				</div>
			</div>
			<Typography variant='body2' style={{ paddingTop: '56px', paddingLeft: '16px' }}>*The actual charge will be calculated each month based on actual volume of documents processed and respective discounts applied</Typography> */}

		</Paper>
		{/* <Paper className={`${classes.column} ${classes.item}`} style={{ padding: 16 }}>
			<Typography variant="h6" style={{ paddingBottom: '16px' }} id="discrete-slider-restrict" gutterBottom>
				Service Description
			</Typography>
			{renderServiceDesc(ServEnum.EXTRACTION, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac elit elit. Sed nec porttitor sapien.')}
			{renderServiceDesc(ServEnum.DOCUMENTATION_PAIRING, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac elit elit. Sed nec porttitor sapien.')}
			{renderServiceDesc(ServEnum.APPROVAL, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac elit elit. Sed nec porttitor sapien.')}
			{renderServiceDesc(ServEnum.BOOKING, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac elit elit. Sed nec porttitor sapien.')}
			{renderServiceDesc(ServEnum.ARCHIVE, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac elit elit. Sed nec porttitor sapien.')}

		</Paper> */}
	</div >
	)
}