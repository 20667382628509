import { CloudDownload, FileCopy, Print, Share } from '@material-ui/icons'
import { DemoRecord } from 'api/api-types'
import { FormContext } from 'elements/form/Form'
import React from 'react'
import { Spinner } from 'reactstrap'

type Props = {
  result: DemoRecord | null,
  error: string | null
  resetResult: () => void,
  fileLoaded: boolean,
  spinning: boolean
}

const renderArray = (array: string[] | undefined, name: string) => {
  return array?.map((e, index) => {
    return (
      <div className="form-row">
        <span>{name}{array.length > 1 ? ` ${index + 1}` : ""}</span>
        <span id="iban-field">{e}</span>
      </div>
    )
  })
}

export function ResultBox({ result, fileLoaded, resetResult, spinning, error }: Props) {
  const context = React.useContext(FormContext)

  React.useEffect(() => {
    if (!fileLoaded && result) {
      resetResult()
    }
  }, [fileLoaded, resetResult, result])

  let content
  if (context.isLoading || (!result && spinning) ) {
    content = <Spinner size="lg" className="spinner" />
  } else if (result) {
    if (error) {
      content = <span className="api-error">Ooops we are still learning :)</span>
    } else {
      content = (
        <div className="result-table">
          <div className="form-row">
            <span>Company name</span>
            <span>{result.companyName}</span>
          </div>
          {renderArray(result.iban, "IBAN")}
          <div className="form-row">
            <span>Date of issue</span>
            <span>{result.dateOfIssue}</span>
          </div>
          <div className="form-row">
            <span>Due date</span>
            <span>{result.dueDate}</span>
          </div>
          <div className="form-row">
            <span>Delivery date</span>
            <span>{result.deliveryDate}</span>
          </div>
          <div className="form-row">
            <span>Invoice number</span>
            <span>{result.documentNumber}</span>
          </div>
          <div className="form-row">
            <span>Invoice amount</span>
            <span>{result.invoiceAmount}</span>
          </div>
          <div className="form-row">
            <span>ICO</span>
            <span>{result.ico}</span>
          </div>
          {renderArray(result.orderNumber, "ORDER NUMBER")}
        </div>
      )
    }
  } else {
    content = null
  }

  return <div className="result-box">
    <div className="result-box-content">
      {/* <div className="icons-container">
        <FileCopy fontSize="small" className="icons" onClick={() => console.log("copy")} />
        <CloudDownload fontSize="small" className="icons" onClick={() => console.log("donwload")} />
        <Share fontSize="small" className="icons" onClick={() => console.log("share")} />
        <Print fontSize="small" className="icons" onClick={() => console.log("print")} />
      </div> */}
      {content}
    </div>
  </div>
}
