import * as React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		centered: {
			textAlign: 'center'
		},
		paddingBottom: {
			paddingBottom: '16px'
		},
		paddingLeft: {
			paddingLeft: '27px'
		}
	})
)

export function LicenceNTerms() {
	const classes = useStyle()
	// const dateOfIssueRef = React.createRef<DateInput>()
	return <>
		<div className={`${classes.centered}`}>
			<Typography variant="h5" className={classes.paddingBottom}>GENERAL LICENSE TERMS FOR PRODUCTS from GIDE INTERNATIONAL LIMITED</Typography>
			<Typography variant='h6' className={classes.paddingBottom}>Published and effective date 20 March 2021 These terms supersede all previous versions</Typography>
			<Typography variant='body2' className={classes.paddingBottom}>These General License Terms of Use (the "General Terms and Conditions" or just the “Terms”) govern the use of products from GIDE International Limited located at 3rd Floor Chancery House, St Nicholas Way, Sutton, Surrey SM1 1JB, England, United Kingdom, Company Identification Number 5632219 ("GIDE").</Typography>

		</div>


		<Typography variant='h6' className={classes.paddingBottom}>1. LICENSE AND SCOPE OF RIGHTS</Typography>
		<Typography variant='body2' className={classes.paddingBottom} >GIDE products are subject to licensing rights and are not sold.
			Authorized Users who have been granted a license are entitled to non-exclusive access and
			use of these Products solely in compliance with these General Terms and Conditions.
			You may not (and may not allow any third party) to copy, modify, reverse engineer, decompile, disassemble,
			modify or adapt the Product, in whole or in part, or in the (or any part thereof) the source code.
			The User has no right to access the source code of the Products.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Products may utilise third-party solutions or frameworks (MS SQL, MYSQL, MONGO DB, REACT, SYMPHONY, etc.),
			the use of which is governed by their own Licensing terms.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>2. PRODUCTS</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>The Products are MYGIDE, HEYME, AIS and other desktop and cloud software solutions and services published and made available by GIDE or its affiliates.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>3. REPOSITORY</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Some Products provided to users as a "cloud solution" may be installed and operated on GIDE’s servers or third party servers and repositories, the selection of which
			is the sole responsibility of GIDE or its affiliates. Their availability may be limited from time to time.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>4. AUTHORIZED USERS</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Eligible Users are exclusively persons who have been granted and continue to have valid authorized and legitimate access to the Products provided by GIDE, it’s affiliates or its authorized partners and who have simultaneously accepted and comply with these General Terms and Conditions,
			as well as any additional or specific terms relating to individual Products ("Terms").</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>5. INTELLECTUAL PROPERTY RIGHTS</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>All intellectual property rights in the Products, including any registered and unregistered patents, copyrights,
			industrial design rights, trademarks, service marks, trade secrets, know-how, database rights and other rights that are in the nature of intellectual
			property rights (whether registered or unregistered) and all applications for these rights anywhere in the world relating to the Products belong to,
			and the sole owner is and remains GIDE.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>6. CONTENTS</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Content solutions and data are stored in databases and are protected by user passwords.
			Access of GIDE to the contents is limited solely to solve possible technical issues in the Products or to respond to support requests.
			GIDE processes no such content and data for its own purposes nor shares it with any third parties.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>You agree not to upload, post, email, or otherwise transmit any computer routines, files or programmes designed to interrupt,
			destroy or limit the functionality of any computer service or hardware or telecommunications equipment.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Fair usage policy for all free Products applies. GIDE reserves the right to limit use of the Products for misconduct or abusive practices that degrade the performance,
			we regard as excessive use or any similar reasons that may have, directly or indirectly, a negative impact on the use of the Products for other users.</Typography>

		{/* There is a link in here */}
		<Typography variant='h6' className={classes.paddingBottom}>7. PERSONAL DATA</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE collects personal data in a very limited fashion, the name, email or telephone number in particular, solely for the purpose of
			making our Products available to the user.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>The rules of handling personal data are available at https://mygide.com/privacy-policy/</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>8. PUBLICITY</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE reserves the rights to publish on its web or otherwise the fact that the user or the company that the user represents or is employed with, is or was the user of the Product.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>9. PRODUCT UPDATES</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Product updates, including new or patch releases, are made solely at the time and to the extent specified by GIDE.
			Users have no warranties or guarantees of availability, except when the user has signed a separate agreement for the supply and support of products,
			and the scope of these rights specified therein.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>10. FEES AND PAYMENT TERMS</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE's Products are subject to a fee, according to the applicable price list, except in situations where the selected Products are provided for use
			free of charge, temporarily or permanently, at GIDE's sole discretion.
			Fees are stated without value added tax or other similar taxes and fees for which the user is responsible.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>11. WARRANTIES AND LIMITATIONS OF LIABILITY</Typography>
		{/* { indentetto} */}
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>11.1 The user accepts responsibility for the selection of the Products in order to achieve the intended purposes and results.</Typography>
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>11.2 The content created in and through the Products is fully within the competence of the user.</Typography>
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>11.3 Neither GIDE nor its affiliates delivering the Product shall be liable for any loss or damage caused to the user by the use of the Products, whether direct or indirect,
			immediate or consequential, including lost profits, damages resulting from interruption and availability of the Products,
			loss or non-fulfilment of anticipated savings and loss of goodwill, loss of or damage to data, and other similar direct or indirect, immediate or consequential damages.</Typography>
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>11.4 The total liability of GIDE or its affiliates in any matter arising out of or in connection with the use of the Products shall in no circumstances exceed seventy-five percent (75%)
			of the Fees paid by the User in the year in which the liability arose.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>12. LICENSE EXPIRATION</Typography>
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>12.1 User licence begins at the time of confirmation of the user’s successful registration for the access and use of the Products by GIDE or its
			affiliates and expires at the end of the term set forth during the registration process by the user.</Typography>
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>12.2 The User is entitled to revoke the licence and use of the Products at any time, at their sole discretion. Termination of use does not release the user from the obligation to pay
			any amount due or contractually agreed resulting from the use of the Products.</Typography>
		<Typography variant='body2' className={`${classes.paddingBottom} ${classes.paddingLeft}`}>12.3 GIDE, or its affiliate providing access to the Product, reserve the right to terminate access to the Product at any time if the User
			materially breaches any obligation under these Terms, including the inability to pay its debts.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>13. INTERPRETATION</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>The titles of the articles and paragraphs in the Terms do not affect its interpretation. References to statutory provisions include
			those statutory provisions as amended or re-enacted. References to any grammatical gender include all genders. Singular words include the plural and vice versa.</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>14. APPLICABLE LAW AND JURISDICTION</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>These Terms and Conditions and any disputes or claims arising out of or in connection with it, its subject matter and origin (including non-contractual disputes or claims) shall be governed by and
			construed in accordance with English law and shall be subject to the exclusive jurisdiction of the English courts.</Typography>







	</>
}