import { CircularProgress, Collapse, IconButton, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core'
import * as React from 'react'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useDrawerStyle } from 'main/useDrawerStyle';

const useStyles = makeStyles({
	expandIcon: {
		'&:focus': {
			outline: "none"
		}
	}
})

// Extend with mui props
type Props = {
	className: string,
	text: string,
	onExpandAction?: () => Promise<void>
	onClick?: () => void
	disabled?: boolean
	style?: React.CSSProperties
}

export function ExpandableListItem(props: React.PropsWithChildren<Props>) {
	const classes = useStyles()
	const drawerClasses = useDrawerStyle()
	const [isOpen, setIsOpen] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)

	const renderIconForStep = () => {
		if (isLoading) {
			return <CircularProgress size={16} style={{ color: 'white' }} />
		} else if (isOpen) {
			return <RemoveIcon style={{ fontSize: 16 }} />
		} else {
			return <AddIcon style={{ fontSize: 16 }} />
		}
	}

	const renderExpandIcon = () => {
		return <IconButton
			className={classes.expandIcon}
			disabled={isLoading}
			onClick={async () => {
				setIsLoading(true)
				if (!isOpen && props.onExpandAction) {
					await props.onExpandAction()
				}
				setIsLoading(false)
				setIsOpen(!isOpen)
			}}
			style={{ color: 'white' }}
			edge="end"
			aria-label="expand"
		>
			{renderIconForStep()}
		</IconButton>
	}

	return (
		<>
			<ListItem
				button
				className={`${drawerClasses.listItem} ${props.className}`}
				onClick={props.onClick}
				disabled={props.disabled || isLoading}
				style={props.style}
			>
				<ListItemText className={`${drawerClasses.listItemText}`}>{props.text}</ListItemText>
				<ListItemSecondaryAction>
					{renderExpandIcon()}
				</ListItemSecondaryAction>
			</ListItem>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				{props.children}
			</Collapse>
		</>
	)
}