import { CircularProgress, Snackbar } from "@material-ui/core"
import { ApprovalStatus, Counters, ExtractionStatus, Item, JobRole, UiViews } from "api/api-types"
import { backToAttention, getArchive, getCurrentItemsCount, getItems, getItemsCount, removeItem, revertItems, setItemIsRead, sumbitApprovalProcess } from "api/ApiServices"
import { useApi } from "api/useApi"
import { DrawerContext } from "drawer/AppWithDrawer"
import { Alert, AlertSettings } from "elements/Alerts"
import { Section, ItemTable, ItemTableActions, ItemTableProps } from "elements/ItemTable/ItemTable"
import * as _ from 'lodash'
import { filter } from "lodash"
import { LOG } from "logger/Log"
import { FilterContext } from "main/AppWithFiltersNew"
import { type } from "os"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { isTemplateSpan } from "typescript"
import { AuthContext } from "utils/Auth"
import { ApprovalsModal } from "./ApprovalsModal"
import { DuplicateModal } from "./DuplicateModal"
import { EditItemModal } from "./EditItemModal"
import { ExtractionErrorModal } from "./ExtractionErrorModal"
import { FeedDrawer } from "./FeedDrawer"
import { ActionName } from "./ItemActionPopover"
import { ItemModal } from "./ItemModalCommon"
import { SendBackModal } from "./SendBackModal"
import { UploadDocumentationModal } from "./UploadDocumentationModal"
import { useFetchItems } from "./useFetchItems"

export type ItemStatus = {
	approvalStatus?: ApprovalStatus,
	extractionStatus?: ExtractionStatus
}

export type ItemState = {
	// offset: number
	// limit: number
	items: Item[]
	refetch: (offset?: number, limit?: number) => void
}

export function Feed() {
	// What should we do about counters
	//Should have some polling intervarl
	//and refetchItemsCount should be passed down as well should be passed
	//Redeploy
	const { t } = useTranslation();
	const [itemsCountData, refetchItemsCount] = useApi({
		apiRequest: getItemsCount,
		pollingInterval: 5000

	})

	// const { attention, correct, duplicated, all, incompleteDocumentation, readyForSumbision } = React.useContext(ItemContext)
	// const [items, setItems] = React.useState<ItemState>({
	// 	// offset: 0,
	// 	// limit: 10,
	// 	items: [],
	// 	refetch: () => { }

	// })
	const { items, fetchItems, currentCount, paginationParams } = useFetchItems({ pollInterval: 5000 })


	const [forSection, setForSection] = React.useState<Section>("main")
	const { roles, iss } = React.useContext(AuthContext).getUser()!

	const { activeFilter, setActiveFilter } = React.useContext(FilterContext)

	const [alertsMessage, setAlertsMessage] = React.useState<string>('')
	const [alert, setAlert] = React.useState<AlertSettings>({ open: false, message: '', color: 'success' });
	// const [openAlert, setOpenAlert] = React.useState<{open:boolean,message, color}>(false);

	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ open: false, message: '', color: 'success' })
	}
	const [lastCounters, setLastCounters] = React.useState<Counters>()
	const [notViewedCounters, setNotViewedCounters] = React.useState<Counters>()


	// const [pagination, setPagination] = React.useState<{ offset: number, limit: number }>({ offset: 0, limit: 10 })
	// const [currentCount, setCurrentCount] = React.useState<number>(0)

	const [uIView, setUIView] = React.useState<UiViews>()

	// const fetchItems = async (uiView: UiViews, offset?: number, limit?: number) => {
	// 	console.log('inside')
	// 	console.log('Recalling3 params:', uiView, offset, limit)
	// 	refetchItemsCount()
	// 	const count = await getCurrentItemsCount(uiView)
	// 	if (count._type === 'data') {
	// 		setCurrentCount(count.data)
	// 	}
	// 	const data = await getItems(uiView, offset, limit)
	// 	if (data._type === 'data') {
	// 		let itemsTmp: Item[] = []
	// 		console.log('GetItems param:', uiView, offset, limit)

	// 		LOG.dev(items.items)
	// 		if (uiView === UiViews.PENDING_APPROVAL) {
	// 			data.data.forEach(e => {
	// 				e.approvers.forEach(b => (b.username === iss && !b.approved && itemsTmp.push(e)))

	// 			})
	// 			setItems({
	// 				items: itemsTmp,
	// 				refetch: (o, p) => fetchItems(uiView, o || pagination.offset, p || pagination.limit)
	// 			})

	// 		} else {
	// 			setItems({
	// 				items: data.data,
	// 				refetch: (o, p) => fetchItems(uiView, o || pagination.offset, p || pagination.limit)
	// 			})

	// 		}
	// 	} else if (data._type === 'error') {
	// 		console.log('error', data.error.id)
	// 		LOG.dev(data.error)
	// 	}

	// }

	// const fetchArchiveItems = async (range?: { month: number, year: number }) => {
	// 	// If approval and extraction are undefined we are calling from AccountDrawer and this logic hits
	// 	const data = await getArchive(range)
	// 	if (data._type === 'data') {
	// 		setItems({
	// 			items: data.data,
	// 			refetch: () => getArchive(range)
	// 		})
	// 	} else {
	// 		console.log("Error fetching Archive", data)

	// 	}
	// }


	React.useEffect(() => {
		const isAdmin = _.some(roles, e => e === JobRole.ADMIN)
		const isProcurment = _.some(roles, e => e === JobRole.PROCUREMENT_ADMIN)
		const isApprover = _.some(roles, e => e === JobRole.APPROVER)
		if (isAdmin || isProcurment) {
			setForSection("main")
		} else {
			// setActiveDefaultFilter({
			// 	name: "approving_progress",
			// 	columns: [],
			// 	status: StatusFilter.PENDING_APPROVAL
			// })
			//
			// setActiveFilter("approving_progress")
			setForSection("approvals")
		}
		// Runned only first 
		// fetchItems({ forView: 'feed', uiView: UiViews.CONTROL_BOARD, offset: pagination.offset, limit: pagination.limit })
		fetchItems({ forView: 'feed', uiView: UiViews.CONTROL_BOARD })

	}, [])


	// React.useEffect(() => {



	// 	const timer = setInterval(() => {
	// 		if (items.items) {
	// 			console.log('recalll')
	// 			items.refetch()

	// 		}
	// 	}, 5000);

	// 	return () => {
	// 		if (timer) {
	// 			clearTimeout(timer)
	// 			timeout.current = null
	// 		}
	// 	}


	// }, [])
	// const [count, setCount] = React.useState(0);

	// useInterval(() => {
	// 	items.refetch()
	// 	setCount(count + 1);
	// }, 5000);


	// // @ts-ignore
	// function useInterval(callback, delay) {
	// 	const savedCallback = React.useRef();

	// 	// Remember the latest function.
	// 	React.useEffect(() => {
	// 		savedCallback.current = callback;
	// 	}, [callback]);

	// 	// Set up the interval.
	// 	React.useEffect(() => {
	// 		function tick() {
	// 			// @ts-ignore
	// 			savedCallback.current();
	// 		}
	// 		if (delay !== null) {
	// 			let id = setInterval(tick, delay);
	// 			return () => clearInterval(id);
	// 		}
	// 	}, [delay]);
	// }

	// This should be done using useApi!!!!!!!!!!

	const drawerContext = React.useContext(DrawerContext)

	React.useEffect(() => {
		// console.log('It changes')
		if (!lastCounters && itemsCountData !== 'loading' && itemsCountData != null) {
			setLastCounters(itemsCountData)
		}
		let counting: Counters
		if (itemsCountData !== 'loading' && itemsCountData && lastCounters) {
			counting = {
				CONTROL_BOARD: itemsCountData?.CONTROL_BOARD - lastCounters?.CONTROL_BOARD,
				APPROVALS_ALL: itemsCountData?.APPROVALS_ALL - lastCounters?.APPROVALS_ALL,
				READY_FOR_SUBMISION: itemsCountData?.CONTROL_BOARD - lastCounters?.CONTROL_BOARD,
				CLASSIFIED: itemsCountData?.CLASSIFIED - lastCounters?.CLASSIFIED,
				CONFLICTING: itemsCountData?.CLASSIFIED - lastCounters?.CLASSIFIED,
				EXTRACTION_ALL: itemsCountData?.CLASSIFIED - lastCounters?.CLASSIFIED,
				APPROVED: 0,
				ATTENTION_REVERTED: 0,
				DUPLICATE: 0,
				MISSING_DOCUMENTATION: 0,
				ON_HOLD: 0,
				PENDING_APPROVAL: 0,
				REJECTED: 0,
				RETURNED: 0
			}
			// console.log('Counters in feeed', counting)

		}



		drawerContext.setRenderContent(() => (
			//Getting completly rid of filterContext needs to be done.
			//Probbably delete everything except forSection setForSection invoiceAttentionCount invoiceCorrectCount invoiceDuplicateCount invoiceIncompleteDocCount invoiceReadyForSubmissionCount
			<FeedDrawer
				// activeFilter={activeFilter}
				// setActiveFilter={setActiveFilter}
				forSection={forSection}
				setForSection={setForSection}
				fetchItems={fetchItems}
				counters={(itemsCountData !== "loading" && itemsCountData !== null) ? itemsCountData : undefined}
				// activeDefaultFilter={activeDefaultFilter}
				// activeCustomFilter={activeCustomFilter}
				// customFilters={customFilters}
				// columnFilters={columnFilters}
				// saveCustomFilter={saveCustomFilter}
				// setActiveDefaultFilter={setActiveDefaultFilter}
				// setActiveCustomFilter={setActiveCustomFilter}
				// invoiceAttentionCount={all.length}
				// invoiceCorrectCount={all.length}
				// invoiceDuplicateCount={all.length}
				// invoiceIncompleteDocCount={all.length}
				//this can be better, withought undefined
				invoiceReadyForSubmissionCount={(itemsCountData !== "loading") ? itemsCountData?.READY_FOR_SUBMISION : undefined}
			/>
		))
		//BEFORE [activeDefaultFilter, activeCustomFilter, customFilters, columnFilters, all.length, forSection])
		//I should probabbly introduce state itemsCount, because i can set it depending on itemsCountData 
	}, [itemsCountData, forSection])



	const editModalRef = React.useRef<ItemModal>(null)
	const extractionErrorModalRef = React.useRef<ItemModal>(null)
	const uploadDocumentationModalRef = React.useRef<UploadDocumentationModal>(null)
	const duplicateModalRef = React.useRef<DuplicateModal>(null)
	const sendBackModalRef = React.useRef<SendBackModal>(null)
	const approvalsModalRef = React.useRef<ItemModal>(null)
	// Need to ask for detailed instructions on where which action!!!
	const invoiceTableActions: (itemsParam: Item[]) => ItemTableActions = (itemsParam: Item[]) => {

		function statusToactionName(item: Item): ActionName[] {

			switch (item.approvalStatus) {
				case ApprovalStatus.RETURNED:
					return [ActionName.REMOVE]
					break;
				case ApprovalStatus.REJECTED:
					return [ActionName.SEND_TO_ATTENTION, ActionName.REMOVE]
					break;
				default:
					return []
					break;
			}
		}
		if (forSection === 'extraction') {
			return (item, setLoading) => [{
				action: () => {
					const index = _.findIndex(itemsParam, item)
					editModalRef.current?.show({
						currentIndex: index,
						item: itemsParam[index],
						size: itemsParam.length
					})
				},
				actionName: ActionName.EDIT
			},
			{
				action: async () => {
					sendBackModalRef.current?.show({ itemId: item.itemId, sendTo: item.labels.inputGroup.senderEmail?.value || '' })
					// const itemTmp = _.find(itemsParam, e => e.itemId === item.itemId)
					// Patch this should be changed
					// We can't use this patch anymore, now it would be too costly to do. 
					//We would have to search through items, and then figure out through switch(approval, extraction) which UiView param to send.
					// fetchItems(itemTmp?.approvalStatus, itemTmp?.extractionStatus)
					refetchItemsCount()
					// refetch()
					fetchItems()
				},
				actionName: ActionName.SEND_BACK
			},
			// {
			// 	action: () => { },
			// 	actionName: ActionName.VIEW
			// },
			{
				action: () => { },
				actionName: ActionName.PRINT
			}]
		} else {
			return (item, setLoading) => [{
				action: () => {
					const index = _.findIndex(itemsParam, item)
					editModalRef.current?.show({
						currentIndex: index,
						item: itemsParam[index],
						size: itemsParam.length
					})
				},
				actionName: ActionName.EDIT
			},
			{
				action: async () => {
					sendBackModalRef.current?.show({ itemId: item.itemId, sendTo: item.labels.inputGroup.senderEmail?.value || '' })
					// const itemTmp = _.find(itemsParam, e => e.itemId === item.itemId)
					// Patch this should be changed
					// We can't use this patch anymore, now it would be too costly to do. 
					//We would have to search through items, and then figure out through switch(approval, extraction) which UiView param to send.
					// fetchItems(itemTmp?.approvalStatus, itemTmp?.extractionStatus)
					refetchItemsCount()
					// refetch()
					fetchItems()

				},
				actionName: ActionName.SEND_BACK
			},
			// {
			// 	action: () => { },
			// 	actionName: ActionName.VIEW
			// },
			{
				action: () => { },
				actionName: ActionName.PRINT
			}, {
				actionName: ActionName.REMOVE,
				action: async () => {
					removeItem(item.itemId)
					// const itemTmp = _.find(itemsParam, e => e.itemId === item.itemId)
					// Same as for send back, we need to change something
					// Patch this should be changed
					// fetchItems(itemTmp?.approvalStatus, itemTmp?.extractionStatus)
					refetchItemsCount()
					fetchItems()
				}
			}
				, {
				actionName: ActionName.SEND_TO_ATTENTION,
				action: async () => {
					// console.log("Came inside back to attention")
					backToAttention(item.itemId)
					// const itemTmp = _.find(itemsParam, e => e.itemId === item.itemId)
					// Same as for send back, we need to change something
					// Patch this should be changed
					// fetchItems(itemTmp?.approvalStatus, itemTmp?.extractionStatus)
					refetchItemsCount()
					fetchItems()
				}
			}
			].filter(e => ((forSection === 'approvals') ? statusToactionName(item).includes(e.actionName) : true))
		}
	}

	const getTableProps: () => ItemTableProps = () => {
		if (forSection === "main" || forSection === "extraction") {
			//This filteredItems should be changed to already filterd Items from back?
			return {
				showUploadDocumentation: uploadDocumentationModalRef.current?.show,
				actions: invoiceTableActions(items!),
				items: items!,
				showEditItem: editModalRef.current?.show,
				showDuplicateItem: duplicateModalRef.current?.show,
				section: forSection,
				fetchItems: fetchItems,
				pagination: paginationParams,
				refetchItems: fetchItems,
				currentCount: currentCount,
				itemsCount: itemsCountData !== 'loading' ? itemsCountData! : undefined
			}
		} else if (forSection === "approvals") {
			// console.log('approvals!!!', forSection)
			// console.log('ActiveFilter: ', activeFilter)
			return {
				// This must be prettier
				showApprovalsModal: (activeFilter === 'on_hold' || activeFilter === 'approving_progress') ? approvalsModalRef.current?.show : undefined,
				actions: invoiceTableActions(items!),
				items: items!,
				showEditItem: editModalRef.current?.show,
				section: forSection,
				fetchItems: fetchItems,
				refetchItems: fetchItems,
				pagination: paginationParams,
				currentCount: currentCount,
				itemsCount: itemsCountData !== 'loading' ? itemsCountData! : undefined

			}
		} else if (forSection === "submision") {
			return {
				// Second await isnt waiting?
				revert: async (invoiceIds) => {
					const response = await revertItems(invoiceIds)
					if (response._type === 'generic_success') {
						//This should be fetch maybe?
						// console.log('Revert')
						//Same for revert
						// await fetchItems(ApprovalStatus.READY_FOR_SUMBISION, ExtractionStatus.READY)
						await fetchItems()

					} else {
						// Handle errors
					}
					fetchItems()
				},
				submit: async (invoiceIds) => {
					if (items && items.length < 1) {
						setAlert({ open: true, message: t("Alert.No_Docs"), color: 'warning' })
						// setAlertsMessage('There are no Members with Approval Role')
						// setOpenAlert(true)
						return
					}
					const response = await sumbitApprovalProcess(invoiceIds)
					if (response._type === 'generic_success') {
						setAlert({ open: true, message: t("Alert.Docs_Submited"), color: 'success' })
						// await fetchItems(ApprovalStatus.READY_FOR_SUMBISION, ExtractionStatus.READY)
						await fetchItems()

					} else if (response._type === 'error') {
						if (response.error.message === 'Bad request') {
							setAlert({ open: true, message: t("Alert.No_Approver"), color: 'warning' })
							// setAlertsMessage('You need to create user Approver beefore submitting')
						} else {
							setAlert({ open: true, message: t("Alert.Submit_Fail"), color: 'error' })
						}
						// Handle errors
						LOG.dev("Error submit approval", response)
					}
				},
				actions: invoiceTableActions(items!),
				items: items!,
				showEditItem: editModalRef.current?.show,
				section: forSection,
				fetchItems: fetchItems,
				refetchItems: fetchItems,
				pagination: paginationParams,
				currentCount: currentCount,

			}

		} else if (forSection === "extraction errors") {
			//This filteredItems should be changed to already filterd Items from back?
			return {
				actions: invoiceTableActions(items!),
				items: items!,
				showExtractionErrorModal: extractionErrorModalRef.current?.show,
				section: forSection,
				fetchItems: fetchItems,
				refetchItems: fetchItems,
				itemsCount: itemsCountData !== 'loading' ? itemsCountData! : undefined,
				pagination: paginationParams,
				currentCount: currentCount,

			}
		} else if (forSection === "booking submision") {
			//This filteredItems should be changed to already filterd Items from back?
			// console.log('USAOOOOOOOOOO')
			return {
				submitBooking: async (invoiceIds) => {
					// const response = await submitForBooking(invoiceIds)
					// if (response._type === 'generic_success') {
					// 	console.log('submited for booking')
					// 	// await fetchItems(ApprovalStatus.READY_FOR_SUMBISION, ExtractionStatus.READY)
					// 	await items.refetch()

					// } else {
					// 	// Handle errors
					// 	LOG.dev("Error submiting for  booking", response)
					// }
				},
				actions: invoiceTableActions(items!),
				items: items!,
				// showExtractionErrorModal: extractionErrorModalRef.current?.show,
				section: forSection,
				fetchItems: fetchItems,
				refetchItems: fetchItems,
				itemsCount: itemsCountData !== 'loading' ? itemsCountData! : undefined,
				pagination: paginationParams,
				currentCount: currentCount,

			}
		} else if (forSection === "archive") {
			//This filteredItems should be changed to already filterd Items from back?
			// console.log('USAOOOOOOOOOO ovde')
			return {

				actions: invoiceTableActions(items!),
				items: items!,
				// showExtractionErrorModal: extractionErrorModalRef.current?.show,
				section: forSection,
				fetchItems: fetchItems,
				refetchItems: fetchItems,
				itemsCount: itemsCountData !== 'loading' ? itemsCountData! : undefined,
				pagination: paginationParams,
				currentCount: currentCount,
			}
		}
		else {
			throw Error()
		}
	}
	if (itemsCountData === 'loading') {
		return <CircularProgress />
	}
	return (
		<>
			<ItemTable {...getTableProps()} />
			<EditItemModal
				refetchInvoices={fetchItems}
				refetchCounters={refetchItemsCount}
				ref={editModalRef}
				paginate={(id, paginate) => paginate(items, id, forSection)}

			/>
			<ExtractionErrorModal
				refetchInvoices={fetchItems}
				refetchCounters={refetchItemsCount}
				ref={extractionErrorModalRef}
				paginate={(id, paginate) => paginate(items, id, forSection)}

			/>
			<UploadDocumentationModal ref={uploadDocumentationModalRef} />
			<DuplicateModal ref={duplicateModalRef}
				refetchInvoices={fetchItems}
				refetchCounters={refetchItemsCount}
				paginate={(id, paginate) => paginate(items, id, forSection)}

			/>
			<SendBackModal ref={sendBackModalRef} refetchInvoices={fetchItems}
				refetchCounters={refetchItemsCount}
				paginate={(id, paginate) => paginate(items, id, forSection)}
			/>
			<ApprovalsModal
				refetchCounters={refetchItemsCount}
				ref={approvalsModalRef}
				refetchInvoices={fetchItems}
				paginate={(id, paginate) => paginate(items, id, forSection)}
			/>
			<Snackbar open={alert.open} autoHideDuration={4000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				<Alert onClose={handleCloseAlert} severity={alert.color}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	)
}