import { makeStyles, Theme, createStyles } from '@material-ui/core'



export function useAnalyticsStyle() {
    return makeStyles((theme: Theme) =>
        createStyles({
            titleContainer: {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '24px',
                paddingTop: '12px',
                paddingBottom: '6px'
            },
            title: {
                lineHeight: '64px',
            },
            expandableSectionTitle: {
                paddingRight: '12px',
                lineHeight: 1,
            },
            expandableSection: {
                backgroundColor: 'rgb(250,250,250)',
                marginBottom: '12px'
            },
            labelContainer: {
                display: 'flex',
                padding: '6px 0px 12px 24px',
            },
            label: {
                marginRight: '8px',
                background: "#08ad6c",
                backgroundColor: 'rgba(9, 182, 109, 0.1)',
                paddingRight: 8,
                border: 'none',
                borderRadius: '5px',
                color: '#08ad6c',
                fontSize: '12px'
            },
            row: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
                width: '100%',
            },
            column: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '25%',
                alignSelf: 'stretch',
                paddingLeft: '24px',
                paddingRight: '24px',
                marginRight: '24px'
            },
            analyticsTable: {
                width: '75%',
            }
        })
    )()
}