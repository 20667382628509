import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppMain } from '../main/AppMain'

interface DrawerContext {
	renderContent: () => React.ReactNode
	setRenderContent: (renderContent: () => React.ReactNode) => void
}

export const DrawerContext = React.createContext<DrawerContext>(undefined as any)

export function AppWithDrawer(props: RouteComponentProps) {
	const [renderContent, setRenderContent] = React.useState<() => React.ReactNode>(() => null)
	const value: DrawerContext = {
		renderContent: renderContent,
		setRenderContent: (renderContent) => setRenderContent(renderContent)
	}

	return <DrawerContext.Provider value={value}>
		<AppMain {...props} />
	</DrawerContext.Provider>
}