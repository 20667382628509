import { CircularProgress, createStyles, fade, IconButton, makeStyles, TableCell, Theme, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ReplayIcon from '@material-ui/icons/Replay';
import { VerifiedSuppliers, JobRole } from 'api/api-types';
import { getVerifiedSuppliers, resendVerifiyEmail } from 'api/ApiServices';
import { handleApiResponse } from 'api/useApi';
import * as _ from 'lodash';
import * as React from 'react';
import { AuthContext } from 'utils/Auth';
import { useModalStyles } from 'styles/useModalStyle'
import 'styles/colors.css'
import { CompanyContractModal } from './CompanyContractModal';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import { defaultCellStyle, defaultHeaderStyle } from 'elements/ItemTable/ItemTableColumns';
import { parseValue, RowValue } from 'elements/ItemTable/ItemTableRows';
import { useItemTableStyles } from 'elements/ItemTable/useItemTableStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MoreVertIcon from '@material-ui/icons/MoreVert'

// import { CompanyContractModal } from './CompanyContractModal';
import { useTranslation } from 'react-i18next';

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			minHeight: 835,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		},
		flexRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 20,
		},
		topFlexRow: {
			// borderBottom: '1px solid #ededed',
			paddingBottom: 20
		},
		bottomFlexRow: {
			paddingTop: 5
		},
		//change to succesButton
		addMemberButton: {
			marginLeft: 'auto',
			marginRight: 15,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.1),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.01),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			border: '1px solid #e1e3e1'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		table: {

		},
		userIcon: {
			width: 22,
			height: 22,
			color: 'rgba(0, 0, 0, 0.54)',
		},
		manageIcons: {
			height: 20,
			cursor: 'pointer'
		},
		removeIcon: {
			// marginLeft: 15
		},
		userIconCell: {
			padding: 0
		},
		usernameCell: {
			paddingLeft: 0
		}
	}))

export function jobRoleLabel(role: JobRole): "Administration" | "Procurement" | "Approvals" | "Analytics" {
	switch (role) {
		case JobRole.ADMIN: return "Administration"
		case JobRole.PROCUREMENT_ADMIN: return "Procurement"
		case JobRole.APPROVER: return "Approvals"
		case JobRole.ANALYST: return "Analytics"
		default: throw Error("Invalid Job Role")
	}
}

export function CompanyContract() {
	const classes = useStyles()
	const classesTable = useItemTableStyles()
	const modalClasses = useModalStyles()
	const { t, i18n } = useTranslation()
	const [data, setData] = React.useState<VerifiedSuppliers[] | null>(null)
	const [offset, setOffset] = React.useState(0)

	const [resendLinkStep, setResendLinkStep] = React.useState<"resend" | "error" | "success" | "pending">("resend")
	// const [selectedSupplier, setSelectedSupplier] = React.useState<Supplier | null>(null)

	const authContext = React.useContext(AuthContext)

	const companyContractRef = React.useRef<CompanyContractModal>(null)

	async function init() {
		const response = await getVerifiedSuppliers()
		if (response._type === "data") {
			setData(response.data)

		} else {
			setData(null)

		}

	}
	React.useEffect(() => {


		init()
	}, [offset])

	async function resendUserVerification(username: string) {
		setResendLinkStep("pending")
		const response = await resendVerifiyEmail(username)
		handleApiResponse(
			response,
			(error) => {
				setResendLinkStep("error")
				setTimeout(() => {
					setResendLinkStep('resend')
				}, 2000)
			},
			(data) => {
				if (data === 'success') {
					setResendLinkStep("success")
					setTimeout(() => {
						setResendLinkStep('resend')
					}, 2000)
				}
			}
		)
	}

	function renderVerificationIcon(
		verified: boolean,
		step: "resend" | "error" | "success" | "pending",
		username: string
	) {
		if (!verified) {
			if (step === 'resend') {
				return <ReplayIcon className={classes.manageIcons} onClick={() => {
					resendUserVerification(username)
				}} />
			} else if (step === 'pending') {
				return <CircularProgress size={13} thickness={5} />
			} else if (step === "error") {
				return <ErrorIcon className={classes.manageIcons} />
			} else if (step === "success") {
				return <CheckIcon className={classes.manageIcons} />
			} else {
				throw Error()
			}
		} else {
			return null
		}
	}


	type Column = '' | 'Company Name' | 'Company Idefication Number' | 'Tax Identification Number' | 'City' | 'Contracts' | ''
	type Row = string[] | string | number

	function constructColumn(
		// section: Section,
		column: Column,
		options: MUIDataTableColumnOptions
	): MUIDataTableColumn {
		return {
			name: column,
			label: column,
			// options: { ...columnOptions(section, column, options) },
			options: { ...options }
		}
	}

	function renderColumns() {
		const columns: MUIDataTableColumn[] = [
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',
						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',

						}
					})
				}
			),
			constructColumn(
				'Company Name',
				{

					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Procurement.Comp_Name')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Company Idefication Number',
				{

					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Procurement.Comp_Ico')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Tax Identification Number',
				{

					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Procurement.Comp_Dic')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'City',
				{

					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Procurement.Comp_City')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Contracts',
				{

					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Procurement.Contract_Num')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("right")
					}),
					setCellProps: () => ({
						...defaultCellStyle("right")
					})
				}
			)


		]
		return columns
	}

	function renderSuppliersRow(suppliers: VerifiedSuppliers[], actionButton: (invoice: VerifiedSuppliers, index: number) => JSX.Element) {

		return suppliers.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// Check with Jorge how to propperly use reduce and why is first showing []
			if (i === 0)
				p.shift()
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					['', <LibraryBooksIcon className={classes.userIcon} />],
					['Company Name', parseValue(c.name)],
					['Company Idefication Number', parseValue(c.ico)],
					['Tax Identification Number', parseValue(c.dic)],
					['City', parseValue(c.city)],
					['Contracts', parseValue(c.contracts.map((e, i) => c.contracts.length === i + 1 ? `${e}` : `${e}, `))],
					['', actionButton(c, i)]

				]
			// const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			// const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]
			const tmp = row.map(e => e[1])
			p.push(tmp)
			return p
		}, [[]])

	}

	// function renderUserVerified(verified: boolean) {
	// 	if (verified === true) {
	// 		return <ThumbUpAltIcon className='bgGreen' />
	// 	} else {
	// 		return <HourglassEmpty className='bgOrange' />
	// 	}
	// }function renderUserVerified(verified: boolean) {
	// 	if (verified === true) {
	// 		return <ThumbUpAltIcon className='bgGreen' />
	// 	} else {
	// 		return <HourglassEmpty className='bgOrange' />
	// 	}
	// }

	if (data) {
		return (
			<>
				<div className={classesTable.tableWrapper}>
					<MUIDataTable
						title='Supplier'
						data={renderSuppliersRow(
							data!,
							(item, index) => {
								return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', marginRight: '16px' }} onClick={e => {
									e.stopPropagation()

									// popoverRef.current?.show({
									// 	anchorElement: e.currentTarget,
									// 	actions: [
									// 		{
									// 			actionName: ActionName.EDIT,
									// 			action: () => { }
									// 		}
									// 	]
									// })
									// setSelectedItem(item.itemId, index)
								}} />
							}

						)}
						columns={renderColumns()}
						options={{
							elevation: 0,
							selectableRows: 'none',
							customToolbar: () => {
								return <div className={classesTable.toolbarButtonsContainer}>
									{/* <div style={{ display: 'flex' }}> */}
									<IconButton onClick={() => {
										companyContractRef.current?.show({ suppliers: data, refetch: init })

									}}>
										<AddCircleIcon className={classesTable.addIcon} />
									</IconButton>
									{/* </div> */}
								</div>
							}
						}}

					>

					</MUIDataTable>
					{/* <div className={`${classes.flexRow} ${classes.topFlexRow}`}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
						<Translation>{ (t) => <Button variant='contained' color='primary' className={`${classes.addMemberButton}`} onClick={() => {
							companyContractRef.current?.show({ suppliers: data, refetch: init })

						}}>Add company<AddIcon /></Button> }</Translation>
					</div>
					<div className={`${classes.flexRow} ${classes.bottomFlexRow}`}>
					<Translation>{ (t) => <TableContainer>
							<Table className={classes.table} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left"></StyledTableCell>
										<StyledTableCell align="left" className={classes.usernameCell}>Company ICO</StyledTableCell>
										<StyledTableCell align="left">Company DIC</StyledTableCell>
										<StyledTableCell align="left">Company Name</StyledTableCell>
										<StyledTableCell align="left">City</StyledTableCell>
										<StyledTableCell align="left">Suppliers Contracts</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{renderSuppliers(data)}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TablePagination
											count={-1}
											rowsPerPage={25}
											page={offset}
											onChangePage={(e, p) => setOffset(p)}
											These two were commented out
										onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
										rowsPerPageOptions={[10, 15, 30]}
										/>
									</TableRow>
								</TableFooter>

							</Table>
						</TableContainer>
					</div> */}
				</div>
				<CompanyContractModal ref={companyContractRef} />
			</>
		)
	} else {
		return null
	}
}
