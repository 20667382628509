import { Typography } from '@material-ui/core'
import { ExpandableSection } from 'elements/ExpandableSection'
import * as React from 'react'
import { useAnalyticsStyle } from './useAnalyticsStyle'

type Props = {
    title: string
}

export function AnalyticsTableTitle(props: Props) {
    const classes = useAnalyticsStyle()

    const renderAnalyticsHeader = () => {
		return <>
				<div className={classes.titleContainer}>
					<Typography variant='h6' className={classes.expandableSectionTitle}>{props.title}</Typography>
					<span><a href='#'>Test</a> / <a href='#'>Test</a></span>
				</div>
				<div className={classes.labelContainer}>
					{/* Render Labels */}
					<div className={`${classes.label} `}>Label1</div>
					<div className={`${classes.label} `}>Label2</div>
					<div className={`${classes.label} `}>Label3</div>
				</div>
		</>
	}
	return <>
        <Typography variant='h6' className={classes.title}>Agregation</Typography>
		<ExpandableSection style={{maxWidth: '60%'}} renderHeader={() => renderAnalyticsHeader()} className={classes.expandableSection}>
		</ExpandableSection>
	</>
}