import { DateTimeFormatter, Instant, LocalDate, ZonedDateTime, ZoneId } from "@js-joda/core"
import { Tooltip } from "@material-ui/core"
import { ApprovalStatus, DocStatusEnum, ExtractionStatus } from "api/api-types"
import * as _ from 'lodash'
import { MUIDataTableColumn, MUIDataTableColumnOptions, } from "mui-datatables"
import * as React from 'react'
import { JsxElement } from "typescript"
import { formatDateFromEpoch } from "utils/DateTime"
import { Section } from "./ItemTable"
import { RowValue } from "./ItemTableRows"
import { ItemTable_Render } from "./ItemTable_Render"


// const { t, i18n } = useTranslation();

export type Column = "Supplier" | "Invoice ID" | "Invoice #" | "Requisition #" | "Purchase Order #"
	| "Extraction" | "Approval" | "Name" | "Document" | "Amount" | "IBAN" | "Delivery Date" | "Date of Issue"
	| "Due Date" | "ICO" | "Actions" | "Order #" | "Billing" | "Booking" | "Archive" | "Extraction Time" | "Sender Email" | "Document Format" | "Language" | "Created At" | "Tracking #"

export function defaultCellStyle(align: 'center' | 'left' | 'right') {
	return {
		align: align,
		style: {
			fontSize: '12px',

		},

	}
}

export function defaultHeaderStyle(align: 'center' | 'left' | 'right') {
	return {
		align: align,
		style: {
			fontSize: '12px',
			fontWeight: '700',
		}
	}
}

function constructColumn(
	section: Section,
	column: Column,
	options: MUIDataTableColumnOptions
): MUIDataTableColumn {
	return {
		name: column,
		label: column,
		options: { ...columnOptions(section, column, options) },
	}
}

export function columnOrdinal(column: Column) {
	switch (column) {
		case "Tracking #": return 0
		case "Supplier": return 1
		case "Invoice ID": return 2
		case "Name": return 3
		case "Invoice #": return 4
		case "Document": return 5
		case "Requisition #": return 6
		case "Purchase Order #": return 7
		case "Billing": return 8
		case "Booking": return 9
		case "Archive": return 10
		case "Amount": return 11
		case "IBAN": return 12
		case "Order #": return 13
		case "Date of Issue": return 14
		case "Delivery Date": return 15
		case "Due Date": return 16
		case "ICO": return 17
		case "Extraction Time": return 18
		case "Created At": return 19
		case "Sender Email": return 20
		case "Document Format": return 21
		case "Language": return 22
		case "Extraction": return 23
		case "Approval": return 24
		default: throw Error()
	}
}

function columnOptions(
	section: Section,
	column: Column,
	additionalOptions?: MUIDataTableColumnOptions
): MUIDataTableColumnOptions {
	function isVisible(condition: boolean) {
		if (condition) {
			return "true"
		} else {
			return "false"
		}
	}

	const buildCondition = (section: Section, sections: Section[]) => _.some(sections, e => e === section)

	const options = (condition: boolean): MUIDataTableColumnOptions => {
		return {
			display: isVisible(condition),
			filter: condition,
			searchable: condition,
			viewColumns: condition,
			download: condition,
			print: condition,
			...additionalOptions
		}
	}

	switch (column) {
		case "Supplier": return options(
			buildCondition(
				section,
				["extraction", "approvals", "submision", "main", "booking submision", 'archive']
			)
		)
		case "Name": return options(
			buildCondition(
				section,
				["extraction", "main"]
			)
		)
		case "Invoice ID": return options(
			buildCondition(
				section,
				[]
			)
		)
		case "Invoice #": return options(
			buildCondition(
				section,
				["extraction", "approvals", "submision", "main", "booking submision", "archive"]
			)
		)
		case "Requisition #": return options(
			buildCondition(
				section,
				["extraction", "approvals", "submision", "main", "booking submision", "archive"]
			)
		)
		case "Purchase Order #": return options(
			buildCondition(
				section,
				["extraction", "approvals", "submision", "main", "booking submision", "archive"]
			)
		)
		case "Extraction": return options(
			buildCondition(
				section,
				["main", "extraction", "booking submision"]
			)
		)
		case "Approval": return options(
			buildCondition(
				section,
				["main", "approvals", "archive"]
			)
		)
		case "Billing": return options(
			buildCondition(
				section,
				["main"]
			)
		)
		case "Booking": return options(
			buildCondition(
				section,
				["main"]
			)
		)
		case "Archive": return options(
			buildCondition(
				section,
				["main"]
			)
		)
		case "Document": return options(
			buildCondition(
				section,
				["submision", "main", "extraction", "extraction errors", "booking submision"]
			)
		)
		case "Amount": return options(
			buildCondition(
				section,
				["extraction", "approvals", "submision", "booking submision", "archive"]
			)
		)
		case "IBAN": return options(
			buildCondition(
				section,
				["submision"]
			)
		)
		case "Order #": return options(
			buildCondition(
				section,
				["submision"]
			)
		)
		case "Date of Issue": return options(
			buildCondition(
				section,
				["extraction", "submision", "booking submision"]
			)
		)
		case "Due Date": return options(
			buildCondition(
				section,
				["extraction", "submision", "booking submision"]
			)
		)
		case "Delivery Date": return options(
			buildCondition(
				section,
				["extraction", "submision", "booking submision"]
			)
		)
		case "ICO": return options(
			buildCondition(
				section,
				["extraction", "submision", "booking submision"]
			)
		)
		case "Extraction Time": return options(
			buildCondition(
				section,
				["main"]
			)
		)
		case "Sender Email": return options(
			buildCondition(
				section,
				["extraction errors"]
			)
		)
		case "Document Format": return options(
			buildCondition(
				section,
				["extraction errors"]
			)
		)
		case "Language": return options(
			buildCondition(
				section,
				["extraction errors"]
			)
		)
		case "Created At": return options(
			buildCondition(
				section,
				["extraction errors"]
			)
		)
		case "Tracking #": return options(
			buildCondition(
				section,
				["extraction", "approvals", "submision", "main", "booking submision", "archive"]
			)
		)
		default: throw Error()
	}
}

// Defaults are render on all filters (actions are on most)
function defaultColumns(display: boolean): MUIDataTableColumn[] {
	return [
		{
			name: '',
			options: {
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right"),
					style: {
						// paddingRight: '16px'
					}
				}),
				setCellProps: () => ({
					...defaultCellStyle("right"),
					// paddingRight: '16px'
				}),
				display: display ? "true" : "false",
				filter: false,
				searchable: false,
				download: false,
				print: false,
				sort: false,
				viewColumns: false
			}
		},
		{
			name: 'Item Id',
			options: {
				display: 'false',
				filter: false,
				searchable: false,
				download: false,
				print: false,
				sort: false,
				viewColumns: false
			}
		}
	]
}

type ItemTableColumns = (
	section: Section,
	onColumnClick: (rowIndex: number, columnIndex: number) => void,
	renderApprovalTooltip: (approvalStatus: ApprovalStatus, rowIndex: number) => JSX.Element | null | undefined,
	translation?: any
) => MUIDataTableColumn[]


export const getItemTableColumns: ItemTableColumns = (
	section,
	onColumnClick,
	renderApprovalTooltip,
	translation
) => {
	const columns: MUIDataTableColumn[] = [
		constructColumn(
			section,
			"Supplier",
			{
				customBodyRender: (supplier: RowValue<string>) => {
					return <Tooltip title={ItemTable_Render.value(supplier)}><div style={{
						maxWidth: 130,
						whiteSpace: "nowrap",
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}>{ItemTable_Render.value(supplier)}</div></Tooltip>
				},
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Supplier'];
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")

				})
			}
		),
		constructColumn(
			section,
			"Invoice #",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Invoice #'];
				},
				customBodyRender: (invoiceNumber: RowValue<string>) => {
					return ItemTable_Render.value(invoiceNumber)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
		constructColumn(
			section,
			"Invoice ID",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Invoice ID'];
				},
				customBodyRender: (invoiceId: RowValue<string>) => {
					return ItemTable_Render.value(invoiceId)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
		constructColumn(
			section,
			"Requisition #",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Requisition #'];
				},
				customBodyRender: (documentationStatus: DocStatusEnum) => ItemTable_Render.documentationStatus(documentationStatus),
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right"),
					style: {
						justifyContent: 'flex-end',
						fontWeight: 700,
					}
				}),
				setCellProps: (value, rowIndex, columnIndex) => ({
					onClick: () => {
						onColumnClick(rowIndex, columnIndex)
					},
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Purchase Order #",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Purchase Order #'];
				},
				customBodyRender: (documentationStatus: DocStatusEnum) => ItemTable_Render.documentationStatus(documentationStatus),
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right"),
					style: {
						justifyContent: 'flex-end',
						fontWeight: 700,
					}
				}),
				setCellProps: (value, rowIndex, columnIndex) => ({
					onClick: () => {
						onColumnClick(rowIndex, columnIndex)
					},
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Extraction",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Extraction'];
				},
				customBodyRender: (extractionStatus: ExtractionStatus) => {
					return ItemTable_Render.extractionStatus(extractionStatus)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
		constructColumn(
			section,
			"Approval",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Approval'];
				},
				customBodyRender: (approvalStatus: ApprovalStatus, meta) => {
					return renderApprovalTooltip(approvalStatus, meta.rowIndex)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
		constructColumn(
			section,
			"Billing",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Billing'];
				},
				customBodyRender: () => ItemTable_Render.billingBookingArchive(),
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("center")
				}),
				setCellProps: () => ({
					...defaultCellStyle("center")
				})
			}
		),
		constructColumn(
			section,
			"Booking",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Booking'];
				},
				customBodyRender: () => ItemTable_Render.billingBookingArchive(),
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("center")
				}),
				setCellProps: () => ({
					...defaultCellStyle("center")
				})
			}
		),
		constructColumn(
			section,
			"Archive",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Archive'];
				},
				customBodyRender: () => ItemTable_Render.billingBookingArchive(),
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("center")
				}),
				setCellProps: () => ({
					...defaultCellStyle("center")
				})
			}
		),
		constructColumn(
			section,
			"Document",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Document'];
				},
				customBodyRender: (documentType: RowValue<string>) => {
					return ItemTable_Render.value(documentType)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
		constructColumn(
			section,
			"Amount",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Amount'];
				},
				customBodyRender: (invoiceAmount: RowValue<JsxElement>) => {
					if (invoiceAmount !== "Missing") {
						return ItemTable_Render.value(invoiceAmount)
					} else {
						return ItemTable_Render.value("Missing")
					}

				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),

		constructColumn(
			section,
			"IBAN",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['IBAN'];
				},
				customBodyRender: (iban: RowValue<string[]>) => {
					if (iban !== "Missing") {
						return iban.map((e, i) => <Tooltip key={`iban-${e}-${i}`} title={ItemTable_Render.value(e)}><div style={{
							maxWidth: 100,
							whiteSpace: "nowrap",
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
						>{ItemTable_Render.value(e)}</div></Tooltip>)
					} else {
						return <Tooltip title={ItemTable_Render.value(iban)}><div style={{
							maxWidth: 100,
							whiteSpace: "nowrap",
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
						>{ItemTable_Render.value(iban)}</div></Tooltip>
					}
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Order #",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Order #'];
				},
				customBodyRender: (orderNumber: RowValue<string[]>) => {
					if (orderNumber !== 'Missing') {
						return orderNumber.map((e, i) => <div key={`order-n-${e}-${i}`}>{ItemTable_Render.value(e)}</div>)
					} else {
						return ItemTable_Render.value(orderNumber)
					}
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Date of Issue",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Date of Issue'];
				},
				customBodyRender: (dateOfIssue: RowValue<JsxElement>) => {
					if (dateOfIssue !== "Missing") {
						return ItemTable_Render.value(dateOfIssue)
					} else {
						return ItemTable_Render.value("Missing")
					}
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Delivery Date",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Delivery Date'];
				},
				customBodyRender: (deliveryDate: RowValue<JsxElement>) => {
					if (deliveryDate !== "Missing") {
						return ItemTable_Render.value(deliveryDate)
					} else {
						return ItemTable_Render.value("Missing")
					}
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Due Date",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Due Date'];
				},
				customBodyRender: (dueDate: RowValue<JsxElement>) => {
					if (dueDate !== "Missing") {
						return ItemTable_Render.value(dueDate)
					} else {
						return ItemTable_Render.value("Missing")
					}
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"ICO",
			{

				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['ICO'];
				},
				customBodyRender: (ico: RowValue<number>) => {
					return ItemTable_Render.value(ico)
				},
				setCellHeaderProps: () => {
					let style
					if (section === 'submision') {
						style = {
							paddingRight: '16px',
							fontSize: '12px',
							fontWeight: '700',
						}
					} else {
						style = {
							paddingRight: '0px',
							fontSize: '12px',
							fontWeight: '700',
						}
					}
					return {
						...defaultHeaderStyle("right"),
						style
					}
				},

				setCellProps: () => {
					let style
					if (section === 'submision') {
						style = {
							paddingRight: '16px'
						}
					} else {
						style = {
							paddingRight: '0px'
						}
					}
					return {
						...defaultCellStyle("right"),
						style
					}
				}
			}
		),
		constructColumn(
			section,
			"Extraction Time",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Extraction Time'];
				},
				customBodyRender: (timeCreated: RowValue<JsxElement>) => {
					// We never expect this to be null

					if (timeCreated !== "Missing") {
						return ItemTable_Render.value(timeCreated)
					} else {
						return ItemTable_Render.value("Missing")
					}



				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		), constructColumn(
			section,
			"Sender Email",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Sender Email'];
				},
				customBodyRender: (senderEmail: RowValue<string>) => {
					return ItemTable_Render.value(senderEmail)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Document Format",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Invoice #'];
				},
				customBodyRender: (documentFormat: RowValue<string>) => {
					return ItemTable_Render.value(documentFormat)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Language",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Language'];
				},
				customBodyRender: (Language: RowValue<string>) => {
					return ItemTable_Render.value(Language)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		), constructColumn(
			section,
			"Created At",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Created At'];
				},
				customBodyRender: (createdAt: RowValue<number>) => {
					if (createdAt !== 'Missing')
						return ItemTable_Render.value(formatDateFromEpoch(createdAt, "MM/dd/yyy"))
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("right")
				}),
				setCellProps: () => ({
					...defaultCellStyle("right")
				})
			}
		),
		constructColumn(
			section,
			"Tracking #",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Tracking #'];
				},
				customBodyRender: (trackingNum: RowValue<string>) => {
					return ItemTable_Render.value(trackingNum)
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
		constructColumn(
			section,
			"Name",
			{
				customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
					return translation['Name'];
				},
				customBodyRender: (invoiceNumber: RowValue<string>) => {
					return <Tooltip title={ItemTable_Render.value(invoiceNumber)}><div style={{
						maxWidth: 80,
						whiteSpace: "nowrap",
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}>{ItemTable_Render.value(invoiceNumber)}</div></Tooltip>
				},
				setCellHeaderProps: () => ({
					...defaultHeaderStyle("left")
				}),
				setCellProps: () => ({
					...defaultCellStyle("left")
				})
			}
		),
	]

	const ordered = _.orderBy(columns, e => columnOrdinal(e.name as Column), 'asc')
	const defaults = defaultColumns(section === "extraction" || section === "approvals" || section === "main")

	return [...ordered, ...defaults]
}
