import { DemoRecord } from 'api/api-types'
import { getDemoUploadUrl, downloadDemoResult, uploadFile } from 'api/ApiServices'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { LOG } from 'logger/Log'
import * as React from 'react'
import "./demo.css"
import { ResultBox } from './ResultBox'
import { UploadBox } from './UploadBox'

export const Demo = () => {
	const [result, setResult] = React.useState<DemoRecord | null>(null)
	const [error, setError] = React.useState<string | null>(null)
	const [file, setFile] = React.useState<File | null>(null)
	const [spinning, setSpinning] = React.useState<boolean>(false)

	const onSubmit = async () => {
		if (!file) {
			return
		} else {
			// LOG.dev("file", file)
		}
		const name = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
		const folder = new Date().toISOString().substr(0, 10)
		const fullname = folder + '/' + name
		const urlResponse = await getDemoUploadUrl(fullname)
		if (urlResponse._type === 'data') {
			await uploadFile(urlResponse.data, file)
			setTimeout(fetchResults, 5000, fullname, 0)
			setSpinning(true)
		}
	}

	const fetchResults = async (name: string, counter: number) => {
		counter = counter + 1
		const demoResult = await downloadDemoResult(name)
		if (demoResult._type === 'data') {
			// If result not ready retry up to 12 times ~1min
			if (!demoResult.data && counter < 12) {
				setTimeout(fetchResults, 5000, name, counter)
			} else {
				// Display results
				setResult(demoResult.data)
				setError(null)
				setSpinning(false)
			}
		} else if (demoResult._type === 'generic_success') {
			// If result not ready retry up to 12 times ~1min
			if (counter < 12) {
				setTimeout(fetchResults, 5000, name, counter)
			} else {
				setError('Extraction took too long')
				setSpinning(false)
			}
		} else if (demoResult._type === 'error') {
			// setResult(result.data)
			setError(demoResult.error.message)
			setSpinning(false)
		} else {
			setSpinning(false)
		}
	}

	return (
		<>
			<div className="main-picture-container">
				<a href="https://mygide.com/">
					<img src="/AiBERT_purple2.png" alt="logo" id="logo" />
				</a>
			</div>
			{/* <div className="document-title">
				<span id="header-title">Artificial Intelligence Systems</span>
				<span id="header-subtitle">Beating volumes and time with our AI robots</span>
			</div> */}
			{/* <div className="timeline-container">
				<img src="/Timeline.png" alt="timeline" id="timeline-img" />
			</div> */}
			<div className="boxes-title-container">
				<span id="boxes-title">AI PDF INVOICE EXTRACTION</span>
			</div>
			<Form onSubmit={onSubmit} >
				<div className="boxes-container">
					<div className="upload-box-container">
						<UploadBox setFile={(file) => setFile(file)} file={file} />
					</div>
					<div className="result-box-container">
						<ResultBox fileLoaded={file != null} result={result} error={error} spinning={spinning} resetResult={() => {
							setResult(null)
							setError(null)
							setSpinning(false)
						}} />
					</div>
				</div>
				<div className="button-container">
					<SubmitButton children="Process" className="upload-button" disabled={spinning} />
				</div>
			</Form>
			{/* <div className="main-picture-container">
				<img src="/MainPic.png" alt="content" id="main-picture" />
			</div> */}
			{/* <div className="conactus-container">
				<form id="contact" action="" method="post">
					<h4>Contact us</h4>
					<fieldset>
						<input placeholder="Your name" type="text" tabIndex={1} required />
					</fieldset>
					<fieldset>
						<input placeholder="Your Email Address" type="email" tabIndex={2} required />
					</fieldset>
					<fieldset>
						<input placeholder="Your Phone Number (optional)" type="tel" tabIndex={3} required />
					</fieldset>
					<fieldset>
						<input placeholder="Your Web Site (optional)" type="url" tabIndex={4} required />
					</fieldset>
					<fieldset>
						<textarea placeholder="Type your message here...." tabIndex={5} required></textarea>
					</fieldset>
					<fieldset>
						<button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
					</fieldset>
				</form>
			</div> */}
		</>
	)
}