import { makeStyles, Theme, createStyles, Typography, Paper, Snackbar } from '@material-ui/core'
import { ApprovalModes, DupSettingsOpts, TrackNumSettingsOpts } from 'api/api-types'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

import _ from 'lodash'
import * as React from 'react'
import { putSettings } from 'api/ApiServices'
import { LOG } from 'logger/Log'
import { SubmitButton } from 'elements/form/SubmitButton'
import { UserContext } from 'main/AppWithUser'
import { Alert, AlertSettings } from 'elements/Alerts'

//TODO  fix css
const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			flexWrap: 'wrap'
		},
		row: {
			display: 'flex',
			width: '100%',
		},
		settingsWrapper: {
			display: 'flex',
			minWidth: '49%',
			maxWidth: '50%',
			flexDirection: 'column',
			padding: 16,

			alignSelf: 'flex-start',
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
		},
		marginLeft: {
			marginLeft: '16px',
		},
		marginRight: {
			marginRight: '16px',
		},
		settingWrapperMargin: {
			marginTop: 10
		},
		settingsRow: {
			marginTop: 5,
			marginBottom: 5,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		label: {
			opacity: 0.6,
			fontWeight: 700,
		},
		settingsTitle: {
			fontWeight: 700,
			marginBottom: 25
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
		},
		fullWidth: {
			width: '100%',
		},
		updateButton: {
			justifySelf: 'center',
			width: '50%',
		},
		// select: {
		// 	width: 200,
		// 	textAlign: 'right'
		// },
		// selectInner: {
		// 	border: 'none !important',
		// }
		containerColumn: {
			width: '70%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			padding: '16px 64px 16px 16px',
			marginBottom: '16px'
		},
		serviceRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '22px',

		},
		input: {
			width: '30%',
			justifySelf: 'flex-end'

		},
		description: {
			width: '48%',
			paddingLeft: '8px',
		},
		subTitle: {
			// paddingTop: 12,
			width: '20%',
		},
		title: {
			paddingBottom: 16
		}
	})
)

export enum DocTypes {
	INVOICE = "INVOICE",
	RECEIPT = "RECEIPT",
	DELIVERY_LIST = "DELIVERY LIST"
}

export function ProcessProtocols() {

	const { t } = useTranslation();

	const classes = useStyle()
	const user = React.useContext(UserContext)
	const [alert, setAlert] = React.useState<AlertSettings>({ message: '', open: false, color: 'success' })
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ ...alert, open: false })
	}
	const [docType, setDocType] = React.useState<{ value: DocTypes, label: string } | null>({ value: DocTypes.INVOICE, label: DocTypes.INVOICE })
	const [docTypeError, setDocTypeError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [trackingNumber, setTrackingNumber] = React.useState<{ value: TrackNumSettingsOpts, label: string } | null>(null)
	const [trackingNumberError, setTrackingNumberError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const [duplicate, setDuplicate] = React.useState<{ value: DupSettingsOpts, label: string } | null>(null)
	const [duplicateError, setDuplicateError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const [thirdParty, setThirdParty] = React.useState<{ value: string, label: string }>({ value: 'NO', label: 'NO' })
	const [thirdPartyError, setThirdPartyError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [requisitionSystem, setRequisitionSystem] = React.useState<{ value: string, label: string }>({ value: 'NO', label: 'NO' })
	const [requisitionSystemError, setRequisitionSystemError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [orderSystem, setOrderSystem] = React.useState<{ value: string, label: string }>({ value: 'NO', label: 'NO' })
	const [orderSystemError, setOrderSystemError] = React.useState({
		error: false,
		errorMessage: ""
	})
	//To change cause it is not selecet
	// const [noCheckTreshold, setNoCheckTreshold] = React.useState<{ value: number, label: string }[]>([])
	// const [noCheckTresholdError, setNoCheckTresholdError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })

	const [approvalMode, setApprovalMode] = React.useState<{ value: ApprovalModes, label: string } | null>(null)
	const [approvalModeError, setApprovalModeError] = React.useState({
		error: false,
		errorMessage: ""
	})
	// const [processes, setProcesses] = React.useState<AppProcessesType | null>(null)

	const tresholdRef = React.useRef<CustomInput>(null)
	const accountingDayRef = React.useRef<CustomInput>(null)
	const approvalPeriodLimitRef = React.useRef<CustomInput>(null)

	React.useEffect(() => {
		const userSettings = user.organisation.settings
		// user.refetch()
		// Ask Jorge weather any of this options can be null or undefined
		setTrackingNumber({ value: userSettings.whenAssignTrackingNum, label: userSettings.whenAssignTrackingNum })
		setApprovalMode({ value: userSettings.whichApprovalMode, label: userSettings.whichApprovalMode })
		setDuplicate({ value: userSettings.duplicateItemAction, label: userSettings.duplicateItemAction })
		// setProcesses(userSettings.processes)
		// This might cause an infinite fetching
	}, [user.organisation.settings])


	async function onSubmit() {
		// const settings : SettingsBody = {}
		//  if(treshholdRef.current!.value)
		//  const treshold = treshholdRef.current?.value
		if (!tresholdRef.current!.checkErrors()) {
			try {
				const response = await putSettings({
					whichApprovalMode: approvalMode!.value,
					duplicateItemAction: duplicate!.value,
					maxAmountNoItemCheck: parseInt(tresholdRef.current!.value),
					whenAssignTrackingNum: trackingNumber!.value,
					// processes: processes
					toAccountDate: parseInt(accountingDayRef.current!.value),
					approvalPeriodLimit: parseInt(approvalPeriodLimitRef.current!.value)

				})
				if (response._type === 'generic_success') {
					setAlert({ message: t("Alert.Protocols_Upd"), open: true, color: 'success' })
					user.refetch()
				}
			} catch (e) {
				setAlert({ message: t("Alert.Protocls_Upd_Fail"), open: true, color: 'warning' })
				LOG.dev("Error putting Settings", e)
			}
		} else {
		}
	}
	// Check for error if these are arrays are withouth unique key
	// The value of the enum are used for translation, not the keys
	function trackNumberOptions() {
		return _.toArray(TrackNumSettingsOpts).map(e => {

			return { value: e, label: t(e) }
		})
	}
	function approvalModeOptions() {
		return _.toArray(ApprovalModes).map(e => {
			return { value: e, label: t(e) }
		})
	}
	function duplicateOptions() {
		return _.toArray(DupSettingsOpts).map(e => {
			return { value: e, label: t(e) }
		})
	}
	function documentTypeOptions() {
		return _.toArray(DocTypes).map(e => ({ value: e, label: t(e) }))
	}
	function yesNoOptions() {
		return [{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }]
	}
	return (
		<Form onSubmit={onSubmit} className={classes.fullWidth}>
			<div className={classes.pageContent}>
				<Paper className={`${classes.containerColumn}`} style={{ alignSelf: 'stretch' }}>
					<Typography className={classes.title} variant='h6'>{t("Settings.Feed")}</Typography>
					<div className={classes.serviceRow}>
						<span className={classes.subTitle}>

							<Typography variant='subtitle1'>{t("Setttings.Doc_Types")}</Typography>
						</span>
						<div className={classes.input}>
							<Select
								isMulti
								name="TrackNumber"
								options={documentTypeOptions()}
								value={docType}
								onChange={selected => {
									if (selected != null) {
										if (trackingNumberError.error) {
											setDocTypeError({ error: false, errorMessage: "" })
										}
										setDocType(selected as { value: DocTypes, label: string })
									} else {
										setDocType(null)
									}
								}}
								className={`${classes.select}`}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									}),
									menu: provided => ({ ...provided, zIndex: 10 })
								}}
							/>
						</div>

						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Doc_Types_Desc")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow}>
						<span className={classes.subTitle}>

							<Typography variant='subtitle1'>{t("Settings.Track_num")}</Typography>
						</span>
						<div className={classes.input}>
							<Select
								name="TrackNumber"
								options={trackNumberOptions()}
								value={trackingNumber}
								onChange={selected => {
									if (selected != null) {
										if (trackingNumberError.error) {
											setTrackingNumberError({ error: false, errorMessage: "" })
										}
										setTrackingNumber(selected as { value: TrackNumSettingsOpts, label: string })
									} else {
										setTrackingNumber(null)
									}
								}}
								className={`${classes.select}`}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})
								}}
							/>
						</div>

						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Assign_Track_num")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow}>
						<span className={classes.subTitle}>
							<Typography variant='subtitle1'>{t("Settings.Dup_Docs")}</Typography>
						</span>

						<div className={classes.input}>
							<Select Finish Twice
								name="Duplicate"
								options={duplicateOptions()}
								value={duplicate}
								onChange={selected => {
									if (selected != null) {
										if (duplicateError.error) {
											setDuplicateError({ error: false, errorMessage: "" })
										}
										setDuplicate(selected as { value: DupSettingsOpts, label: string })
									} else {
										setDuplicate(null)
									}
								}}
								className={classes.select}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})
								}}
							/>
						</div>

						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Dup_Invoices")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow}>
						<span className={classes.subTitle}>
							<Typography variant='subtitle1'>Advanced validation</Typography>
						</span>

						{/* <div className={classes.input}>
							<Select Finish Twice
								name="Duplicate"
								options={duplicateOptions()}
								value={duplicate}
								onChange={selected => {
									if (selected != null) {
										if (duplicateError.error) {
											setDuplicateError({ error: false, errorMessage: "" })
										}
										setDuplicate(selected as { value: DupSettingsOpts, label: string })
									} else {
										setDuplicate(null)
									}
								}}
								className={classes.select}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})
								}}
							/>
						</div> */}

						<div className={classes.description}>
							<Typography variant='body2'> Contact develeoper team </Typography>
						</div>
					</div>

				</Paper>
				<Paper className={`${classes.containerColumn}`} style={{ alignSelf: 'stretch' }}>
					<Typography className={classes.title} variant='h6'>{t("Settings.Connectors")}</Typography>
					<div className={classes.serviceRow} >
						<span className={classes.subTitle}>
							<Typography variant='subtitle1'>{t("Settings.Sys_Integrator")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>

							<Select Finish Twice
								name="3rdpary"
								options={yesNoOptions()}
								value={thirdParty}
								onChange={selected => {
									if (selected != null) {
										if (duplicateError.error) {
											setThirdPartyError({ error: false, errorMessage: "" })
										}
										setThirdParty(selected as { value: string, label: string })
									} else {
										setThirdParty({ value: 'NO', label: 'NO' })
									}
								}}
								className={classes.select}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})
								}}
							/>
						</div>

						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Third_Integration")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow} >
						<span className={classes.subTitle}>
							<Typography variant='subtitle1'>{t("Settings.Req_System")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>

							<Select Finish Twice
								name="3rdpary"
								options={yesNoOptions()}
								value={requisitionSystem}
								onChange={selected => {
									if (selected != null) {
										if (duplicateError.error) {
											setRequisitionSystemError({ error: false, errorMessage: "" })
										}
										setRequisitionSystem(selected as { value: string, label: string })
									} else {
										setRequisitionSystem({ value: 'NO', label: 'NO' })
									}
								}}
								className={classes.select}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})
								}}
							/>
						</div>

						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Req_System_Desc")} </Typography>
						</div>
					</div>

					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle}>
							<Typography variant='subtitle1'>{t("Settings.Order_System")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>

							<Select Finish Twice
								name="3rdpary"
								options={yesNoOptions()}
								value={orderSystem}
								onChange={selected => {
									if (selected != null) {
										if (duplicateError.error) {
											setOrderSystemError({ error: false, errorMessage: "" })
										}
										setOrderSystem(selected as { value: string, label: string })
									} else {
										setOrderSystem({ value: 'NO', label: 'NO' })
									}
								}}
								className={classes.select}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})
								}}
							/>
						</div>

						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Order_System_Desc")} </Typography>
						</div>
					</div>
				</Paper>
				<Paper className={`${classes.containerColumn}`} style={{ alignSelf: 'stretch' }}>
					<Typography className={classes.title} variant='h6'>{t("Settings.Approval_Protocol")}</Typography>

					<div className={classes.serviceRow}>
						<span className={classes.subTitle}>

							<Typography variant='subtitle1'>{t("Settings.Approval_Proc")}</Typography>
						</span>
						<div className={classes.input}>

							<Select
								name="ApprovalMode"
								options={approvalModeOptions()}
								value={approvalMode}
								onChange={selected => {
									if (selected != null) {
										if (approvalModeError.error) {
											setApprovalModeError({ error: false, errorMessage: "" })
										}
										setApprovalMode(selected as { value: ApprovalModes, label: string })
									} else {
										setApprovalMode(null)
									}
								}}
								className={classes.select}
								styles={{
									control: (base, props) => ({
										...base,
										border: 'none',
										boxShadow: 'none'
									})

								}}
							/>
						</div>
						<div className={classes.description}>
							<Typography variant='body2'>{t("Settings.Approval_Process_Desc")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
							<Typography variant='subtitle1'>{t("Settings.Approval_Proc_Thres")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>

							<CustomInput
								ref={tresholdRef}
								errorText=""
								label=""
								required={true}
								type="number"
								placeholder=""
								//Chane to read from label not value.
								startingValue={user.organisation.settings.maxAmountNoItemCheck.toString()}
							/>
						</div>

						<div className={classes.description} style={{ paddingBottom: '20px' }}>
							<Typography variant='body2'>{t("Settings.Approval_Proc_Thres_Desc")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle} style={{ paddingBottom: '20px' }}>
							<Typography variant='subtitle1'>{t("Settings.Approval_Sum")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>

							<CustomInput
								// ref={}
								errorText=""
								label=""
								required={true}
								type="number"
								placeholder=""
							//Chane to read from label not value.
							// startingValue={user.organisation.settings.maxAmountNoItemCheck.toString()}
							/>
						</div>

						<div className={classes.description} style={{ paddingBottom: '20px' }}>
							<Typography variant='body2'>{t("Settings.Approval_Sum_Desc")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle} style={{ paddingBottom: '20px' }}>

							<Typography variant='subtitle1'>{t("Settings.Approval_Deadline")}</Typography>

						</span>
						{/* ClassName didnt work */}
						<div className={classes.input} >

							<CustomInput
								ref={approvalPeriodLimitRef}
								errorText=""
								label=""
								required={true}
								type="number"
								placeholder=""
								// This value need to be read from back-end
								startingValue={user.organisation.settings.approvalPeriodLimit?.toString()}
							/>
						</div>
						<div className={classes.description} style={{ paddingBottom: '20px' }}>
							<Typography variant='body2'>{t("Settings.Approval_Deadline_Desc")} </Typography>
						</div>
					</div>
				</Paper>
				<Paper className={`${classes.containerColumn}`} style={{ alignSelf: 'stretch' }}>

					<Typography className={classes.title} variant='h6'>{t("Settings.Booking_Protocols")}</Typography>

					{/* Place holder */}
					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle} style={{ paddingBottom: '20px' }}>
							<Typography variant='subtitle1'>{t("Settings.Booking_Cycle")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>
							<CustomInput
								// ref={accountingDayRef}
								errorText=""
								label=""
								required={true}
								type="number"
								placeholder=""
							// This value need to be read from back-end
							// startingValue={user.organisation.settings.toAccountDate?.toString()}
							/>
						</div>

						<div className={classes.description} style={{ paddingBottom: '20px' }}>
							<Typography variant='body2'>{t("Settings.Booking_Cycle_Desc")} </Typography>
						</div>
					</div>
					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle} style={{ paddingBottom: '20px' }}>
							<Typography variant='subtitle1'>{t("Settings.Booking_Date")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>
							<CustomInput
								ref={accountingDayRef}
								errorText=""
								label=""
								required={true}
								type="number"
								placeholder=""
								// This value need to be read from back-end
								startingValue={user.organisation.settings.toAccountDate?.toString()}
							/>
						</div>

						<div className={classes.description} style={{ paddingBottom: '20px' }}>
							<Typography variant='body2'>{t("Settings.Booking_Date_Desc")} </Typography>
						</div>
					</div>

				</Paper>
				{/* Place holders */}
				<Paper className={`${classes.containerColumn}`} style={{ alignSelf: 'stretch' }}>
					<Typography className={classes.title} variant='h6'>{t("Settings.Archive_Protocols")}</Typography>
					<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
						<span className={classes.subTitle} style={{ paddingBottom: '20px' }}>
							<Typography variant='subtitle1'>{t("Settings.Archive_Cycle")}</Typography>
						</span>
						{/* ClassName didnt work */}
						<div className={classes.input}>
							<CustomInput
								// ref={accountingDayRef}
								errorText=""
								label=""
								required={true}
								type="number"
								placeholder=""
							// This value need to be read from back-end
							// startingValue={user.organisation.settings.toAccountDate?.toString()}
							/>
						</div>

						<div className={classes.description} style={{ paddingBottom: '20px' }}>
							<Typography variant='body2'>{t("Settigns.Archive_Cycle_Desc")} </Typography>
						</div>
					</div>

				</Paper>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
					<SubmitButton className={classes.updateButton}>{t("Button.Update")}</SubmitButton>

				</div>
			</div>
			<Snackbar open={alert.open} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				<Alert onClose={handleCloseAlert} severity={alert.color}>
					{alert.message}
				</Alert>
			</Snackbar>
		</Form>

	)
}