import { DrawerContext } from 'drawer/AppWithDrawer'
import { Container, Page } from 'elements/Container'
import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import "styles/feed.css"
import { AccountDrawer } from './AccountDrawer'
// import { ApprovalGroups } from '../hr/approvals/ApprovalGroups'
import { Billings } from './Billing/Billings'
// import { Members } from '../hr/members/Members'
import { ProcessProtocols } from './ProcessProtocols'
import { Privacy } from './Privacy'
import { useApi } from 'api/useApi'
import { deleteSubscription, getAccount, getBillingDetails, getInvoiceHistory } from 'api/ApiServices'
import { CircularProgress, createStyles, IconButton, List, ListItem, ListItemText, makeStyles, Paper, Snackbar, Theme, Typography } from '@material-ui/core'
import { formatDateFromEpoch } from 'utils/DateTime'
import { Alert } from 'elements/Alerts'
import { CustomInput } from 'elements/form/CustomInput'
import { ApprovalModes, DupSettingsOpts, ServEnum, TrackNumSettingsOpts } from 'api/api-types'
import {
	XAxis,
	YAxis,
	RadialChart,
	FlexibleWidthXYPlot,
	VerticalBarSeries,
} from 'react-vis'
import { UserContext } from 'main/AppWithUser'
import _ from 'lodash'
import "react-vis/dist/style.css";
import { Pricing } from './Pricing'
import EditIcon from '@material-ui/icons/Edit';
import { LicenceNTerms } from './LicenceNTerms'
import { useTranslation } from 'react-i18next';
import { CompanyInformation } from './CompanyInformation'



type RouteParams = {
	sub: "members" | "ai-services" | "billings" | "privacy" | "overview" | "subscription" | 'process-protocols' | 'license' | 'company-information'
}


export function Account(props: RouteComponentProps<RouteParams>) {
	const drawerContext = React.useContext(DrawerContext)
	React.useEffect(() => {
		drawerContext.setRenderContent(() => (
			<AccountDrawer {...props} />
		))
	}, [props.location])

	let content
	// if (props.match.params.sub === "members") {
	// 	content = <Members />
	// } 
	if (props.match.params.sub === "ai-services") {
		// content = <AiServices />
	} else if (props.match.params.sub === "billings") {
		content = <Billings />
	} else if (props.match.params.sub === "privacy") {
		content = <Privacy />
	} else if (props.match.params.sub === "subscription") {
		content = <Pricing />
	} else if (props.match.params.sub === "process-protocols") {
		content = <ProcessProtocols />
		// } else if (props.match.params.sub === "approval-groups") {
		// 	content = <ApprovalGroups />
		// 
	} else if (props.match.params.sub === "company-information") {
		content = <CompanyInformation />
	} else if (props.match.params.sub === "license") {
		content = <LicenceNTerms />
	}


	else {
		content = <AccountX />
	}

	return (
		<Container page={Page.ACCOUNT} title="Account">
			{content}
		</Container>
	)

}

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		containerRow: {
			display: 'flex',
			// padding: '16px'
		},
		item: {
			width: '50%'
		},
		leftPadding: {
			paddingLeft: '16px'
		},
		rightPadding: {
			paddingRight: '16px'
		},
		marginLeft: {
			marginLeft: '16px',
		},
		marginRight: {
			marginRight: '16px',
		},
		containerColumn: {
			// width: '100%',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			padding: '16px 64px 32px 16px'
		},
		settingsRow: {
			width: '100%',

			marginBottom: 8,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
			alignItems: 'center'
		},
		serviceRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			paddingLeft: '16px',
		},
		serviceColumn: {
			display: 'flex',
			flexDirection: 'column',
		},
		label: {
			opacity: 0.6,
			fontWeight: 700,
			paddingBottom: '0px'
		},
		processes: {
			maxWidth: '40%',
			alignItems: 'flex-start',
			padding: '16px'

		},
		cancelbutton: {
			minWidth: '202px',
			backgroundColor: '#1976d2',
			"&:hover": {
				backgroundColor: '#1976d2'
			}

		},
		blocked: {
			position: 'relative',
			"&::after": {
				content: 'test',
				position: 'absolute',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 99,
				backgroundColor: 'green',

			}
		},
		serviceSubColumn: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
			width: '55%'
		},
		pieText: {
			width: '113px',
			top: '50%',
			left: '53%',
			'-webkit-transform': 'translate(-50%, -50%)',
			position: 'absolute'
		}
	})
)


const chartData = {

	// labels: [
	// 	'Boston',
	// 	'Worcester',
	// 	'Springfield',
	// 	'Lowell',
	// 	'Cambridge',
	// 	'New Bedford'
	// ],
	datasets: [
		{
			data: [{ x: 'Jan', y: 11 }, { x: 'Feb', y: 11 }, { x: 'Mar', y: 11 }, { x: 'Apr', y: 11 }, { x: 'May', y: 617594 }, { x: 'Jun', y: 617594 }, { x: 'Jul', y: 617594 }, { x: 'Aug', y: 617594 }, { x: 'Sep', y: 617594 }, { x: 'Oct', y: 617594 }, { x: 'Nov', y: 617594 }, { x: 'Dec', y: 617594 }],
			backgroundColor: 'blue',
			// backgroundColor: [
			// 	'rgba(255, 99, 132, 0.6)',
			// 	'rgba(54, 162, 235, 0.6)',
			// 	'rgba(255, 206, 86, 0.6)',
			// 	'rgba(75, 192, 192, 0.6)',
			// 	'rgba(153, 102, 255, 0.6)',
			// 	'rgba(255, 159, 64, 0.6)',
			// 	'rgba(255, 99, 132, 0.6)'
			// ]
		}
	]

}

const data = [{ x: 'Jan', y: 11 }, { x: 'Feb', y: 5 }, { x: 'Mar', y: 11 }, { x: 'Apr', y: 11 }, { x: 'May', y: 11 }, { x: 'Jun', y: 3 }, { x: 'Jul', y: 11 }, { x: 'Aug', y: 11 }, { x: 'Sep', y: 11 }, { x: 'Oct', y: 11 }, { x: 'Nov', y: 11 }, { x: 'Dec', y: 11 }]
const labels = [{ x: 1, y: 11, label: 'Test' }, { x: 2, y: 5, label: 3 }, { x: 3, y: 11, label: 'Test' }, { x: 4, y: 11, label: 'Test' }, { x: 5, y: 11, label: 'Test' }, { x: 6, y: 3, label: 'Test' }, { x: 7, y: 11, label: 'Test' }, { x: 8, y: 11, label: 'Test' }, { x: 9, y: 11, label: 'Test' }, { x: 10, y: 11, label: 'Test' }, { x: 11, y: 11, label: 'Test' }, { x: 12, y: 11, label: 'Test' }]

// const chartOptions: ChartOptions = {
// 	scales: {

// 	}

// 	// scales: {
// 	// 	xAxes: [{
// 	// 		scaleShowVerticalLines: true,
// 	// 		gridLines: {
// 	// 			display: false
// 	// 		}
// 	// 	}],
// 	// 	yAxes: [{
// 	// 		gridLines: {
// 	// 			display: false
// 	// 		}
// 	// 	}]
// 	// }
// }

export function AccountX() {
	const classes = useStyle()
	const user = React.useContext(UserContext)
	const { t } = useTranslation();

	const [billingDetails, refetchBillingDetails] = useApi({
		apiRequest: getBillingDetails
	})
	const [account] = useApi({
		apiRequest: getAccount
	})

	const [invoiceHistory] = useApi({
		apiRequest: getInvoiceHistory
	})

	const [history, setHistory] = React.useState<{ month: string, count: number }[] | 'loading'>([])

	const [trackingNumber, setTrackingNumber] = React.useState<{ value: TrackNumSettingsOpts, label: string } | null>(null)
	const [trackingNumberError, setTrackingNumberError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const [duplicate, setDuplicate] = React.useState<{ value: DupSettingsOpts, label: string } | null>(null)
	const [duplicateError, setDuplicateError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const [approvalMode, setApprovalMode] = React.useState<{ value: ApprovalModes, label: string } | null>(null)
	const [approvalModeError, setApprovalModeError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const [alertsMessage, setAlertsMessage] = React.useState<string>('')
	const [openAlert, setOpenAlert] = React.useState(false);
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAlert(false)
	}


	const nameRef = React.useRef<CustomInput>(null)
	const emailRef = React.useRef<CustomInput>(null)
	const departmentRef = React.useRef<CustomInput>(null)
	const titleRef = React.useRef<CustomInput>(null)
	const jobRoles = React.useRef<CustomInput>(null)
	const companyRef = React.useRef<CustomInput>(null)

	const tresholdRef = React.useRef<CustomInput>(null)
	const approvalPeriodLimitRef = React.useRef<CustomInput>(null)

	React.useEffect(() => {
		const userSettings = user.organisation.settings

		setTrackingNumber({ value: userSettings.whenAssignTrackingNum, label: userSettings.whenAssignTrackingNum })
		setApprovalMode({ value: userSettings.whichApprovalMode, label: userSettings.whichApprovalMode })
		setDuplicate({ value: userSettings.duplicateItemAction, label: userSettings.duplicateItemAction })

	}, [])
	React.useEffect(() => {
		if (invoiceHistory !== 'loading' && invoiceHistory != null) {
			let tmpHistory = []

			for (let i = 1; i <= 12; i++) {
				let tmp = invoiceHistory?.find(e => e.month === i)
				if (tmp) {
					tmpHistory.push({ month: numToMonth(tmp.month).toString(), count: tmp.count })
				} else {
					tmpHistory.push({ month: numToMonth(i).toString(), count: 0 })
				}
			}
			setHistory(tmpHistory)
		}

	}, [invoiceHistory])
	function numToMonth(num: number) {
		switch (num) {
			case 1: return 'Jan'
			case 2: return 'Feb'
			case 3: return 'Mar'
			case 4: return 'Apr'
			case 5: return 'May'
			case 6: return 'Jun'
			case 7: return 'Jul'
			case 8: return 'Aug'
			case 9: return 'Sep'
			case 10: return 'Oct'
			case 11: return 'Nov'
			case 12: return 'Dec'
			default: return 'JAn'



		}

	}
	// const handleDelete = async (e: React.FormEvent) => {
	// 	e.preventDefault();
	// 	const billingDetails = await getBillingDetails()
	// 	if (billingDetails._type === 'data') {
	// 		const subscriptionId = billingDetails.data.billing?.stripeSubscriptionId
	// 		console.log(billingDetails.data)
	// 		if (subscriptionId) {
	// 			const response = await deleteSubscription(subscriptionId)
	// 			console.log(response)
	// 			if (response._type === 'generic_success') {
	// 				setAlertsMessage('Subscription canceled')
	// 				setOpenAlert(true)
	// 				refetchBillingDetails()
	// 			}
	// 		}
	// 	} else {
	// 		console.log("Error getting billing details")
	// 	}

	// }

	function trackNumberOptions() {
		return _.toArray(TrackNumSettingsOpts).map(e => ({ value: e, label: e }))
	}
	function approvalModeOptions() {
		return _.toArray(ApprovalModes).map(e => ({ value: e, label: e }))
	}
	function duplicateOptions() {
		return _.toArray(DupSettingsOpts).map(e => ({ value: e, label: e }))
	}

	function renderRow(title: string, value: any) {
		return (
			<>
				<div className={classes.settingsRow}>
					<span style={{ width: '55%' }}>
						<Typography variant='subtitle1'>{title}</Typography>
					</span>
					{/* Not working, check with Jorge  */}
					<span >
						<Typography variant='body2'>{value}</Typography>
					</span>
				</div>
			</>
		)
	}

	const servicesAll = _.toArray(ServEnum)
	function renderServiceRows(services: ServEnum[]) {
		let tmp: { angle: number, radius: number, label: string, color: string, subLabel?: string, padAngle: number }[] = []
		let indexTrue: number[] = []

		servicesAll.forEach((e) => {
			if (services.includes(e)) {
				if (e === ServEnum.DOCUMENTATION_PAIRING) {
					tmp.push(
						{ angle: 3, radius: 5, label: 'Connector', color: '#08ad6c', padAngle: 10 }
					)
				} else if (e === ServEnum.APPROVAL) {
					tmp.push(
						{ angle: 3, radius: 5, label: 'Approval', color: '#08ad6c', padAngle: 10 }
					)
				} else if (e === ServEnum.BOOKING) {
					tmp.push(
						{ angle: 3, radius: 5, label: 'Booking', color: '#08ad6c', padAngle: 10 }
					)
				} else {
					tmp.push(
						{ angle: 3, radius: 5, label: e, color: '#08ad6c', padAngle: 10 }
					)
				}
			} else {
				if (e === ServEnum.DOCUMENTATION_PAIRING) {
					tmp.push(
						{ angle: 3, radius: 5, label: 'Connector', color: 'rgba(0, 0, 0, 0.1)', padAngle: 10 }
					)
				} else if (e === ServEnum.APPROVAL) {
					tmp.push(
						{ angle: 3, radius: 5, label: 'Approval', color: 'rgba(0, 0, 0, 0.1)', padAngle: 10 }
					)
				} else if (e === ServEnum.BOOKING) {
					tmp.push(
						{ angle: 3, radius: 5, label: 'Booking', color: 'rgba(0, 0, 0, 0.1)', padAngle: 10 }
					)
				} else {
					tmp.push(
						{ angle: 3, radius: 5, label: e, color: 'rgba(0, 0, 0, 0.1)', padAngle: 10 }
					)
				}
			}
		})
		return tmp
		// console.log('These are servicesAll', servicesAll)
		// console.log('These are services from back', services)
		// servicesAll.forEach((e, index) => {
		// 	if (services)
		// 		services.forEach(d => {
		// 			if (e == d) {

		// 				indexTrue.push(index)
		// 			} else {

		// 			}
		// 		})
		// })

		// servicesAll.forEach((e, index) => {
		// 	if (indexTrue.includes(index)) {
		// 		console.log('labelBLUE:', e)
		// 		if (e === 'Documentation Pairing') {
		// 			tmp.push(
		// 				{ angle: 3, radius: 5, label: 'Connector', color: '#08ad6c', padAngle: 10 }
		// 			)
		// 		} else {
		// 			tmp.push(
		// 				{ angle: 3, radius: 5, label: e, color: '#08ad6c', padAngle: 10 }
		// 			)
		// 		}
		// 	} else {
		// 		console.log('labelRed:', e)
		// 		if (e === 'Documentation Pairing') {
		// 			tmp.push(
		// 				{ angle: 3, radius: 5, label: 'Connector', color: 'rgba(0, 0, 0, 0.1)', padAngle: 10 }
		// 			)
		// 		} else {
		// 			tmp.push(
		// 				{ angle: 3, radius: 5, label: e, color: 'rgba(0, 0, 0, 0.1)', padAngle: 10 }
		// 			)
		// 		}
		// 	}

		// })

	}

	if (billingDetails === 'loading' || account === 'loading') {
		return <CircularProgress></CircularProgress>
	} else
		return (<>
			<div className={`${classes.containerRow}`}>
				<Paper className={`${classes.containerColumn} ${classes.marginRight}`} >
					<Typography style={{ paddingBottom: 16 }} variant="h6" >{t("Account.Info")}</Typography>
					{/* {renderRow('Name', `${account?.firstName} ${account?.lastName}`)}
					{renderRow('Email', account?.username)}
					{renderRow('Department', account?.departmentName)}
					{renderRow('Title', account?.title)}
					{renderRow('Job Roles', account?.jobRoles.toString())}
					{renderRow('Company', account?.company)} */}
					<CustomInput
						required={false}
						disabled
						key="name"
						ref={nameRef}
						label={t("Account.Name")}
						errorText=""
						placeholder="Write your Name"
						type="text"
						startingValue={account?.firstName}
					// startingValue={supplier?.value.compIco}
					/>
					<CustomInput
						required={false}
						disabled
						key="email"
						ref={emailRef}
						label={t("Account.Email")}
						errorText=""
						placeholder="Write your Email"
						type="text"
						startingValue={account?.username}
					// startingValue={supplier?.value.compIco}
					/>
					<CustomInput
						required={false}
						disabled
						key="department"
						ref={departmentRef}
						label={t("Hr.Department")}
						errorText=""
						placeholder="Write your line"
						type="text"
						startingValue={account?.departmentName}
					// startingValue={supplier?.value.compIco}
					/>
					<CustomInput
						required={false}
						disabled
						key="title"
						ref={titleRef}
						label={t("Hr.Title")}
						errorText=""
						placeholder="Write your line"
						type="text"
						startingValue={account?.title}
					// startingValue={supplier?.value.compIco}
					/>
					<CustomInput
						required={false}
						disabled
						key="jobRoles"
						ref={jobRoles}
						label={t("Hr.Job_Roles")}
						errorText=""
						placeholder="Write your line"
						type="text"
						startingValue={account?.jobRoles.toString()}
					// startingValue={paymentDetails?.billingDetails.address.line1}
					// startingValue={supplier?.value.compIco}
					/>
					<CustomInput
						required={false}
						disabled
						key="company"
						ref={companyRef}
						label={t("Hr.Company")}
						errorText=""
						placeholder="Write your Company"
						type="text"
						startingValue={account?.company}
					// startingValue={supplier?.value.compIco}
					/>

				</Paper>
				<Paper className={`${classes.containerColumn} ${classes.marginRight}`} style={{ paddingRight: '16px', paddingTop: '8px' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
						<Typography style={{ width: '50%' }} variant="h6">{t("Account.Subscribed_Services")}</Typography>
						<div style={{ marginLeft: 'auto' }}>
							<Link to="/settings/subscription" >
								<IconButton>
									<EditIcon></EditIcon>
								</IconButton>
							</Link>
						</div>
					</div>

					{/* {renderRow('Plan', billingDetails?.billing?.subscription)}
					{renderRow('Subscription Interval', billingDetails?.billing?.plan)}
					{renderRow('Prepaid Documents', `${billingDetails?.invoiceCountMonth} / ${billingDetails?.invoiceLimitMonth}`)}
					{renderRow('Subscription Renewal Date', billingDetails!.invoiceCountReset ? formatDateFromEpoch(parseInt(billingDetails!.invoiceCountReset), "dd/MM/yyy") : '')}
					{renderRow('Payment Status', billingDetails?.billing?.paymentStatus)} */}

					<div className={classes.serviceSubColumn} style={{ margin: 'auto' }}>
						<span style={{ width: '55%', alignSelf: 'start' }}>
							{/* <Typography variant='subtitle1'>Services</Typography> */}
						</span>
						{/* Not working, check with Jorge  */}

						<div style={{ alignSelf: 'center', position: 'relative' }}>
							<div className={classes.pieText}>
								<List>
									{renderServiceRows(billingDetails?.billing?.activeServices!).map(e => <>
										<ListItem key={`${e.label}`} style={{ paddingBottom: '0px', paddingTop: '4px' }}>
											<ListItemText>
												<span style={{ position: 'relative' }}>
													<span style={{
														// position: 'absolute',
														verticalAlign: 'middle',
														lineHeight: '1.43',
														height: '8px',
														width: '8px',
														// marginBottom: '4px',
														marginRight: '8px',
														// lineHeight: '50px',
														backgroundColor: `${e.color}`,
														borderRadius: '50%',
														display: 'inline-block'
													}}></span>{t(e.label)}</span>
											</ListItemText>
										</ListItem>
									</>)}
								</List>

							</div>

							<RadialChart
								style={{ margin: 'auto' }}
								innerRadius={110}
								radius={120}
								data={renderServiceRows(billingDetails?.billing?.activeServices!)}
								width={350}
								height={350}
								colorType='literal'
								// showLabels={true}
								labelsAboveChildren={false}
							/>
						</div>
						{/* <LabelSeries
							animation
							allowOffsetToBeReversed
							getLabel={d => d.y}
							data={data} /> */}
					</div>


					{/* <Button className={classes.cancelbutton} variant='contained' color='primary' onClick={handleDelete} style={{ alignSelf: 'center' }}>Cancel Subscription</Button> */}

				</Paper>
				<Paper className={`${classes.containerColumn} `}>
					<Typography style={{ paddingBottom: 16 }} variant="h6">{t("Account.Performance")}</Typography>
					{/* {renderRow('Plan', billingDetails?.billing?.subscription)}
					{renderRow('Subscription Interval', billingDetails?.billing?.plan)}
					{renderRow('Prepaid Documents', `${billingDetails?.invoiceCountMonth} / ${billingDetails?.invoiceLimitMonth}`)}
					{renderRow('Subscription Renewal Date', billingDetails!.invoiceCountReset ? formatDateFromEpoch(parseInt(billingDetails!.invoiceCountReset), "dd/MM/yyy") : '')}
					{renderRow('Payment Status', billingDetails?.billing?.paymentStatus)}

					<div className={classes.serviceSubColumn}>
						<span style={{ width: '55%', alignSelf: 'start' }}>
							<Typography variant='subtitle1'>Services</Typography>
						</span>

						<div style={{ alignSelf: 'center', position: 'relative' }}>
							<div className={classes.pieText}>
								<List>
									{renderServiceRows(billingDetails?.billing?.activeServices!).map(e => <>
										<ListItem key={`${e.label}`} style={{ paddingBottom: '0px', paddingTop: '4px' }}>
											<ListItemText><Typography variant='body2' style={{ color: (e.color === 'red') ? 'red' : 'grey' }}>{e.label}</Typography></ListItemText>
										</ListItem>
									</>)}
								</List>

							</div>
							<RadialChart
								innerRadius={100}
								radius={110}
								data={renderServiceRows(billingDetails?.billing?.activeServices!)}
								width={300}
								height={300}
								labelsAboveChildren={false}
							/>
						</div>
						
					</div> */}



				</Paper>

				{/* <Pie type='pie'
					data={chartData}

				/> */}

			</div>
			<div className={`${classes.containerRow}`} style={{ paddingTop: '16px' }}>
				<Paper className={` ${classes.blocked}`} style={{ width: '100%' }}>
					<Typography style={{ padding: 16 }} gutterBottom variant="h6" >Documents Processed</Typography>
					<div style={{ justifySelf: 'center' }}>
						{history !== 'loading' ?

							< FlexibleWidthXYPlot height={260} xType="ordinal" color='#1976d2'>
								<YAxis></YAxis>
								<XAxis></XAxis>

								<VerticalBarSeries data={history?.map(e => {
									return { x: e.month, y: e.count }
								})} barWidth={0.4} />
								{/* <LabelSeries
							animation
							allowOffsetToBeReversed
							getLabel={d => d.y}
							data={data} /> */}
							</FlexibleWidthXYPlot> : <CircularProgress></CircularProgress>
						}
					</div>
					{/* <Bar
						type='bar'
						data={chartData}
						options={chartOptions}
						// type="bar"
						// data={chartData}
						width={100}
						height={35}
						style={{ paddingLeft: 16, paddingRight: 16 }}
					// options={chartOptions}


					/> */}
				</Paper>
				{/* <div className={classes.marginLeft} style={{ width: '70%' }}>
					<MembersPartial />
					</div> */}
				<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert onClose={handleCloseAlert} severity={'success'}>
						{alertsMessage}
					</Alert>
				</Snackbar>
			</div>

		</>
		)
}


