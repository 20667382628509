import * as React from "react"
import { makeStyles, Theme, createStyles, AppBar, Toolbar, Typography, useTheme, Box, Button, Tooltip, IconButton, Input, Snackbar } from "@material-ui/core"
import { Link, RouteComponentProps } from "react-router-dom"
import "styles/header.css"
import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from "../drawer/MainDrawer"
import { AuthContext } from "utils/Auth"
import * as _ from 'lodash'
import { JobRole } from "api/api-types"
import { Upload } from "account/Upload"
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import Select, { components, IndicatorProps } from 'react-select'
import LanguageIcon from '@material-ui/icons/Language';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Alert } from 'elements/Alerts';
import { OpenParams } from "drawer/rightDrawer/RightDrawer"
import HelpIcon from '@material-ui/icons//Help';


export const HEADER_HEIGHT = 52
// export const DRAWER_WIDTH_SM = 64

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			width: `calc(100% - ${DRAWER_WIDTH_SM}px)`,

			backgroundColor: "#ffffff",
			boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042), 0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
			height: HEADER_HEIGHT,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${DRAWER_WIDTH}px)`,
				// marginLeft: DRAWER_WIDTH,
			},
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		appBarShiftRight: {
			// 72 is smaller size
			width: `calc(100% - ${DRAWER_WIDTH + DRAWER_WIDTH_SM}px)`,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: DRAWER_WIDTH,
		},
		appBarShiftBoth: {
			width: `calc(100% - ${DRAWER_WIDTH * 2}px)`,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: DRAWER_WIDTH,
		},
		menuButton: {
			marginRight: theme.spacing(2),
			// [theme.breakpoints.up('sm')]: {
			// 	display: 'none',
			// },
			color: 'blue',
			letterSpacing: '20px'
		},
		button: {
			color: 'rgba(0, 0, 0, 0.54)',
			'&:focus': {
				outline: "none"
			},
			width: 146,
			marginLeft: 5,
			fontSize: '0.75rem',
			borderRadius: 0,
			paddingBottom: 7,
			'& :hover': {
				color: '#222A45'
			}

		},
		active: {
			color: '#222A45',
			fontWeight: 700,
			borderBottom: '2px solid #222A45',
			paddingBottom: 5
		},
		label: {
			letterSpacing: '0.1rem'
		},
		link: {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'none'
			}
		},
		iconButtons: {
			maxWidth: '50px'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
			minWidth: 118
		},
		selectIcon: {
			color: 'rgba(0, 0, 0, 0.54)'
		}
	})
)

type Props = {
	setMobileOpen: () => void
	setLeftDrawerOpen: () => void
	leftDrawerOpen: boolean
	setRightDrawerOpen: (openParams: OpenParams) => void;
	rightDrawerOpen: OpenParams

} & RouteComponentProps

enum Language {
	ENGLISH = 'English',
	SLOVAK = 'Slovak',
	SPANISH = 'Spanish',
	GERMAN = 'German'
}

export function Header(props: Props) {
	const theme = useTheme()
	const classes = useStyles(theme)
	const authContext = React.useContext(AuthContext)
	const { t, i18n } = useTranslation();

	const [lang, setLang] = React.useState<{ value: Language, label: string } | null>({ value: codeToLan(i18n.language), label: `${codeToLan(i18n.language)}` })

	const [alert, setAlert] = React.useState<{ message: string, open: boolean }>({ message: '', open: false })
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ ...alert, open: false })
	}

	function languageOptions() {
		return _.toArray(Language).map(e => ({ value: e, label: e }))
	}

	const changeLanguage = (lng: string) => {
		let code
		if (lng === "Slovak") {
			code = "sk"
		} else if (lng === 'Spanish') {
			code = "es"
		} else {
			code = "en"
		}
		i18n.changeLanguage(code);
	};

	function codeToLan(code: string): Language {
		let lan
		if (code === "sk") {
			lan = "Slovak"
		} else if (code === 'de') {
			lan = "German"
		} else if (code === 'es') {
			lan = "Spanish"
		} else {
			lan = "English"
		}
		return lan as Language
	}

	function getNavigationByRole() {
		const { roles } = authContext.getUser()!
		const isAdmin = _.some(roles, e => e === JobRole.ADMIN)
		const isApprover = _.some(roles, e => e === JobRole.APPROVER)

		let content: React.ReactNode
		if (isAdmin) {
			content = (
				<>
					<Link to="/feed" className={classes.link}>
						<Button color="inherit" classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname === '/feed' ? classes.active : ''}`}>{t('Main.Feed')}</Button>
					</Link>
					<Link to="/reporting" className={classes.link}>
						<Button color="inherit" disabled classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname === '/reporting' ? classes.active : ''}`}>{t('Main.Reporting')}</Button>
					</Link>
					<Link to="/procurement/suppliers" className={classes.link}>
						<Button color="inherit" classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname.startsWith('/procurement') ? classes.active : ''}`}>{t('Main.Procurement')}</Button>
					</Link>
					<Link to="/inventory/overview" className={classes.link}>
						<Button color="inherit" disabled classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname.startsWith('/inventory') ? classes.active : ''}`}>{t('Main.Inventory')}</Button>
					</Link>
					<Link to="/invoicing/invoice" className={classes.link}>
						<Button color="inherit" classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname.startsWith('/invoicing') ? classes.active : ''}`}>{t('Main.Invoicing')}</Button>
					</Link>
					<Link to="/yellow-pages/employees" className={classes.link}>
						<Button color="inherit" classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname.startsWith('/yellow-pages') ? classes.active : ''}`}>{t('Main.Yellow_Pages')}</Button>
					</Link>
					{/* <Link to="/dashboard" className={classes.link}>
						<Button color="inherit" classes={{ label: classes.label }} className={`${ classes.button } ${ props.history.location.pathname === '/dashboard' ? classes.active : '' } `}>Dashboard</Button>
					</Link> */}
				</>
			)
		} else {
			content = roles.map(role => {
				if (role === JobRole.PROCUREMENT_ADMIN || role === JobRole.APPROVER) {
					return (
						<Link key="feed" to="/feed" className={classes.link}>
							<Button color="inherit" classes={{ label: classes.label }} className={`${classes.button} ${props.history.location.pathname === '/feed' ? classes.active : ''}`}>{t('Main.Feed')}</Button>
						</Link>
					)
				} else {
					return null
				}
			})
		}

		const DropdownIndicator = (props: any) => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						{props.selectProps.menuIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}

						{/* <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"} /> */}
					</components.DropdownIndicator>
				)
			);
		};
		// @ts-ignore
		const ValueContainer = ({ children, ...props }) => {
			return (
				components.ValueContainer && (
					// @ts-ignore
					<components.ValueContainer {...props}>
						{!!children && (
							<LanguageIcon className={classes.selectIcon} style={{ position: 'absolute', left: 6 }} />

						)}
						<div style={{ left: 100 }}>
							{children}
						</div>
					</components.ValueContainer>
				)
			);
		};

		return (
			<>
				<Box className="left-toolbar">
					{content}
				</Box>
				<Box className="right-toolbar">
					<Tooltip title='Help'>
						<IconButton aria-label='Help' onClick={() => {
							props.setRightDrawerOpen({ open: true, for: 'help' })
						}}>
							< HelpIcon className="bgBlue" />
						</IconButton>
					</Tooltip>
					<Select
						components={{ ValueContainer: ValueContainer, DropdownIndicator: DropdownIndicator }}
						// placeholder={<span>tst</span>}
						key="tst4"
						name="Manager"
						options={languageOptions()}
						value={lang}
						onChange={selected => {
							if (selected) {
								const lan = selected as { value: Language, label: string }
								changeLanguage(lan.value)
							}
							setLang(selected as { value: Language, label: string })
						}}
						className={classes.select}
						styles={{
							container: (base, props) => ({
								...base,
								border: 'none',
								boxShadow: 'none',
								display: 'flex'
							}),
							control: (base, props) => ({
								...base,
								border: 'none',
								boxShadow: 'none',
								minWidth: 118

							}),
							singleValue: (base, props) => ({
								...base,
								marginLeft: '24px'
							}),
							menuList: (base, props) => ({
								...base,
								color: 'black'
							}),
						}}
					/>
					{(isAdmin) && <Tooltip title="Upload invoices">
						<IconButton id='icon' onClick={() => {
							props.setRightDrawerOpen({ open: true, for: 'upload' })
						}}
							role="alert" style={{ lineHeight: 1.5 }} >
							<CloudUploadIcon className='bgOrange' />
						</IconButton>
					</Tooltip>}
					{/* <Upload /> */}
					{(isAdmin) && < Tooltip title='Download last month report'>
						<IconButton aria-label='Download last month report' onClick={() => {
							props.setRightDrawerOpen({ open: true, for: 'download' })
						}}>
							< CloudDownloadIcon className="bgBlue" />
						</IconButton>
					</Tooltip>}
					{/* <Button color="inherit" classes={{ label: classes.label }} className={`${ classes.button } ${ props.history.location.pathname.startsWith('/account') ? classes.active : '' } `}>Account</Button> */}

					{isAdmin && <Link to="/settings/overview" className={`${classes.link} login - button`} >
						<Tooltip title='Settings'>

							<IconButton className={`${classes.button} ${classes.iconButtons} ${props.history.location.pathname.startsWith('/account') ? classes.active : ''} `}>
								{/* <AccountCircleIcon /> */}
								<SettingsIcon />
							</IconButton>
						</Tooltip>
						{/* <Button color="inherit" classes={{ label: classes.label }} className={`${ classes.button } ${ props.history.location.pathname.startsWith('/account') ? classes.active : '' } `}>Account</Button> */}
					</Link>}
					{/* <Link to="/settings/settings" className={classes.link}>
						<Tooltip title='Settings'>
							<IconButton className={`${ classes.button } ${ classes.iconButtons } ${ props.history.location.pathname.startsWith('/settings') ? classes.active : '' } `}>
								<SettingsIcon />
							</IconButton>
						</Tooltip>
					</Link> */}
					<Link to="/" className={classes.link}>
						<Tooltip title='Log Out'>

							<IconButton className={`${classes.button} ${classes.iconButtons} `} onClick={() => {
								authContext.logout()
							}}>

								<PowerSettingsNewIcon />
							</IconButton>
						</Tooltip>
					</Link>
					{/* <Button color="inherit" classes={{ label: classes.label }} className={`${ classes.button } ${ props.history.location.pathname.startsWith('/account') ? classes.active : '' } `}  onClick={() => {
						authContext.logout()
					}}>Logout</Button> */}
				</Box>
			</>
		)

	}

	return (
		<AppBar position="fixed" className={clsx(classes.appBar, {

			//  For left is inverted
			[classes.appBarShift]: props.leftDrawerOpen,
			[classes.appBarShiftRight]: props.rightDrawerOpen.open,
			[classes.appBarShiftBoth]: props.rightDrawerOpen.open && props.leftDrawerOpen
		})}>
			<Toolbar style={{ maxHeight: 52 }}>
				<Snackbar open={alert.open} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert onClose={handleCloseAlert} severity={'success'}>
						{alert.message}
					</Alert>
				</Snackbar>
				{getNavigationByRole()}
			</Toolbar>
		</AppBar>
	)
}
