import { Item, UiViews } from 'api/api-types'
import { getArchive, getCurrentItemsCount, getItems } from 'api/ApiServices'
import * as React from 'react'
import { AuthContext } from 'utils/Auth'

type Props = {
	pollInterval: number
}

export type ViewParams = {
	forView: "feed"
	uiView: UiViews
} | {
	forView: "archive"
	month: number
	year: number
}

export type PaginationParams = {
	offset: number
	limit: number
}

export function useFetchItems({ pollInterval }: Props) {
	// Whatch out if you need to change places inside array
	// access prev state in that case
	const { roles, iss } = React.useContext(AuthContext).getUser()!

	const [items, setItems] = React.useState<Item[]>([])
	const [currentCount, setCurrentCount] = React.useState<number>(0)

	const [viewParams, setViewParams] = React.useState<ViewParams>({
		forView: "feed",
		uiView: UiViews.CONTROL_BOARD
	})

	const [paginationParams, setPaginationParams] = React.useState<PaginationParams>({
		offset: 0,
		limit: 10,
	})

	const intervalRef = React.useRef<NodeJS.Timeout | null>(null)

	const fetchItems = async (viewP?: ViewParams, paginationP?: PaginationParams) => {



		let viewParamsX: ViewParams
		if (viewP == null) {
			viewParamsX = viewParams

		} else {
			viewParamsX = viewP
		}

		let paginationParamsX: PaginationParams
		if (paginationP == null) {
			// Ako si promenio view, resetuj params
			// u suprotnom ostavi isto...
			if (viewParamsX !== viewParams) {
				paginationParamsX = {
					limit: 10,
					offset: 0
				}
			} else {
				paginationParamsX = paginationParams
			}
		} else {
			paginationParamsX = paginationP
		}


		// console.log("ViewParamsX:   ", viewParamsX)
		// console.log("PaginationParamsX:   ", paginationParamsX)
		let itemsTmp: Item[] = []
		if (viewParamsX.forView === "feed") {
			// Do Feed
			const count = await getCurrentItemsCount(viewParamsX.uiView)
			if (count._type === 'data') {
				setCurrentCount(count.data)
			}
			const data = await getItems(viewParamsX.uiView || UiViews.CONTROL_BOARD, paginationParamsX.offset, paginationParamsX.limit)
			if (data._type === 'data') {
				if (viewParamsX.uiView === UiViews.PENDING_APPROVAL) {
					data.data.forEach(e => {
						if (e.approvers) {
							let approved = e.approvers.map((b) => {
								if (b.username === iss && b.approved) {
									return 1
								} else {
									return 0
								}
							}).reduce((a: number, b: number) => a + b, 0)
							// Ask Jorge
							itemsTmp.push({ ...e, approvedByMe: approved !== 0 })
						}
					})
				} else {
					itemsTmp = [...data.data]

				}
			} else if (data._type === 'error') {
				console.log('error', data.error.id)
				// LOG.dev(data.error)
			}
		} else if (viewParamsX.forView === "archive") {
			// Do archive
			const data = await getArchive({ month: viewParamsX.month, year: viewParamsX.year })
			if (data._type === 'data') {
				itemsTmp = [...data.data]
			} else {
				console.log("Error fetching Archive", data)
			}
		} else {
			throw Error()
		}
		// console.log('This are items after fetch', itemsTmp)
		// in the end sace params
		setViewParams(viewParamsX)
		setPaginationParams(paginationParamsX)

		// then save items
		setItems(itemsTmp)
	}

	React.useEffect(() => {
		// console.log("Inside UseEffect")

		if (intervalRef.current != null) {
			clearInterval(intervalRef.current)
			intervalRef.current = null
		}

		intervalRef.current = setInterval(() => {
			// OVO MORA UNUTRA
			// u suprotnom infinite rerender
			fetchItems()
		}, pollInterval)

		return () => {
			if (intervalRef.current != null) {
				clearInterval(intervalRef.current)
				intervalRef.current = null
			}
		}

	}, [viewParams, paginationParams])

	return { items, fetchItems, currentCount, paginationParams } as const

}