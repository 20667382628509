import { makeStyles, Typography } from '@material-ui/core'
import { Counters, JobRole, UiViews } from 'api/api-types'
import { InvoiceCounter } from 'elements/InvoiceCounter'
import { PaginationParams, ViewParams } from 'feed/useFetchItems'
// import { FetchItemsParams } from 'feed/useFetchItems'
import * as _ from 'lodash'
import { LOG } from 'logger/Log'
import { FeedFilter, FilterContext } from 'main/AppWithFiltersNew'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'utils/Auth'
import { Section } from './ItemTable'
import { useItemTableStyles } from './useItemTableStyles'



const useStyles = makeStyles({
	filterButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 8,
		cursor: 'pointer',
		marginRight: 15,
		minWidth: 50
	},
	buttonTitle: {
		fontSize: 14,
		opacity: 1
	},
	selected: {
		backgroundColor: '#e0e0e0'
	},
	counter: {
		marginLeft: 10
	},
	title: {
		paddingRight: '8px'
	}
})
type ItemTitle = {
	All: 'All',
	AWAITING_APPROVAL: 'Awaiting Approval',
	ATTENTION: 'Attention',
	INCOMPLETE_SET: 'Incomplete Set',
	DUPLICATES: 'Duplicates',
	APPROVED: 'Approved',
	REJECTED: 'Rejected',
	ON_HOLD: 'On Hold',
	RETURNED: 'Returned',
}
type Props = {
	forPage: Section
	fetchItems: (viewP?: ViewParams, paginationP?: PaginationParams) => void
	offset?: number
	limit?: number
	itemsCount?: Counters
}
export function ItemTableTitle(props: Props) {
	const classes = useStyles()
	const invoiceTableClasses = useItemTableStyles()
	const { roles, iss } = React.useContext(AuthContext).getUser()!
	const { t } = useTranslation();

	// const { attention, incompleteDocumentation, duplicated,
	// 	approved, rejected, returned, onHold, pendingApproval, correct } = React.useContext(ItemContext)

	const [isActive, setIsActive] = React.useState<FilterText>('All')
	type FilterText = "All" | "Attention" | "Incomplete Set"
		| "Duplicates" | "Approved" | "Rejected" | "On Hold" | "Returned"
		| "Awaiting Approval" | "Systemic Errors" | 'Classification Errors'

	const isAdmin = _.some(roles, e => e === JobRole.ADMIN)
	const isProcurment = _.some(roles, e => e === JobRole.PROCUREMENT_ADMIN)
	const isApprover = _.some(roles, e => e === JobRole.APPROVER)

	const filterContext = React.useContext(FilterContext)

	function highlightActive(status: FilterText) {
		return isActive === status
	}

	React.useEffect(() => {
		if (props.forPage === 'extraction errors') {
			return setIsActive('Systemic Errors')
		} else if (props.forPage === 'extraction') {
			return setIsActive('Attention')
		} else
			return setIsActive('All')
	}, [props.forPage])

	const renderFilterButton = (title: FilterText, uiView: UiViews, activeFilter: FeedFilter, count?: number) => {
		return (<div
			className={`${classes.filterButton} ${highlightActive(title) && classes.selected}`}
			onClick={() => {
				console.log('This are props', props?.offset, props?.limit)
				// Pagination will probbably be removed from here
				props.fetchItems({
					forView: "feed",
					uiView: uiView
				}, props.offset && props.limit ? { offset: props?.offset, limit: props?.limit } : undefined)
				setIsActive(title)
				filterContext.setActiveFilter(activeFilter)
			}}
		>
			<span className={classes.buttonTitle}>{title}</span>
			{/* To be done. One option is to have items.lenght here somehow, but then we dont have counter in the feed?
			Other option is to create CounterContext but then there is to much of rerender? */}
			{count ? <InvoiceCounter className={classes.counter} count={count} /> : null}
		</div >)
	}

	// function renderFilterButton(title: string, filter: FeedFilter, count: number) {
	// 	const selected = highlightActive(filter) ? classes.selected : ""
	// 	return <div
	// 		className={`${classes.filterButton} ${selected}`}
	// 		onClick={() => {

	// 			filterContext.setActiveFilter(filter)
	// 		}
	// 		}
	// 	>
	// 		<span className={classes.buttonTitle}>{title}</span>
	// 		{count > 0 ? <InvoiceCounter className={classes.counter} count={count} /> : null}
	// 	</div>
	// }
	//check what to do with approval count if needed
	function renderApprovalProgress() {


		// const count = pendingApproval.filter(e => _.some(e.approvers, a => a.username === iss && !a.approved)).length
		if (isAdmin || isApprover) {
			return renderFilterButton(
				'Awaiting Approval',
				UiViews.PENDING_APPROVAL,
				"approving_progress",
				props.itemsCount?.PENDING_APPROVAL

			)
		}
	}


	const renderButtons = (page: Section) => {
		let content
		if (page === "extraction") {
			content = <>
				{renderFilterButton(
					'Attention',
					UiViews.ATTENTION_REVERTED,
					'attention',
					props.itemsCount?.ATTENTION_REVERTED
					// attention.length
				)}
				{renderFilterButton(
					'Incomplete Set',
					UiViews.MISSING_DOCUMENTATION,
					'incomplete_documentation',
					props.itemsCount?.MISSING_DOCUMENTATION
				)}
				{renderFilterButton(
					'Duplicates',
					UiViews.DUPLICATE,
					'duplicates',
					props.itemsCount?.DUPLICATE
					// duplicated.length
				)}
			</>
		} else if (page === "approvals") {
			content = <>
				{renderFilterButton(
					'Approved',
					UiViews.APPROVED,
					'approved',
					props.itemsCount?.APPROVED
					// approved.length
				)}
				{renderFilterButton(
					'Rejected',
					UiViews.REJECTED,
					'rejected',
					props.itemsCount?.REJECTED
					// rejected.length
				)}
				{renderFilterButton(
					'On Hold',
					UiViews.ON_HOLD,
					'on_hold',
					props.itemsCount?.ON_HOLD
					// onHold.length
				)}
				{renderFilterButton(
					'Returned',
					UiViews.RETURNED,
					'returned',
					props.itemsCount?.RETURNED
					// returned.length
				)}
				{renderApprovalProgress()}
			</>
		} else if (page === "extraction errors") {
			content = <>
				{renderFilterButton(
					'Systemic Errors',
					UiViews.CLASSIFIED,
					'classified',
					props.itemsCount?.CLASSIFIED
					// duplicated.length
				)}
				{renderFilterButton(
					'Classification Errors',
					UiViews.CONFLICTING,
					'conflicting',
					props.itemsCount?.CONFLICTING
					// duplicated.length
				)}
			</>
		} else {
			content = null
		}
		return <>
			{/* Alll needs fixing!!!!!!!!!!!!!!!!!!!! */}
			{/* {(props.forPage === "extraction" && (isAdmin || isProcurment)) && renderFilterButton(
				'All',
				UiViews.EXTRACTION_ALL,
				'all',
				// All counters should add up we have separated counters now
				props.itemsCount ? props.itemsCount.EXTRACTION_ALL : undefined,
			)} */}
			{(props.forPage === "approvals" && (isAdmin || isProcurment)) && renderFilterButton(
				'All',
				UiViews.APPROVALS_ALL,
				'all',
				props.itemsCount ? props.itemsCount.APPROVALS_ALL : undefined,
			)}
			{/* {(props.forPage === "extraction" && (isAdmin || isProcurment)) && renderFilterButton(
				"All",
				ExtractionStatus.
			)}
			{((isAdmin || isProcurment) && content) && renderFilterButton(
				"All",
				// 0
			)} */}
			{content}
		</>
	}
	return <div className={invoiceTableClasses.titleContainer}>
		<Typography variant='h6' className={classes.title}>{t("Table_Title.Documents")}</Typography>
		{renderButtons(props.forPage)}
	</div>
}