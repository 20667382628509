import { CircularProgress, createStyles, fade, IconButton, makeStyles, TableCell, TableFooter, TablePagination, TableRow, Theme, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ReplayIcon from '@material-ui/icons/Replay';
import { Client, JobRole, Supplier } from 'api/api-types';
import { getClients, getSuppliers, resendVerifiyEmail } from 'api/ApiServices';
import { handleApiResponse, useApi } from 'api/useApi';
import * as _ from 'lodash';
import * as React from 'react';
import { AuthContext } from 'utils/Auth';
import { useModalStyles } from 'styles/useModalStyle'
import 'styles/colors.css'
import { ClientModal } from './ClientModal';
import { parseValue, RowValue } from 'elements/ItemTable/ItemTableRows';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import { useItemTableStyles } from 'elements/ItemTable/useItemTableStyles';
import { defaultCellStyle, defaultHeaderStyle } from 'elements/ItemTable/ItemTableColumns';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { ActionName, ItemActionPopover } from 'feed/ItemActionPopover';
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next';


const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			minHeight: 835,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		},
		flexRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 20,
		},
		topFlexRow: {
			// borderBottom: '1px solid #ededed',
			paddingBottom: 20
		},
		bottomFlexRow: {
			paddingTop: 5
		},
		//change to succesButton
		addMemberButton: {
			marginLeft: 'auto',
			marginRight: 15,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.1),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.01),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			border: '1px solid #e1e3e1'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		table: {

		},
		userIcon: {
			width: 22,
			height: 22,
			color: 'rgba(0, 0, 0, 0.54)',
		},
		manageIcons: {
			height: '1em',
			fontSize: '1.2rem',
			cursor: 'pointer',
			color: 'rgba(0, 0, 0, 0.54)'
		},
		removeIcon: {
			// marginLeft: 15
		},
		userIconCell: {
			padding: 0
		},
		usernameCell: {
			paddingLeft: 0
		},
		addIcon: {
			color: '#08ad6c'
		}
	}))

export function jobRoleLabel(role: JobRole): "Administration" | "Procurement" | "Approvals" | "Analytics" {
	switch (role) {
		case JobRole.ADMIN: return "Administration"
		case JobRole.PROCUREMENT_ADMIN: return "Procurement"
		case JobRole.APPROVER: return "Approvals"
		case JobRole.ANALYST: return "Analytics"
		default: throw Error("Invalid Job Role")
	}
}

export function ClientPage() {
	const classes = useStyles()
	const tableClasses = useItemTableStyles()
	const { t, i18n } = useTranslation()


	const [data, setData] = React.useState<Client[] | null>(null)
	const [pagination, setPagination] = React.useState<{ offset: number, limit: number }>({ offset: 0, limit: 10 })


	const [resendLinkStep, setResendLinkStep] = React.useState<"resend" | "error" | "success" | "pending">("resend")
	const [selectedClient, setSelectedClient] = React.useState<Client | null>(null)

	const authContext = React.useContext(AuthContext)

	const clientModalRef = React.useRef<ClientModal>(null)
	const popoverRef = React.useRef<ItemActionPopover>(null)


	// Check this warn

	async function init() {
		const data = await getClients(pagination.offset, pagination.limit)
		if (data._type === "data") {
			console.log(data.data)
			// if (data.data === []) {
			// 	setData(null)
			// }

			setData(data.data)
			console.log("SetDataCalled", data)

		} else {
			console.log('Eror', data)
		}

	}

	React.useEffect(() => {
		console.log('PARAMS FOR FETCH', pagination)

		init()
	}, [pagination])


	// To be deleted
	// function renderSuppliers(suppliers: Supplier[]) {
	// 	// function renderDepartments(departments: OrgDepartment[] | undefined) {
	// 	// 	if (!departments) {
	// 	// 		return "N/A"
	// 	// 	} else {
	// 	// 		return departments.map(e => <div key={e}>{e}</div>)
	// 	// 	}
	// 	// }

	// 	return suppliers
	// 		.map((row) => {
	// 			return <TableRow key={row.compIco} onClick={e => {
	// 				console.log('CLICKED CLICKED CLICKED')
	// 				setSelectedSupplier(row)
	// 				// Should also highlight here
	// 			}}>
	// 				<StyledTableCell align="left"></StyledTableCell>
	// 				{/* <StyledTableCell align="left" className={classes.userIconCell}><AccountCircleIcon className={classes.userIcon} /></StyledTableCell> */}
	// 				<StyledTableCell align="left" className={classes.usernameCell}>{row.compIco}</StyledTableCell>
	// 				{/* N/A is option for first user, since he will not have those fields available */}
	// 				<StyledTableCell align="left">{row.dic}</StyledTableCell>
	// 				<StyledTableCell align="left">{row.companyName}</StyledTableCell>
	// 				<StyledTableCell align="left">{row.city || "N/A"}</StyledTableCell>
	// 				<StyledTableCell align="left">{row.zipCode || "N/A"}</StyledTableCell>
	// 				{/* <StyledTableCell align="center">{_.sortBy(row.jobRoles, e => e.toLowerCase()).map(e => <div key={e}>{jobRoleLabel(e)}</div>)}</StyledTableCell>
	// 				<StyledTableCell align="center">{renderDepartments(row.departments)}</StyledTableCell> */}
	// 				{/* Might have issue because of falsey, we should check for false/true, this might pass if null/undefined */}
	// 				{/* <StyledTableCell align="center">{row.departmentOwner ? <CheckIcon className='bgGreen' /> : <RemoveIcon />}</StyledTableCell>
	// 				<StyledTableCell align="center">{row.masterApprover ? <CheckIcon className='bgGreen' /> : <RemoveIcon />}</StyledTableCell>
	// 				<StyledTableCell align="center">{renderUserVerified(row.verified)}</StyledTableCell>
	// 				<StyledTableCell align="center">
	// 					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
	// 						{row.verified && <EditIcon className={classes.manageIcons} onClick={() => {
	// 							memberRef.current?.show({ member: row, refetch })
	// 						}} />}
	// 						{renderVerificationIcon(row.verified, resendLinkStep, row.username)}
	// 						{authContext.getUser()!!.iss !== row.username && <DeleteIcon
	// 							className={`${classes.manageIcons} ${classes.removeIcon}`}
	// 							onClick={() => {
	// 								removeMember(row.username)
	// 							}}
	// 						/>}
	// 					</div>
	// 				</StyledTableCell> */}
	// 			</TableRow>
	// 		})
	// }



	type Column = '' | 'Client Number' | 'Name' | 'Address' | 'Contact Name' | 'INO' | 'TINO' | 'INOVAT' | 'Delivery Address'
	type Row = string[] | string | number

	function constructColumn(
		// section: Section,
		column: Column,
		options: MUIDataTableColumnOptions
	): MUIDataTableColumn {
		return {
			name: column,
			label: column,
			// options: { ...columnOptions(section, column, options) },
			options: { ...options }
		}
	}

	function renderColumns() {
		const columns: MUIDataTableColumn[] = [
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',
						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',

						}
					})
				}
			),
			constructColumn(
				'Client Number',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'Client Number'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Name',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'Name'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Address',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'Address'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Contact Name',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'Contact Name'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'INO',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'INO'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'TINO',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'TINO'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'INOVAT',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return 'INOVAT'
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Delivery Address',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Procurement.Zip')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("right")
					}),
					setCellProps: () => ({
						...defaultCellStyle("right")
					})
				}
			)


		]
		return columns
	}
	function renderSuppliersRow(
		suppliers: Client[],
		actionButton: (invoice: Client, index: number) => JSX.Element
	) {
		return suppliers.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// Check with Jorge how to propperly use reduce and why is first showing []
			if (i === 0)
				p.shift()
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					['', <LibraryAddCheckIcon className={classes.userIcon} />],
					['Client Number', parseValue(c.clientNumber)],
					['Name', parseValue(c.clientName)],
					['Address', parseValue(c.clientAddress)],
					['Contact Name', parseValue(c.clientContactName)],
					['INO', parseValue(c.clientINO)],
					['TINO', parseValue(c.clientTINO)],
					['INOVAT', parseValue(c.clientINOVAT)],
					['Delivery Address', parseValue(c.clientdeliveryAddress)],

					['', actionButton(c, i)]

				]
			// const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			// const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]
			const tmp = row.map(e => e[1])
			p.push(tmp)
			return p
		}, [[]])

	}


	// function renderUserVerified(verified: boolean) {
	// 	if (verified === true) {
	// 		return <ThumbUpAltIcon className='bgGreen' />
	// 	} else {
	// 		return <HourglassEmpty className='bgOrange' />
	// 	}
	// }function renderUserVerified(verified: boolean) {
	// 	if (verified === true) {
	// 		return <ThumbUpAltIcon className='bgGreen' />
	// 	} else {
	// 		return <HourglassEmpty className='bgOrange' />
	// 	}
	// }

	const renderCustomFooter = (
		count: number,
		page: number,
		rowsPerPage: number,
		changeRowsPerPage: (page: React.ReactText) => void,
		changePage: (newPage: number) => void
	) => {
		return (
			<TableFooter>
				<TableRow>
					<TablePagination
						count={210}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={(_, page) => changePage(page)}
						onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
						rowsPerPageOptions={[10, 15, 30]}
					/>
				</TableRow>
			</TableFooter>
		)
	}


	// if (data === 'loading')
	// 	return null
	if (data != null) {
		return (
			<>
				<div className={tableClasses.tableWrapper}>
					{/* <div className={`${classes.flexRow} ${classes.topFlexRow}`}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
						<Translation>{ (t) => <Button variant='contained' color='primary' className={`${classes.addMemberButton}`} onClick={() => {
							console.log('AddCompanyClicked')
							if (selectedSupplier == null) {
								console.log('First if')

								SuppliersRef.current?.show({ suppliers: data })
							}
							else {
								console.log('passed to else')
								SuppliersRef.current?.show({ suppliers: data, supplier: selectedSupplier, refetch: init })
							}
						}}>Add company<AddIcon /></Button>
					</div> */}
					{/* <div className={`${tableClasses.tableWrapper}`}> */}
					<MUIDataTable
						title='Supplier'
						data={renderSuppliersRow(
							data,
							(item, index) => {
								return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', marginRight: '16px' }} onClick={e => {
									e.stopPropagation()

									popoverRef.current?.show({
										anchorElement: e.currentTarget,
										actions: [
											{
												actionName: ActionName.EDIT,
												action: () => {
													clientModalRef.current?.show({ client: item })
												}
											}
										]
									})
									// setSelectedItem(item.itemId, index)
								}} />
							}
							// }
							// else {
							// 	return <></>
							// }

						)}
						columns={renderColumns()}
						options={{
							elevation: 0,
							selectableRows: 'none',
							customFooter: renderCustomFooter,
							customToolbar: () => {
								return <div className={tableClasses.toolbarButtonsContainer}>
									{/* <div style={{ display: 'flex' }}> */}
									<IconButton onClick={() => {
										if (selectedClient != null) {

											clientModalRef.current?.show({ client: selectedClient, refetch: init })
										}
										else {
											clientModalRef.current?.show({ refetch: init })
											// SuppliersRef.current?.show({suppliers: data, supplier: selectedSupplier, refetch: init })
										}
									}}>
										<AddCircleIcon className={classes.addIcon} />
									</IconButton>
									{/* </div> */}
								</div>
							},
							onChangePage: (pageNumber) => {
								setPagination({ ...pagination, offset: pageNumber })
							}

						}}

					>

					</MUIDataTable>
					{/* <TableContainer>
							<Table className={classes.table} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left"></StyledTableCell>
										<StyledTableCell align="left" className={classes.usernameCell}>Company ICO</StyledTableCell>
										<StyledTableCell align="left">Company DIC</StyledTableCell>
										<StyledTableCell align="left">Company Name</StyledTableCell>
										<StyledTableCell align="left">City</StyledTableCell>
										<StyledTableCell align="left">Zip Code</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									
									{renderSuppliers(data)}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TablePagination
											count={-1}
											rowsPerPage={25}
											page={offset}
											onChangePage={(e, p) => setOffset(p)}
											This might be needed
										onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
										rowsPerPageOptions={[10, 15, 30]}
										/>
									</TableRow>
								</TableFooter>

							</Table>
						</TableContainer> */}
					{/* </div> */}
				</div >
				<ClientModal ref={clientModalRef} />
				<ItemActionPopover ref={popoverRef} />

			</>
		)
	} else {
		return null
	}
}
