import { DrawerContext } from "drawer/AppWithDrawer"
import { Container, Page } from "elements/Container"
import * as React from "react"
import "styles/feed.css"
// import { Page } from "react-pdf"
import { RouteComponentProps } from "react-router-dom"
import { InventoryDrawer } from "./InventoryDrawer"

type RouteParams = {
	sub: "central-warehouse" | "create-warehouse" | "manual-load"
}

export function InventoryPage(props: RouteComponentProps<RouteParams>) {
	const drawerContext = React.useContext(DrawerContext)
	React.useEffect(() => {
		drawerContext.setRenderContent(() => (
			<InventoryDrawer {...props} />
		))
	}, [props.location])
	let content
	if (props.match.params.sub === "central-warehouse") {
		content = null
	} else if (props.match.params.sub === "create-warehouse") {
		content = null
	} else if (props.match.params.sub === "manual-load") {
		content = null
	} else {
		content = null
	}

	return (
		<Container page={Page.PROCUREMENT} title="Procurement">
			{content}
		</Container>
	)

}

