import React from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';

import { ApprovaGroupApprover } from 'api/api-types';
import { Paper, Typography, IconButton, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		root: {
			borderColor: 'white',
		},
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}

	}),
)(TableCell);

const useStyles = makeStyles(theme => ({
	root: {
		// width: 150,
		maxWidth: 400,
		minWidth: '25%',
		// borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
		backgroundColor: 'rgba(0, 0, 0, 0.04)'
	},
	userIcon: {
		width: 22,
		height: 22,
		color: 'rgba(0, 0, 0, 0.54)'
	},
	list: {
		paddingTop: '20px'
	},
	itemText: {
		paddingRight: '4px'
	},
	itemIcon: {
		minWidth: '34px'
	},
	row: {
		display: 'flex',
		alignItems: 'center'
	},
	grey: {
		backgroundColor: 'inherit'
	},
	iconCell: {
		paddingRight: 0,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'space-between',
	},


}));

type props = {
	open: {
		open: boolean;
		approvers: ApprovaGroupApprover[],
		masterApprovers: ApprovaGroupApprover[]
	}
	setOpen: React.Dispatch<React.SetStateAction<{
		open: boolean;
		approvers: ApprovaGroupApprover[],
		masterApprovers: ApprovaGroupApprover[]

	}>>
}
export default function MakeshiftDrawer(props: props) {
	const classes = useStyles();
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	function handleListItemClick(event: any, index: any) {
		setSelectedIndex(index);
	}

	//  To be deleted
	// function renderApprovers(approvers: ApprovaGroupApprover[]) {
	// 	return approvers.map((e) => (<>
	// 		<ListItem
	// 			// selected={selectedIndex === 0}
	// 			onClick={event => handleListItemClick(event, 0)}
	// 			key={e.username}
	// 		>
	// 			<ListItemIcon className={classes.itemIcon}>
	// 				<AccountCircleIcon className={classes.userIcon} />
	// 			</ListItemIcon>
	// 			<ListItemText className={classes.itemText} primary={`${e.lastName} `} />
	// 			<ListItemText className={classes.itemText} primary={`${e.firstName} `} />
	// 			<ListItemText className={classes.itemText} primary={`${e.username} `} />

	// 		</ListItem>
	// 	</>))
	// }

	function renderApproverRow(approvers: ApprovaGroupApprover[], lvl: string) {
		return approvers.map((e) => (<>
			<TableRow>
				<StyledTableCell align="left" className={classes.iconCell}><AccountCircleIcon className={classes.userIcon} /></StyledTableCell>
				<StyledTableCell align="left">{e.lastName} {e.firstName}</StyledTableCell>
				<StyledTableCell align="left">{e.username}</StyledTableCell>
				<StyledTableCell align="center">{lvl}</StyledTableCell>
			</TableRow>
		</>))
	}

	return (
		<Slide direction="left" in={props.open.open} mountOnEnter unmountOnExit>
			<Paper elevation={0} className={classes.root}>
				<div className={classes.drawerHeader}>
					<Typography variant='h6' style={{ paddingLeft: '16px' }}>Approvers</Typography>
					<IconButton onClick={() => {
						props.setOpen({ open: false, approvers: [], masterApprovers: [] })
					}}>
						<CloseIcon />
					</IconButton>
				</div>

				<TableContainer>
					{/* className={classes.table} */}
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell align="left" className={classes.grey}></StyledTableCell>
								<StyledTableCell align="left" className={classes.grey}>Name</StyledTableCell>
								<StyledTableCell align="left" className={classes.grey}>Email</StyledTableCell>
								<StyledTableCell align="left" className={classes.grey}>Level</StyledTableCell>

							</TableRow>
						</TableHead>
						<TableBody style={{ overflowY: 'auto' }}>
							{renderApproverRow(props.open.approvers, 'M')}
							{renderApproverRow(props.open.approvers, 'S')}
							{/* {renderMembers(data)} */}
						</TableBody>
					</Table>
				</TableContainer>
				{/* <List component="nav" aria-label="main mailbox folders" className={classes.list}>
					{renderApprovers(props.ApprovaGroupApprover)}
				</List> */}

			</Paper>
		</Slide >
	);
}