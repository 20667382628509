import { ListItem, ListItemIcon, ListItemText, SvgIconTypeMap } from '@material-ui/core'
import { Counters, JobRole, UiViews } from 'api/api-types'
import { Section } from 'elements/ItemTable/ItemTable'
import { FeedFilter } from 'main/AppWithFiltersNew'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { AuthContext } from 'utils/Auth'
import { FilterModal } from './NewFilterModal'
import * as _ from 'lodash'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import AllInboxIcon from '@material-ui/icons/AllInbox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';
import PublishIcon from '@material-ui/icons/Publish';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useTranslation } from 'react-i18next';
import { archivingPeriod } from 'utils/DateTime'
import { InvoiceCounter } from 'elements/InvoiceCounter'
import { useApi } from 'api/useApi'
import { getStatus } from 'api/ApiServices'
import { PaginationParams, ViewParams } from './useFetchItems'
// import { } from './useFetchItems'
type Props = {
	// activeFilter: FeedFilter,
	// setActiveFilter: (FeedFilter) => void,
	forSection: Section,
	setForSection: React.Dispatch<React.SetStateAction<Section>>,
	fetchItems: (viewP?: ViewParams, paginationP?: PaginationParams) => void,
	// activeDefaultFilter: DefaultFilter
	// activeCustomFilter: CustomFilter | null
	// customFilters: CustomFilter[]
	// columnFilters: ColumnFilter[]
	// setActiveDefaultFilter: (filter: DefaultFilter) => void
	// setActiveCustomFilter: (filter: CustomFilter | null) => void
	// saveCustomFilter: (filter: CustomFilter) => void
	// invoiceAttentionCount: number
	// invoiceDuplicateCount: number
	// invoiceIncompleteDocCount: number
	// invoiceCorrectCount: number
	invoiceReadyForSubmissionCount?: number
	counters?: Counters

}

export function FeedDrawer(props: Props) {
	const drawerClasses = useDrawerStyle()
	const [open, setOpen] = React.useState(false)
	const filterModalRef = React.useRef<FilterModal>(null)
	const { roles } = React.useContext(AuthContext).getUser()!
	const isAdmin = _.some(roles, e => e === JobRole.ADMIN)
	const isProcurment = _.some(roles, e => e === JobRole.PROCUREMENT_ADMIN)
	const isAnalyst = _.some(roles, e => e === JobRole.ANALYST)
	const isApprover = _.some(roles, e => e === JobRole.APPROVER)




	const { t } = useTranslation();
	React.useEffect(() => {

	}, [])
	// const mapCustomFilters = () => {
	// 	const filters = props.customFilters.map((e, i) => {
	// 		const isActive = props.activeCustomFilter?.name === e.name && props.activeDefaultFilter.name === "control_board"
	// 		if (props.customFilters.length > 0) {
	// 			return (
	// 				<ListItem
	// 					button
	// 					className={`${drawerClasses.nested} ${drawerClasses.listItem}`}
	// 					key={`${e.name}-${i}`}
	// 					onClick={_ => {
	// 						if (props.activeCustomFilter?.name === e.name) {
	// 							props.setActiveCustomFilter(null)
	// 						} else {
	// 							props.setActiveCustomFilter(e)
	// 						}
	// 					}}
	// 					classes={{ selected: drawerClasses.selected }}
	// 					selected={isActive}
	// 				>
	// 					<ListItemText className={`${drawerClasses.listItemText}`}>{e.name}</ListItemText>
	// 				</ListItem >
	// 			)
	// 		}
	// 	})
	// 	const addNewFilter = (
	// 		<ListItem button className={`${drawerClasses.nested} ${drawerClasses.listItem}`} key={`add-new-filter`} onClick={e => {
	// 			filterModalRef.current?.show()
	// 		}}>
	// 			<ListItemText className={drawerClasses.listItemText}>Add New</ListItemText>
	// 		</ListItem>
	// 	)
	// 	return [...filters, addNewFilter]
	// }

	//Should clean this function to not have filters
	function renderLinkItem({ filter, section, text, Icon }: { filter?: FeedFilter, section: Section, text: string, Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> }) {
		// console.log('HERE ARE COUNTEEEEERS', props.counters)
		// console.log("Count props in drawer", props.counters)
		let count
		switch (section) {
			case 'extraction':
				if (props.counters)
					count = props.counters?.EXTRACTION_ALL
				break;
			case 'approvals':
				// uiView = UiViews.APPROVALS_ALL
				if (props.counters)
					count = props.counters?.APPROVALS_ALL
				break
			case 'submision':
				// uiView = UiViews.READY_FOR_SUBMISION
				if (props.counters)
					count = props.counters?.READY_FOR_SUBMISION
				// console.log('Ready for submision count', count)

				break
			case 'main':
				if (props.counters)
					count = props.counters?.CONTROL_BOARD
				break
			case 'extraction errors':
				if (props.counters) {
					count = props.counters?.CLASSIFIED + props.counters?.CONFLICTING
					// console.log('errors count', count)
				}
				break
			case 'booking submision':
				break
			case 'archive':
				break
			// Ask Jorge which will be status when archived, better UIViews
			// case 'archive':
			// 	props.fetchItems(UiViews.)
		}
		return <ListItem
			button
			className={`${drawerClasses.listItem}`}
			classes={{
				selected: drawerClasses.selected,
			}}
			onClick={_ => {
				// props.setActiveDefaultFilter(filter)
				// Maybe add setActiveFilter() here from FilterContext
				switch (section) {
					case 'extraction':
						props.fetchItems({ forView: 'feed', uiView: UiViews.ATTENTION_REVERTED })
						break;
					case 'approvals':
						props.fetchItems({ forView: 'feed', uiView: UiViews.APPROVALS_ALL })
						// uiView = UiViews.APPROVALS_ALL
						break
					case 'submision':
						props.fetchItems({ forView: 'feed', uiView: UiViews.READY_FOR_SUBMISION })
						// uiView = UiViews.READY_FOR_SUBMISION
						break
					case 'main':
						props.fetchItems({ forView: 'feed', uiView: UiViews.CONTROL_BOARD })
						break
					case 'extraction errors':
						props.fetchItems({ forView: 'feed', uiView: UiViews.CLASSIFIED })

						break
					case 'booking submision':
						props.fetchItems({ forView: 'feed', uiView: UiViews.APPROVED })
						break

					case 'archive':
						// This will never be called, we dont have uiView for archive	

						const current = archivingPeriod()
						console.log('Calling fetch archive')
						props.fetchItems({ forView: 'archive', month: current.month, year: current.year })
					// Ask Jorge which will be status when archived, better UIViews
					// case 'archive':
					// 	props.fetchItems(UiViews.)
				}
				props.setForSection(section)

			}}
			selected={props.forSection === section}
		>
			<div className={drawerClasses.listItemWrapper}>
				<ListItemIcon className={drawerClasses.itemIcon}><Icon></Icon></ListItemIcon>
				{/* <ListItemIcon className={drawerClasses.itemIcon}><CloudUploadIcon /></ListItemIcon> */}
				<ListItemText className={`${drawerClasses.listItemText}`} primary={text} />
				{count !== 0 && section !== 'main' && <span style={{ marginRight: '11px' }}>{(count && count != 0) && <InvoiceCounter count={count}></InvoiceCounter>}</span>}

			</div>
		</ListItem>
	}

	// if (status === 'loading' && status !== null)
	// 	return null
	return (
		<>
			<div className={drawerClasses.section}>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
					{/* <ListItem component='li' className={`${drawerClasses.listItemHeader}`}>
					<Typography variant='body1' className={drawerClasses.listItemHeader}>MENU</Typography>
				`````</ListItem> */}
					<div>
						{(isAdmin || isProcurment) && renderLinkItem({
							// filter: {
							// 	name: "all",
							// 	columns: [],
							// 	status: StatusFilter.ALL_PROCESSED
							// },
							section: 'main',
							text: t('Feed.Control_Board'),
							Icon: AllInboxIcon
						})}
						{/* IF extraction approval status is Open */}
						{/* IF Extraction approvalStatus is Open(all), or approvalStatus is open and extractionStatus = current */}
						{(isAdmin || isProcurment) && renderLinkItem({
							// filter: {
							// 	name: "all",
							// 	columns: [],
							// 	status: StatusFilter.ALL_PROCESSED
							// },
							section: 'extraction',
							text: t('Feed.Extraction'),
							Icon: AssignmentIcon
						})}
						{(isAdmin || isProcurment) && renderLinkItem({
							// filter: {
							// 	name: "all",
							// 	columns: [],
							// 	status: StatusFilter.ALL_PROCESSED
							// },
							section: 'extraction errors',
							text: t('Feed.Extraction_Errors'),
							Icon: WarningIcon
						})}
						{/* IF Extraction approvalStatus is Ready for submission(all)*/}
						{(isAdmin || isProcurment) && renderLinkItem({
							// filter: {
							// 	name: "submision",
							// 	columns: [],
							// 	status: StatusFilter.READY_FOR_SUBMISION
							// },
							section: 'submision',
							text: t('Feed.Ready_Submission'),
							Icon: PublishIcon
						})}
						{/* IF Extraction ExtractionStatus is correct(all), or approvalStatus is correct and ApprovalStatus = current */}
						{(isAdmin || isApprover) && renderLinkItem({
							// filter: {
							// 	name: "all",
							// 	columns: [],
							// 	status: StatusFilter.ALL_PROCESSED
							// },
							section: 'approvals',
							text: t('Feed.Approvals'),
							Icon: AssignmentTurnedInIcon
						})}
						{(isAdmin) && renderLinkItem({
							// filter: {
							// 	name: "all",
							// 	columns: [],
							// 	status: StatusFilter.ALL_PROCESSED
							// },
							section: 'booking submision',
							text: t('Feed.Ready_Booking'),
							Icon: LibraryBooksIcon
						})}
						{(isAdmin) && renderLinkItem({
							// filter: {
							// 	name: "all",
							// 	columns: [],
							// 	status: StatusFilter.ALL_PROCESSED
							// },
							section: 'archive',
							text: t('Feed.Archive'),
							Icon: ArchiveIcon
						})}
					</div>
					{/* <ListItem
					button
					className={`${drawerClasses.listItem}`}
					classes={{
						selected: drawerClasses.selected,
					}}
					onClick={_ => {
					}}
					selected={false}
				>
					<div className={`${drawerClasses.listItemWrapper}`}>
						<ListItemText className={`${drawerClasses.listItemText}`} primary="Archive" />
					</div>
				</ListItem> */}
					{/* <StatusLight status={status!} /> */}
				</div>
			</div>
			{/* <div className={drawerClasses.section}>
				<ListItem
					component='li'
					className={`${drawerClasses.listItemHeader}`}
					classes={{
						selected: drawerClasses.selected,
					}}
				>
					<Typography variant='body1' className={drawerClasses.listItemHeader}>FILTER</Typography>
				</ListItem>
				<ListItem
					button
					onClick={() => setOpen(!open)}
					className={drawerClasses.listItem}
					classes={{
						selected: drawerClasses.selected,
					}}
				>
					<ListItemText className={drawerClasses.listItemText} primary="Filters" />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{mapCustomFilters()}
					</List>
				</Collapse>
				<FilterModal
					ref={filterModalRef}
					saveFilter={props.saveCustomFilter}
					columnFilters={props.columnFilters}
				/>
				<Divider />
			</div> */}
		</>
	)
}