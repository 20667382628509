import { DateTimeFormatter, Instant, LocalDate, ZoneId } from '@js-joda/core'
import { Button, Checkbox, createStyles, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Tooltip, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove'
import { ApprovalStatus, ApproversType, DocStatusEnum, Label, OriginEnum, TagsEnum, TagType } from 'api/api-types'
import { addTag, getByIco, postItem, removeTag, setItemIsRead } from 'api/ApiServices'
import { ExpandableSection } from 'elements/ExpandableSection'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX, ModalXBody, ModalXHeader, ModalXSideBar } from 'elements/ModalX'
import * as _ from 'lodash'
import { fill, forEach } from 'lodash'
import { UserContext } from 'main/AppWithUser'
import * as React from 'react'
import { useModalStyles } from 'styles/useModalStyle'
import { regexPatterns } from 'utils/Validation'
import { ItemLabels } from './ItemLabels'
import { ArrayInputs, ItemModal, ItemModalCommon, ItemModalParams, Props, State } from './ItemModalCommon'
import { TagsPopover } from './TagsPopover'
import { useItemModalStyles } from './useItemModalStyle'
import { PanoramaSharp, TramRounded } from '@material-ui/icons';
import { upcFirst } from "utils/TextFormating"
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { DateInput } from 'elements/DateInput';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateKeyInput } from 'elements/DateKeyInput';

const DTF = DateTimeFormatter.ofPattern("yyyy-MM-dd")

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		root: {
			borderColor: 'white',
			paddingTop: '4px',
			paddingBottom: '4px',
		},
		head: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.38)',
			fontWeight: 700,
		},
		body: {
			fontSize: '0.8125rem',
			color: 'rgba(0, 0, 0, 0.38)',
		}

	}),
)(TableCell);

export const EditItemModal = React.forwardRef((props: Props, ref: React.Ref<ItemModal>) => {
	const classes = useItemModalStyles()
	const modalClasses = useModalStyles({ maxModalHeight: 930 })

	const user = React.useContext(UserContext)
	const [isOpen, setIsOpen] = React.useState(false)
	const [state, setState] = React.useState<State | null>(null)
	const [isNotEditable, setIsNotEditable] = React.useState<boolean>(false)
	const [orderNumberNotApplicable, setOrderNumberNotApplicable] = React.useState(false)
	const [rerender, forceRerender] = React.useState(0)
	// const [expendableSection, setExpendableSections] = React.useState<>(0)


	const companyNameRef = React.useRef<CustomInput>(null)
	// const dateOfIssueReff = React.useRef<CustomInput>(null)
	const dateOfIssueRef = React.useRef<DateInput>(null)
	const deliveryDateRef = React.useRef<DateInput>(null)
	const dueDateRef = React.useRef<DateInput>(null)
	const icoRef = React.useRef<CustomInput>(null)
	const invoiceAmountRef = React.useRef<CustomInput>(null)
	const documentNumberRef = React.useRef<CustomInput>(null)
	const ibanRefs = React.useRef<ArrayInputs<string | null>>([])
	const orderNumRefs = React.useRef<ArrayInputs<string | null>>([])

	const popoverRef = React.useRef<TagsPopover>(null)

	const { t } = useTranslation();

	const show = async (params: ItemModalParams) => {
		const { labels, docName, itemId, rejectReason, approvalStatus, values, extractionStatus, confidenceLevel, approvers } = params.item
		console.log('Documents', labels)
		const iban = labels.supplierGroup.iban
		const orderNumber = labels.documentationGroup.orderNumber
		if (iban.length > 0) {
			ibanRefs.current = iban.map(e => ({
				label: e,
				ref: React.createRef()
			}))
		} else {
			ibanRefs.current = [{
				label: {
					origin: OriginEnum.USER_INPUT,
					value: ""
				},
				ref: React.createRef()
			}]
		}
		if (orderNumber && orderNumber.length > 0) {
			orderNumRefs.current = orderNumber.map(e => ({
				label: e,
				ref: React.createRef()
			}))
		} else {
			orderNumRefs.current = [{
				label: {
					origin: OriginEnum.USER_INPUT,
					value: ""
				},
				ref: React.createRef()
			}]
		}
		const itemsRead = await setItemIsRead([itemId])
		if (itemsRead._type === 'data') {
		} else {
			console.log('Item is read failed')
		}
		console.log('passed')

		// console.log("Item params", params.item)
		const isNotEditable = approvalStatus === ApprovalStatus.APPROVED
			|| approvalStatus === ApprovalStatus.REJECTED || approvalStatus === ApprovalStatus.PENDING_APPROVAL || approvalStatus === ApprovalStatus.RETURNED || params.forSection === 'main' || approvalStatus === ApprovalStatus.READY_FOR_SUMBISION

		setIsNotEditable(isNotEditable)
		setState({
			docName: docName,
			itemId: itemId,
			labels: labels,
			values: values,
			rejectedReason: rejectReason,
			approvalStatus: approvalStatus,
			extractionStatus: extractionStatus,
			// Ask about confidence lvl
			confidenceLevel: confidenceLevel ? confidenceLevel : 100,
			approvers: approvalStatus !== ApprovalStatus.RETURNED ? approvers : undefined,
			pagination: {
				hasNext: (params.currentIndex + 1) < params.size,
				hasPrev: params.currentIndex > 0
			}
		})

		if (labels.documentationGroup.requestIdStatus?.value === DocStatusEnum.NOT_APPLICABLE) {
			setOrderNumberNotApplicable(true)
		} else {
			setOrderNumberNotApplicable(false)
		}
		checkInputErrors()
		setIsOpen(true)
	}

	function checkInputErrors() {
		if (!isNotEditable) {
			companyNameRef.current?.checkErrors()
			dateOfIssueRef.current?.checkErrors()
			deliveryDateRef.current?.checkErrors()
			dueDateRef.current?.checkErrors()
			icoRef.current?.checkErrors()
			invoiceAmountRef.current?.checkErrors()
			documentNumberRef.current?.checkErrors()
			ibanRefs.current.forEach(e => e.ref.current?.checkErrors())
			orderNumRefs.current.forEach(e => e.ref.current?.checkErrors())
		}
	}

	async function handleClose() {
		setState(null)
		setOrderNumberNotApplicable(false)
		setIsOpen(false)
		props.refetchInvoices?.()
		props.refetchCounters()
	}

	React.useImperativeHandle(ref, () => ({ show }))

	const paginateNext = ItemModalCommon.paginateNext(show, handleClose)
	const paginatePrev = ItemModalCommon.paginatePrev(show, handleClose)

	function updateLabel<T>(label: Label<T>, value: T): Label<T> {
		if (label.value === value) {
			return label
		} else {
			return {
				...label,
				value: value,
				origin: OriginEnum.USER_INPUT
			}
		}
	}
	function autoFillLabel<T>(label: Label<T>, value: T): Label<T> {
		return {
			...label,
			value: value,
			origin: OriginEnum.USER_INPUT
		}
	}

	const onSubmit = async () => {
		if (!state) {
			return
		}
		console.log("on submit, before parse", dueDateRef.current!.value)
		console.log("After PArse", moment(dueDateRef.current!.value, 'DD/MM/YYYY').valueOf())

		const companyNameLabel = updateLabel(
			state.labels.supplierGroup.companyName,
			companyNameRef.current!.value
		)
		// console.log("ORIGINAL", companyNameLabel)
		const companyNameErrors = companyNameRef.current?.checkErrors()
		const icoErrors = icoRef.current?.checkErrors()
		const deliveryDateErrors = deliveryDateRef.current?.checkErrors()
		const dueDateErrors = dueDateRef.current?.checkErrors()
		const dateOfIssueErrors = dateOfIssueRef.current?.checkErrors()
		const documentNumberErrors = documentNumberRef.current?.checkErrors()
		const invoiceAmountErrors = invoiceAmountRef.current?.checkErrors()

		const ibansErrors = _.some(ibanRefs.current, e => e.ref.current?.checkErrors())
		const orderNumberErrors = !orderNumberNotApplicable && _.some(orderNumRefs.current, e => e.ref.current?.checkErrors())



		if (ibansErrors || orderNumberErrors || companyNameErrors ||
			icoErrors || dateOfIssueErrors || deliveryDateErrors ||
			dueDateErrors || documentNumberErrors || invoiceAmountErrors || state.labels.documentationGroup.purchaseOrderIdStatus == null || state.labels.documentationGroup?.documentNumber == null) {
			return
		} else {


			// Dates
			const dateOfIssueLabel = updateLabel(
				state.labels.datesGroup.dateOfIssue,
				moment(dateOfIssueRef.current!.value, 'DD/MM/YYYY').valueOf()
				// console.log("Passed")
			)
			// console.log("")
			const deliveryDateLabel = updateLabel(
				state.labels.datesGroup.deliveryDate,
				moment(deliveryDateRef.current!.value, 'DD/MM/YYYY').valueOf()
			)

			const dueDateLabel = updateLabel(
				state.labels.datesGroup.dueDate,
				moment(dueDateRef.current!.value, 'DD/MM/YYYY').valueOf()
			)

			// Supplier
			const companyNameLabel = updateLabel(
				state.labels.supplierGroup.companyName,
				companyNameRef.current!.value
			)
			const ibanLabel = ibanRefs.current.map((e, i) => {
				const value = e.ref.current!.value
				const label = e.label
				return updateLabel(label, value)
			})
			const icoLabel = updateLabel(
				state.labels.supplierGroup.ico,
				icoRef.current!.value
			)

			// Documentation
			if (state.values.invoiceAmount && state.labels.documentationGroup.purchaseOrderIdStatus && orderNumRefs.current) {
				const orderNumberLabel = orderNumRefs.current.map(e => {
					const value = e.ref.current!.value
					const label = e.label!
					return updateLabel(label, value)
				})
				// Check only if orderNumberNotApplicable is selected
				// In other case we don't care, it will check orderNumber value
				const requestIdStatusLabel: () => Label<DocStatusEnum> = () => {
					const requestIdStatus = state.labels.documentationGroup.purchaseOrderIdStatus!
					if (orderNumberNotApplicable) {
						return updateLabel(requestIdStatus, DocStatusEnum.NOT_APPLICABLE)
					} else {
						return requestIdStatus
					}

				}
				const documentNumberLabel = updateLabel(
					state.labels.documentationGroup?.documentNumber,
					documentNumberRef.current!.value
				)

				// Values
				let invoiceAmountLabel = updateLabel(
					state.values.invoiceAmount,
					parseFloat(invoiceAmountRef.current!.value)
				)
				// console.log("PARAAAAMS", {
				// 	...state.labels,
				// 	datesGroup: {
				// 		...state.labels.datesGroup,
				// 		dateOfIssue: dateOfIssueLabel,
				// 		deliveryDate: deliveryDateLabel,
				// 		dueDate: dueDateLabel
				// 	},
				// 	supplierGroup: {
				// 		...state.labels.supplierGroup,
				// 		companyName: companyNameLabel,
				// 		iban: ibanLabel,
				// 		ico: icoLabel
				// 	},
				// 	documentationGroup: {
				// 		...state.labels.documentationGroup,
				// 		orderNumber: orderNumberLabel,
				// 		requestIdStatus: requestIdStatusLabel(),
				// 		documentNumber: documentNumberLabel
				// 	},
				// 	accountingGroup: state.labels.accountingGroup
				// }, {
				// 	invoiceAmount: invoiceAmountLabel
				// })
				const response = await postItem(state.itemId, {
					...state.labels,
					datesGroup: {
						...state.labels.datesGroup,
						dateOfIssue: dateOfIssueLabel,
						deliveryDate: deliveryDateLabel,
						dueDate: dueDateLabel
					},
					supplierGroup: {
						...state.labels.supplierGroup,
						companyName: companyNameLabel,
						iban: ibanLabel,
						ico: icoLabel
					},
					documentationGroup: {
						...state.labels.documentationGroup,
						orderNumber: orderNumberLabel,
						requestIdStatus: requestIdStatusLabel(),
						documentNumber: documentNumberLabel
					},
					accountingGroup: state.labels.accountingGroup
				}, {
					invoiceAmount: invoiceAmountLabel
				})

				if (response._type === 'generic_success') {
					props.paginate(state.itemId, paginateNext)
				} else {
					// Hndle errors
					console.log(response)
				}
			}
		}
	}
	const fillByIco = async () => {
		if (icoRef.current?.value) {
			const response = await getByIco(icoRef.current?.value)
			if (response._type === 'data') {
				// companyNameRef.current?.value = response.data.companyName
				if (state !== null) {
					const companyName = autoFillLabel(
						state.labels.supplierGroup.companyName,
						response.data.companyName
					)
					setState({
						...state, labels: { ...state.labels, supplierGroup: { ...state.labels.supplierGroup, companyName: companyName } }
					})
				}

			} else {
				console.log('Failed get by ico', response)
			}
		}
	}
	function renderCustomInputs<T>(
		inputs: ArrayInputs<T>,
		placeholder: string,
		label: string,
		key: string,
		disabled: boolean,
		renderDisableCheckbox?: () => React.ReactNode
	) {
		return inputs.map((e, i) => {
			const addIcon = !disabled && <AddIcon style={{ cursor: 'pointer' }} onClick={() => {
				inputs.push({
					label: {
						origin: OriginEnum.USER_INPUT,
						value: undefined as any
					},
					ref: React.createRef()
				})
				// Forcing rerender
				forceRerender(rerender + 1)
			}} />

			let removeIcon
			if (i !== 0) {
				removeIcon = <RemoveIcon
					style={{
						cursor: 'pointer'
					}}
					onClick={() => {
						inputs.pop()
						forceRerender(rerender + 1)
					}} />
			} else {
				removeIcon = null
			}

			return (
				<div key={`${key}-input-${i}`} style={{ display: 'flex', alignItems: 'center' }}>
					<CustomInput
						ref={e.ref}
						label={`${inputs.length > 1 ? `#${i + 1}` : ""} ${label}`}
						errorText=""
						placeholder={placeholder}
						required={!disabled}
						type="text"
						showRequiredOnMount={true}
						disabled={disabled}
						startingValue={`${e.label.value}`}
						shrink={true}
						endAndorment={<div style={{
							display: 'flex',
							flexDirection: 'row',
							padding: 5
						}}>
							{removeIcon}
							{addIcon}
						</div>}
					/>
					{renderDisableCheckbox && renderDisableCheckbox()}
				</div>
			)
		})
	}

	function formatDateFromEpoch(epochMilis: number | null) {
		if (epochMilis != null) {
			return moment(epochMilis).format("DD/MM/YYYY")
		} else {
			return null
		}
	}



	function renderApproverRow(approvers: ApproversType[], lvl: string) {
		return approvers.map((e) => (<>
			<Tooltip title={e.closedAt != null ? formatDateFromEpoch(e.closedAt)! : ''}>

				<TableRow>
					<StyledTableCell align="left" className={classes.iconCell} style={{ paddingTop: 0, paddingLeft: 0 }}><AccountCircleIcon className={classes.userIcon} /></StyledTableCell>
					<StyledTableCell align="left">{e.lastName} {e.firstName}</StyledTableCell>
					<StyledTableCell align="left">{e.username}</StyledTableCell>
					<StyledTableCell align="left">{e.approved ? <CheckIcon /> : ''}</StyledTableCell>

					<StyledTableCell align="center">{lvl}</StyledTableCell>
				</TableRow>
			</Tooltip>

		</>))
	}

	function expandableSectionIsOpen() {
		// console.log('HERE IS APProval status', state?.approvalStatus)
		switch (state?.approvalStatus) {
			case ApprovalStatus.REJECTED:
				return false
			case ApprovalStatus.PENDING_APPROVAL:
				return false
			default:
				return true
		}
	}
	function invoiceOpen() {
		if (state?.rejectedReason == null && (state?.approvers == null || state.approvers.length < 1)) {
			return true
		} else {
			return false
		}
	}



	function renderForm(state: State) {
		return <MuiPickersUtilsProvider utils={MomentUtils}>
			<div className={classes.form}>

				<ItemLabels labels={state!.labels.accountingGroup} removeLabel={(labels) => {
					setState({
						...state,
						labels: {
							...state.labels,
							accountingGroup: {
								...labels
							}
						}
					})
				}} />
				{state.rejectedReason && state.approvalStatus !== ApprovalStatus.RETURNED && <ExpandableSection
					renderHeader={() => <Typography variant="body2">{t('Modal.Rejected_Reason')}</Typography>}
					className={classes.expandableSection}
					startExpanded={true}
				>
					<p style={{ wordWrap: 'break-word' }}>{state.rejectedReason}</p>
				</ExpandableSection>}


				<ExpandableSection
					renderHeader={() => <Typography variant="body2">{t('Modal.Supplier')}</Typography>}
					className={classes.expandableSection}
					startExpanded={true}
				>
					<CustomInput
						ref={icoRef}
						label={t('Modal.Ico')}
						errorText=""
						placeholder={t('Modal.Ico_Long')}
						required={true}
						type="text"
						showRequiredOnMount={true}
						disabled={isNotEditable}
						customOnBlur={fillByIco}
						startingValue={state.labels.supplierGroup.ico.value}
					/>
					<CustomInput
						ref={companyNameRef}
						label={t('Modal.Company_Name')}
						errorText=""
						placeholder={t('Modal.Company_Name_Long')}
						required={true}
						type="text"
						showRequiredOnMount={true}
						disabled={isNotEditable}
						startingValue={state.labels.supplierGroup.companyName.value}

					/>
					{renderCustomInputs(
						ibanRefs.current,
						"Type in correct iban value",
						"IBAN",
						"iban",
						isNotEditable
					)}
				</ExpandableSection>
				<ExpandableSection
					renderHeader={() => <Typography variant="body2">{t('Modal.Invoice')}</Typography>}
					className={classes.expandableSection}
					startExpanded={invoiceOpen()}
				>
					<CustomInput
						ref={documentNumberRef}
						label={t('Modal.Invoice_Num')}
						errorText={t('Modal.Invoice_Long')}
						placeholder=""
						required={true}
						type="text"
						showRequiredOnMount={true}
						disabled={isNotEditable}
						startingValue={state.labels.documentationGroup.documentNumber?.value}
						regex={regexPatterns.invoiceNumber}
					/>
					<div className={classes.datesContainer}>

						{/* <DateInput
							ref={dateOfIssueRef}
							label={t('Modal.Date_Issue')}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>

							</div>}

							startingValue={state.labels.datesGroup.dateOfIssue.value != null ? state.labels.datesGroup.dateOfIssue.value : null}

						/> */}
						<DateKeyInput
							label={t('Modal.Date_Issue')}
							ref={dateOfIssueRef}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							// regex={regexPatterns.}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>
							</div>}
							startingValue={state.labels.datesGroup.dateOfIssue.value != null ? state.labels.datesGroup.dateOfIssue.value : undefined}
						/>
						{/* <CustomInput
							ref={dateOfIssueReff}
							label={t('Modal.Date_Issue')}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>

							</div>}

							startingValue={formatDateFromEpoch(state.labels.datesGroup.dateOfIssue.value)}
						/> */}
						<DateKeyInput
							ref={deliveryDateRef}
							label={t('Modal.Date_Delivery')}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>

							</div>}

							startingValue={state.labels.datesGroup.deliveryDate.value != null ? state.labels.datesGroup.deliveryDate.value : undefined} />
						{/* <DateInput
							ref={deliveryDateRef}
							label={t('Modal.Date_Delivery')}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>

							</div>}

							startingValue={state.labels.datesGroup.deliveryDate.value != null ? state.labels.datesGroup.deliveryDate.value : null}

						/> */}
						{/* <CustomInput
						ref={deliveryDateRef}
						label={t('Modal.Date_Delivery')}
						errorText=""
						placeholder=""
						required={true}
						type="date"
						showRequiredOnMount={true}
						shrink={true}
						disabled={isNotEditable}
						startingValue={formatDateFromEpoch(state.labels.datesGroup.deliveryDate.value)}
					/> */}
						<DateKeyInput
							ref={dueDateRef}
							label={t('Modal.Date_Due')}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>

							</div>}
							startingValue={state.labels.datesGroup.dueDate.value != null ? state.labels.datesGroup.dueDate.value : undefined}
						/>
						{/* <DateInput
							ref={dueDateRef}
							label={t('Modal.Date_Due')}
							errorText=""
							placeholder=""
							required={true}
							type="date"
							showRequiredOnMount={true}
							shrink={true}
							disabled={isNotEditable}
							endAndorment={<div style={{
								marginRight: '-10px'
							}}>

							</div>}

							startingValue={state.labels.datesGroup.dueDate.value != null ? state.labels.datesGroup.dueDate.value : null}

						/> */}
						{/* <CustomInput
						ref={dueDateRef}
						label={t('Modal.Date_Due')}
						errorText=""
						placeholder=""
						required={true}
						type="date"
						showRequiredOnMount={true}
						shrink={true}
						disabled={isNotEditable}
						startingValue={formatDateFromEpoch(state.labels.datesGroup.dueDate.value)}
					/> */}
					</div>
					<CustomInput
						ref={invoiceAmountRef}
						label={t('Modal.Inv_Amount')}
						errorText={t('Modal.Inv_Amount_Error')}
						placeholder={t('Modal.Inv_Amount_Long')}
						required={true}
						type="text"
						showRequiredOnMount={true}
						disabled={isNotEditable}
						regex={regexPatterns.amount}
						startingValue={state.values.invoiceAmount?.value?.toString()}
					/>
					{renderCustomInputs(
						orderNumRefs.current,
						"Type in correct order number value",
						orderNumRefs.current.length === 1 && orderNumberNotApplicable ? "Invoice doesn't contain order number" : "Order Number",
						"order-number",
						orderNumberNotApplicable || isNotEditable,
						() => {
							if (orderNumRefs.current.length === 1) {
								return <FormControlLabel
									className={classes.checkboxLabel}
									control={<Checkbox
										color="primary"
										checked={orderNumberNotApplicable}
										disabled={isNotEditable}
										onChange={() => {
											setOrderNumberNotApplicable(!orderNumberNotApplicable)
											orderNumRefs.current[0].ref.current!.removeErrors()
										}}
									/>}
									label={t('Modal.NA')}
									labelPlacement="end"
								/>
							} else {
								return null
							}
						}
					)}
				</ExpandableSection>
				{(!state.rejectedReason && state.approvers && state.approvers?.length > 0) && <ExpandableSection
					renderHeader={() => <Typography variant="body2">{t('Modal.Approval_Status')}</Typography>}
					className={classes.expandableSection}
					startExpanded={true}
					childrenContainer={true}
				>
					<TableContainer>
						{/* className={classes.table} */}
						<Table aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="left" style={{ paddingTop: 0, paddingLeft: 0 }}></StyledTableCell>
									<StyledTableCell align="left" >Name</StyledTableCell>
									<StyledTableCell align="left" >Email</StyledTableCell>
									<StyledTableCell align="left" >Approved</StyledTableCell>
									<StyledTableCell align="left" >Level</StyledTableCell>

								</TableRow>
							</TableHead>
							<TableBody style={{ overflowY: 'auto' }}>

								{renderApproverRow(state.approvers.filter(e => e.conditional), 'M')}
								{renderApproverRow(state.approvers.filter(e => !e.conditional), 'S')}
								{/* {renderMembers(data)} */}
							</TableBody>
						</Table>
					</TableContainer>
					{/* <p style={{ wordWrap: 'break-word' }}>Test</p> */}
				</ExpandableSection>}
				<ExpandableSection renderHeader={() => <Typography variant="body2">{t('Modal.Booking')}</Typography>} className={classes.expandableSection}></ExpandableSection>
				<ExpandableSection renderHeader={() => <Typography variant="body2">{t('Modal.Payment')}</Typography>} className={classes.expandableSection}></ExpandableSection>
			</div>
		</MuiPickersUtilsProvider>

	}

	const renderButtonContent = () => {
		let content
		if (state!.pagination.hasNext) {
			content = <>{t('Button.Save')} & {t('Button.Next')} <ArrowForwardIcon /></>
		} else {
			content = t('Button.Save') + " & " + t('Button.Close')
		}
		return content
	}

	if (!state) {
		return null
	}

	return <ModalX
		isOpen={isOpen}
		onClose={handleClose}
		maxModalHeight={930}
	>
		<ModalXHeader style={{ backgroundColor: 'white' }}>
			<div className={classes.headerLabels}>
				<span className={classes.headerText}>{upcFirst(state.labels.inputGroup.documentType ? state.labels.inputGroup.documentType.value : '')}</span>
				{ItemModalCommon.renderHeaderLabels(
					state.approvalStatus,
					state.extractionStatus,
					state.confidenceLevel,
					state.labels.documentationGroup.requestIdStatus?.value,
					state.labels.documentationGroup.purchaseOrderIdStatus?.value
				)}
			</div>
		</ModalXHeader>
		{/* Key is used to trigger rerender on paginate, pagination is making inputs go wild */}
		<Form key={state.itemId} onSubmit={onSubmit} pure={true}>
			<ModalXBody style={{
				flexDirection: 'row',
				backgroundColor: '#ededed',
			}}>
				<div className={classes.section}>
					<div className={classes.content} style={{ height: 820 }}>
						{ItemModalCommon.renderDocument(state, classes.documentContainer)}
						{renderForm(state)}
					</div>
					{ItemModalCommon.renderPagination(
						state,
						props,
						paginatePrev,
						paginateNext,
						classes.pagination,
						classes.paginationButton,
					)}
				</div>
				<ModalXSideBar title="Labels">
					<div className={modalClasses.sidebarContent}>
						<Button
							disabled={((state && state.rejectedReason && state.approvalStatus !== ApprovalStatus.RETURNED) || (state.approvalStatus === ApprovalStatus.APPROVED)) ? true : false}
							className={classes.labelButton}
							onClick={e => popoverRef.current?.show({
								element: e.currentTarget,
								id: "groups",
								addNew: async (tag) => {
									await addTag({
										label: TagsEnum.GROUPS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								remove: async (tag) => {
									await removeTag({
										label: TagsEnum.GROUPS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								setSelected: (selected) => {
									setState({
										...state,
										labels: {
											...state.labels,
											accountingGroup: {
												...state.labels.accountingGroup,
												groupCode: {
													value: selected.code,
													origin: OriginEnum.USER_INPUT
												},
												groupAlias: {
													value: selected.alias,
													origin: OriginEnum.USER_INPUT
												}
											}
										}
									})
								},
								tags: user.organisation.tags.Groups
							})}>Groups</Button>
						<Button
							className={classes.labelButton}
							onClick={e => popoverRef.current?.show({
								addNew: async (tag) => {
									await addTag({
										label: TagsEnum.DEPARTMENTS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								remove: async (tag) => {
									await removeTag({
										label: TagsEnum.DEPARTMENTS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								element: e.currentTarget,
								id: "deps",
								setSelected: (selected) => {
									setState({
										...state,
										labels: {
											...state.labels,
											accountingGroup: {
												...state.labels.accountingGroup,
												departmentCode: {
													origin: OriginEnum.USER_INPUT,
													value: selected.code
												},
												departmentAlias: {
													origin: OriginEnum.USER_INPUT,
													value: selected.alias
												}
											}
										}
									})
								},
								tags: user.organisation.tags.Departments
							})}>Departments</Button>
						<Button
							className={classes.labelButton}
							onClick={e => popoverRef.current?.show({
								addNew: async (tag) => {
									await addTag({
										label: TagsEnum.MAJORS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								remove: async (tag) => {
									await removeTag({
										label: TagsEnum.MAJORS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								element: e.currentTarget,
								id: "majors",
								setSelected: (selected) => {
									setState({
										...state,
										labels: {
											...state.labels,
											accountingGroup: {
												...state.labels.accountingGroup,
												majorCode: {
													origin: OriginEnum.USER_INPUT,
													value: selected.code
												},
												majorAlias: {
													origin: OriginEnum.USER_INPUT,
													value: selected.alias
												}
											}
										}
									})
								},
								tags: user.organisation.tags.Majors
							})}>Majors</Button>
						<Button
							className={classes.labelButton}
							onClick={e => popoverRef.current?.show({
								addNew: async (tag) => {
									await addTag({
										label: TagsEnum.MINORS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								remove: async (tag) => {
									await removeTag({
										label: TagsEnum.MINORS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								element: e.currentTarget,
								id: "minors",
								setSelected: (selected) => {
									setState({
										...state,
										labels: {
											...state.labels,
											accountingGroup: {
												...state.labels.accountingGroup,
												minorCode: {
													origin: OriginEnum.USER_INPUT,
													value: selected.code
												},
												minorAlias: {
													origin: OriginEnum.USER_INPUT,
													value: selected.alias
												}
											}
										}
									})
								},
								tags: user.organisation.tags.Minors
							})}>Minors</Button>
						<Button
							className={classes.labelButton}
							onClick={e => popoverRef.current?.show({
								addNew: async (tag) => {
									await addTag({
										label: TagsEnum.SEGMENTS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								remove: async (tag) => {
									await removeTag({
										label: TagsEnum.SEGMENTS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								element: e.currentTarget,
								id: "segments",
								setSelected: (selected) => {
									setState({
										...state,
										labels: {
											...state.labels,
											accountingGroup: {
												...state.labels.accountingGroup,
												segmentCode: {
													origin: OriginEnum.USER_INPUT,
													value: selected.code
												},
												segmentAlias: {
													origin: OriginEnum.USER_INPUT,
													value: selected.alias
												}
											}
										}
									})
								},
								tags: user.organisation.tags.Segments
							})}>Segments</Button>
						<Button
							className={classes.labelButton}
							onClick={e => popoverRef.current?.show({
								addNew: async (tag) => {
									await addTag({
										label: TagsEnum.SUBSEGMENTS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								remove: async (tag) => {
									await removeTag({
										label: TagsEnum.SUBSEGMENTS,
										data: [{
											"alias": tag.alias,
											"code": tag.code
										}]
									})
									await user.refetch()
								},
								element: e.currentTarget,
								id: "sub-segments",
								setSelected: (selected) => {
									setState({
										...state,
										labels: {
											...state.labels,
											accountingGroup: {
												...state.labels.accountingGroup,
												subsegmentCode: {
													origin: OriginEnum.USER_INPUT,
													value: selected.code
												},
												subsegmentAlias: {
													origin: OriginEnum.USER_INPUT,
													value: selected.alias
												}
											}
										}
									})
								},
								tags: user.organisation.tags.Subsegments
							})}>Subsegments</Button>
					</div>
					<SubmitButton className={classes.submitButton}>{renderButtonContent()}</SubmitButton>
				</ModalXSideBar>
			</ModalXBody>
		</Form>
		<TagsPopover ref={popoverRef} />
	</ModalX>
})