import { Checkbox, createStyles, FormControl, FormControlLabel, FormHelperText, makeStyles, Theme } from '@material-ui/core'
import { DepartmentType, Member } from 'api/api-types'
import { postDepartments, updateDepartment, } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX, ModalXHeader } from 'elements/ModalX'
import * as _ from 'lodash'

import * as React from 'react'
import Select from 'react-select'
import { ModalBody } from 'reactstrap'
import { regexPatterns } from 'utils/Validation'
// import { jobRoleLabel } from './Members'
import { useTranslation } from 'react-i18next';

// Change type of membersOption to {value: member, label: member}[], it will be cleaner, having less to typecast and less code, maybe even faster
//Then for then when setting setMemberOption() i will need to create {value: member, label: member}[] from array<member>
//Introduce selectFormatType
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			padding: 30,
			maxWidth: 600
		},
		formControl: {
			marginBottom: 10,
		},
		selectLabel: {
			color: 'rgba(0, 0, 0, 0.54)'
		},
		selectError: {
			color: 'red'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
		}
	}))

type Params = {
	department?: DepartmentType
	departments: DepartmentType[]
	members: Member[]
	refetchDepartments?: () => Promise<void>
}

export type DepartmentsModal = {
	show: (params: Params) => void
}
// Make better- MembersOption shouldnt be in state, it should be a function that return propper array

export const DepartmentsModal = React.forwardRef((props: {}, ref: React.Ref<DepartmentsModal>) => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | null>(null)


	// const [jobRoleError, setJobRoleError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })
	// const [jobRoles, setJobRoles] = React.useState<{ value: JobRole, label: string }[]>([])
	// const [departments, setDepartments] = React.useState<{ value: OrgDepartment, label: string }[]>([])

	// const [departmentError, setDepartmentError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })

	const [manager, setManager] = React.useState<{ value: Member, label: string } | null>(null)
	const [employees, setEmployees] = React.useState<{ value: Member, label: string }[]>([])
	const [parent, setParent] = React.useState<{ value: string, label: string } | null>(null)
	// We use membersState to save
	const [membersOptions, setMembersOptions] = React.useState<{ value: Member, label: string }[]>([])

	const { t } = useTranslation();

	const [isDepartmentOwner, setIsDepartmentOwner] = React.useState(false)
	const [isDepartmentOwnerError, setIsDepartmentOwnerError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [isMasterApprover, setIsMasterApprover] = React.useState(false)
	const [isMasterApproverError, setIsMasterApproverError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const codeRef = React.useRef<CustomInput>(null)
	const nameRef = React.useRef<CustomInput>(null)
	const aliasRef = React.useRef<CustomInput>(null)

	function findMemberByEmail(manageUsername: string) {
		//check for this if it can be undefined or null at any time
		return membersOptions.find(member => member.value.username === manageUsername)!
	}
	function findMembersByEmail(manageUsernames: string[]) {
		//check for this if it can be undefined or null at any time
		return membersOptions.filter(member => !manageUsernames.includes(member.value.username))
	}

	function toSelectFormat(members: Member[]) {
		const selectFormat = [] as { value: Member, label: string }[]
		members.forEach(member => selectFormat.push({ value: member, label: `${member.firstName} ${member.lastName}` }))
		return selectFormat
	}
	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			if (params.members && params.departments) {
				setParams(params)
				setMembersOptions(params.members.map((e) => ({ value: e, label: `${e.firstName} ${e.lastName}` })).filter(e => (e.value.departmentCode === params.department?.code && e.value.verified === true)))
				//This will trigger on edit	
				if (params.department) {
					if (params.department.manager) {
						const tmpMngr = params.members.find(member => member.username === params?.department?.manager)!
						tmpMngr && setManager({ value: tmpMngr, label: `${tmpMngr.firstName} ${tmpMngr.lastName}` })
					}
					if (params.department.parent)
						setParent({ value: params.department.parent, label: params.department.parent })
					//You can use reduce instead of map and filter!

					if (params.department.hasEmployees) {

						const union = params.members.filter((item) => { return params.department?.hasEmployees?.indexOf(item.username) !== -1 }).map((item) => { return { value: item, label: `${item.firstName} ${item.lastName}` } })
						setEmployees(union)
					}

				}
			}
			// To be deleted
			// if (params.department) {
			// 	const { department } = params
			// 	if (department) {
			// 		console.log('usao, will set')
			// 		if (department.manager) {

			// 			console.log('manageeeeeeeeeeeeeeeeeeeeeeeeeeeeeer')
			// 			console.log(manager)
			// 		}
			// 		else if (department.parent) {
			// 			//I can set the name of the department or anything else here
			// 			if (params.department) {

			// 				console.log(parent)

			// 			}
			// 		}
			// 		//how can i make this better !?!?!?
			// 		//DAAAAAAAmn
			// 		if (department.hasEmployees) {
			// 		}
			// 	}
			// }
			// const response = await getMembers()
			// if (response._type === 'data') {
			// 	const members = response.data
			// 	// console.log(members)
			// 	setMembersOptions(toSelectFormat(members))

			// } else if (response._type === 'generic_error') {
			// 	console.log(response.error)
			// }
			// if (params) {
			// 	// const { member } = params
			// 	setParams(params)
			// 	if (member.departments) {
			// 		setDepartments(member.departments.map(e => {
			// 			return {
			// 				value: e,
			// 				label: e
			// 			}
			// 		}))
			// 	}
			// 	if (member.jobRoles.length > 0) {
			// 		// setJobRoles(member.jobRoles.map(e => {
			// 		// 	return {
			// 		// 		value: e,
			// 		// 		// label: jobRoleLabel(e)
			// 		// 	}
			// 		// }))
			// 	}
			// 	setIsDepartmentOwner(member.departmentOwner)
			// 	setIsMasterApprover(member.masterApprover)
			// }
			setIsOpen(true)
		}
	}))

	//pitati stefani da li ovo treba u  show mozda


	async function onSubmit() {
		// const companyError = companyRef.current?.checkErrors()
		// const firstNameError = firstNameRef.current?.checkErrors()
		// const lastNameError = lastNameRef.current?.checkErrors()
		// const titleError = titleRef.current?.checkErrors()
		// const usernameError = userNameRef.current?.checkErrors()

		// if (jobRoles.length <= 0) {
		// 	setJobRoleError({
		// 		error: true,
		// 		errorMessage: "Field is required"
		// 	})
		// }

		// if (departments.length <= 0) {
		// 	setDepartmentError({
		// 		error: true,
		// 		errorMessage: "Field is required"
		// 	})
		// 	return
		// }


		//Handle errors here
		// if (companyError || firstNameError || lastNameError || titleError || usernameError || jobRoleError.error || departmentError.error) {
		// 	return
		// } else {
		// This is editing, if there are params it means that edit mode is active
		// We make sure that that is the case
		if (params?.department) {
			//I should get authContext(or userContext) here or pass it thrue props
			//
			// This if to check if admin, might be redundant
			// if (params.member.jobRoles.indexOf(JobRole.ADMIN) === -1) {
			// 	// Replace console.log by notif
			// 	LOG.dev('You cannot update unless you are admin')
			// 	handleClose()
			// } else {
			// Edit member
			// console.log('Entered edit')
			// console.log('code', codeRef.current!.value)
			// console.log('name', nameRef.current!.value)
			// console.log('aliasRef', aliasRef.current!.value)
			// console.log('manager ', manager?.value.username)
			// console.log('Employees', employees.map((e) => e.value.username))
			// console.log('Parent', parent ? parent.value : null)
			const response = await updateDepartment({
				code: codeRef.current!.value,
				alias: aliasRef.current!.value,
				name: nameRef.current!.value,
				manager: manager ? manager?.value.username : null,
				//fix this to separated state
				// hasEmployees: employees.map((e) => e.value.username),
				parent: parent ? parent.value : null
			})

			if (response._type === 'data' || response._type === 'generic_success') {
				handleClose()
			} else {
				// handle errors
				console.error(response)
			}
		}
		else {
			console.log('Entered u CreateDepartment')
			console.log('code', codeRef.current!.value)
			console.log('name', nameRef.current!.value)
			console.log('aliasRef', aliasRef.current!.value)
			console.log('manager ', manager?.value.username)
			console.log('Employees', employees.map((e) => e.value.username))
			console.log('Parent', parent ? parent.value : null)
			const response = await postDepartments({
				code: codeRef.current!.value,
				alias: aliasRef.current?.value || null,
				name: nameRef.current!.value,
				//manager and parent state doesn't have to be array
				// manager: manager ? manager?.value.username : null,
				// hasEmployees: employees.map((e) => e.value.username),
				parent: parent ? parent.value : null
			})
			if (response._type === 'data' || response._type === 'generic_success') {
				handleClose()
			} else {
				// handle errors
				console.error(response)
			}

			// }
		}
	}
	// function departmentOptions() {
	// 	return _.toArray(OrgDepartment).map(e => ({ value: e, label: e }))
	// }

	function handleClose() {
		if (params) {
			setParams(null)
		}
		//Hanndle arrors later
		// setJobRoleError({
		// 	error: false,
		// 	errorMessage: ""
		// })
		// setJobRoles([])
		// setDepartments([])
		// setDepartmentError({
		// 	error: false,
		// 	errorMessage: ""
		// })

		setEmployees([])
		setManager(null)
		setParent(null)
		setIsOpen(false)
		setIsMasterApprover(false)
		setIsMasterApproverError({
			error: false,
			errorMessage: ""
		})
		setIsDepartmentOwner(false)
		setIsDepartmentOwnerError({
			error: false,
			errorMessage: ""
		})
	}

	function renderCheckBox(
		checked: boolean,
		label: string,
		error: boolean,
		errorText: String,
		setValue: () => void,
	) {
		return (
			<FormControl>
				<FormControlLabel
					control={<Checkbox
						checked={checked}
						onChange={setValue}
						name={label}
					/>}
					label={label}
				/>
				<FormControl error={error}>
					<FormHelperText>{errorText}</FormHelperText>
				</FormControl>
			</FormControl>
		)
	}

	return (
		<ModalX isOpen={isOpen} onClose={handleClose}>
			<ModalXHeader>{params?.department ? "Update Department" : "Create Department"}</ModalXHeader>
			<ModalBody>
				<Form onSubmit={onSubmit} className={classes.form}>
					<CustomInput
						key="tst"
						ref={codeRef}
						label={t('Hr.Code')}
						errorText=""
						placeholder={t('Hr.Code_Long')}
						required={true}
						type="text"
						startingValue={params?.department?.code}
					/>
					<CustomInput
						key="tst2"
						ref={nameRef}
						label={t('Hr.Name')}
						errorText=""
						required={true}

						placeholder={t('Hr.Dpt_Name_Long')}
						type="text"
						startingValue={params?.department?.name}
					/>
					<CustomInput
						key="tst3"
						ref={aliasRef}
						label={t('Hr.Alias')}
						errorText=""
						placeholder={t('Hr.Alias_Long')}
						required={true}
						type="text"
						startingValue={params?.department?.alias}
					/>
					{params?.department && <FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>Department Manager</p>
						<Select
							key="tst4"
							name={t('Hr.Manager')}
							options={membersOptions}
							value={manager}
							onChange={selected => {
								// if (selected != null) {
								// 	if (departmentError.error) {
								// 		setDepartmentError({ error: false, errorMessage: "" })
								// 	}
								// 	setDepartments(selected as { value: OrgDepartment, label: string }[])
								// } else {
								// setDepartments([])
								setManager(selected as { value: Member, label: string })
								// }
							}}
							className={classes.select}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								})
							}}
						/>
						{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
					</FormControl>}

					{/* <FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>Department Manager</p>
						<Select
							key="tst5"
							name="Employees"
							options={membersOptions}
							value={manager}
							onChange={selected => {
								// if (selected != null) {
								// 	if (departmentError.error) {
								// 		setDepartmentError({ error: false, errorMessage: "" })
								// 	}
								// 	setDepartments(selected as { value: OrgDepartment, label: string }[])
								// } else {
								// setDepartments([])
								setEmployees(selected as { value: Member, label: string }[])
								// }
							}}
							className={classes.select}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								})
							}}
						/>
						 {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} 
					</FormControl> */}
					<FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>Reporting Department</p>
						<Select
							key="tst6"
							name={t('Hr.Parent')}
							options={params?.departments.map((e) => ({ value: e.code, label: e.name }))}
							value={parent}
							onChange={selected => {
								// if (selected != null) {
								// 	if (departmentError.error) {
								// 		setDepartmentError({ error: false, errorMessage: "" })
								// 	}
								// 	setDepartments(selected as { value: OrgDepartment, label: string }[])
								// } else {
								// setDepartments([])
								setParent(selected as { value: string, label: string })
								// }
							}}
							className={classes.select}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								})
							}}
						/>
						{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
					</FormControl>
					{/* <FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>Job Roles</p>
						<Select
							isMulti
							name="Job Roles"
							options={jobRoleOptions()}
							value={jobRoles}
							onChange={selected => {
								if (selected != null) {
									if (jobRoleError.error) {
										setJobRoleError({ error: false, errorMessage: "" })
									}
									setJobRoles(selected as { value: JobRole, label: string }[])
								} else {
									setJobRoles([])
								}
							}}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								})
							}}
							className={classes.select}
						/>
						{jobRoleError.error && <FormHelperText className={classes.selectError}>{jobRoleError.errorMessage}</FormHelperText>}
					</FormControl> */}
					{/* {renderCheckBox(
						isMasterApprover,
						"Master Approver",
						isMasterApproverError.error,
						isMasterApproverError.errorMessage,
						() => setIsMasterApprover(!isMasterApprover)
					)}
					{renderCheckBox(
						isDepartmentOwner,
						"Department Owner",
						isDepartmentOwnerError.error,
						isDepartmentOwnerError.errorMessage,
						() => setIsDepartmentOwner(!isDepartmentOwner)
					)} */}
					<SubmitButton>{params?.department ? "Update" : "Create"}</SubmitButton>
				</Form>
			</ModalBody>
		</ModalX >
	)

})