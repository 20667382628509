import { Button, createStyles, makeStyles, Modal, Theme, Typography } from '@material-ui/core'
import { removeItem, setItemIsRead, updatePendingReview } from 'api/ApiServices'
import { PdfDocument } from 'elements/PdfDocument'
import { LOG } from 'logger/Log'
import { ItemContext } from 'main/AppWithItems'
import * as React from 'react'
import { useModalStyles } from 'styles/useModalStyle'
import { Props } from './ItemModalCommon'
import { useTranslation } from 'react-i18next';
import { formatDateFromEpoch } from 'utils/DateTime'
import CloseIcon from '@material-ui/icons/Close'

const MODAL_HEIGHT = 900
const MODAL_WIDTH = 1400

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-evenly',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			marginTop: 'auto',
			marginBottom: 'auto'
		},
		column: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		},
		documentContainer: {
			width: MODAL_WIDTH / 2,
			// height: MODAL_HEIGHT,
			padding: '16px 30px'
		},
		document: {
			width: '100%',
			height: '100%',
		},
		close: {
			position: 'absolute',
			top: 0,
			right: 0,
			margin: 20,
			cursor: 'pointer'
		},
		buttonContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			width: '100%',
			alignItems: 'center',

		},
		button: {
			maxWidth: '200px'
		},
		success: {
			backgroundColor: "#35b905",
			'&:focus': {
				outline: "none"
			},
			'&:hover': {
				backgroundColor: "#4bc72c",
			},
		},
		warn: {
			backgroundColor: '#fc0303',
			'&:hover': {
				backgroundColor: '#fca7a7'
			}
		},
		approveButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#08ad6c',
			border: '2px solid #08ad6c',
			width: 200
		},
		rejectButton: {
			justifySelf: 'flex-end',
			marginBottom: 16,
			padding: '10px 0px',
			color: '#FF3D57',
			border: '2px solid #FF3D57',
			width: 200


		},
	})
)

export type DuplicateModalParams = {
	itemId: string
	duplicates: string
	setLoading: (isLoading: boolean) => void
	duplicatesCreatedAt: number[]
}

export type DuplicateModal = {
	show: (params: DuplicateModalParams) => void
}

export const DuplicateModal = React.forwardRef((props: Props, ref: React.Ref<DuplicateModal>) => {
	const classes = useStyles()
	const modalClasses = useModalStyles({ modalHeight: MODAL_HEIGHT, modalWidth: MODAL_WIDTH })
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<DuplicateModalParams | null>(null)
	// const [cursor, setCursor] = React.useState(0)

	// const itemContext = React.useContext(ItemContext)

	const { t } = useTranslation();

	async function handleClose() {
		setIsOpen(false)
		if (params) {
			params.setLoading(true)
			props.refetchCounters()
			props.refetchInvoices()
			params.setLoading(false)
		}
		setParams(null)
		// setCursor(0)
	}

	const setNotDuplicate = async (invoiceId: string) => {
		const response = await updatePendingReview([invoiceId])
		if (response._type === "generic_success") {
			// setCursor(cursor - 1)
			handleClose()
		} else {
			LOG.dev("Error update invoice status", response)
		}
		// if (cursor === 0) {
		// 	handleClose()
		// }
	}

	const deleteDuplicate = async (invoiceId: string) => {
		const response = await removeItem(invoiceId)
		if (response._type === "generic_success") {
			// setCursor(cursor - 1)
		} else {
			LOG.dev("Error removing invoice", response)
		}
		handleClose()
		// if (cursor === 0) {
		// 	handleClose()
		// }
	}

	React.useImperativeHandle(ref, () => ({
		show: async (params) => {
			const itemsRead = await setItemIsRead([params.duplicates])
			if (itemsRead._type === 'data') {
			} else {
				console.log('Item is read failed')
			}
			setParams(params)
			// setCursor(params.duplicates.length - 1)
			setIsOpen(true)
		}
	}))

	if (params) {
		const current = params.duplicates
		return <div>
			<Modal open={isOpen} onClose={handleClose}>
				<div className={`${modalClasses.paper} ${modalClasses.modal}`} style={{ maxHeight: 980 }}>
					<CloseIcon className={modalClasses.closeButton} onClick={handleClose} />

					<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
						<Typography variant='body2' style={{ width: '50%', paddingLeft: 32, paddingTop: 16 }}><Typography variant='h6'>Existing Document</Typography> recieved on {formatDateFromEpoch(params.duplicatesCreatedAt[0], 'dd/MM/yyy')}</Typography>
						<Typography variant='body2' style={{ width: '50%', paddingLeft: 32, paddingTop: 16 }}><Typography variant='h6'>New Document</Typography> recieved on {formatDateFromEpoch(params.duplicatesCreatedAt[1], 'dd/MM/yyy')}</Typography>
					</div>

					<div className={classes.row}>
						<div className={classes.documentContainer}>
							{/* Use render document from itemTableCommon */}
							<PdfDocument itemId={params.itemId} />
						</div>
						<div className={classes.documentContainer}>
							<PdfDocument itemId={current} />
						</div>
					</div>
					<div className={classes.buttonContainer}>
						<div style={{ width: '50%' }}></div>
						<div style={{
							width: '50%',
							display: 'flex',
							justifyContent: 'center'
						}}>

							<Button className={classes.rejectButton} onClick={() => deleteDuplicate(current)}>
								{t('Button.Del_Duplicate')}
							</Button>
							<Button className={classes.approveButton} style={{ marginLeft: 16 }} onClick={() => setNotDuplicate(current)}>
								{t('Button.Not_Duplicate')}
							</Button>
						</div>
						{/* <Button className={`${classes.button} ${classes.success}`} onClick={() => </Button>
						<Button className={`${classes.button} ${classes.warn}`} onClick={() => </Button> */}
					</div>
				</div>
			</Modal>
		</div>
	} else {
		return null
	}
})