import { makeStyles } from '@material-ui/core'
import { ErrorContext } from 'main/AppWithErrors'
import * as React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { DRAWER_WIDTH } from 'drawer/MainDrawer'

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '32px',
		minWidth: '100%',
		// minus drawer width - padding
		maxWidth: `calc(100vw - ${DRAWER_WIDTH}px - 64px)`,
	},
	errorWrapper: {
		width: '100%',
		height: 30,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundImage: "radial-gradient(circle, #e95c5c, #eb5a5a, #ec5757, #ee5555, #ef5252)",
		padding: 15,
		zIndex: 1,
		justifyItems: 'space-between',
		marginBottom: 5
	},
	errorMessage: {
		color: 'white'
	},
	errorAction: {
		cursor: 'pointer',
		marginLeft: 'auto'
	},
	errorDismiss: {
		cursor: 'pointer',
		height: 20,
		color: 'white',
		marginLeft: 10
	}
})

export enum Page {
	DASHBOARD,
	FEED,
	ANALYTICS,
	APPROVALS,
	ACCOUNT,
	LOGIN,
	REGISTER,
	PROCUREMENT,
	INVENTORY,
	INVOICING,
	HR,
	SETTINGS,
}

type Props = {
	title: string
	page: Page
}

export function Container(props: React.PropsWithChildren<Props>) {
	const classes = useStyles()
	const errorContext = React.useContext(ErrorContext)

	const renderError = (
		id: string,
		message: string,
		onDismiss: () => void,
		action?: {
			text: string,
			callback: () => void
		}) => {
		return <div key={id} className={classes.errorWrapper}>
			<span className={classes.errorMessage}>{message}</span>
			{action && <span className={classes.errorAction} onClick={action.callback}>{action.text}</span>}
			<CloseIcon className={classes.errorDismiss} onClick={onDismiss} />
		</div>
	}

	const errors = Array.from(errorContext.errors.values()).map(e => {
		if (e.forPage.find(p => p === props.page)) {
			renderError(
				e.id,
				e.message,
				() => errorContext.dismissError(e),
				e.action
			)
		}
	})

	return (
		<div className={classes.container}>
			{errors}
			{props.children}
		</div>
	)
}