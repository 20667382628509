import { List, ListItem, Typography } from '@material-ui/core'
import { AggregationParams, AggregationTree } from 'api/api-types'
import { getAggregationNodes, getAggregationTreeList } from 'api/ApiServices'
import * as _ from 'lodash'
import { ErrorContext } from 'main/AppWithErrors'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { AggregationTreeNode } from './AggregationTreeNode'

type Props = {
	setParams: React.Dispatch<React.SetStateAction<AggregationParams | null>>
}

export const AnalyticsDrawer = (props: Props) => {
	const drawerClasses = useDrawerStyle()
	const [treeNodes, setTreeNodes] = React.useState<[number, AggregationTree][]>([])
	const errorContext = React.useContext(ErrorContext)

	React.useEffect(() => {
		async function init() {
			const result = await getAggregationTreeList()
			if (result._type === "data") {
				const requests = result.data.map(d => getAggregationNodes(d.value_list_id))
				const responses = await Promise.all(requests)
				responses.forEach(r => {
					if (r._type === "data") {
						const data: [number, AggregationTree] = [
							r.data.value.value_list_id, r.data.value]

						setTreeNodes(current => [...current, data])
					} else if (r._type !== "generic_success") {
						// errorContext.addError({
						// 	...result.error,
						// 	forPage: [Page.DASHBOARD]
						// })
					} else {
						throw Error()
					}
				})
			} else if (result._type !== "generic_success") {
				// errorContext.addError({
				// 	...result.error,
				// 	forPage: [Page.DASHBOARD, Page.FEED]
				// })
			} else {
				throw Error()
			}
		}
		init()
	}, [])

	if (treeNodes.length > 0) {
		return <div className={drawerClasses.section}>
			<ListItem component='li' className={`${drawerClasses.listItem}`}>
				<Typography variant='body1' className={drawerClasses.listItemHeader}>AGGREGATIONS</Typography>
			</ListItem>
			<List component="div" disablePadding style={{ paddingLeft: '20px' }}>
				{treeNodes
					.map((n, i) => (
						<AggregationTreeNode
							key={`node-${n[0]}-${i}`}
							aggregationNode={n[1].nodes}
							aggregationNodeChildren={n[1].nodes.children}
							aggregationValueListId={n[0]}
							setParams={props.setParams}
						/>
					))}
			</List>
		</div>
	} else {
		return null
	}

}
