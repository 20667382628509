import { Paper, Typography } from '@material-ui/core'
import { AggregationParams, AggregationValues } from 'api/api-types'
import { DrawerContext } from 'drawer/AppWithDrawer'
import { Container, Page } from 'elements/Container'
import * as React from 'react'
import { AnalyticsDrawer } from './AnalyticsDrawer'
import { AnalyticsTable } from './AnalyticsTable'
import { ExpandableSection } from 'elements/ExpandableSection'
import { useAnalyticsStyle } from './useAnalyticsStyle'

export const CHUNK_SIZE = 30

export function AnalyticsPage() {
	const classes = useAnalyticsStyle()
	const drawerContext = React.useContext(DrawerContext)
	const [params, setParams] = React.useState<AggregationParams | null>(null)

	React.useEffect(() => {
		drawerContext.setRenderContent(() => (
			<AnalyticsDrawer
				setParams={setParams}
			/>
		))
	}, [params])

	const renderExpandableSectionHeader = (title: string) => {
		return <>
				<div className={classes.titleContainer}>
					<Typography variant='h6' className={classes.expandableSectionTitle}>{title}</Typography>
					<span><a href='#'>Test</a> / <a href='#'>Test</a></span>
				</div>
				<div className={classes.labelContainer}>
					{/* Render Labels */}
					<div className={`${classes.label} `}>Label1</div>
					<div className={`${classes.label} `}>Label2</div>
					<div className={`${classes.label} `}>Label3</div>
				</div>
			
		</>
	}

	return <Container page={Page.ANALYTICS} title="Analytics">
		<div className={classes.row}>
		<Paper className={classes.column}>
			<Typography variant='h6' className={classes.title} >Filter</Typography>
			<ExpandableSection renderHeader={() => renderExpandableSectionHeader('Hospital')} className={classes.expandableSection}></ExpandableSection>
			<ExpandableSection renderHeader={() => renderExpandableSectionHeader('Segment')} className={classes.expandableSection}></ExpandableSection>
			<ExpandableSection renderHeader={() => renderExpandableSectionHeader('Odbornost')} className={classes.expandableSection} ></ExpandableSection>
		</Paper>
		<div className={classes.analyticsTable}>
		<AnalyticsTable renderHeader={() => renderExpandableSectionHeader('Year')} aggregationParams={params} />
		</div>
		</div>
	</Container>
}