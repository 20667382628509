import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment'
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { FormHelperTextProps, InputAdornment, InputBaseComponentProps, InputLabelProps, InputProps, OutlinedInputProps, TextField, TextFieldProps } from '@material-ui/core';
import { CustomInput } from './form/CustomInput';
import { handleInputChange } from 'react-select/src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type Props = {
	label: string
	placeholder: string
	errorText: string
	required: boolean
	type: string
	showRequiredOnMount?: boolean
	shrink?: boolean
	regex?: RegExp
	startingValue?: number | null
	otherErrorCondition?: () => boolean
	style?: React.CSSProperties
	disabled?: boolean
	disableUnderline?: boolean
	className?: string
	startAndorment?: React.ReactNode
	endAndorment?: React.ReactNode
	customOnChange?: (e: any) => void
}
export type DateInput = {
	removeErrors: () => void
	checkErrors: () => boolean
	hasError: boolean
	value: string

}
export const InputCommon = {
	inputLabelProp: <T extends InputLabelProps>(props?: T) => ({
		...props,
		shrink: props?.shrink || true,
		style: {
			padding: 5,
			backgroundColor: 'white'
		}
	}),
	inputProps: <T extends InputProps>(props?: T) => ({
		...props,
		style: {
			border: '2px solid #ededed',
			borderRadius: 0,
			// width: props?.multiline ? '400px' : '',
			// height: props?.multiline ? '200px' : '',


		}
	}),
	helperTextProps: <T extends FormHelperTextProps>(props?: T) => ({
		...props,
		style: {

		}
	})
}

const TextFieldComp = (params: TextFieldProps) => {

	return <TextField {...params}
		// variant="outlined"
		// ref={ref}
		// ref={ref}

		// InputProps={{style: }
		// 	// params.inputProps as OutlinedInputProps
		// }
		// defaultValue={null}

		FormHelperTextProps={InputCommon.helperTextProps({})}
	// helperText={renderErrorText()}
	// type={params.type}
	// value={selectedDate}
	></TextField>
}

export const DateKeyInput = React.memo(React.forwardRef((props: Props, ref: React.Ref<DateInput>) => {
	const [selectedDate, setDate] = useState<Moment | null>(null)
	const [inputValue, setInputValue] = useState(moment().format("DD/MM/YYYY"));
	const [error, setError] = React.useState({
		hasError: false,
		errorMessage: ""
	})
	// const [inputValue, setInputValue] = useState(moment());
	const onDateChange = (date: MaterialUiPickersDate, value: string) => {
		console.log("will set date", date)
		const tmpDate = moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : ''
		if (date) {
			console.log('Settings date to date: ', date)
			setDate(date);
			console.log('Settings inputvalue to inputValue: ', value)
			setInputValue(value);
			props.customOnChange?.(value)
		}
		if (value.length > 4 && !date?.isValid) {

		}

	};

	const dateFormatter = (str: any) => {
		if (str !== '')
			return str;
	};

	const setIsRequiredError = () => {
		setError({
			hasError: true,
			errorMessage: "Field is required"
		})
	}

	const resetErrors = () => {
		setError({
			hasError: false,
			errorMessage: ""
		})
	}
	function handleErrors() {
		if (props.required) {
			if (selectedDate == null) {
				setIsRequiredError()
				return true
			}
		}

		// if (props.regex) {
		// 	setError({
		// 		hasError: !props.regex.test(value),
		// 		errorMessage: props.errorText
		// 	})
		// 	return !props.regex.test(value)
		// }
		if (props.otherErrorCondition) {
			setError({
				hasError: props.otherErrorCondition(),
				errorMessage: props.errorText
			})
			return props.otherErrorCondition()
		}
		console.log('Will reset errors')
		resetErrors()
		return false
	}

	React.useEffect(() => {

		if (props.startingValue) {
			console.log('Before parse,', props.startingValue)
			setDate(moment(props.startingValue))
			setInputValue(moment(props.startingValue).format('DD/MM/YYYY'))
			console.log('after Parsing', moment(props.startingValue).format('DD/MM/YYYY'))
		} else {
			if (props.showRequiredOnMount && props.required && !props.startingValue) {
				setDate(null)
				setInputValue('')
				setIsRequiredError()
			}
		}
	}, [props.startingValue])

	React.useImperativeHandle(ref, () => (
		{
			removeErrors: resetErrors,
			checkErrors: handleErrors,
			hasError: error.hasError,
			value: inputValue!
		}
	))
	function renderErrorText() {
		if (error.hasError) {
			return error.errorMessage
		} else {
			return <>&nbsp;</>
		}
	}




	// console.log("This is before return,", selectedDate)
	return (
		<KeyboardDatePicker
			// defaultValue={''}
			maskChar="_"
			mask='__/__/____'
			key={`${props.label} 839`}
			autoOk={false}
			onInvalid={(e) => { }}
			format="DD/MM/YYYY"
			value={selectedDate}
			inputValue={inputValue}

			// variant='inline'

			inputVariant='outlined'
			onChange={(e, v) => {
				if (v) {
					onDateChange(e, v)
					// props.customOnChange?.(e)
				}
			}}
			rifmFormatter={dateFormatter}
			placeholder="dd/mm/yyyy"
			// style={{

			// }}
			// inputProps={{
			// 	style:
			// 	{
			// 		border: '2px solid #ededed',
			// 		borderRadius: 0,
			// 	}
			// }}
			InputProps={InputCommon.inputProps<InputProps>({
				startAdornment: <InputAdornment position="start">{props.startAndorment || ""}</InputAdornment>,
				endAdornment: <InputAdornment position="end">{props.endAndorment || ""}</InputAdornment>,
				// multiline: props.multilne,
				style: {
					height: '100%', border: '2px solid #ededed',
					borderRadius: 0,
				}
			})}
			InputLabelProps={InputCommon.inputLabelProp({ shrink: props.shrink })}
			// onBlur={(e) => { e.preventDefault() }}


			disabled={props.disabled}
			required={props.required}
			label={props.label}
			error={error.hasError}

			TextFieldComponent={TextFieldComp}

			// onChange={e => {
			// 	onDateChange(e.target.value)
			// }}
			onBlur={_ => {
				_.preventDefault()
				if (!selectedDate && props.required) {
					setError({
						hasError: true,
						errorMessage: "Field is required"
					})
				}
				else if (props.otherErrorCondition) {
					setError({
						hasError: props.otherErrorCondition(),
						errorMessage: props.errorText
					})
				} else if (!selectedDate?.isValid()) {
					console.log("ENTER")
					setError({
						hasError: true,
						errorMessage: 'Invalid Date'
					})
				} else {
					setError({
						...error,
						hasError: false
					})
				}
				console.log('INSIIIIIIIIDE')
				console.log(props)


			}}
			// onFocus={()=>{
			// 	if(!selectedDate?.isValid){
			// 		setError('')
			// 	}
			// }}
			helperText={renderErrorText()}

		// />}
		// TextFieldComponent={(params) => <TextField {...params}
		// 	// variant="outlined"
		// 	// ref={ref}
		// 	error={error.hasError}
		// 	InputProps={InputCommon.inputProps<InputProps>({
		// 		startAdornment: <InputAdornment position="start">{props.startAndorment || ""}</InputAdornment>,
		// 		endAdornment: <InputAdornment position="end">{props.endAndorment || ""}</InputAdornment>,
		// 		style: { height: '100%' }
		// 	})}
		// 	InputLabelProps={InputCommon.inputLabelProp({ shrink: props.shrink })}
		// 	style={props.style}
		// 	label={props.label}
		// 	// type={params.type}
		// 	disabled={props.disabled}
		// 	required={props.required}
		// 	value={selectedDate}

		// 	// onChange={e => {
		// 	// 	onDateChange(e.target.value)
		// 	// }}
		// 	// 	onBlur={_ => {
		// 	// 		if (!selectedDate && props.required) {
		// 	// 			setError({
		// 	// 				hasError: true,
		// 	// 				errorMessage: "Field is required"
		// 	// 			})
		// 	// 		}
		// 	// 		else if (props.otherErrorCondition) {
		// 	// 			setError({
		// 	// 				hasError: props.otherErrorCondition(),
		// 	// 				errorMessage: props.errorText
		// 	// 			})
		// 	// 		} else {
		// 	// 			setError({
		// 	// 				...error,
		// 	// 				hasError: false
		// 	// 			})
		// 	// 		}
		// 	// 		console.log('INSIIIIIIIIDE')
		// 	// 		console.log(props)


		// 	// 	}}
		// 	FormHelperTextProps={InputCommon.helperTextProps({})}
		// 	helperText={renderErrorText()}

		// />}
		/>
		// </MuiPickersUtilsProvider>

	)
}))