export function upcFirst(value: string) {
	if (value) {
		return `${value.charAt(0).toUpperCase()}${value.substring(1)}`
	} else {
		return ""
	}
}

export const getRandomString = (length: number) => {
	return Math.random().toString(36).substring(length);
}