import { Checkbox, Typography, makeStyles, createStyles, Button, Theme, Snackbar } from '@material-ui/core';
import { downloadAccountingReport } from 'api/ApiServices';
import { Alert, AlertSettings } from 'elements/Alerts';
import * as React from 'react'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// title: {
		// 	flexGrow: 1,
		// },
		// hide: {
		// 	display: 'none',
		// },
		// drawer: {
		// 	// width: DRAWER_WIDTH,
		// 	flexShrink: 0,
		// },
		// drawerPaper: {
		// 	width: DRAWER_WIDTH,
		// },
		// drawerHeader: {
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	padding: theme.spacing(0, 1),
		// 	// necessary for content to be below app bar
		// 	...theme.mixins.toolbar,
		// 	justifyContent: 'flex-end',
		// },
		// content: {
		// 	flexGrow: 1,
		// 	padding: theme.spacing(3),
		// 	transition: theme.transitions.create('margin', {
		// 		easing: theme.transitions.easing.sharp,
		// 		duration: theme.transitions.duration.leavingScreen,
		// 	}),
		// 	marginRight: -DRAWER_WIDTH,
		// },
		// contentShift: {
		// 	transition: theme.transitions.create('margin', {
		// 		easing: theme.transitions.easing.easeOut,
		// 		duration: theme.transitions.duration.enteringScreen,
		// 	}),
		// 	marginRight: 0,
		// },
		// To be splited in separate component
		MuiDropzonePreviewList: {
			image: {

			}
		},
		dropZoneText: {
			fontSize: '1rem',
			padding: '0px 16px'
		},

		buttonSection: {
			display: 'flex',
			height: '32%',
			width: '100%',
			maxWidth: '200px',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			padding: '0px 10px',
			alignSelf: 'center',


		},
		approveButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#08ad6c',
			border: '2px solid #08ad6c',
		},
		rejectButton: {
			justifySelf: 'flex-end',
			marginBottom: 16,
			padding: '10px 0px',
			color: '#FF3D57',
			border: '2px solid #FF3D57',

		},
		dropZoneGirdContainer: {

		},
		dropZoneGirdItem: {

		},
		dropZoneGirdImage: {
			color: 'rgba(0, 0, 0, 0.87)',
			width: 'initial',
			height: '30px',
			opacity: 1,
			zIndex: 5,
			maxWidth: '100%',
			boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;',
			boxSizing: 'border-box',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
			borderRadius: '4px',
		},
		list: {
			overflowY: 'auto',
			height: '100%'
		},
		listItem: {
			paddingBottom: '2px',
			paddingTop: '2px',
		},
		itemIcon: {
			minWidth: '0px',
			paddingRight: '10px'
		},
		icon: {
			color: 'rgba(34, 42, 69, 0.96)',
		},
		link: {
			color: '#1976d2'
		}

	}),
)

export const DownloadReport = () => {
	const classes = useStyles()
	const { t } = useTranslation();
	const [lastMonth, setLastMonth] = React.useState(true)
	const [alert, setAlert] = React.useState<AlertSettings>({ open: false, message: '', color: 'success' });
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ open: false, message: '', color: 'warning' })
		// setOpenAlert(false)
	}

	async function handleDownload() {
		const response = await downloadAccountingReport()
		if (response._type === 'data') {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `report.csv`);
			document.body.appendChild(link);
			link.click();
		} else {
			setAlert({ message: t("Alert.No_Invoices"), open: true, color: 'warning' })
		}
	}
	return (<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
		<Typography variant='body2' style={{ padding: 16 }}>{t("Alert.Download_Support")}</Typography>
		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
			<Checkbox name="checkbox1" checked={lastMonth} onChange={(e) => setLastMonth(!lastMonth)}></Checkbox>
			<Typography variant='subtitle1'>Download Last month Report</Typography>
		</div>
		<div style={{ height: '100%' }}></div>
		<div className={classes.buttonSection}>
			<Button className={classes.approveButton} onClick={handleDownload}>
				Download
			</Button>
		</div>
		<Snackbar open={alert.open} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} style={{ marginBottom: '60px', marginRight: '14px' }}>
			<Alert onClose={handleCloseAlert} severity={alert.color}>
				<Typography variant='body2' style={{ color: 'white' }}>{alert.message}</Typography>
			</Alert>
		</Snackbar>
	</div>)
}