import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { registerMember } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { regexPatterns } from 'utils/Validation'
import { useModalStyles } from 'styles/useModalStyle'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			padding: 30
		},
		registerContainer: {
			marginTop: 20,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		registerLabel: {
			opacity: 0.7,
			fontSize: 12
		},
		registrationSuccess: {
			padding: 40
		}
	})
)

type Props = {
	token: string
}

export function RegisterMember(props: Props) {
	const classes = useStyles()
	const modalClasses = useModalStyles()
	const [registrationSuccess, setRegistrationSucess] = React.useState(false)
	const passwordInputRef = React.useRef<CustomInput>(null)
	const repeatPasswordInputRef = React.useRef<CustomInput>(null)

	async function onSubmit() {
		const passwordError = passwordInputRef.current?.checkErrors()
		const repeatPasswordError = repeatPasswordInputRef.current?.checkErrors()

		if (!passwordError && !repeatPasswordError) {
			const response = await registerMember({ password: passwordInputRef.current!.value, token: props.token })
			if (response._type === 'data') {
				setRegistrationSucess(response.data)
			}
		}
	}

	function renderInner() {
		if (registrationSuccess) {
			return <Redirect to="/login" />
		} else {
			return (
				<>

					<div className={classes.form}>
						<div style={{ textAlign: 'center' }}>
							<a href="https://mygide.com/" >
								<img src="/AiBERT_purple2.png" alt="logo" id="logo" />
							</a>
						</div>
						<Form onSubmit={onSubmit} className={classes.form}>

							<CustomInput
								label="Password"
								placeholder="Enter your password"
								type="password"
								required={true}
								errorText="Password must contain one capital letter, one number and at least 8 characters"
								ref={passwordInputRef}
								regex={regexPatterns.password}
							/>
							<CustomInput
								label="Repeat password"
								placeholder="Repeat your password"
								type="password"
								required={true}
								errorText="Password doesn't match"
								ref={repeatPasswordInputRef}
								otherErrorCondition={() => {
									if (passwordInputRef.current?.value && repeatPasswordInputRef.current?.value) {
										return passwordInputRef.current.value !== repeatPasswordInputRef.current.value
									} else {
										return false
									}
								}}
							/>
							<SubmitButton>Register</SubmitButton>
						</Form>
					</div>
				</>
			)
		}
	}

	return (
		<div className={`${modalClasses.paper} ${modalClasses.paperCentered}`}>
			{renderInner()}
		</div>
	)
}