import { CardCvcElementProps, CardExpiryElementProps, CardNumberElementProps } from '@stripe/react-stripe-js';
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useRef, useImperativeHandle, PropsWithChildren } from 'react'

type Props<T> = {
	component: React.FC<CardNumberElementProps | CardExpiryElementProps | CardCvcElementProps>,
	inputRef: React.RefObject<T>
}

export const StripeInput: React.FC<PropsWithChildren<Props<any>>> = ({ component, inputRef, ...other }) => {
	const elementRef = useRef<StripeCardNumberElement | StripeCardExpiryElement | StripeCardCvcElement>();
	useImperativeHandle(inputRef, () => {
		return {
			focus: elementRef.current?.focus
		}
	})
	const Component = component
	return (
		<Component
			onReady={(element: any) => (elementRef.current = element)}
			{...other}
		/>
	)
}
