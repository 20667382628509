import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { Account } from 'account/Account'
import { AnalyticsPage } from 'analytics/AnalyticsPage'
import { JobRole } from 'api/api-types'
import { Dashboard } from 'dashboard/Dashboard'
import { OpenParams, RightDrawer } from 'drawer/rightDrawer/RightDrawer'
import { FeedPage } from 'feed/FeedPage'
import { HRPage } from 'hr/HR'
import { InventoryPage } from 'inventory/Inventory'
import { InvoicingPage } from 'invoicing/invoicing'
import * as _ from 'lodash'
import { ProcurementPage } from 'procurement/ProcurementPage'
import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { AuthContext } from 'utils/Auth'
import { DRAWER_WIDTH, MainDrawer } from '../drawer/MainDrawer'
import { Header } from './Header'
import clsx from 'clsx';

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex'
		},
		content: {
			flexGrow: 1,
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		shiftRight: {
			marginRight: DRAWER_WIDTH,
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		toolbar: { ...theme.mixins.toolbar, maxHeight: '52px' }
	})
)

export function AppMain(props: RouteComponentProps) {
	const theme = useTheme()
	const classes = useStyle(theme)
	const [mobileOpen, setMobileOpen] = React.useState(false)
	const [leftDrawerOpen, setLeftDrawerOpen] = React.useState(true)
	const [rightDrawerOpen, setRightDrawerOpen] = React.useState<OpenParams>({ open: false, for: 'download' })


	const authContext = React.useContext(AuthContext)

	const { roles } = authContext.getUser()!
	const isAdmin = _.some(roles, role => role === JobRole.ADMIN)
	const isProcurement = _.some(roles, role => role === JobRole.PROCUREMENT_ADMIN)
	const isApprover = _.some(roles, role => role === JobRole.APPROVER)
	const isAnalyst = _.some(roles, role => role === JobRole.ANALYST)

	return (
		<div className={classes.container}>
			<Header
				{...props}
				setMobileOpen={() => setMobileOpen(!mobileOpen)}
				setLeftDrawerOpen={() => setLeftDrawerOpen(!leftDrawerOpen)}
				leftDrawerOpen={leftDrawerOpen}
				setRightDrawerOpen={setRightDrawerOpen}
				rightDrawerOpen={rightDrawerOpen}
			/>
			<MainDrawer
				routeProps={props}
				leftDrawerOpen={leftDrawerOpen}
				setLeftDrawerOpen={() => setLeftDrawerOpen(!leftDrawerOpen)}
				mobileOpen={mobileOpen}
				setMobileOpen={() => setMobileOpen(!mobileOpen)}
			/>
			<RightDrawer
				rightDrawerOpen={rightDrawerOpen} setRightDrawerOpen={setRightDrawerOpen} />
			{/* <RightDrawer rightDrawerOpen={rightDrawerOpen} setRightDrawerOpen={() => setRightDrawerOpen(!rightDrawerOpen)} /> */}


			<div className={clsx(classes.content, {
				[classes.shiftRight]: rightDrawerOpen.open,
			})}>

				<div className={classes.toolbar} style={{ maxHeight: 52, minHeight: 52 }} />
				<Switch>
					{(isAdmin || isProcurement || isApprover) && <Route path="/feed" component={FeedPage} />}
					{isAdmin && <Route path="/reporting" component={AnalyticsPage} />}
					{isAdmin && <Route path="/procurement/:sub" component={ProcurementPage} />}
					{(isAdmin || isAnalyst) && <Route path="/dashboard" component={Dashboard} />}
					{isAdmin && <Route path="/invoicing/:sub" component={InvoicingPage} />}
					{isAdmin && <Route path="/inventory/:sub" component={InventoryPage} />}
					{isAdmin && <Route path="/yellow-pages/:sub" component={HRPage} />}
					{/* {isAdmin && <Route path="/settings/:sub" component={SettingsPage} />} */}


					{/* {((isAdmin && isApprover) || isApprover) && <Route path="/approvals-chain" component={ApprovalsPage} />} */}
					{isAdmin && <Route path="/settings/:sub" component={Account} />}
					<Route render={() => {
						if (isAdmin || isAnalyst) {
							return <Redirect to="/feed" />
						} else if (isProcurement || isApprover) {
							return <Redirect to="/feed" />
						} else if (isAnalyst) {
							return <Redirect to="/feed" />
						} else {
							return null
						}
					}} />
				</Switch>
			</div>
		</div>
	)
}