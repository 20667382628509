import { ResponseType } from "api/api-types"
import { CustomError } from "main/AppWithErrors"
import * as React from 'react'

type Props<T> = {
	apiRequest: () => Promise<T>
	pollingInterval?: number
	handleError?: (error: CustomError) => void
}

type Parsed<T> = T | 'loading' | null

export function handleApiResponse<T>(
	response: ResponseType<T>,
	handleError: (error: CustomError) => void,
	handleData: (data: T | null) => void
) {
	if (response._type === 'error' || response._type === 'generic_error') {
		// Error handled by backend
		handleError(response.error)
		handleData(null)
	} else if (response._type === "generic_success") {
		// Generic succes, there is no data property returned from backend
		// NOTE: cast is used since we need to handle this one where the actual usage is
		handleData("success" as any)
	} else if (response._type === 'data') {
		// Success, there is data returned
		handleData(response.data)
	} else {
		throw Error()
	}
}

// NOTE: 
// Generic success is not supported by this hook,
// it will throw in that case, this hook expect
// data to be returned from backend.
export function useApi<T>(props: Props<ResponseType<T>>) {
	const [data, setData] = React.useState<Parsed<T>>('loading')

	// Todo: 
	// Errors are not handled currently.
	// We will create error context to handle errors.
	// For now just a placeholder.
	const [error, setError] = React.useState<CustomError | null>(null)

	const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null)

	async function fetch() {
		const response = await props.apiRequest()
		if (response._type === 'generic_success') throw Error()
		handleApiResponse(
			response,
			props.handleError || setError,
			setData
		)
	}

	React.useEffect(() => {
		// First start fetching
		// Then schedule interval if polling interval is provided
		fetch()
		if (props.pollingInterval) {
			timeout.current = setInterval(fetch, props.pollingInterval)
			return () => {
				if (timeout.current) {
					clearTimeout(timeout.current)
					timeout.current = null
				}
			}
		}
	}, [props.pollingInterval])

	return [
		data,
		fetch
	] as const
}