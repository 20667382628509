import { Modal } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close'
import * as React from "react"
import { useModalStyles } from "styles/useModalStyle"

type ModalProps = {
	modalWidth?: number
	modalHeight?: number
	maxModalWidth?: number
	maxModalHeight?: number
	isOpen: boolean
	onClose: () => void
}

export function ModalX(props: React.PropsWithChildren<ModalProps>) {
	const modalClasses = useModalStyles({
		modalHeight: props.modalHeight,
		modalWidth: props.modalWidth,
		maxModalWidth: props.maxModalWidth,
		maxModalHeight: props.maxModalHeight,
	})

	return <Modal open={props.isOpen} onClose={props.onClose} style={{ overflow: 'scroll' }}>
		<div className={`${modalClasses.paper} ${modalClasses.modal}`}>
			{props.children}
			<CloseIcon className={modalClasses.closeButton} onClick={props.onClose} />
		</div>
	</Modal>
}

type HeaderProps = {
	className?: string
	style?: React.CSSProperties
}

export function ModalXHeader(props: React.PropsWithChildren<HeaderProps>) {
	const modalClasses = useModalStyles()
	return <div
		className={`${modalClasses.modalHeader} ${props.className}`}
		style={{ ...props.style }}
	>
		{props.children}
	</div>
}

type BodyProps = {
	className?: string
	style?: React.CSSProperties
}

export function ModalXBody(props: React.PropsWithChildren<BodyProps>) {
	const modalClasses = useModalStyles()
	return (
		<div
			className={`${modalClasses.modalBody} ${props.className}`}
			style={{ ...props.style }}
		>
			{props.children}
		</div>
	)
}

type FooterProps = {

}

export function ModalXFooter(props: React.PropsWithChildren<FooterProps>) {
	const modalClasses = useModalStyles()
	return (
		<div className={modalClasses.modalFooter}>
			{props.children}
		</div>
	)
}
type SideBarProps = {
	className?: string,
	hasNext?: boolean,
	title: string
}

export function ModalXSideBar(props: React.PropsWithChildren<SideBarProps>) {
	const modalClasses = useModalStyles()
	return (
		<div className={modalClasses.sidebar}>
			<span className={modalClasses.sidebarHeader}>{props.title}</span>
			{props.children}
		</div>
	)
}