import { Paper, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { uploadCompanyInvoiceInfo } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { UserContext } from 'main/AppWithUser'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: {
			display: 'flex',
			width: '100%',
			justifyItems: 'center',
		},
		column: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

		},
		columnTwo: {
			paddingRight: '16px',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

		},
		submitButton: {
			borderRadius: 0,
			boxShadow: 'none',
			padding: 20,
			justifySelf: 'flex-end'
		},
		containerColumn: {
			width: '70%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			padding: '16px 64px 16px 16px',
			marginBottom: '16px'
		},
		serviceRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '22px',

		},
		subTitle: {
			// paddingTop: 12,
		},
		input: {
			width: '68%',
			justifySelf: 'flex-end'

		},
		marginRigh: {
			marginRight: '8px'
		},
		marginLeft: {
			marginLeft: '8px'
		}

	}))

export const CompanyInformation = () => {
	const classes = useStyles()

	const [companyLogo, setCompanyLogo] = React.useState<any>()

	const user = React.useContext(UserContext)

	// const companyLogoRef = React.createRef<TextField>()
	const companyNameRef = React.createRef<CustomInput>()
	const companyAddressRef = React.createRef<CustomInput>()
	const companyRegistrarRef = React.createRef<CustomInput>()
	const companyWebRef = React.createRef<CustomInput>()
	const companyContactNameRef = React.createRef<CustomInput>()
	const companyPhoneNumberRef = React.createRef<CustomInput>()
	const companyEmailRef = React.createRef<CustomInput>()
	const companyInoRef = React.createRef<CustomInput>()
	const companyTINORef = React.createRef<CustomInput>()
	const companyINOVATRef = React.createRef<CustomInput>()
	const companyBankAccountRef = React.createRef<CustomInput>()
	const companyBankCodeRef = React.createRef<CustomInput>()
	const companyBankSWIFTRef = React.createRef<CustomInput>()
	const companyIBANRef = React.createRef<CustomInput>()

	async function onSubmit() {

		// let reader = new FileReader();
		// reader.onload = (e) => {
		// 	let res = reader.result
		// 	console.log(res)
		// }
		// if (companyLogoRef.current)
		// reader.readAsDataURL(companyLogoRef.current.value)
		// reader.onload = function (event) {
		// 	var res = event.target.result;
		// 	console.log(res)
		// }
		// var file = this.response;
		// reader.readAsDataURL(file)
		// console.log('image', URL.createObjectURL({ companyLogoRef.current.value }))
		let image

		if (companyLogo && companyNameRef.current && companyAddressRef.current && companyRegistrarRef.current && companyWebRef.current && companyContactNameRef.current &&
			companyPhoneNumberRef.current && companyPhoneNumberRef.current && companyEmailRef.current && companyInoRef.current && companyTINORef.current && companyINOVATRef.current &&
			companyBankAccountRef.current && companyBankCodeRef.current && companyBankSWIFTRef.current && companyIBANRef.current) {
			const response = await uploadCompanyInvoiceInfo(
				{
					companyLogo: companyLogo,
					companyName: companyNameRef.current.value,
					companyAddress: companyAddressRef.current.value,
					companyRegistar: companyRegistrarRef.current.value,
					companyWeb: companyWebRef.current.value,
					companyContactName: companyContactNameRef.current.value,
					companyPhone: companyPhoneNumberRef.current.value,
					companyEmail: companyEmailRef.current.value,
					companyINO: companyInoRef.current.value,
					companyTINO: companyTINORef.current.value,
					companyINOVAT: companyINOVATRef.current.value,
					companyBankAcc: companyBankAccountRef.current.value, // 
					companyBankCode: companyBankCodeRef.current.value, //
					companyBankSWIFT: companyBankSWIFTRef.current.value, // 
					companyIBAN: companyIBANRef.current.value, //
				}
			)
			if (response._type === 'generic_success') {
				console.log('uploaded')
			} else {
				console.log('failed')
			}
		}
	}

	const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target instanceof HTMLInputElement && event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0]
			const base64 = await convertBase64(file)
			setCompanyLogo(base64)
			console.log('this is base64', base64)
		}
	}

	const convertBase64 = (file: File) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				if (fileReader.result) {
					// fileReader.
					// console.log('This file will be set to', fileReader.result.toString())
				}
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}


	return (<Form onSubmit={onSubmit} pure={true}>
		<div className={classes.row}>
			<Paper className={`${classes.containerColumn} ${classes.marginRigh}`} style={{ alignSelf: 'stretch' }}>

				<Typography variant='h6' style={{ paddingBottom: '16px' }}>Company Header</Typography>

				<div className={classes.serviceRow} style={{
					marginBottom: 0, height: '131px'
				}}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company logo</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						{user.organisation.information.companyLogo ? <img src={user.organisation.information.companyLogo}></img> : <TextField
							// ref={companyLogoRef}
							label="Company logo"
							// errorText=''
							placeholder=''
							required
							type='file'
							onChange={handleFileRead}
							inputProps={{ accept: '.jpeg, .jpg, .png' }}
							style={{ marginBottom: '19px' }}
						>

						</TextField>}
					</div>

				</div>

				{/* <CustomInput
					ref={companyLogoRef}
					label="Company logo"
					errorText=''
					placeholder=''
					required
					type='file'
				/> */}

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company name</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyName}
							ref={companyNameRef}
							label="Company name"
							errorText=''
							placeholder=''
							required
							type='text'
						/>
					</div>

				</div>
				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company address</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyAddress}
							ref={companyAddressRef}
							label="Company address"
							errorText=''
							placeholder=''
							required
							type='text'
						/>
					</div>

				</div>



			</Paper>
			<Paper className={`${classes.containerColumn} ${classes.marginLeft}`} style={{ alignSelf: 'stretch' }}>

				<div className={classes.serviceRow} style={{ marginBottom: 0, marginTop: 40 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company registrar</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyRegistar}
							ref={companyRegistrarRef}
							label="Company registrar"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company web</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyWeb}
							ref={companyWebRef}
							label="Company web"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>
				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company contact name</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyContactName}
							ref={companyContactNameRef}
							label="Company contact name"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company phone number</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyPhone}
							ref={companyPhoneNumberRef}
							label=""
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

			</Paper>
		</div>

		<div className={classes.row}>
			<Paper className={`${classes.containerColumn} ${classes.marginRigh}`} style={{ alignSelf: 'stretch' }}>


				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company Email</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyEmail}
							ref={companyEmailRef}
							label=" "
							errorText=''
							placeholder=''
							required
							type='email'

						/>
					</div>

				</div>


				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company INO</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyINO}
							ref={companyInoRef}
							errorText=''
							label=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company TINO</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyTINO}
							ref={companyTINORef}
							label=""
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company INO VAT</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyINOVAT}
							ref={companyINOVATRef}
							label=""
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

			</Paper>

			<Paper className={`${classes.containerColumn} ${classes.marginLeft}`} style={{ alignSelf: 'stretch' }}>


				{/* Billing info */}
				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company bank account</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyBankAcc}
							ref={companyBankAccountRef}
							label="Company bank account"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>



				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company's bank code (BIC)</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyBankCode}
							ref={companyBankCodeRef}
							label=""
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company SWIFT</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyBankSWIFT}
							ref={companyBankSWIFTRef}
							label="Company SWIFT"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>

				<div className={classes.serviceRow} style={{ marginBottom: 0 }}>
					<span className={classes.subTitle} style={{ paddingBottom: 20 }}>
						<Typography variant='subtitle1'>Company IBAN</Typography>
					</span>
					{/* ClassName didnt work */}
					<div className={classes.input}>

						<CustomInput
							startingValue={user.organisation.information.companyIBAN}
							ref={companyIBANRef}
							label=""
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>

				</div>
			</Paper>

		</div>
		<SubmitButton className={classes.submitButton}>Upload</SubmitButton>

	</Form >
	)
}