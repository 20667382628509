import { DateTimeFormatter, Instant, LocalDate } from '@js-joda/core'
import { Button, Checkbox, createStyles, FormControlLabel, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Tooltip, Typography, withStyles } from '@material-ui/core'
import { ApprovalStatus, ApproversType, DocStatusEnum } from 'api/api-types'
import { putItemOnHold, setItemIsRead, submitApproved } from 'api/ApiServices'
import { RejectedReasonModal } from 'approvals/RejectedReasonModal'
import { ExpandableSection } from 'elements/ExpandableSection'
import { CustomInput } from 'elements/form/CustomInput'
import { ModalX, ModalXBody, ModalXHeader, ModalXSideBar } from 'elements/ModalX'
import { LOG } from 'logger/Log'
import * as React from 'react'
import { upcFirst } from 'utils/TextFormating'
import { regexPatterns } from 'utils/Validation'
import { ItemModal, ItemModalCommon, ItemModalParams, Props, State } from './ItemModalCommon'
import { useItemModalStyles } from './useItemModalStyle'
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CheckIcon from '@material-ui/icons/Check';


const DTF = DateTimeFormatter.ofPattern("yyyy-MM-dd")

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		approvalButtonsWrapper: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: '0px 10px'
		},
		approveButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#08ad6c',
			border: '2px solid #08ad6c',
		},
		onHoldButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#FFAF38',
			border: '2px solid #FFAF38',

		},
		returnButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#1976d2',
			border: '2px solid #1976d2',

		},
		rejectButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#FF3D57',
			border: '2px solid #FF3D57',

		}
	})
)
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		root: {
			borderColor: 'white',
			paddingTop: '4px',
			paddingBottom: '4px',
		},
		head: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.38)',
			fontWeight: 700,
		},
		body: {
			fontSize: '0.8125rem',
			color: 'rgba(0, 0, 0, 0.38)',
		}

	}),
)(TableCell);
export const ApprovalsModal = React.forwardRef((props: Props, ref: React.Ref<ItemModal>) => {
	const classes = useItemModalStyles()
	const classesLocal = useStyles()
	const [isOpen, setIsOpen] = React.useState(false)
	const [state, setState] = React.useState<State | null>(null)
	const [isNotEditable, setIsNotEditable] = React.useState<boolean>(false)
	const [orderNumberNotApplicable, setOrderNumberNotApplicable] = React.useState(false)

	const { t } = useTranslation();

	const rejectModalRef = React.useRef<RejectedReasonModal>(null)

	const show = async (params: ItemModalParams) => {
		// approvedByMe removed from here approvedByMe
		const { labels, docName, itemId, rejectReason, approvalStatus, values, extractionStatus, confidenceLevel, approvers } = params.item

		const isNotEditable = approvalStatus === ApprovalStatus.APPROVED
			|| approvalStatus === ApprovalStatus.REJECTED || approvalStatus === ApprovalStatus.PENDING_APPROVAL

		setIsNotEditable(isNotEditable)
		if (labels.documentationGroup.requestIdStatus?.value === DocStatusEnum.NOT_APPLICABLE) {
			setOrderNumberNotApplicable(true)
		}
		const itemsRead = await setItemIsRead([itemId])
		if (itemsRead._type === 'data') {

		} else {
			console.log('Item is read failed')
		}
		setState({
			docName: docName,
			// isNotEditable: isNotEditable,
			itemId: itemId,
			labels: labels,
			values: values,
			approvalStatus: approvalStatus,
			extractionStatus: extractionStatus,
			// Ask about confidence lvl
			confidenceLevel: confidenceLevel ? confidenceLevel : 100,
			rejectedReason: rejectReason,
			pagination: {
				hasNext: (params.currentIndex + 1) < params.size,
				hasPrev: params.currentIndex > 0
			},
			approvers: approvers,
			// approvedByMe: approvedByMe,
		})
		setIsOpen(true)
	}

	const paginateNext = ItemModalCommon.paginateNext(show, handleClose)
	const paginatePrev = ItemModalCommon.paginatePrev(show, handleClose)

	React.useImperativeHandle(ref, () => ({ show }))

	async function handleClose() {
		setIsOpen(false)
		// setState(null)
		setOrderNumberNotApplicable(false)
		props.refetchInvoices()
		props.refetchCounters()
	}

	function formatDateFromEpoch(epochMilis: number | null) {
		if (epochMilis != null) {
			return LocalDate.ofInstant(Instant.ofEpochMilli(epochMilis)).format(DTF)
		}
	}

	function renderApproverRow(approvers: ApproversType[], lvl: string) {
		return approvers.map((e) => (<>
			<Tooltip title={e.closedAt != null ? formatDateFromEpoch(e.closedAt)! : ''}>

				<TableRow>
					<StyledTableCell align="left" className={classes.iconCell} style={{ paddingTop: 0, paddingLeft: 0 }}><AccountCircleIcon className={classes.userIcon} /></StyledTableCell>
					<StyledTableCell align="left">{e.lastName} {e.firstName}</StyledTableCell>
					<StyledTableCell align="left">{e.username}</StyledTableCell>
					<StyledTableCell align="left">{e.approved ? <CheckIcon /> : ''}</StyledTableCell>

					<StyledTableCell align="center">{lvl}</StyledTableCell>
				</TableRow>
			</Tooltip>

		</>))
	}

	function renderForm(state: State) {
		return <div className={classes.form}>
			<ExpandableSection
				renderHeader={() => <Typography variant='h6'>{t('Modal.Supplier')}</Typography>}
				className={classes.expandableSection}
				startExpanded={!state.approvedByMe}
			>
				<CustomInput
					label={t('Modal.Ico')}
					errorText=""
					placeholder={t('Modal.Ico_Long')}
					required={true}
					type="text"
					showRequiredOnMount={true}
					disabled={isNotEditable}
					startingValue={state.labels.supplierGroup.ico.value}
				/>
				<CustomInput
					label={t('Modal.Company_Name')}
					errorText=""
					placeholder={t('Modal.Company_Name_Long')}
					required={true}
					type="text"
					showRequiredOnMount={true}
					disabled={isNotEditable}
					startingValue={state.labels.supplierGroup.companyName.value}
				/>
				{state.labels.supplierGroup.iban.map(e => (
					<CustomInput
						label={t('Modal.Iban')}
						errorText=""
						placeholder=""
						required={true}
						type="text"
						disabled={true}
						startingValue={e.value}
					/>
				))}
			</ExpandableSection>
			<ExpandableSection
				renderHeader={() => <Typography variant='h6'>{t('Modal.Invoice')}</Typography>}
				className={classes.expandableSection}
				startExpanded={true}
			>
				<CustomInput
					label={t('Modal.Invoice_Num')}
					errorText={t('Modal.Invoice_Long')}
					placeholder=""
					required={true}
					type="text"
					showRequiredOnMount={true}
					disabled={isNotEditable}
					startingValue={state.labels.documentationGroup.documentNumber?.value}
					regex={regexPatterns.invoiceNumber}
				/>
				<div className={classes.datesContainer}>
					<CustomInput
						label={t('Modal.Date_Issue')}
						errorText=""
						placeholder=""
						required={true}
						type="date"
						showRequiredOnMount={true}
						shrink={true}
						disabled={isNotEditable}
						startingValue={formatDateFromEpoch(state.labels.datesGroup.dateOfIssue.value)}
					/>
					<CustomInput
						label={t('Modal.Date_Delivery')}
						errorText=""
						placeholder=""
						required={true}
						type="date"
						showRequiredOnMount={true}
						shrink={true}
						disabled={isNotEditable}
						startingValue={formatDateFromEpoch(state.labels.datesGroup.deliveryDate.value)}
					/>
					<CustomInput
						label={t('Modal.Date_Due')}
						errorText=""
						placeholder=""
						required={true}
						type="date"
						showRequiredOnMount={true}
						shrink={true}
						disabled={isNotEditable}
						startingValue={formatDateFromEpoch(state.labels.datesGroup.dueDate.value)}
					/>
				</div>
				<CustomInput
					label={t('Modal.Inv_Amount')}
					errorText={t('Modal.Inv_Amount_Error')}
					placeholder={t('Modal.Inv_Amount_Long')}
					required={true}
					type="text"
					showRequiredOnMount={true}
					disabled={isNotEditable}
					regex={regexPatterns.amount}
					startingValue={state.values?.invoiceAmount?.value?.toString()}
				/>
				{state.labels.documentationGroup.orderNumber?.map(e => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<CustomInput
							label={t('Modal.Iban')}
							errorText=""
							placeholder=""
							required={true}
							type="text"
							disabled={true}
							startingValue={e.value}
						/>
						{orderNumberNotApplicable && <FormControlLabel
							className={classes.checkboxLabel}
							control={<Checkbox
								color="primary"
								checked={orderNumberNotApplicable}
								disabled={isNotEditable}
							/>}
							label={t('Modal.NA')}
							labelPlacement="end"
						/>}
					</div>
				))}
			</ExpandableSection>
			{(!state.rejectedReason && state.approvers) && <ExpandableSection
				renderHeader={() => <Typography variant="body2">{t('Modal.Approval_Status')}</Typography>}
				className={classes.expandableSection}
				startExpanded={true}
				childrenContainer={true}
			>
				<TableContainer>
					{/* className={classes.table} */}
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell align="left" style={{ paddingTop: 0, paddingLeft: 0 }}></StyledTableCell>
								<StyledTableCell align="left" >Name</StyledTableCell>
								<StyledTableCell align="left" >Email</StyledTableCell>
								<StyledTableCell align="left" >Approved</StyledTableCell>
								<StyledTableCell align="left" >Level</StyledTableCell>

							</TableRow>
						</TableHead>
						<TableBody style={{ overflowY: 'auto' }}>

							{renderApproverRow(state.approvers.filter(e => e.conditional), 'M')}
							{renderApproverRow(state.approvers.filter(e => !e.conditional), 'S')}
							{/* {renderMembers(data)} */}
						</TableBody>
					</Table>
				</TableContainer>
				{/* <p style={{ wordWrap: 'break-word' }}>Test</p> */}
			</ExpandableSection>}
			<ExpandableSection renderHeader={() => <Typography variant='h6'>{t('Modal.Booking')}</Typography>} className={classes.expandableSection}></ExpandableSection>
			<ExpandableSection renderHeader={() => <Typography variant='h6'>{t('Modal.Payment')}</Typography>} className={classes.expandableSection}></ExpandableSection>
		</div>
	}

	const renderButtons = () => {
		return <div className={classesLocal.approvalButtonsWrapper}>
			<Button
				className={classesLocal.approveButton}
				onClick={async () => {
					try {
						const response = await submitApproved(state!.itemId)
						if (response._type === 'generic_success') {
							props.paginate(state!.itemId, paginateNext)
						}
					} catch (e) {
						LOG.dev("Error approving", e)
					}
				}}
			>{t('Button.Approve')}</Button>
			<Button
				className={classesLocal.onHoldButton}
				onClick={async () => {
					try {
						const response = await putItemOnHold(state!.itemId)
						if (response._type === 'generic_success') {
							props.paginate(state!.itemId, paginateNext)
						}
					} catch (e) {
						LOG.dev("Error approving", e)
					}
				}}
			>{t('Button.On_Hold')}</Button>
			<Button
				className={classesLocal.rejectButton}
				onClick={() => {
					rejectModalRef.current?.show({
						rejectedId: state!.itemId,
						onReject: () => props.paginate(state!.itemId, paginateNext)
					})
				}}
			>{t('Button.Reject')}</Button>
		</div>
	}

	if (!state) {
		return null
	} else {
		return <ModalX
			isOpen={isOpen}
			onClose={handleClose}
		// modalHeight={}
		>
			<ModalXHeader style={{ backgroundColor: 'white' }}>
				<div className={classes.headerLabels}>
					<span>{upcFirst(state.labels.inputGroup.documentType ? state.labels.inputGroup.documentType.value : '')}</span>
					{ItemModalCommon.renderHeaderLabels(
						state.approvalStatus,
						state.extractionStatus,
						state.confidenceLevel,
						state.labels.documentationGroup.requestIdStatus?.value,
						state.labels.documentationGroup.purchaseOrderIdStatus?.value
					)}
				</div>
			</ModalXHeader>
			<ModalXBody key={state.itemId} style={{
				flexDirection: 'row',
				backgroundColor: '#ededed',
			}}>
				<div className={classes.section}>
					<div className={classes.content} style={{ height: 820 }}>
						{ItemModalCommon.renderDocument(state, classes.documentContainer)}
						{renderForm(state)}
					</div>
					{ItemModalCommon.renderPagination(
						state,
						props,
						paginatePrev,
						paginateNext,
						classes.pagination,
						classes.paginationButton
					)}
				</div>
				<ModalXSideBar title="Labels">
					{!state.approvedByMe ?
						renderButtons() :
						<div className={classesLocal.approvalButtonsWrapper}>
							<Button
								className={classesLocal.approveButton}
								onClick={async () => { }}
								style={{ cursor: 'default', pointerEvents: 'none' }}
							>Already approved by you</Button>
						</div>
					}
				</ModalXSideBar>
			</ModalXBody>
			<RejectedReasonModal ref={rejectModalRef} />
		</ModalX>
	}
})