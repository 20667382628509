import * as React from 'react'
import { createStyles, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import { Status } from 'api/api-types';

const useStyle = makeStyles((theme) =>
	createStyles(
		{
			container: {
				// backgroundColor: "#2c3e50",
				borderRadius: "50px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-around",
				padding: "0px 0 0",
				marginLeft: '24px',
				height: "50px",
				width: "17px",
			},
			circle: {
				backgroundColor: "rgba(0, 0, 0, 0.3)",
				borderRadius: "100%",
				position: "relative",
				height: "10px",
				width: "10px",
				"&::after": {
					// borderRight: "4px solid rgba(255, 255, 255, 0.6)",
					// borderRadius: "100%",
					// content: "' '",
					// position: "absolute",
					// top: "5px",
					// left: "4px",
					// width: "1px",
					// height: "1px"
				}
			},
			circle_red: {
				backgroundColor: "#c0392b",
				boxShadow: "0 0 20px 5px #c0392b"
			},
			circle_yellow: {
				backgroundColor: "#FFAF38",
				// boxShadow: "0 0 5px 1px #FFAF38",
				animation: `$blink-animation 1s steps(5, start) infinite`,

			},
			circle_green: {
				backgroundColor: "#08ad6c",
				// boxShadow: "0 0 5px 1px #08ad6c",
			},
			'@keyframes blink-animation': {
				'to': {
					visibility: 'hidden'
				}
			},
			'@-webkit-keyframes blink-animation': {
				'to': {
					visibility: 'hidden'
				}
			}
		}

	)
)



export function StatusLight({ status, start, setStart }: { status: Status, start: boolean, setStart: (b: boolean) => void }) {
	const classes = useStyle()
	if (status.processing == null)
		return null
	if (status.processing > 0) {
		setTimeout(() => {
			setStart(false)
		}, status.processing * 6000)
	}
	// console.log('THESE ARE TJE LIGHTS', status.processing)

	return <div className={classes.container}>
		{(status.processing != null && (start)) ?
			<Tooltip title='Aibert Working'>
				<div className={`${classes.circle} ${classes.circle_yellow} `} ></div>
			</Tooltip> :
			<Tooltip title='Aibert Done'>
				<div className={`${classes.circle} ${classes.circle_green}`} ></div>
			</Tooltip >
		}

	</div >
}