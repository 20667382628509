import * as React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { Container, Page } from 'elements/Container'
import { DrawerContext } from 'drawer/AppWithDrawer'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: "absolute",
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			outline: 0,
			top: "50%",
			left: "55%",
			transform: 'translate(-50%, -50%)',
			display: 'flex'
		},
		card: {
			backgroundColor: "#fff",
			boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
			borderRadius: 4,
			paddingTop: 10,
			paddingBottom: 10,
			marginBottom: 10,
			border: "none",
		}
	})
)

export function Dashboard() {
	const classes = useStyles()
	const drawerContext = React.useContext(DrawerContext)

	React.useEffect(() => {
		drawerContext.setRenderContent(() => null)
	}, [])

	return <Container page={Page.ANALYTICS} title="Analytics">
		<div className={`${classes.container} ${classes.card}`}>
			Coming soon!
	</div>
	</Container>
}