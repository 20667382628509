import { CircularProgress, createStyles, fade, IconButton, makeStyles, TableCell, Theme, withStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import ReplayIcon from '@material-ui/icons/Replay';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { JobRole, Member } from 'api/api-types';
import { getDepartments, getMembers, removeMember, resendVerifiyEmail } from 'api/ApiServices';
import { handleApiResponse, useApi } from 'api/useApi';
import * as _ from 'lodash';
import * as React from 'react';
import { AuthContext } from 'utils/Auth';
import { MemberModal } from './MemberModal';
import 'styles/colors.css'
import { HourglassEmpty } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import { defaultCellStyle, defaultHeaderStyle } from 'elements/ItemTable/ItemTableColumns';
import { parseValue, RowValue } from 'elements/ItemTable/ItemTableRows';
import { useItemTableStyles } from 'elements/ItemTable/useItemTableStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ActionName, ItemActionPopover } from 'feed/ItemActionPopover';



const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			minHeight: 835,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		},
		flexRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 20,
		},
		topFlexRow: {
			// borderBottom: '1px solid #ededed',
			paddingBottom: 20
		},
		bottomFlexRow: {
			paddingTop: 5
		},
		//change to succesButton
		addMemberButton: {
			marginLeft: 'auto',
			marginRight: 15,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.1),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.01),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			border: '1px solid #e1e3e1'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		table: {

		},
		userIcon: {
			width: 22,
			height: 22,
			color: 'rgba(0, 0, 0, 0.54)'
		},
		manageIcons: {
			height: '1em',
			fontSize: '1.2rem',
			cursor: 'pointer',
			color: 'rgba(0, 0, 0, 0.54)'
		},
		removeIcon: {
			// marginLeft: 15
		},
		userIconCell: {
			padding: 0,
			color: 'rgba(0, 0, 0, 0.54)'
		},
		usernameCell: {
			paddingLeft: 0
		}
	}))

export function jobRoleLabel(role: JobRole): "Administration" | "Procurement" | "Approvals" | "Analytics" {
	switch (role) {
		case JobRole.ADMIN: return "Administration"
		case JobRole.PROCUREMENT_ADMIN: return "Procurement"
		case JobRole.APPROVER: return "Approvals"
		case JobRole.ANALYST: return "Analytics"
		default: throw Error("Invalid Job Role")
	}
}

export function Members() {
	const classes = useStyles()
	const classesTable = useItemTableStyles()
	const [data, refetch] = useApi({
		apiRequest: getMembers,
		pollingInterval: 3000
	})
	const [departmentsData, refetchDepartments] = useApi({
		apiRequest: getDepartments,
	})

	const [resendLinkStep, setResendLinkStep] = React.useState<"resend" | "error" | "success" | "pending">("resend")

	const authContext = React.useContext(AuthContext)
	const { roles, iss } = authContext.getUser()!

	const memberRef = React.useRef<MemberModal>(null)
	const popoverRef = React.useRef<ItemActionPopover>(null)


	const { t } = useTranslation();

	async function resendUserVerification(username: string) {
		setResendLinkStep("pending")
		const response = await resendVerifiyEmail(username)
		handleApiResponse(
			response,
			(error) => {
				setResendLinkStep("error")
				setTimeout(() => {
					setResendLinkStep('resend')
				}, 2000)
			},
			(data) => {
				if (data === 'success') {
					setResendLinkStep("success")
					setTimeout(() => {
						setResendLinkStep('resend')
					}, 2000)
				}
			}
		)
	}







	type Column = '' | 'Name' | 'Email' | 'Identity Number' |
		'Job Title' | 'Company' | 'Service Role' | 'Departments' |
		'Department Manager' | 'Master Approver' | 'Verified'
	type Row = string[] | string | number

	function constructColumn(
		// section: Section,
		column: Column,
		options: MUIDataTableColumnOptions
	): MUIDataTableColumn {
		return {
			name: column,
			label: column,
			// options: { ...columnOptions(section, column, options) },
			options: { ...options }
		}
	}

	function renderColumns() {
		const columns: MUIDataTableColumn[] = [
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',
						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',

						}
					})
				}
			),
			constructColumn(
				'Name',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Name')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Email',
				{

					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Identity Number',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Modal.Id_Number')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Job Title',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Modal.Job_Title')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Company',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Company')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
					})
				}
			),
			constructColumn(
				'Service Role',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Service_Role')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Departments',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Departments')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Department Manager',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Dpt_Manager')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("center")
					}),
					setCellProps: () => ({
						...defaultCellStyle("center")
					})
				}
			),
			constructColumn(
				'Master Approver',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Master_Approver')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("center")
					}),
					setCellProps: () => ({
						...defaultCellStyle("center")
					})
				}
			),
			constructColumn(
				'Verified',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Verified')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("center")
					}),
					setCellProps: () => ({
						...defaultCellStyle("center")
					})
				}
			),
			// constructColumn(
			// 	'Verified',
			// 	{
			// 		setCellHeaderProps: () => ({
			// 			...defaultHeaderStyle("left")
			// 		}),
			// 		setCellProps: () => ({
			// 			...defaultCellStyle("left")
			// 		})
			// 	}
			// ),
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("right")
					}),
					setCellProps: () => ({
						...defaultCellStyle("right")
					})
				}
			)


		]
		return columns
	}

	function renderSuppliersRow(
		members: Member[],
		actionButton: (member: Member, index: number) => JSX.Element
	) {
		return members.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// Check with Jorge how to propperly use reduce and why is first showing []
			if (i === 0)
				p.shift()
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					['', <AccountCircleIcon className={classes.userIcon} />],
					['Name', parseValue(`${c.lastName} ${c.firstName}`)],

					['Email', parseValue(c.username)],
					['Identity Number', c.serialNumber ? parseValue(c.serialNumber) : ''],
					['Job Title', parseValue(c.title)],
					['Company', parseValue(c.company)],
					['Service Role', parseValue(c.jobRoles.map((e, i) => c.jobRoles.length === i + 1 ? `${e}` : `${e}, `))],
					['Departments', c.departmentName ? parseValue(c.departmentName) : ''],
					['Department Manager', c.departmentManager ? <CheckIcon className='bgGreen' /> : <RemoveIcon />],
					['Master Approver', c.masterApprover ? <CheckIcon className='bgGreen' /> : <RemoveIcon />],
					['Verified', renderUserVerified(c.verified)],
					// ['', <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '42px', }}>
					// 	{c.verified && departmentsData !== 'loading' && <EditIcon className={classes.manageIcons} onClick={() => {
					// 		memberRef.current?.show({ member: c, refetch: refetch, departments: departmentsData!, roles: roles })
					// 	}} />}
					// 	{renderVerificationIcon(c.verified, resendLinkStep, c.username)}
					// 	{iss !== c.username && <DeleteIcon
					// 		className={`${classes.manageIcons} ${classes.removeIcon}`}
					// 		onClick={() => {
					// 			removeMember(c.username)
					// 		}}
					// 	/>}
					// </div>],
					['', actionButton(c, i)]



				]
			// const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			// const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]
			const tmp = row.map(e => e[1])
			p.push(tmp)
			return p

		}, [[]])

	}

	function renderVerificationIcon(
		verified: boolean,
		step: "resend" | "error" | "success" | "pending",
		username: string
	) {
		if (!verified) {
			if (step === 'resend') {
				return <ReplayIcon className={classes.manageIcons} onClick={() => {
					resendUserVerification(username)
				}} />
			} else if (step === 'pending') {
				return <CircularProgress size={13} thickness={5} />
			} else if (step === "error") {
				return <ErrorIcon className={classes.manageIcons} />
			} else if (step === "success") {
				return <CheckIcon className={classes.manageIcons} />
			} else {
				throw Error()
			}
		} else {
			return null
		}
	}


	function renderUserVerified(verified: boolean) {
		if (verified === true) {
			return <ThumbUpAltIcon className='bgGreen' />
		} else {
			return <HourglassEmpty className='bgOrange' />
		}
	}

	if (data === 'loading' || departmentsData === 'loading') {
		return <CircularProgress />
	} else if (data) {
		return (
			<>
				<div className={classesTable.tableWrapper}>
					<MUIDataTable
						title='Employees'
						data={renderSuppliersRow(
							data!,
							(member, index) => {
								return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', zIndex: 999, marginRight: '16px' }} onClick={e => {
									e.stopPropagation()
									popoverRef.current?.show({
										anchorElement: e.currentTarget,
										actions: [
											{
												actionName: ActionName.EDIT,
												action: () => {
													memberRef.current?.show({ member: member, refetch: refetch, departments: departmentsData!, roles: roles })
												}
											},
											{
												actionName: ActionName.REMOVE,
												action: () => {
													removeMember(member.username)
												}
											}
										]
									})
									// setSelectedItem(item.itemId, index)
								}} />
							}
						)}
						columns={renderColumns()}
						options={{
							elevation: 0,
							selectableRows: 'none',
							customToolbar: () => {
								return <div className={classesTable.toolbarButtonsContainer}>
									{/* <div style={{ display: 'flex' }}> */}
									<IconButton onClick={() => {
										if (departmentsData != null)

											memberRef.current?.show({ departments: departmentsData!, roles: roles })

									}}>
										<AddCircleIcon className={`${classesTable.addIcon}`} />
									</IconButton>
									{/* </div> */}
								</div>
							}
						}}

					/>
					{/* <div className={`${classes.flexRow} ${classes.topFlexRow}`}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
						<Button variant='contained' color='primary' className={`${classes.addMemberButton}`} onClick={() => {
							if (departmentsData != null && departmentsData !== 'loading')
								
								memberRef.current?.show({ departments: departmentsData!, roles: roles })
						}}>{t('Button.Add_Member')}<AddIcon /></Button>
					</div>
					<div className={`${classes.flexRow} ${classes.bottomFlexRow}`}>
						<TableContainer>
							<Table className={classes.table} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left"></StyledTableCell>
										<StyledTableCell align="left" className={classes.usernameCell}>{t('Login.Email')}</StyledTableCell>
										<StyledTableCell align="left">{t('Modal.Id_Number')}</StyledTableCell>
										<StyledTableCell align="left">{t('Login.Name')}</StyledTableCell>
										<StyledTableCell align="left">{t('Login.Surname')}</StyledTableCell>
										<StyledTableCell align="left">{t('Modal.Job_Title')}</StyledTableCell>
										<StyledTableCell align="left">{t('Hr.Company')}</StyledTableCell>
										<StyledTableCell align="center">{t('Hr.Service_Role')}</StyledTableCell>
										<StyledTableCell align="center">{t('Hr.Departments')}</StyledTableCell>
										<StyledTableCell align="center">{t('Hr.Dpt_Manager')}</StyledTableCell>
										<StyledTableCell align="center">{t('Hr.Master_Approver')}</StyledTableCell>
										<StyledTableCell align="center">{t('Hr.Verified')}</StyledTableCell>
										<StyledTableCell align="center"></StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{renderMembers(data)}
								</TableBody>
							</Table>
						</TableContainer>
					</div> */}
				</div>
				<MemberModal ref={memberRef} />
				<ItemActionPopover ref={popoverRef} />
			</>
		)
	} else {
		return null
	}
}
