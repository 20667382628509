import { InputAdornment, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { sendBackItem } from 'api/ApiServices'
import { InputCommon } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX, ModalXHeader } from 'elements/ModalX'
import * as React from 'react'
import { ModalBody } from 'reactstrap'
import { Props } from './ItemModalCommon'

const useStyles = makeStyles({
	form: {
		width: '100%',
		height: '100%',
		padding: 30
	},
	input: {
		marginTop: 10,
		marginBottom: 10,
		fontSize: 10,
		width: '100%'
	},
	messageBox: {
		marginTop: 10,
		fontSize: 12
	},
	inputAdornment: {
		marginLeft: 2.5,
		marginRight: 2.5
	},
	innerElement: {
		marginLeft: 10,
		borderRadius: 15,
		padding: 2,
		paddingLeft: 10,
		paddingRight: 10,
		backgroundColor: '#ededed',
		fontWeight: 400,
		fontSize: 10,
		opacity: 0.9,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#f5b5b5'
		}
	}
})

type Params = {
	itemId: string
	sendTo: string
	// refetchInvoices: () => void
	// refetchInvoiceCounters: () => void

}

export type SendBackModal = {
	show: (params: Params) => void
}

export const SendBackModal = React.forwardRef((props: Props, ref: React.Ref<SendBackModal>) => {
	const classes = useStyles()

	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | null>(null)
	const [toElements, setToElements] = React.useState<React.ReactNode[]>([])
	const [ccElements, setCCElements] = React.useState<React.ReactNode[]>([])
	const [bccElements, setBCCElemts] = React.useState<React.ReactNode[]>([])
	const toRef = React.useRef<(string | null)[]>([])
	const ccRef = React.useRef<(string | null)[]>([])
	const bccRef = React.useRef<(string | null)[]>([])
	const [to, setTo] = React.useState("")
	const [cc, setCC] = React.useState("")
	const [subject, setSubject] = React.useState("")
	const [bcc, setBcc] = React.useState("")
	const [body, setBody] = React.useState("")

	//  This need fixing
	async function onSubmit() {


		// let toTmp: string = toRef.current.filter(it => it !== null).toString().match('\w+\s+<(\w+@\w+\.com)>')![0] || ''
		// console.log('This is after Regex', toTmp)
		// let toTmp = toRef.current.filter(it => it !== null) as string[]
		// let tmp2 = toTmp.toString().split(' ')[2] as string
		// console.log('Look HERERERE', tmp2, '   ', tmp2.slice(1, tmp2.length - 1))
		const p = {
			to: toRef.current.filter(it => it !== null) as string[],
			cc: ccRef.current.filter(it => it !== null) as string[],
			bcc: bccRef.current.filter(it => it !== null) as string[],
			subject: subject,
			body: body,
			id: params!.itemId
		}


		const res = await sendBackItem(p)
		if (res._type === 'generic_success') {
			handleClose()

		} else {
			console.log('This resp', res)
		}
	}

	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			console.log('Theese are parmas for Send Back', params)
			setParams(params)
			setIsOpen(true)
			if (params.sendTo) {
				setToElements([createInnerElement(params.sendTo, 0, setToElements, toRef)])
				toRef.current.push(params.sendTo)
			}
		}
	}))

	function handleClose() {
		setIsOpen(false)
		setParams(null)
		setTo("")
		setToElements([])
		setCCElements([])
		setBCCElemts([])
		setCC("")
		setSubject("")
		setBody("")
		toRef.current! = []
		ccRef.current! = []
		bccRef.current! = []
		props.refetchCounters()
		props.refetchInvoices()

	}

	const createInnerElement = (
		value: string,
		currentIndex: number,
		setElements: React.Dispatch<React.SetStateAction<React.ReactNode[]>>,
		ref: React.MutableRefObject<(string | null)[]>
	) => {
		return <span
			className={classes.innerElement}
			key={`${currentIndex}-${value}`}
			onClick={() => {
				setElements(current => {
					ref.current! = [...ref.current.slice(0, currentIndex), null, ...ref.current.slice(currentIndex + 1)]
					return [...current.slice(0, currentIndex), null, ...current.slice(currentIndex + 1)]
				})
			}}
		>
			{value}
		</span>
	}

	const renderInnerElement = (
		value: string,
		setValue: React.Dispatch<React.SetStateAction<string>>,
		setElements: React.Dispatch<React.SetStateAction<React.ReactNode[]>>,
		ref: React.MutableRefObject<(string | null)[]>
	) => {
		if (!value) return
		ref.current!.push(value)
		setElements(current => {
			const currentIndex = current.length
			return [...current, createInnerElement(value, currentIndex, setElements, ref)]
		})
		setValue("")
	}

	if (!params) {
		return null
	} else {
		return <ModalX isOpen={isOpen} onClose={handleClose}>
			<ModalXHeader>Send Back</ModalXHeader>
			<ModalBody>
				<Form onSubmit={onSubmit} className={classes.form}>
					<div style={{ width: '100%', position: 'relative' }}>
						<TextField
							InputLabelProps={InputCommon.inputLabelProp()}
							InputProps={InputCommon.inputProps({
								startAdornment: <InputAdornment position="start">{toElements}</InputAdornment>
							})}
							className={classes.input}
							label="TO"
							variant="outlined"
							value={to}
							onChange={e => {
								const value = e.currentTarget.value
								if (value !== " ") {
									setTo(value)
								}
							}}
							onBlur={() => renderInnerElement(to, setTo, setToElements, toRef)}
							onKeyDown={e => {
								if (e.key === " " && to) {
									renderInnerElement(to, setTo, setToElements, toRef)
								}
							}} />
					</div>
					<div style={{ width: '100%', position: 'relative' }}>
						<TextField
							InputLabelProps={InputCommon.inputLabelProp()}
							InputProps={InputCommon.inputProps({
								startAdornment: <InputAdornment position="start">{ccElements}</InputAdornment>
							})}
							className={classes.input}
							label="CC"
							variant="outlined"
							value={cc}
							onChange={e => {
								const value = e.currentTarget.value
								if (value !== " ") {
									setCC(value)
								}
							}}
							onBlur={() => renderInnerElement(cc, setCC, setCCElements, ccRef)}
							onKeyDown={e => {
								if (e.key === " " && cc) {
									renderInnerElement(cc, setCC, setCCElements, ccRef)
								}
							}} />
					</div>
					<div style={{ width: '100%', position: 'relative' }}>
						<TextField
							InputLabelProps={InputCommon.inputLabelProp()}
							InputProps={InputCommon.inputProps({
								startAdornment: <InputAdornment position="start">{bccElements}</InputAdornment>
							})}
							className={classes.input}
							label="BCC"
							variant="outlined"
							value={bcc}
							onChange={e => {
								const value = e.currentTarget.value
								if (value) {
									setBcc(value)
								}
							}}
							onBlur={() => renderInnerElement(bcc, setBcc, setBCCElemts, bccRef)}
							onKeyDown={e => {
								if (e.key === " " && bcc) {
									renderInnerElement(bcc, setBcc, setBCCElemts, bccRef)
								}
							}} />
					</div>
					<TextField
						InputLabelProps={InputCommon.inputLabelProp()}
						InputProps={InputCommon.inputProps()}
						className={classes.input}
						label="Subject"
						variant="outlined"
						value={subject}
						onChange={e => setSubject(e.currentTarget.value)}
					/>
					<TextField
						InputLabelProps={InputCommon.inputLabelProp()}
						InputProps={InputCommon.inputProps()}
						className={classes.input}
						label="Attachment"
						variant="outlined"
						disabled
					/>
					<textarea
						style={{ maxHeight: 250, height: 250 }}
						className={`${classes.input} ${classes.messageBox}`}
						onChange={e => setBody(e.currentTarget.value)}
						value={body}
					/>
					<SubmitButton>Send</SubmitButton>
				</Form>
			</ModalBody>
		</ModalX>
	}
})