import { DateTimeFormatter, ZonedDateTime } from '@js-joda/core'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { AggregationParams, AggregationValues } from 'api/api-types'
import { getAggregationData } from 'api/ApiServices'
import { ExpandableSection } from 'elements/ExpandableSection'
import { HEADER_HEIGHT } from 'main/Header'
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables'
import * as React from 'react'
import { Waypoint } from 'react-waypoint'
import { AnalyticsTableTitle } from './AnalyticsTableTitle'

type Props = {
	aggregationParams: AggregationParams | null
	renderHeader: () => JSX.Element
}


export function AnalyticsTable(props: Props) {
	const [data, setData] = React.useState<AggregationValues | null>(null)
	const [offset, setOffset] = React.useState(0)

	const labelMap = React.useMemo(() => {
		if (data) {
			return new Map(data!.value.map.map(e => [e.index, e.label]))
		} else {
			return new Map()
		}
	}, [data?.value.map])

	const hasMore = (currentOffset: number, chunksAvailable: number) => currentOffset <= chunksAvailable

	React.useEffect(() => {
		async function init() {
			const { aggregationParams } = props
			// First time it will be 0
			if (aggregationParams && aggregationParams.chunk_offset === 0) {
				setOffset(aggregationParams.chunk_offset)
				const data = await getAggregationData(aggregationParams)
				if (data._type === "data") {
					setData(data.data)
				}
			}
		}
		init()
	}, [props.aggregationParams])

	if (!data) {
		return null
	}

	const paginate = async (
		nextOffset: number,
		aggregationParams: AggregationParams,
		setData: React.Dispatch<React.SetStateAction<AggregationValues | null>>
	) => {
		const result = await getAggregationData({
			...aggregationParams,
			chunk_offset: nextOffset
		})
		if (result._type === "data") {
			setData(curr => {
				const previous = curr!
				return {
					requestId: result.data.requestId,
					value: {
						header: previous.value.header,
						map: [...previous.value.map, ...result.data.value.map],
						total_chunks_available: previous.value.total_chunks_available,
						values: [...previous.value.values, ...result.data.value.values] as unknown as [(number | null)[]]
					}
				}
			})
		}
	}

	const mapColumns: () => MUIDataTableColumn[] = () => {
		return data!.value.header.map((e, i) => {
			if (i === 0) {
				return {
					name: "",
					label: "",
					options: {
						customBodyRender: (value, meta) => {
							const { rowIndex } = meta
							if (data?.value.values.length - 10 === rowIndex) {
								return <>
									<Waypoint
										onEnter={() => {
											if (hasMore(offset, data ? data.value.total_chunks_available : 1)) {
												if (props.aggregationParams) {
													paginate(
														offset + 1,
														props.aggregationParams,
														setData,
													)
													setOffset(offset + 1)
												}
											}
										}}
									/>
									{value}
								</>
							} else {
								return value
							}
						}
					} as MUIDataTableColumnOptions
				}
			} else {
				const name = e ? DateTimeFormatter.ISO_LOCAL_DATE.format(ZonedDateTime.parse(e)) : ""
				return {
					name: name,
					label: name,
					options: {
						sortThirdClickReset: true
					}
				}
			}
		})
	}

	const mapRows = () => {
		return data!.value.values.map(e => {
			const label = labelMap.get(e[0]!)
			return [label, ...e.slice(1).map(e => e?.toFixed(3) || "-")]
		})
	}
	
	return <MUIDataTable
		title={<AnalyticsTableTitle title='test' />}
		data={mapRows()}
		columns={mapColumns()}
		options={{
			elevation: 2,
			selectableRows: 'none',
			pagination: false,
			fixedHeader: true,
			// Change AnalyticsTableTitle to use fixed height, because it can cause problems if actual AnalyticsTableTitle changes size!
			// -164 = AnalyticsTableTitle Height, 64px padding, 64px Header?
			tableBodyMaxHeight: 'calc(100vh - 164px - 64px - 64px)'
		}}
	/>
}