import * as React from 'react'
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	PDFViewer,
	Image,
	PDFDownloadLink,
} from "@react-pdf/renderer";
import { InvoicerDoc } from 'api/api-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Style } from '@material-ui/icons';
import moment from 'moment';
// Create styles
const styles = StyleSheet.create({

	page: {
		backgroundColor: "white",
		color: "black",
		height: '100%'

	},
	section: {
		border: '2px',
		margin: 10,
		padding: 10,
	},
	viewer: {
		width: window.innerWidth, //the pdf viewer will take up all of the width and height
		height: window.innerHeight,
	},
	row: {
		display: 'flex'
	},
	column50: {
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		color: 'rgba(34, 42, 69, 0.96)',
		fontSize: '1rem',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
		fontWeight: 500,
		lineHeight: 1.6,
		letterSpacing: '0.0075em',
	},
	textRow: {
		maxWidth: '200px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	}
});

type Props = {
	doc: InvoicerDoc | null
}
export const PdfDocTemplate = (props: Props) => {
	const convertBase64 = (file: string) => {
		return new Promise((resolve, reject) => {
			// const url = URL.
			const fileReader = new FileReader();

			fileReader.onload = () => {
				if (fileReader.result) {
					// fileReader.
					// 	console.log('This file will be set to', fileReader.result.toString())
					// setValue(fileReader.result.toString())
				}
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}
	console.log("These are props of docTemplate", props)
	if (props.doc == null) {
		return <CircularProgress />
	} else {
		return (
			<Document >
				{/* <PDFDownloadLink> */}
				<Page size="A4" style={styles.page}>
					{/* <View style={styles.section}>
					<View style={styles.section}>
						<Text>{props.doc.docType}</Text>
						<Text>{props.doc.companyLogo}</Text>
					</View>
				</View>
				<View style={styles.section}>
					<Text>Section #2</Text>
				</View> */}
					{/* 3 major rows */}
					{/* <View style={styles.row}>

					2 columns 50%
					<View style={styles.column50}>
						3 colum items
						<View>
							
						</View>
						<View>

						</View>
						<View>

						</View>

					</View>
					<View style={styles.column50}>
						2 column items
					</View>
					<View>

					</View>
					<View>

					</View>
				</View>

				<View>

				</View>
				<View>

				</View> */}
					<View>
						{/* <View style={styles.textRow}><Text>test</Text> <Text>{props.doc.companyLogo}</Text></View> */}
						{/* <Text style={styles.title}>Company Info</Text> */}

						<Image src={`${props.doc.companyLogo}`}></Image>
						<View style={styles.textRow}>
							<Text>Company Name</Text>
							<Text>{props.doc.companyName}</Text>
						</View>

						{/* <Text>{props.doc.companyName}</Text> */}
						<Text>Company Address: {props.doc.companyAddress}</Text>
						<Text>Company Registar: {props.doc.companyRegistar}</Text>
						<Text>Company Web: {props.doc.companyWeb}</Text>
						{/* <Text>{props.doc.companyName}</Text> */}
						<Text>Company Contact Name: {props.doc.companyContactName}</Text>
						<Text> company Phone: {props.doc.companyPhone}</Text>
						<Text>Company Email: {props.doc.companyEmail}</Text>
						<Text>Company INO:{props.doc.companyINO}</Text>
						<Text>Company TINO: {props.doc.companyTINO}</Text>
						<Text>Company INOVAT: {props.doc.companyINOVAT}</Text>

						{/*Company Billing info  */}
						{/* <Text style={styles.title}>Company Billing Info</Text> */}
						<Text>Company Bank Acc: {props.doc.companyBankAcc}</Text>
						<Text>Company Bank Code: {props.doc.companyBankCode}</Text>
						<Text>Company Bank SWIFT: {props.doc.companyBankSWIFT}</Text>
						<Text>Company IBAN: {props.doc.companyIBAN}</Text>

						{/* Client */}
						{/* <Text style={styles.title}>Client</Text> */}
						<Text>Client Name: {props.doc.clientName}</Text>
						<Text>Client Address: {props.doc.clientAddress}</Text>
						<Text>Client Contact Name: {props.doc.clientContactName}</Text>
						<Text>Client INO: {props.doc.clientINO}</Text>
						<Text>Client TINO: {props.doc.clientTINO}</Text>
						<Text>Client INOVAT: {props.doc.clientINOVAT}</Text>
						<Text>Client Number: {props.doc.clientNumber}</Text>
						{/* Client email missing, should go instead of deliveryAddress */}



						{/* Invoice */}
						{/* <Text style={styles.title}>Invoice</Text> */}
						<Text>Order Number: {props.doc.orderNumber}</Text>
						<Text>Contract Number: {props.doc.contractNumber !== -1 ? props.doc.contractNumber : ''}</Text>
						<Text>Specific Symbol: {props.doc.specificSymbol}</Text>
						<Text>Variable Symbol: {props.doc.variableSymbol}</Text>
						<Text>Order Number: {props.doc.orderNumber}</Text>
						<Text>Date Of Issue: {props.doc.dateOfIssue != null ? moment(props.doc.dateOfIssue).format('DD/MM/YYYY') : ' '}</Text>
						<Text>Due Date: {props.doc.dueDate != null ? moment(props.doc.dueDate).format('DD/MM/YYYY') : ' '}</Text>
						<Text>Delivery Date: {props.doc.deliveryDate != null ? moment(props.doc.deliveryDate).format('DD/MM/YYYY') : ' '}</Text>
						{/* <Text>Invoiced Period: {props.doc.invoicedPeriod}</Text> */}

						{/* Delivery Info */}
						{/* <Text style={styles.title}>Delivery Info</Text> */}
						<Text>Supplied Goods: {props.doc.suppliedGoods}</Text>
						<Text>Supplied Services: {props.doc.suppliedServices}</Text>
						<Text>Units: {props.doc.units !== -1 ? props.doc.units : ''}</Text>


						{/* Invoiced amount */}
						{/* <Text style={styles.title}>Invoiced Amount</Text> */}
						<Text>Amount Excl VAT: {props.doc.amountExclVAT !== -1 ? props.doc.amountExclVAT : ''}</Text>
						<Text>VAT: {props.doc.VAT !== -1 ? props.doc.VAT : ''}</Text>
						<Text>VAT percentage: {props.doc.VATPercentage !== -1 ? props.doc.VATPercentage : ''}</Text>
						<Text>Amount Incl VAT: {props.doc.amountInclVAT !== -1 ? props.doc.amountInclVAT : ''}</Text>
						<Text>Text Field: {props.doc.textField}</Text>










					</View>
				</Page>
			</Document >
		)
	}

}