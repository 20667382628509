import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import DomainIcon from '@material-ui/icons/Domain';
import KeyboardIcon from '@material-ui/icons/Keyboard';



export function InventoryDrawer(props: RouteComponentProps) {
	const drawerClasses = useDrawerStyle()
	return (
		<>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/inventory/central-warehouse")
			}}>
				<ListItemIcon className={drawerClasses.itemIcon}><DomainIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/inventory/central-warehouse' && drawerClasses.active}`} primary="Central Warehouse" />
			</ListItem>

			{/* <ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/inventory/create-warehouse")
			}}>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/inventory/create-warehouse' && drawerClasses.active}`} primary="Create Warehouse" />
			</ListItem> */}

			<ListItem button disabled={true} className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/inventory/manual-load")
			}}>
				<ListItemIcon className={drawerClasses.itemIcon}><KeyboardIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/inventory/manual-load' && drawerClasses.active}`} primary="Manual Load" />
			</ListItem>


		</>
	)
}