import { Aggregation, Counters, Organisation, SettingsBody, TagsEnum, TagType, DepartmentType, Supplier, UiViews, VerifiedSuppliers, approvalGroupPostType, ApprovalGroupType, CompanyContract, UpdateMemberType, DepartmentPostType, BillingDetails, PaymentDetails, StripePaymentDetails, ServEnum, stripeInvoice, InvoiceHistory, Status, StripeAddress, DocTypesEnum, CompanyInfo, InvoicerDoc, Client, DemoRecord } from 'api/api-types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { CustomError } from 'main/AppWithErrors'
//To be deleted
import { CustomFilter } from 'main/AppWithFiltersOld'
import { Auth } from 'utils/Auth'
import { AggregationParams, AggregationTreeReturnType, AggregationValues, ApiResponse, Item, LabelGroupsType, Member, ResponseType, ValuesType } from "./api-types"

if (!process.env.REACT_APP_LISTENER_URL) {
	throw Error("Please provide REACT_APP_LISTENER_URL inside .env")
}

if (!process.env.REACT_APP_DATA_LAKE_URL) {
	throw Error("Please provide REACT_APP_DATA_LAKE_URL inside .env")
}

const API_HOST = process.env.REACT_APP_LISTENER_URL
const Api = axios.create()

Api.defaults.baseURL = API_HOST

const DATA_LAKE_API_HOST = process.env.REACT_APP_DATA_LAKE_URL
const DataLakeApi = axios.create()

DataLakeApi.defaults.baseURL = DATA_LAKE_API_HOST

const s3Api = axios.create()

type Request<T> = Promise<AxiosResponse<ApiResponse<T>>>

export async function withParsedResponse<T>(request: Request<T>): Promise<ResponseType<T>> {
	try {
		const response = await request
		if (response.data.data) {
			return {
				_type: 'data',
				data: response.data.data
			}
		} else if (response.status === 200) {
			return {
				_type: 'generic_success'
			}
		} else {
			throw new CustomError(`api-${response.config.url}`, `Unknown api response ${response.status}`)
		}
	} catch (err) {
		const error = err as AxiosError<ApiResponse<T>>
		if (error.isAxiosError) {
			if (error.response && error.response.data.error) {
				return {
					_type: 'error',
					error: new CustomError(`api-${error.config.url}`, error.response.data.error)
				}
			} else {
				return {
					_type: 'generic_error',
					error: new CustomError(`api-${error.config.url}`, 'Ooops something went wrong!')
				}
			}
		}
		throw err
	}
}


export async function getItemsCount() {
	return withParsedResponse(Api.get<ApiResponse<Counters>>(`/items/pending-number`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function getSuppliers(page?: number, limit?: number) {
	return withParsedResponse(Api.post<ApiResponse<Supplier[]>>(`suppliers/`, {
		page,
		limit
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function postDepartments(department: DepartmentPostType) {
	return withParsedResponse(Api.post<ApiResponse<void>>(`organisation/departments`, {
		...department
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export async function updateDepartment(department: DepartmentPostType) {
	return withParsedResponse(Api.put<ApiResponse<void>>(`organisation/departments`, {
		...department
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export async function getDepartments() {
	return withParsedResponse(Api.get<ApiResponse<DepartmentType[]>>(`/organisation/departments`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
//If params are undefined it means it should fetch all invoices
export async function getItems(view: UiViews, offset?: number, limit?: number
) {
	return withParsedResponse(Api.post<ApiResponse<Item[]>>(`items`,
		{ view, offset, limit },
		{
			headers: {
				'Authorization': `Bearer ${Auth.getToken()}`
			}
		}))
}

export function getItemDocument(itemId: string) {
	return withParsedResponse(Api.get<ApiResponse<string>>(`items/item/${itemId}`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function postItemDocument(formData: FormData) {
	return withParsedResponse(Api.post<ApiResponse<Item>>(`${process.env.REACT_APP_SERVICE_URL}/invoice`, formData, {
		headers: {
			"mimeType": "multipart/form-data",
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function postItem(itemId: string, labels: LabelGroupsType, values: ValuesType, confidenceLevel?: number) {
	return withParsedResponse(Api.put<ApiResponse<void>>(`items`, {
		itemId: itemId,
		labels: labels,
		values: values,
		confidenceLevel: confidenceLevel
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}
	))
}

export function postBulkItems(invoices: Item[]) {
	return withParsedResponse(Api.put<ApiResponse<void>>(`items/bulk`, {
		invoices
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function login({ username, password }: { username: string, password: string }) {
	return withParsedResponse(Api.post<ApiResponse<string>>(`/login`, {
		username,
		password
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function register({ username, password, organisationName, firstName, lastName }: { username: string, password: string, organisationName: string, firstName: string, lastName: string }) {
	return withParsedResponse(Api.post<ApiResponse<boolean>>(`/registration/organisation`, {
		username,
		password,
		organisationName,
		firstName,
		lastName
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function verifyRegistration(token: string) {
	return withParsedResponse(Api.post<ApiResponse<boolean>>(`/registration/verify`, {
		token
	}))
}

type MemberX = Omit<Member, 'status' | 'verified' | 'departmentOwner'>

export function inviteMember(member: UpdateMemberType) {
	return withParsedResponse(Api.post<ApiResponse<boolean>>(`/registration/user`, {
		...member
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function resendVerifiyEmail(username: string) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`/registration/verify/resend`, {
		username: username
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function registerMember({ password, token }: { password: string, token: string }) {
	return withParsedResponse(Api.post<ApiResponse<boolean>>(`/registration/verify`, {
		password,
		token
	}))
}

export function getMembers() {
	return withParsedResponse(Api.get<ApiResponse<Member[]>>(`/account/all`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getOrganisation() {
	return withParsedResponse(Api.get<ApiResponse<Organisation>>(`/organisation`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

type ManualUploadType = {
	label: TagsEnum
	data: TagType[]
}

export function addTag(tag: ManualUploadType) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`/upload/labels/manual`, {
		...tag
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function updateTag(tag: ManualUploadType) {
	// Check with Jorge and change route
	return withParsedResponse(Api.put<ApiResponse<null>>(`/upload/labels/manual`, {
		...tag
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function removeTag(tag: ManualUploadType) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`/upload/labels/delete/single`, {
		...tag
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getPendingApprovalItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>(`items/pending-approval`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function sumbitApprovalProcess(itemIds: string[]) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`items/pending-approval`, {
		itemIds: itemIds
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function revertItems(itemIds: string[]) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`items/revert`, {
		itemIds: itemIds
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function submitApproved(itemId: string) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`items/approved`, {
		itemId: itemId
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function submitRejected(itemId: string, rejectReason: string) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`items/reject`, {
		itemId: itemId,
		rejectReason: rejectReason
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
//This won't be used
// export function getItemPendingCount() {
// 	return withParsedResponse(Api.get<ApiResponse<number>>(`items/pending-number`, {
// 		headers: {
// 			'Authorization': `Bearer ${Auth.getToken()}`
// 		}
// 	}))
// }

export function getApprovedItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>(`items/approved`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getRejectedItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>(`items/rejected`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getCorrectItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>(`items/correct`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getAttentionItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>(`items/attention`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

// export function getApprovalGroups() {
// 	return withParsedResponse(Api.get<ApiResponse<ApprovalGroupType[]>>('/approvalgroup', {
// 		headers: {
// 			'Authorization': `Bearer ${Auth.getToken()}`
// 		}
// 	}))
// }

// export function addApprovlGroup(approvalGroup: Omit<ApprovalGroup, "_id">) {
// 	return withParsedResponse(Api.post<ApiResponse<null>>('/approvalgroup', {
// 		...approvalGroup
// 	}, {
// 		headers: {
// 			'Authorization': `Bearer ${Auth.getToken()}`
// 		}
// 	}))
// }

// export function removeApprovalGroup(id: string) {
// 	return withParsedResponse(Api.delete<ApiResponse<null>>(`/approvalgroup/${id}`, {
// 		headers: {
// 			'Authorization': `Bearer ${Auth.getToken()}`
// 		}
// 	}))
// }

export function removeMember(email: string) {
	return withParsedResponse(Api.post<ApiResponse<boolean>>(`/account/delete`, {
		username: email
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function updateMember(account: UpdateMemberType) {
	return withParsedResponse(Api.put<ApiResponse<boolean>>(`/account`, {
		...account
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function removeItem(id: string) {
	return withParsedResponse(Api.put<ApiResponse<boolean>>(`items/delete`, {
		id
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

type SendBackParams = {
	id: string
	to: string[]
	cc: string[]
	bcc: string[]
	subject: string
	body: string
}

export function sendBackItem(params: SendBackParams) {
	return withParsedResponse(Api.put<ApiResponse<null>>(`items/send-back`, {
		...params
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function sendBackToAttention(id: string) {
	return withParsedResponse(Api.put<ApiResponse<null>>(`items/back-to-attention`, {
		itemIds: [id]
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function putItemOnHold(id: string) {
	return withParsedResponse(Api.put<ApiResponse<boolean>>(`items/on-hold`, {
		id
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getDuplicateItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>('items/duplicated', {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getItemsOnHold() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>('items/on-hold', {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getReturnedItems() {
	return withParsedResponse(Api.get<ApiResponse<Item[]>>('items/returned', {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

// isRead invoice property...
export function markItemAsRead(invoiceId: string) {
	return withParsedResponse(Api.post<ApiResponse<null>>('items/set-read', {
		invoiceId
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

// Used to update invoice frow other status to PENDING_REVIEW (succesfully extracted)
export function updatePendingReview(itemIds: string[]) {
	return withParsedResponse(Api.put<ApiResponse<null>>(`items/pending-review`, {
		itemIds
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function saveCustomFilter(filters: CustomFilter[]) {
	return withParsedResponse(Api.put<ApiResponse<null>>(`/account/filters`, filters, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getCustomFilters() {
	return withParsedResponse(Api.get<ApiResponse<CustomFilter[]>>(`/account/filters`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getAggregationTreeList() {
	return withParsedResponse(DataLakeApi.get<ApiResponse<Aggregation[]>>(`/get-tree-list`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getAggregationNodes(aggregationId: number) {
	return withParsedResponse(DataLakeApi.post<ApiResponse<AggregationTreeReturnType>>('/get-tree', {
		value_list_id: aggregationId
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getAggregationData(params: AggregationParams) {
	return withParsedResponse(DataLakeApi.post<ApiResponse<AggregationValues>>('/get-tree-values', {
		...params
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
// To be deleted
// export function getSettings(){
// 	return withParsedResponse(Api.get<ApiResponse<SettingsBody>>('/setting',{
// 		headers: {
// 			'Authorization': `Bearer ${Auth.getToken()}`
// 		}
// 	}))

// }

export function putSettings(params: SettingsBody) {
	return withParsedResponse(Api.put<ApiResponse<SettingsBody>>('organisation/settings', {
		...params
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))

}

export function createVerifiedSupplier(companyContract: Omit<VerifiedSuppliers, 'name' | 'dic' | 'city' | 'zipCode'>) {
	return withParsedResponse(Api.post<ApiResponse<null>>('/verified-suppliers', {
		...companyContract,
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function getVerifiedSuppliers(departmentCode?: string) {
	return withParsedResponse(Api.get<ApiResponse<VerifiedSuppliers[]>>(`/verified-suppliers`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function getApprovalGroups() {
	return withParsedResponse(Api.get<ApiResponse<ApprovalGroupType[]>>(`/approvalgroup`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export function createApprovalGroup(approvalGroup: approvalGroupPostType) {
	return withParsedResponse(Api.post<ApiResponse<null>>('/approvalgroup', {
		data: {
			...approvalGroup
			,
		}
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getCompanyContracts() {
	return withParsedResponse(Api.get<ApiResponse<CompanyContract[]>>(`/company-contracts`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getUploadUrl(name: string) {
	return withParsedResponse(Api.get<ApiResponse<string>>(`/upload/invoice/` + name, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export async function uploadFile(url: string, file: File) {
	const formData = new FormData();
	formData.append('file', file)
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	return withParsedResponse(s3Api.put<ApiResponse<string>>(url, formData, config))
}

export async function createCustomer(email: string, name: string, address: StripeAddress, metadata: { companyName: string, companyId: string, companyVAT: string, companyPhone: string }) {
	return withParsedResponse(Api.post<ApiResponse<string>>(`/billing/create-customer`, {
		data: {
			email, name, address, metadata
		}
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function createSubscription(customerId: string, priceId: (string | undefined)[], subscriptionId?: string | null) {

	// TBD: Request containing dummy data!!!

	return withParsedResponse(Api.post<ApiResponse<string>>(`/billing/create-subscription`, {
		data: {
			customerId: customerId,
			priceId: priceId,
			subscriptionId: subscriptionId,
		}
	},
		{
			headers: {
				'Authorization': `Bearer ${Auth.getToken()}`
			}
		}))
}

export async function addPaymentMethod(customerId: string, paymentMethodId: string) {
	return withParsedResponse(Api.post<ApiResponse<string>>(`/billing/payment-method`, {
		data: {
			customerId, paymentMethodId
		}
	},
		{
			headers: {
				'Authorization': `Bearer ${Auth.getToken()}`
			}
		}))
}
export async function updateDefoultPaymentMethot(customerId: string, paymentMethodId: string) {
	return withParsedResponse(Api.put<ApiResponse<string>>(`/billing/payment-method`, {
		data: {
			customerId, paymentMethodId
		}
	},
		{
			headers: {
				'Authorization': `Bearer ${Auth.getToken()}`
			}
		}))
}

export function getBillingDetails() {
	return withParsedResponse(Api.get<ApiResponse<BillingDetails>>(`/billing/billing-details`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}


export function deleteSubscription(subscriptionId: string) {
	return withParsedResponse(Api.delete<ApiResponse<null>>(`/billing/cancel-subscription/${subscriptionId}`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getPaymentDetails() {
	return withParsedResponse(Api.get<ApiResponse<StripePaymentDetails[]>>(`/billing/payment-details`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getAccount() {
	return withParsedResponse(Api.get<ApiResponse<Member>>(`/account`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getInvoicesList() {
	return withParsedResponse(Api.get<ApiResponse<stripeInvoice[]>>(`/billing/invoices`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function submitForBooking(itemIds: string[]) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`items/booking-submision`, {
		itemIds: itemIds
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function downloadAccountingReport(range?: { month: number, year: number }) {
	return withParsedResponse(Api.post<ApiResponse<string>>(`/download/account-report`, {
		range
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getArchive(range?: { month: number, year: number }) {
	return withParsedResponse(Api.post<ApiResponse<Item[]>>(`items/get-archive`, {
		range
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function backToAttention(itemIds: string) {
	return withParsedResponse(Api.put<ApiResponse<void>>(`/items/back-to-attention`, {
		itemIds
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export async function getCurrentItemsCount(uiView: UiViews) {
	return withParsedResponse(Api.get<ApiResponse<number>>(`/items/counter/${uiView}`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export async function getInvoiceHistory() {
	return withParsedResponse(Api.get<ApiResponse<InvoiceHistory[]>>(`/items/history`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export async function getStatus() {
	return withParsedResponse(Api.get<ApiResponse<Status>>(`/items/status`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function setItemIsRead(itemIds: string[]) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`/items/set-read `, {
		itemIds: [...itemIds]
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export function deletePaymentMethod(customerId: string, paymentMethodId: string) {
	return withParsedResponse(Api.post<ApiResponse<null>>(`/billing/payment-method/delete`, {
		data: {
			customerId,
			paymentMethodId
		}
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
// Make response into type
export async function getByIco(ico: string) {
	return withParsedResponse(Api.get<ApiResponse<{
		compIco: string
		dic: string
		companyName: string
		city: string
		zipCode: string
	}>>(`/suppliers/byico/${ico}`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export async function getItem(itemId: string) {
	return withParsedResponse(Api.get<ApiResponse<Item>>(`/item/${itemId}`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getForms(docType: DocTypesEnum, limit?: string, offset?: string) {
	return withParsedResponse(Api.post<ApiResponse<Item[]>>(`/forms/get-many`, {
		docType,
		limit,
		offset
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}


export async function uploadCompanyInvoiceInfo(companyInfo: CompanyInfo) {
	return withParsedResponse(Api.put<ApiResponse<CompanyInfo>>(`organisation/invoice-info`, {
		...companyInfo
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function uploadForms(invoicerDoc: InvoicerDoc) {
	return withParsedResponse(Api.post<ApiResponse<Item[]>>(`/forms`, {
		...invoicerDoc
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getClients(limit?: number, offset?: number) {
	return withParsedResponse(Api.post<ApiResponse<Client[]>>(`/clients`, {

	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function createClient(client: Client) {
	return withParsedResponse(Api.post<ApiResponse<Client[]>>('/client', {
		...client
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getClient(clientNum: string) {
	return withParsedResponse(Api.get<ApiResponse<Client>>(`client/${clientNum}`, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export function updateForm(itemId: string, invoicerDoc: InvoicerDoc) {
	return withParsedResponse(Api.put<ApiResponse<Item[]>>(`/forms/${itemId}`, {
		...invoicerDoc
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}
export function updateClient(client: Client) {
	return withParsedResponse(Api.put<ApiResponse<Client[]>>(`/client/`, {
		...client
	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getCompanyInfo() {
	return withParsedResponse(Api.post<ApiResponse<Item[]>>(`/forms/get-many`, {

	}, {
		headers: {
			'Authorization': `Bearer ${Auth.getToken()}`
		}
	}))
}

export function getDemoUploadUrl(name: string) {
	return withParsedResponse(Api.get<ApiResponse<string>>(`/upload/demo-invoice/` + name))
}

export function downloadDemoResult(name: string) {
	return withParsedResponse(Api.get<ApiResponse<DemoRecord>>(`/download/demo-invoice/` + name))
}
