import * as React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		centered: {
			textAlign: 'center'
		},
		paddingBottom: {
			paddingBottom: '16px'
		},
		paddingLeft: {
			paddingLeft: '27px'
		},
		bold: {
			fontWeight: 700
		},
		link: {
			color: '#1976d2'
		}
	})
)

export function Privacy() {
	const classes = useStyle()
	// const dateOfIssueRef = React.createRef<DateInput>()
	return <>
		<div className={`${classes.centered}`}>
			<Typography variant="h5" className={classes.paddingBottom}>GIDE International Limited Privacy Policy</Typography>
			<Typography variant='h6' className={classes.paddingBottom}>Our Company name is GIDE International Limited (GIDE) and our website addresses are: <a className={classes.link} href="https://mygide.com">https://mygide.com</a>, <a className={classes.link} href="https://mygide.uk">https://mygide.uk</a>, <a className={classes.link} href="https://aibert.io">https://aibert.io</a>, <a className={classes.link} href="https://heyme.uk">https://heyme.uk</a></Typography>
		</div>


		<Typography variant='body2' className={classes.paddingBottom} >This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li><Typography variant='body2'>What data do we collect? </Typography></li>
			<li><Typography variant='body2'>How do we collect your data?</Typography></li>
			<li><Typography variant='body2'>How will we use your data?</Typography></li>
			<li><Typography variant='body2'>Marketing</Typography></li>
			<li><Typography variant='body2'>What are cookies?</Typography></li>
			<li><Typography variant='body2'>How do we use cookies?</Typography></li>
			<li><Typography variant='body2'>What types of cookies do we use?</Typography></li>
			<li><Typography variant='body2'>How to manage your cookies</Typography></li>
			<li><Typography variant='body2'>Changes to our privacy policy</Typography></li>
			<li><Typography variant='body2'>How to contact us</Typography></li>


		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>What data do we collect?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>We collect data and process data when you fill in our web forms:</Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li>Requesting information on any of our products or services</li>
			<li>Contact our customer support email</li>
		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>How do we collect your data?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE collects the following personal data if you deliberately decide to share it with us:</Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li>Name, email address, phone number</li>
			<li>Company name, address, phone number</li>
		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>How will we use your data?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE collects your data so that we can:</Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li>Process your request and manage your customer account.</li>
			<li>Email you with special offers on other products and services we think you might like, if so enabled by you.</li>
			<li>Manage your software licence keys</li>
		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>Marketing</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>If so consented to, GIDE may send you information about products and services of ours that we\
			think you might like. GIDE will never pass your contact information to any third-party.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>If you have agreed to receive marketing information, you may always opt out at a later date.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>You have the right at any time to stop GIDE from contacting you for marketing purposes.</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>If you wish not to be contacted for marketing purposes, please uncheck here.	</Typography>

		<Typography variant='h6' className={classes.paddingBottom}>What are your data protection rights?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE follows EU GDPR regulations. Every user is entitled to the following:</Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			{/* There is bold here to be added */}
			<li><span className={classes.bold}>The right to access</span> - You have the right to request copies of your personal data, if any.</li>
			<li><span className={classes.bold}>The right to rectification </span>- You have the right to request to correct any information you believe is inaccurate. You also have the right to request to complete information you believe is incomplete.</li>
			<li><span className={classes.bold}>The right to erasure</span> - You have the right to request that your personal data, if any received, is erased.</li>
			<li><span className={classes.bold}>The right to restrict processing </span>- You have the right to request restriction of the processing of your personal data, if any received.</li>
		</ul></Typography>
		<Typography variant='body2' className={classes.paddingBottom}>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a className={classes.link} href="mailto:privacy@mygide.uk">privacy@mygide.uk</a></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>What are cookies?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>Cookies are text files placed on your computer by your browsers’, collecting Internet logs and visitor click behaviour information on our site, on a no name basis. When you visit our websites,
			we may collect such information from you automatically through third party’s cookies or similar technology. </Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li>For further information, visit allaboutcookies.org.</li>
		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>How do we use cookies?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE uses cookies in a range of ways to improve your experience on our website, including: </Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li>Understanding how you use our website</li>
			<li>Keeping you signed in, if so relevant</li>
		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>What types of cookies do we use?</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>There are a number of different types of cookies, however, our website uses: </Typography>
		<Typography variant='body2' className={classes.paddingBottom}><ul>
			<li><span className={classes.bold}>Functionality</span> - GIDE uses these cookies so that we recognize your computer on our website and remember your previously selected preferences.
				These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
			<li><span className={classes.bold}>Advertising</span> - GIDE uses these cookies to collect information about your visit to our website, the content you viewed,
				the links you followed and information about your browser, device, and your IP address.</li>
		</ul></Typography>

		<Typography variant='h6' className={classes.paddingBottom}>How to manage cookies</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>You can set your browser not to accept cookies, and the allaboutcookies.org website
			tells you how to remove cookies from your browser. </Typography>


		<Typography variant='h6' className={classes.paddingBottom}>How to manage cookies</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>GIDE keeps its privacy policy under regular review and places any updates on this web page.
			This privacy policy was last updated on 1 December 2019. </Typography>


		<Typography variant='h6' className={classes.paddingBottom}>How to contact us</Typography>
		<Typography variant='body2' className={classes.paddingBottom}>If you have any questions about GIDE's privacy policy, the data we hold on you,
			or you would like to exercise one of your data protection rights, please do not hesitate to contact us at <a className={classes.link} href="mailto:privacy@mygide.uk">privacy@mygide.uk</a>.</Typography>







	</>
}