import { Page } from 'elements/Container'
import * as _ from 'lodash'
import * as React from 'react'

type ErrorAction = {
	text: string
	callback: () => void
}

export class CustomError extends Error {
	public id: string
	public message: string

	constructor(id: string, message: string) {
		super(message)
		this.id = id
		this.message = message
	}
}

interface Err extends CustomError {
	forPage: Page[]
	autoClosable?: boolean
	action?: ErrorAction
}

interface ErrorContext {
	errors: Map<string, Err>
	addError: (error: Err) => void
	dismissError: (error: Err) => void
}

export const ErrorContext = React.createContext<ErrorContext>(undefined as any)

const errors = new Map<string, Err>()

export function AppWithErrors(props: React.PropsWithChildren<{}>) {

	const value: ErrorContext = {
		errors,
		addError: (error) => {
			const id = `${error.id}-${error.forPage}`
			errors.set(id, error)
		},
		dismissError: (error) => {
			errors.delete(error.id)
		}
	}

	return <ErrorContext.Provider value={value}>
		{props.children}
	</ErrorContext.Provider>

}
