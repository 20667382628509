import { DocTypesEnum, InvoicerDoc, InvoicerTypesEnum } from 'api/api-types'
import { DateKeyInput } from 'elements/DateKeyInput'
import { CustomInput } from 'elements/form/CustomInput'
import { ModalX, ModalXBody, ModalXHeader, ModalXSideBar } from 'elements/ModalX'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
// import {  ModalBody } from 'reactstrap'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { PdfDocTemplate } from './PdfDocTemplate'
import { ItemModalCommon } from 'feed/ItemModalCommon'
import { useItemModalStyles } from 'feed/useItemModalStyle'
import { useModalStyles } from 'styles/useModalStyle'
import { SubmitButton } from 'elements/form/SubmitButton'
import { TagsPopover } from 'feed/TagsPopover'
import { ExpandableSection } from 'elements/ExpandableSection'
import { UserContext } from 'main/AppWithUser'
import { getClient, updateForm, uploadForms } from 'api/ApiServices'
import { Form } from 'elements/form/Form'




const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: {
			display: 'flex',
			width: '100%',
			justifyItems: 'center',
		},
		column: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

		},
		columnTwo: {
			paddingRight: '16px',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

		},

	}))

type Params = {
	docType: DocTypesEnum
	invoicedDoc?: InvoicerDoc
	itemId?: string
	refetchItems?: () => void
	isEdit?: boolean
	// refetchInvoiceCounters: () => void

}

export type InvoicerModal = {
	show: (params: Params) => void
}

export const InvoicerModal = React.forwardRef((props: {}, ref: React.Ref<InvoicerModal>) => {
	const classes = useStyles()
	const itemModalclasses = useItemModalStyles()
	const modalClasses = useModalStyles({ maxModalHeight: 930 })
	const [docType, setDocType] = React.useState<DocTypesEnum>()
	const [refetch, setRefetch] = React.useState<any>()
	const [itemId, setItemId] = React.useState<string | null>(null)
	const [invoicerDoc, setInvoicerDoc] = React.useState<InvoicerDoc | null>(null)
	const [isOpen, setIsOpen] = React.useState(false)
	const { t } = useTranslation();

	const organisation = React.useContext(UserContext).organisation


	const popoverRef = React.useRef<TagsPopover>(null)

	const clientNameRef = React.createRef<CustomInput>()
	const clientAddressRef = React.createRef<CustomInput>()
	const deliveryAddressRef = React.createRef<CustomInput>()
	const clientContactNameRef = React.createRef<CustomInput>()
	const clientINORef = React.createRef<CustomInput>()
	const clientTINORef = React.createRef<CustomInput>()
	const clientINOVATRef = React.createRef<CustomInput>()
	const clientNumberRef = React.createRef<CustomInput>()
	const invoiceNumberRef = React.createRef<CustomInput>()
	const orderNumberRef = React.createRef<CustomInput>()
	const contractNumberRef = React.createRef<CustomInput>()
	const specificSymbolRef = React.createRef<CustomInput>()
	const variableSymbolRef = React.createRef<CustomInput>()
	const dateOfIssueRef = React.createRef<CustomInput>()
	const deliveryDateRef = React.createRef<CustomInput>()
	const invoicedPeriodRef = React.createRef<CustomInput>()
	const suppliedGoodsRef = React.createRef<CustomInput>()
	const suppliedServicesRef = React.createRef<CustomInput>()
	const unitsRef = React.createRef<CustomInput>()
	const textFieldRef = React.createRef<CustomInput>()
	const vATPercentageRef = React.createRef<CustomInput>()
	const vATRef = React.createRef<CustomInput>()
	const amountExclVATRef = React.createRef<CustomInput>()
	const amountInclVATRef = React.createRef<CustomInput>()



	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			console.log("Cliecked", params)
			setDocType(params.docType)
			if (params.invoicedDoc) {
				setInvoicerDoc(params.invoicedDoc)
			}
			if (params.itemId) {
				setItemId(params.itemId)
			}
			setRefetch(params.refetchItems)
			setIsOpen(true)

		}
	}))

	React.useEffect(() => {
		if (isOpen === true)
			prepareDataForDocRender()
	}, [organisation])

	React.useEffect(() => {
		if (isOpen === true && !itemId) {
			prepareDataForDocRender()
			console.log('will call')
		}

	}, [isOpen])

	function prepareDataForDocRender(tmpInvoicerDocParam?: InvoicerDoc) {
		console.log('dates before edit', dateOfIssueRef.current?.value)
		console.log('issue', deliveryDateRef.current?.value)
		console.log('due', invoicedPeriodRef.current?.value)
		console.log('invoiced period')
		console.log('will call prepareDataForDocRender')
		const tmpInvoicerDoc: InvoicerDoc = {
			docType: invoicerDoc?.docType || DocTypesEnum.INVOICE,
			companyLogo: invoicerDoc?.companyLogo || organisation.information.companyLogo,
			companyName: invoicerDoc?.companyName || organisation.information.companyName || '',
			companyAddress: invoicerDoc?.companyAddress || organisation.information.companyAddress || '',
			companyRegistar: invoicerDoc?.companyRegistar || organisation.information.companyRegistar || '',
			companyWeb: invoicerDoc?.companyWeb || organisation.information.companyWeb || '',
			companyContactName: invoicerDoc?.companyContactName || organisation.information.companyContactName || '',
			companyPhone: invoicerDoc?.companyPhone || organisation.information.companyPhone || '',
			companyEmail: invoicerDoc?.companyEmail || organisation.information.companyEmail || '',
			companyINO: invoicerDoc?.companyINO || organisation.information.companyINO || '',
			companyTINO: invoicerDoc?.companyTINO || organisation.information.companyTINO || '',
			companyINOVAT: invoicerDoc?.companyINOVAT || organisation.information.companyINOVAT || '',
			companyBankAcc: invoicerDoc?.companyBankCode || organisation.information.companyBankAcc || '', // 
			companyBankCode: invoicerDoc?.companyBankCode || organisation.information.companyBankCode || '', //
			companyBankSWIFT: invoicerDoc?.companyBankSWIFT || organisation.information.companyBankSWIFT || '', // 
			companyIBAN: invoicerDoc?.companyBankSWIFT || organisation.information.companyIBAN || '', //
			clientName: invoicerDoc?.companyBankSWIFT || clientNameRef.current?.value || '',
			clientAddress: invoicerDoc?.clientAddress || clientAddressRef.current?.value || '',
			clientdeliveryAddress: invoicerDoc?.clientdeliveryAddress || deliveryAddressRef.current?.value || '',
			clientContactName: invoicerDoc?.clientContactName || clientContactNameRef.current?.value || '',
			clientINO: invoicerDoc?.clientINO || clientINORef.current?.value || '',
			clientTINO: invoicerDoc?.clientTINO || clientTINORef.current?.value || '',
			clientINOVAT: invoicerDoc?.clientINOVAT || clientINOVATRef.current?.value || '',
			clientNumber: invoicerDoc?.clientNumber || clientNumberRef.current?.value || '',
			invoiceNumber: invoicerDoc?.invoiceNumber || invoiceNumberRef.current?.value || '',
			orderNumber: invoicerDoc?.orderNumber || orderNumberRef.current?.value || '',
			contractNumber: invoicerDoc?.contractNumber || parseInt(contractNumberRef.current?.value || '-1'), //
			specificSymbol: invoicerDoc?.specificSymbol || specificSymbolRef.current?.value || '', //
			variableSymbol: invoicerDoc?.variableSymbol || variableSymbolRef.current?.value || '',
			dateOfIssue: invoicerDoc?.dateOfIssue || dateOfIssueRef.current?.value ? moment(dateOfIssueRef.current?.value, 'DD/MM/YYYY').valueOf() : undefined, //
			deliveryDate: invoicerDoc?.deliveryDate || deliveryDateRef.current?.value ? moment(deliveryDateRef.current?.value, 'DD/MM/YYYY').valueOf() : undefined, //
			invoicedPeriod: invoicerDoc?.invoicedPeriod || invoicedPeriodRef.current?.value ? moment(invoicedPeriodRef.current?.value, 'DD/MM/YYYY').valueOf() : undefined, // 
			suppliedGoods: invoicerDoc?.suppliedGoods || suppliedGoodsRef.current?.value || '',
			suppliedServices: invoicerDoc?.suppliedServices || suppliedServicesRef.current?.value || '',
			units: invoicerDoc?.units || parseInt(unitsRef.current?.value || '-1'),
			amountExclVAT: invoicerDoc?.amountExclVAT || parseInt(amountExclVATRef.current?.value || '-1'),
			VAT: invoicerDoc?.VAT || parseInt(vATRef.current?.value || '-1'),
			VATPercentage: invoicerDoc?.VATPercentage || parseInt(vATPercentageRef.current?.value || '-1'),
			amountInclVAT: invoicerDoc?.amountInclVAT || parseInt(amountInclVATRef.current?.value || '-1'),
			textField: invoicerDoc?.textField || textFieldRef.current?.value || ''
		}
		if (tmpInvoicerDocParam) {
			setInvoicerDoc(tmpInvoicerDocParam)
		} else {
			setInvoicerDoc(tmpInvoicerDoc)
		}
	}
	async function onSubmit() {
		console.log('entered submit')
		// Check for errors here
		if (docType && clientNameRef.current && clientAddressRef.current && deliveryAddressRef.current &&
			clientContactNameRef.current && clientINORef.current && clientTINORef.current && clientINOVATRef.current && clientNumberRef.current && invoiceNumberRef.current && orderNumberRef.current &&
			contractNumberRef.current && specificSymbolRef.current && variableSymbolRef.current && dateOfIssueRef.current && deliveryDateRef.current && invoicedPeriodRef.current && suppliedGoodsRef.current &&
			suppliedServicesRef.current && unitsRef.current && amountExclVATRef.current && vATRef.current && amountInclVATRef.current && vATPercentageRef.current && textFieldRef.current) {
			let invoicerDoc: InvoicerDoc
			invoicerDoc = {
				docType: docType,
				companyLogo: organisation.information.companyLogo,
				companyName: organisation.information.companyName,
				companyAddress: organisation.information.companyAddress,
				companyRegistar: organisation.information.companyRegistar,
				companyWeb: organisation.information.companyWeb,
				companyContactName: organisation.information.companyContactName,
				companyPhone: organisation.information.companyPhone,
				companyEmail: organisation.information.companyEmail,
				companyINO: organisation.information.companyINO,
				companyTINO: organisation.information.companyTINO,
				companyINOVAT: organisation.information.companyINOVAT,
				companyBankAcc: organisation.information.companyBankAcc, // 
				companyBankCode: organisation.information.companyBankCode, //
				companyBankSWIFT: organisation.information.companyBankSWIFT, // 
				companyIBAN: organisation.information.companyIBAN, //
				clientName: clientNameRef.current.value,
				clientAddress: clientAddressRef.current.value,
				clientdeliveryAddress: deliveryAddressRef.current.value,
				clientContactName: clientContactNameRef.current.value,
				clientINO: clientINORef.current.value,
				clientTINO: clientTINORef.current.value,
				clientINOVAT: clientINOVATRef.current.value,
				clientNumber: clientNumberRef.current.value,
				invoiceNumber: invoiceNumberRef.current.value,
				orderNumber: orderNumberRef.current.value,
				contractNumber: parseInt(contractNumberRef.current.value), //
				specificSymbol: specificSymbolRef.current.value, //
				variableSymbol: variableSymbolRef.current.value,
				dateOfIssue: moment(dateOfIssueRef.current.value, 'DD/MM/YYYY').valueOf(), //
				deliveryDate: moment(deliveryDateRef.current.value, 'DD/MM/YYYY').valueOf(), //
				dueDate: moment(invoicedPeriodRef.current.value, 'DD/MM/YYYY').valueOf(), //
				invoicedPeriod: moment(invoicedPeriodRef.current.value, 'DD/MM/YYYY').valueOf(), // 
				suppliedGoods: suppliedGoodsRef.current.value,
				suppliedServices: suppliedServicesRef.current.value,
				units: parseInt(unitsRef.current.value),
				amountExclVAT: parseInt(amountExclVATRef.current.value),
				VAT: parseInt(vATRef.current.value),
				VATPercentage: parseInt(vATPercentageRef.current.value),
				amountInclVAT: parseInt(amountInclVATRef.current.value),
				textField: textFieldRef.current.value
			}
			console.log('tHIS IS ITEM ID', itemId)
			if (itemId) {
				console.log('entered edit')
				console.log('will call edit')
				const response = await updateForm(itemId, invoicerDoc)
				if (response._type === 'generic_success') {
					console.log('updated')
					handleClose()
				} else {
					console.log('failed')
				}
			} else {
				console.log('will call creaye')
				const response = await uploadForms(invoicerDoc)
				if (response._type === 'generic_success') {
					console.log('uploaded')
					handleClose()
				} else {
					console.log('failed')
				}
			}
		}
	}

	const fillByNumber = async () => {
		console.log('called')
		if (clientNumberRef.current?.value) {
			const response = await getClient(clientNumberRef.current.value)
			if (response._type === 'data') {
				console.log('yeeees')
				// companyNameRef.current?.value = response.data.companyName
				if (invoicerDoc) {
					let tmpDoc: InvoicerDoc = {
						...invoicerDoc,
						clientNumber: response.data.clientNumber!,
						clientName: response.data.clientName,
						clientContactName: response.data.clientContactName,
						clientAddress: response.data.clientAddress,
						clientINO: response.data.clientINO,
						clientTINO: response.data.clientTINO,
						clientINOVAT: response.data.clientINOVAT,
						clientdeliveryAddress: response.data.clientdeliveryAddress,
					}
					prepareDataForDocRender(tmpDoc)
				}

			} else {
				console.log('Failed get by Number', response)
			}
		}
	}

	async function handleClose() {
		setInvoicerDoc(null)
		setIsOpen(false)
		setItemId(null)

		if (refetch)
			console.log('should refetch', refetch)

		await refetch?.()

	}

	function renderForm() {
		return <><MuiPickersUtilsProvider utils={MomentUtils}>
			{/* Document name  Select */}
			{/* Upload Logo INput */}

			{/* Company Header	 */}
			{/* <div className={classes.row} > */}
			{/* <div className={classes.row}>
					<div className={classes.columnTwo}>
						<Typography variant='h6' style={{ paddingBottom: '16px' }}>Company Header</Typography>
						<CustomInput
							ref={companyLogoRef}
							label="Company logo"
							errorText=''
							placeholder=''
							required
							type='file'
						/>
						<CustomInput
							ref={companyNameRef}
							label="Company name"
							errorText=''
							placeholder=''
							required
							type='text'
						/>
						<CustomInput
							ref={companyAddressRef}
							label="Company address"
							errorText=''
							placeholder=''
							required
							type='text'
						/>

						<CustomInputif (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, docType: docType || DocTypesEnum.INVOICE, clientName: e.currentTarget.value as string })
									}
							ref={companyRegistrarRef}
							label="Company registrar"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>
					<div className={classes.columnTwo} style={{ paddingTop: '42px' }}>

						<CustomInput
							ref={companyWebRef}
							label="Company web"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
						<CustomInput
							ref={companyContactNameRef}
							label="Company contact name"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
						<CustomInput
							ref={companyPhoneNumberRef}
							label="Company phone number"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
					</div>
				</div> */}
			{/* <div className={classes.row}>
					<div className={classes.columnTwo} style={{ paddingTop: '42px' }}>
						<CustomInput
							ref={companyEmailRef}
							label="Company Email "
							errorText=''
							placeholder=''
							required
							type='email'

						/>

						<CustomInput
							ref={companyInoRef}
							label="Company INO"
							errorText=''
							placeholder=''
							required
							type='text'

						/>
						<CustomInput
							ref={companyTINORef}
							label="Company TINO"
							errorText=''
							placeholder=''
							required
							type='email'

						/>

						<CustomInput
							ref={companyINOVATRef}
							label="Company INO VAT"
							errorText=''
							placeholder=''
							required
							type='email'

						/>
					</div> */}

			{/* <div className={classes.columnTwo} style={{ paddingTop: '42px' }}>
					

						Billing info 
						<CustomInput
							ref={companyBankAccountRef}
							label="Company bank account"
							errorText=''
							placeholder=''
							required
							type='email'

						/>
						<CustomInput
							ref={companyBankCodeRef}
							label="Company’s bank code (BIC)"
							errorText=''
							placeholder=''
							required
							type='email'

						/>
						<CustomInput
							ref={companyIBANRef}
							label="Company IBAN"
							errorText=''
							placeholder=''
							required
							type='email'

						/>
					</div> */}
			{/* </div> */}

			{/* Client */}
			{/* <div className={classes.row}>
			 */}

			<div className={classes.column}>
				<ExpandableSection
					renderHeader={() => <Typography variant="body2">{'Client'}</Typography>}
					className={itemModalclasses.expandableSection}
					startExpanded={true}
				>
					<div className={classes.row}>
						<div className={classes.columnTwo}>
							{/* <Typography variant='h6' style={{ paddingBottom: '16px' }}>Company Header</Typography> */}

							<CustomInput
								ref={clientNumberRef}
								label="Client number"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnBlur={async () => {
									console.log('will Call')
									await fillByNumber()
								}}
								startingValue={invoicerDoc?.clientNumber}
							/>
							<CustomInput
								ref={clientNameRef}
								label="Client name"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnBlur={() => {
									// prepareDataForDocRender()
								}}
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, docType: docType || DocTypesEnum.INVOICE, clientName: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientName}


							/>


							<CustomInput
								ref={clientAddressRef}
								label="Client address"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, clientAddress: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientAddress}


							/>
							<CustomInput
								ref={deliveryAddressRef}
								label="Delivery address"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, clientdeliveryAddress: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientdeliveryAddress}

							/>



						</div>
						<div className={classes.columnTwo} >
							<CustomInput
								ref={clientContactNameRef}
								label="Client contact name"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will set date = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, clientContactName: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientContactName}
							/>
							<CustomInput
								ref={clientINORef}
								label="Client INO"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, clientINO: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientINO}

							/>

							<CustomInput
								ref={clientTINORef}
								label="Client TINO"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, clientTINO: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientTINO}
							/>
							<CustomInput
								ref={clientINOVATRef}
								label="Client INO VAT"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, clientINOVAT: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.clientINOVAT}

							/>

						</div>
						{/* </div> */}
					</div>
				</ExpandableSection>
				{/* </div> */}
				{/* <div className={classes.row}> */}
				<ExpandableSection
					renderHeader={() => <Typography variant="body2">{t('Modal.Invoice')}</Typography>}
					className={itemModalclasses.expandableSection}
					startExpanded={true}
				>
					{/* <div className={classes.row}> */}
					<div className={classes.row}>

						<div className={classes.columnTwo} >
							{/* <Typography variant='h6' style={{ paddingBottom: '16px' }}>Company Header</Typography> */}

							{/* Invoice */}
							<CustomInput
								ref={invoiceNumberRef}
								label="Invoice number"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, invoiceNumber: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.invoiceNumber}

							/>
							{/* info */}

							<CustomInput
								ref={orderNumberRef}
								label="Order number"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, orderNumber: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.orderNumber}

							/>
							<CustomInput
								ref={contractNumberRef}
								label="Contract number"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, contractNumber: e.currentTarget.value as number })
									}
								}}
								startingValue={invoicerDoc?.contractNumber === -1 ? '' : invoicerDoc?.contractNumber?.toString()}

							/>
							{!(docType === DocTypesEnum.DELIVERY_LIST || docType === DocTypesEnum.ORDER) && <CustomInput
								ref={specificSymbolRef}
								label="Specific symbol"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, specificSymbol: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.specificSymbol}

							/>}
						</div>
						<div className={classes.columnTwo} >
							{!(docType === DocTypesEnum.DELIVERY_LIST || docType === DocTypesEnum.ORDER) && <CustomInput
								ref={variableSymbolRef}
								label="Variable symbol"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, variableSymbol: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.variableSymbol}

							/>}

							{!(docType === DocTypesEnum.DELIVERY_LIST || docType === DocTypesEnum.ORDER) && <DateKeyInput
								label={t('Modal.Date_Issue')}
								ref={dateOfIssueRef}
								errorText=""
								placeholder=""
								required={true}
								type="date"
								shrink={true}
								// disabled={isNotEditable}
								// regex={regexPatterns.}
								endAndorment={<div style={{
									marginRight: '-10px'
								}}>
								</div>}
								startingValue={invoicerDoc?.dateOfIssue}
								customOnChange={(e) => {
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, dateOfIssue: moment(e, 'DD/MM/YYYY').valueOf() as number })
									}

								}}
							// customOnChange={(e) => {
							// 	console.log('Will start name = ', e)
							// 	if (invoicerDoc) {
							// 		setInvoicerDoc({ ...invoicerDoc, dateOfIssue: e.currentTarget.value as number })
							// 	}
							// }}

							// startingValue={state.labels.datesGroup.dateOfIssue.value != null ? state.labels.datesGroup.dateOfIssue.value : undefined}
							/>}
							{!(docType === DocTypesEnum.DELIVERY_LIST || docType === DocTypesEnum.ORDER) && <DateKeyInput
								ref={deliveryDateRef}
								label={t('Modal.Date_Delivery')}
								errorText=""
								placeholder=""
								required={true}
								type="date"
								shrink={true}
								// disabled={isNotEditable}
								endAndorment={<div style={{
									marginRight: '-10px'
								}}>


								</div>}
								customOnChange={(e) => {
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, deliveryDate: moment(e, 'DD/MM/YYYY').valueOf() as number })
									}

								}}
								// customOnChange={(e) => {
								// 	console.log('Will start name = ', e)
								// 	if (invoicerDoc) {
								// 		setInvoicerDoc({ ...invoicerDoc, deliveryDate: e.currentTarget.value as number })
								// 	}
								// }}
								startingValue={invoicerDoc?.deliveryDate}


							// startingValue={state.labels.datesGroup.deliveryDate.value != null ? state.labels.datesGroup.deliveryDate.value : undefined}
							/>}
							<DateKeyInput
								ref={invoicedPeriodRef}
								label={'Due date'}
								errorText=""
								placeholder=""
								required={true}
								type="date"
								shrink={true}
								// disabled={isNotEditable}
								endAndorment={<div style={{
									marginRight: '-10px'
								}}>

								</div>}
								customOnChange={(e) => {
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, dueDate: moment(e, 'DD/MM/YYYY').valueOf() as number })
									}

								}}
								// customOnChange={(e) => {
								// 	console.log('Will start name = ', e)
								// 	if (invoicerDoc) {
								// 		setInvoicerDoc({ ...invoicerDoc, dueDate: e.currentTarget.value as number })
								// 	}
								// }}
								startingValue={invoicerDoc?.dueDate}


							// startingValue={state.labels.datesGroup.deliveryDate.value != null ? state.labels.datesGroup.deliveryDate.value : undefined}
							/>
						</div>
					</div>
				</ExpandableSection>
				{/* Deilvery info */}

				<ExpandableSection
					renderHeader={() => <Typography variant="body2">{'Delivery Information'}</Typography>}
					className={itemModalclasses.expandableSection}
					startExpanded={false}
				>
					<div className={classes.row}>

						<div className={classes.columnTwo}>
							{/* <Typography variant='h6' style={{ paddingBottom: '16px' }}>Company Header</Typography> */}

							<CustomInput
								ref={suppliedGoodsRef}
								label="Supplied Goods"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, suppliedGoods: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.suppliedGoods}

							/>
							<CustomInput
								ref={suppliedServicesRef}
								label="Supplied Services"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, suppliedServices: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.suppliedServices}

							/>

							<CustomInput
								ref={unitsRef}
								label="Units"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, units: e.currentTarget.value as number })
									}
								}}
								startingValue={invoicerDoc?.units == -1 ? '' : invoicerDoc?.units.toString()}

							/>


							{/* Invoiced amount */}
							<CustomInput
								ref={amountExclVATRef}
								label="Amount excl. VAT"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, amountExclVAT: e.currentTarget.value as number })
									}
								}}
								startingValue={invoicerDoc?.amountExclVAT == -1 ? '' : invoicerDoc?.amountExclVAT.toString()}

							/>
						</div>
						<div className={classes.columnTwo} >

							<CustomInput
								ref={vATRef}
								label="VAT"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, VAT: e.currentTarget.value as number })
									}
								}}
								startingValue={invoicerDoc?.VAT == -1 ? '' : invoicerDoc?.VAT.toString()}

							/>
							<CustomInput
								ref={amountInclVATRef}
								label="Amount incl. VAT"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, amountInclVAT: e.currentTarget.value as number })
									}
								}}
								startingValue={invoicerDoc?.amountInclVAT == -1 ? '' : invoicerDoc?.amountInclVAT.toString()}

							/>
							<CustomInput
								ref={vATPercentageRef}
								label="VAT %"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, amountExclVAT: e.currentTarget.value as number })
									}
								}}
								startingValue={invoicerDoc?.VATPercentage == -1 ? '' : invoicerDoc?.VATPercentage.toString()}

							/>
							{/* Marketing info */}
							<CustomInput
								ref={textFieldRef}
								label="Text field"
								errorText=''
								placeholder=''
								required
								type='text'
								customOnChange={(e) => {
									console.log('Will start name = ', e)
									if (invoicerDoc) {
										setInvoicerDoc({ ...invoicerDoc, textField: e.currentTarget.value as string })
									}
								}}
								startingValue={invoicerDoc?.textField}

							/>
						</div>
					</div>
				</ExpandableSection>
				{/* </div> */}
			</div>


			{/* </div> */}
		</MuiPickersUtilsProvider></>
	}

	if (!isOpen) {
		return null
	} else
		return <ModalX
			isOpen={isOpen}
			onClose={handleClose}
			maxModalHeight={930}
		>
			<ModalXHeader style={{ backgroundColor: 'white' }}>
				<div className={itemModalclasses.headerLabels}>
					{/* <span className={itemModalclasses.headerText}>{upcFirst(state.labels.inputGroup.documentType.value)}</span> */}
					{/* {ItemModalCommon.renderHeaderLabels(
						state.approvalStatus,
						state.extractionStatus,
						state.confidenceLevel,
						state.labels.documentationGroup.requestIdStatus.value,
						state.labels.documentationGroup.purchaseOrderIdStatus.value
					)} */}
				</div>
			</ModalXHeader>
			{/* Key is used to trigger rerender on paginate, pagination is making inputs go wild */}
			{/* Mozda greska form import */}
			<Form onSubmit={onSubmit} pure={true}>
				<ModalXBody style={{
					flexDirection: 'row',
					backgroundColor: '#ededed',
				}}>
					<div className={itemModalclasses.section}>
						<div className={itemModalclasses.content} style={{ height: 820 }}>
							{/* Render Has to be different */}
							<div className={itemModalclasses.documentContainer}> <PDFViewer style={{ height: '100%' }}>
								<PdfDocTemplate doc={invoicerDoc} />

							</PDFViewer></div>
							{/* {ItemModalCommon.renderDocument(state, classes.documentContainer)} */}
							<div style={{ overflow: 'auto' }}>
								{renderForm()}
							</div>

							{/* {renderForm(state)}, */}

						</div>
						{/* pagination might have to be different */}
						{/* {ItemModalCommon.renderPagination(
							state,
							props,
							paginatePrev,
							paginateNext,
							classes.pagination,
							classes.paginationButton,
						)} */}
					</div>
					<ModalXSideBar title="Labels">
						<div className={modalClasses.sidebarContent}>

							{/* <Button
								disabled={((state && state.rejectedReason && state.approvalStatus !== ApprovalStatus.RETURNED) || (state.approvalStatus === ApprovalStatus.APPROVED)) ? true : false}
								className={classes.labelButton}
								onClick={e => popoverRef.current?.show({
									element: e.currentTarget,
									id: "groups",
									addNew: async (tag) => {
										await addTag({
											label: TagsEnum.GROUPS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									remove: async (tag) => {
										await removeTag({
											label: TagsEnum.GROUPS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									setSelected: (selected) => {
										setState({
											...state,
											labels: {
												...state.labels,
												accountingGroup: {
													...state.labels.accountingGroup,
													groupCode: {
														value: selected.code,
														origin: OriginEnum.USER_INPUT
													},
													groupAlias: {
														value: selected.alias,
														origin: OriginEnum.USER_INPUT
													}
												}
											}
										})
									},
									tags: user.organisation.tags.Groups
								})}>Groups</Button>
							<Button
								className={classes.labelButton}
								onClick={e => popoverRef.current?.show({
									addNew: async (tag) => {
										await addTag({
											label: TagsEnum.DEPARTMENTS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									remove: async (tag) => {
										await removeTag({
											label: TagsEnum.DEPARTMENTS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									element: e.currentTarget,
									id: "deps",
									setSelected: (selected) => {
										setState({
											...state,
											labels: {
												...state.labels,
												accountingGroup: {
													...state.labels.accountingGroup,
													departmentCode: {
														origin: OriginEnum.USER_INPUT,
														value: selected.code
													},
													departmentAlias: {
														origin: OriginEnum.USER_INPUT,
														value: selected.alias
													}
												}
											}
										})
									},
									tags: user.organisation.tags.Departments
								})}>Departments</Button>
							<Button
								className={classes.labelButton}
								onClick={e => popoverRef.current?.show({
									addNew: async (tag) => {
										await addTag({
											label: TagsEnum.MAJORS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									remove: async (tag) => {
										await removeTag({
											label: TagsEnum.MAJORS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									element: e.currentTarget,
									id: "majors",
									setSelected: (selected) => {
										setState({
											...state,
											labels: {
												...state.labels,
												accountingGroup: {
													...state.labels.accountingGroup,
													majorCode: {
														origin: OriginEnum.USER_INPUT,
														value: selected.code
													},
													majorAlias: {
														origin: OriginEnum.USER_INPUT,
														value: selected.alias
													}
												}
											}
										})
									},
									tags: user.organisation.tags.Majors
								})}>Majors</Button>
							<Button
								className={classes.labelButton}
								onClick={e => popoverRef.current?.show({
									addNew: async (tag) => {
										await addTag({
											label: TagsEnum.MINORS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									remove: async (tag) => {
										await removeTag({
											label: TagsEnum.MINORS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									element: e.currentTarget,
									id: "minors",
									setSelected: (selected) => {
										setState({
											...state,
											labels: {
												...state.labels,
												accountingGroup: {
													...state.labels.accountingGroup,
													minorCode: {
														origin: OriginEnum.USER_INPUT,
														value: selected.code
													},
													minorAlias: {
														origin: OriginEnum.USER_INPUT,
														value: selected.alias
													}
												}
											}
										})
									},
									tags: user.organisation.tags.Minors
								})}>Minors</Button>
							<Button
								className={classes.labelButton}
								onClick={e => popoverRef.current?.show({
									addNew: async (tag) => {
										await addTag({
											label: TagsEnum.SEGMENTS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									remove: async (tag) => {
										await removeTag({
											label: TagsEnum.SEGMENTS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									element: e.currentTarget,
									id: "segments",
									setSelected: (selected) => {
										setState({
											...state,
											labels: {
												...state.labels,
												accountingGroup: {
													...state.labels.accountingGroup,
													segmentCode: {
														origin: OriginEnum.USER_INPUT,
														value: selected.code
													},
													segmentAlias: {
														origin: OriginEnum.USER_INPUT,
														value: selected.alias
													}
												}
											}
										})
									},
									tags: user.organisation.tags.Segments
								})}>Segments</Button>
							<Button
								className={classes.labelButton}
								onClick={e => popoverRef.current?.show({
									addNew: async (tag) => {
										await addTag({
											label: TagsEnum.SUBSEGMENTS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									remove: async (tag) => {
										await removeTag({
											label: TagsEnum.SUBSEGMENTS,
											data: [{
												"alias": tag.alias,
												"code": tag.code
											}]
										})
										await user.refetch()
									},
									element: e.currentTarget,
									id: "sub-segments",
									setSelected: (selected) => {
										setState({
											...state,
											labels: {
												...state.labels,
												accountingGroup: {
													...state.labels.accountingGroup,
													subsegmentCode: {
														origin: OriginEnum.USER_INPUT,
														value: selected.code
													},
													subsegmentAlias: {
														origin: OriginEnum.USER_INPUT,
														value: selected.alias
													}
												}
											}
										})
									},
									tags: user.organisation.tags.Subsegments
								})}>Subsegments</Button> */}
						</div>
						<PDFDownloadLink document={<PdfDocTemplate doc={invoicerDoc} />} fileName="somename.pdf">
							{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
						</PDFDownloadLink>
						<SubmitButton className={itemModalclasses.submitButton} >{itemId ? 'Edit' : 'Create'}</SubmitButton>
					</ModalXSideBar>
				</ModalXBody>
			</Form>
			<TagsPopover ref={popoverRef} />
		</ModalX>

})