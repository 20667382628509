import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { register } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useModalStyles } from 'styles/useModalStyle'
import { regexPatterns } from 'utils/Validation'
import { ErrorContext } from './AppWithErrors'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			display: 'flex',
			flexDirection: 'column',
			width: 500,
			height: 750,
			padding: 30,
			justifyContent: 'center',
			alignItems: 'center'
		},
		registerContainer: {
			marginTop: 20,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		registerLabel: {
			opacity: 0.7,
			fontSize: 12
		},
		form: {
			marginTop: 40,
			paddingLeft: 40,
			paddingRight: 40
		},
		registrationSuccess: {
			padding: 40
		}
	})
)

export function Register() {
	const classes = useStyles()
	const modalClasses = useModalStyles({ modalWidth: 500, modalHeight: 750 })
	const [registrationSuccess, setRegistrationSucess] = React.useState(false)

	const { t } = useTranslation();

	const emailInputRef = React.useRef<CustomInput>(null)
	const passwordInputRef = React.useRef<CustomInput>(null)
	const repeatPasswordInputRef = React.useRef<CustomInput>(null)
	const organisationInputRef = React.useRef<CustomInput>(null)
	const firstNameInputRef = React.useRef<CustomInput>(null)
	const lastNameInputRef = React.useRef<CustomInput>(null)

	const errorContext = React.useContext(ErrorContext)

	async function onSubmit() {
		const emailError = emailInputRef.current?.checkErrors()
		const passwordError = passwordInputRef.current?.checkErrors()
		const repeatPasswordError = repeatPasswordInputRef.current?.checkErrors()
		const firstNameError = firstNameInputRef.current?.checkErrors()
		const lastNameError = lastNameInputRef.current?.checkErrors()
		const organisationError = organisationInputRef.current?.checkErrors()

		if (!emailError && !passwordError && !repeatPasswordError && !firstNameError && !lastNameError && !organisationError) {
			const response = await register({
				username: emailInputRef.current!.value,
				password: passwordInputRef.current!.value,
				organisationName: organisationInputRef.current!.value,
				firstName: firstNameInputRef.current!.value,
				lastName: lastNameInputRef.current!.value
			})
			if (response._type === 'generic_success') {
				setRegistrationSucess(true)
			} else {
				// Handle errors
			}
		}
	}

	function renderInner() {
		if (registrationSuccess) {
			return <div className={classes.registrationSuccess}>Registration successfull check your email and validate<Link to="/login">login</Link></div>
		} else {
			return (
				<div className={classes.content}>
					<a href="https://mygide.com/">
						<img src="/AiBERT_purple2.png" alt="logo" id="logo" />
					</a>
					<Form className={classes.form} onSubmit={onSubmit}>
						<CustomInput
							label={t('Login.Email')}
							placeholder={t('Login.Email_Long')}
							required={true}
							errorText={t('Login.Email_Error')}
							type="email"
							ref={emailInputRef}
							regex={regexPatterns.email}
						/>
						<CustomInput
							label={t('Login.Name')}
							placeholder={t('Login.Name_Long')}
							required={true}
							type="text"
							errorText=""
							ref={firstNameInputRef}
						/>
						<CustomInput
							label={t('Login.Surname')}
							placeholder={t('Login.Surname_Long')}
							required={true}
							errorText=""
							type="text"
							ref={lastNameInputRef}
						/>
						<CustomInput
							label={t('Login.Organisation')}
							placeholder={t('Login.Organisation_Long')}
							required={true}
							errorText=""
							type="text"
							ref={organisationInputRef}
						/>
						<CustomInput
							label={t('Login.Password')}
							placeholder={t('Login.Password_Long')}
							type="password"
							required={true}
							errorText={t('Login.Password_Error')}
							ref={passwordInputRef}
							regex={regexPatterns.password}
						/>
						<CustomInput
							label={t('Login.Password_Rep')}
							placeholder={t('Login.Password_Rep_Long')}
							type="password"
							required={true}
							errorText={t('Login.Password_Rep_Error')}
							ref={repeatPasswordInputRef}
							otherErrorCondition={() => {
								if (passwordInputRef.current?.value && repeatPasswordInputRef.current?.value) {
									return passwordInputRef.current.value !== repeatPasswordInputRef.current.value
								} else {
									return false
								}
							}}
						/>
						<SubmitButton>{t('Login.Create')}</SubmitButton>
					</Form>
					<div className={classes.registerContainer}>
						<p className={classes.registerLabel}>{t('Login.Create_Account_3')} <Link to="/login">{t('Login.Create_Account_4')}</Link></p>
					</div>
				</div>
			)
		}
	}

	return (
		<div className={`${modalClasses.paper} ${modalClasses.paperCentered}`}>
			{renderInner()}
		</div>
	)
}