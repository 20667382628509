import { CircularProgress, createStyles, fade, IconButton, makeStyles, TableCell, TableRow, Theme, withStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { DepartmentType, JobRole, Member } from 'api/api-types';
import { getDepartments, getMembers, resendVerifiyEmail } from 'api/ApiServices';
import { handleApiResponse, useApi } from 'api/useApi';
import * as _ from 'lodash';
import * as React from 'react';
import { AuthContext } from 'utils/Auth';
// import { MemberModal } from './MemberModal';
import { useModalStyles } from 'styles/useModalStyle'
import 'styles/colors.css'
import { DepartmentsModal } from './DepartmentsModal';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import { defaultCellStyle, defaultHeaderStyle } from 'elements/ItemTable/ItemTableColumns';
import { parseValue, RowValue } from 'elements/ItemTable/ItemTableRows';
import { useItemTableStyles } from 'elements/ItemTable/useItemTableStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import GroupIcon from '@material-ui/icons/Group';
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ActionName, ItemActionPopover } from 'feed/ItemActionPopover';


const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			minHeight: 835,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		},
		flexRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 20,
		},
		topFlexRow: {
			borderBottom: '1px solid #ededed',
			paddingBottom: 20
		},
		bottomFlexRow: {
			paddingTop: 5
		},
		//change to succesButton
		addMemberButton: {
			marginLeft: 'auto',
			marginRight: 15,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.1),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.01),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			border: '1px solid #e1e3e1'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		table: {

		},
		userIcon: {
			width: 22,
			height: 22,
		},
		manageIcons: {
			height: 20,
			cursor: 'pointer'
		},
		removeIcon: {
			// marginLeft: 15
		},
		userIconCell: {
			padding: 0
		},
		usernameCell: {
			paddingLeft: 0
		}
	}))

export function jobRoleLabel(role: JobRole): "Administration" | "Procurement" | "Approvals" | "Analytics" {
	switch (role) {
		case JobRole.ADMIN: return "Administration"
		case JobRole.PROCUREMENT_ADMIN: return "Procurement"
		case JobRole.APPROVER: return "Approvals"
		case JobRole.ANALYST: return "Analytics"
		default: throw Error("Invalid Job Role")
	}
}

export function Departments() {
	const classes = useStyles()
	const modalClasses = useModalStyles()
	const tableClasses = useItemTableStyles()
	const [departmentsData, refetchDepartments] = useApi({
		apiRequest: getDepartments,
		pollingInterval: 3000
	})
	const [membersData, refetchMembers] = useApi({
		apiRequest: getMembers,
		pollingInterval: 3000
	})
	const [resendLinkStep, setResendLinkStep] = React.useState<"resend" | "error" | "success" | "pending">("resend")

	const authContext = React.useContext(AuthContext)
	//after creating departmentsModal
	const departmentModalRef = React.useRef<DepartmentsModal>(null)
	const popoverRef = React.useRef<ItemActionPopover>(null)


	const { t } = useTranslation();

	async function resendUserVerification(username: string) {
		setResendLinkStep("pending")
		const response = await resendVerifiyEmail(username)
		handleApiResponse(
			response,
			(error) => {
				setResendLinkStep("error")
				setTimeout(() => {
					setResendLinkStep('resend')
				}, 2000)
			},
			(data) => {
				if (data === 'success') {
					setResendLinkStep("success")
					setTimeout(() => {
						setResendLinkStep('resend')
					}, 2000)
				}
			}
		)
	}

	//Check if keeys inside of this map are causing error
	let keeeys = 0
	function renderDepartments(departments: DepartmentType[], members: Member[]) {
		// function renderDepartments(departments: OrgDepartment[] | undefined) {
		// 	if (!departments) {
		// 		return "N/A"
		// 	} else {
		// 		return departments.map(e => <div key={e}>{e}</div>)
		// 	}
		// }
		return departments.map((row) => {
			return <TableRow key={row.name}>
				{/* <StyledTableCell align="left" className={classes.userIconCell}><AccountCircleIcon className={classes.userIcon} /></StyledTableCell> */}
				{/* Change className={classes.usernameCell} to firstCell when making general css  */}
				<StyledTableCell align="left"></StyledTableCell>
				<StyledTableCell align="left" className={classes.usernameCell}>{row.code}</StyledTableCell>
				<StyledTableCell align="left">{row.name}</StyledTableCell>
				{/* N/A is option for first user, since he will not have those fields available */}
				{/* if allias null display some icon */}
				<StyledTableCell align="left">{row.alias || <RemoveIcon />}</StyledTableCell>
				<StyledTableCell align="left">{row.manager || < RemoveIcon />}</StyledTableCell>
				<StyledTableCell align="left">{row.hasEmployees ? row.hasEmployees.map(e => <div key={e}>{e}</div>) : <RemoveIcon />}</StyledTableCell>

				<StyledTableCell align="left">{row.parent || "N/A"}</StyledTableCell>
				{/* <StyledTableCell align="center">{_.sortBy(row.jobRoles, e => e.toLowerCase()).map(e => <div key={e}>{jobRoleLabel(e)}</div>)}</StyledTableCell>
					<StyledTableCell align="center">{renderDepartments(row.departments)}</StyledTableCell> */}
				{/* Might have issue because of falsey, we should check for false/true, this might pass if null/undefined */}
				{/* <StyledTableCell align="center">{row.departmentOwner ? <CheckIcon className='bgGreen' /> : <RemoveIcon />}</StyledTableCell>
					<StyledTableCell align="center">{row.masterApprover ? <CheckIcon className='bgGreen' /> : <RemoveIcon />}</StyledTableCell>
					<StyledTableCell align="center">{renderUserVerified(row.verified)}</StyledTableCell> */}
				<StyledTableCell align="center">
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

						{/* Check if verification is needed and how to display AddDepartmentsModal and EditDepartm...  */}
						<EditIcon className={classes.manageIcons} onClick={() => {

							departmentModalRef.current?.show({ department: row, departments: departments, members: members, refetchDepartments })
						}} />
						{/* {row.verified && <EditIcon className={classes.manageIcons} onClick={() => {
								memberRef.current?.show({ member: row, refetch })
							}} />} */}
						{/* {renderVerificationIcon(row.verified, resendLinkStep, row.username)}
							{authContext.getUser()!!.iss !== row.username && <DeleteIcon
								className={`${classes.manageIcons} ${classes.removeIcon}`}
								onClick={() => {
									removeMember(row.username)
								}}
							/>} */}
					</div>
				</StyledTableCell>
			</TableRow>
		})
	}

	// function renderVerificationIcon(
	// 	verified: boolean,
	// 	step: "resend" | "error" | "success" | "pending",
	// 	username: string
	// ) {
	// 	if (!verified) {
	// 		if (step === 'resend') {
	// 			return <ReplayIcon className={classes.manageIcons} onClick={() => {
	// 				resendUserVerification(username)
	// 			}} />
	// 		} else if (step === 'pending') {
	// 			return <CircularProgress size={13} thickness={5} />
	// 		} else if (step === "error") {
	// 			return <ErrorIcon className={classes.manageIcons} />
	// 		} else if (step === "success") {
	// 			return <CheckIcon className={classes.manageIcons} />
	// 		} else {
	// 			throw Error()
	// 		}
	// 	} else {
	// 		return null
	// 	}
	// }

	// function renderUserVerified(verified: boolean) {
	// 	if (verified === true) {
	// 		return <ThumbUpAltIcon className='bgGreen' />
	// 	} else {
	// 		return <HourglassEmpty className='bgOrange' />
	// 	}
	// }
	type Column = '' | 'Code' | 'Name' | 'Alias' | 'Manager' | 'Employees' | 'Parent' | ''
	type Row = string[] | string | number

	function constructColumn(
		// section: Section,
		column: Column,
		options: MUIDataTableColumnOptions
	): MUIDataTableColumn {
		return {
			name: column,
			label: column,
			// options: { ...columnOptions(section, column, options) },
			options: { ...options }
		}
	}

	function renderColumns() {
		const columns: MUIDataTableColumn[] = [
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',
						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',

						}
					})
				}
			),

			constructColumn(
				'Name',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Name')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Code',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Code')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							fontWeight: '700',

						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0
						}
					})
				}
			),
			constructColumn(
				'Alias',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Alias')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Manager',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Manager')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Employees',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Employee')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Parent',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('Hr.Parent')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
		]
		return columns
	}

	function renderDepartmentssRow(
		departments: DepartmentType[],
		actionButton: (department: DepartmentType, index: number) => JSX.Element
	) {
		return departments.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// Check with Jorge how to propperly use reduce and why is first showing []
			if (i === 0)
				p.shift()
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					['', <GroupIcon className={classes.userIcon} />],
					['Name', parseValue(c.name)],
					['Code', parseValue(c.code)],
					['Alias', c.alias || <RemoveIcon />],
					['Manager', c.manager || <RemoveIcon />],
					['Employees', c.hasEmployees ? <>{c.hasEmployees.map(e => <div key={e}>{e}</div>)}</> : <RemoveIcon />],
					['Parent', c.parent || "N/A"],
					['', actionButton(c, i)]
					// ['', <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

					// 	{/* Check if verification is needed and how to display AddDepartmentsModal and EditDepartm...  */}
					// 	<EditIcon className={classes.manageIcons} onClick={() => {
					// 		console.log('onClick in Departments, row: ')
					// 		console.log(row)
					// 		departmentModalRef.current?.show({ department: c, departments: departments, members: members, refetchDepartments })
					// 	}} />
					// </div>

					// ],

				]
			// const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			// const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]
			const tmp = row.map(e => e[1])
			p.push(tmp)
			return p

		}, [[]])

	}


	if (departmentsData === 'loading' || membersData === 'loading') {
		return <CircularProgress />
	} else if (departmentsData && membersData) {
		return (
			<>
				<div className={tableClasses.tableWrapper}>
					<MUIDataTable
						title='Departments'
						data={renderDepartmentssRow(
							departmentsData!,
							(department, index) => {
								return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', zIndex: 999 }} onClick={e => {
									e.stopPropagation()
									popoverRef.current?.show({
										anchorElement: e.currentTarget,
										actions: [
											{
												actionName: ActionName.EDIT,
												action: () => {
													departmentModalRef.current?.show({ department: department, departments: departmentsData, members: membersData, refetchDepartments })
												}
											},
											// {
											// 	actionName: ActionName.REMOVE,
											// 	action: () => {
											// 		removeMember(member.username)
											// 	}
											// }
										]
									})
									// setSelectedItem(item.itemId, index)
								}} />
							}
						)}
						columns={renderColumns()}
						options={{
							elevation: 0,
							selectableRows: 'none',
							customToolbar: () => {
								return <div className={tableClasses.toolbarButtonsContainer}>
									{/* <div style={{ display: 'flex' }}> */}
									<IconButton onClick={() => {
										departmentModalRef.current?.show({ departments: departmentsData, members: membersData })
									}}>
										<AddCircleIcon className={`${tableClasses.addIcon}`} />
									</IconButton>
									{/* </div> */}
								</div>
							}
						}}

					/>
					{/* <div className={`${classes.flexRow} ${classes.topFlexRow}`}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
						<Button variant='contained' color='primary' className={`${classes.addMemberButton}`} onClick={() => {
							departmentModalRef.current?.show({ departments: departmentsData, members: membersData })
						}}>Add Department<AddIcon /></Button>
					</div>
					<div className={`${classes.flexRow} ${classes.bottomFlexRow}`}>
						<TableContainer>
							<Table className={classes.table} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left"></StyledTableCell>
										<StyledTableCell align="left" className={classes.usernameCell}>Code</StyledTableCell>
										<StyledTableCell align="left">Name</StyledTableCell>
										<StyledTableCell align="left">Alias</StyledTableCell>
										<StyledTableCell align="left">Manager</StyledTableCell>
										<StyledTableCell align="left">Employees</StyledTableCell>
										<StyledTableCell align="left">Parent</StyledTableCell>
										<StyledTableCell align="left"></StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{renderDepartments(departmentsData, membersData)}
								</TableBody>
							</Table>
						</TableContainer>
					</div> */}
				</div>
				<DepartmentsModal ref={departmentModalRef} />
				<ItemActionPopover ref={popoverRef} />

			</>
		)
	} else {
		return null
	}
}
