import * as React from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CircularProgress, createStyles, TableCell, makeStyles, Paper, Table, TableContainer, TableHead, TableRow, TextField, Theme, withStyles, TableBody, Checkbox, Typography, Button, Dialog, IconButton, Icon, Tooltip, Snackbar } from '@material-ui/core';
import { StripeInput } from 'elements/form/StripeInput';
import { CustomInput } from 'elements/form/CustomInput';
import { regexPatterns } from 'utils/Validation';
import { createCustomer, addPaymentMethod, getBillingDetails, getPaymentDetails, getInvoicesList, createSubscription, updateDefoultPaymentMethot, deletePaymentMethod } from 'api/ApiServices';
import { SubmitButton } from 'elements/form/SubmitButton';
import { Form } from 'elements/form/Form';
import { useApi } from 'api/useApi';
import { PaymentDetails, StripeAddress, stripeInvoice, StripePaymentDetails } from 'api/api-types';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloudDownload from '@material-ui/icons/CloudDownload';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close'
import { stripePricing } from 'account/payment/products';
import { Alert, AlertSettings } from 'elements/Alerts';
import { useTranslation } from 'react-i18next';


const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		containerRow: {
			display: 'flex',
		},
		itemLeft: {
			width: '35%',
			paddingRight: '16px',
		},
		itemRight: {
			width: '65%'

		},
		leftPadding: {
			paddingLeft: '16px'
		},
		rightPadding: {
			paddingRight: '16px'
		},
		stripeInput: {
			marginBottom: '32px'
		},
		innerItemLeft: {
			paddingRight: '64px',
			paddingLeft: '16px',
			paddingTop: '16px',
			paddingBottom: '16px',
			flexGrow: 1,
			width: '100%',
		},
		containerColumn: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			// justifyContent: 'space-between',
		},
		settingsRow: {
			width: '100%',
			marginTop: 5,
			marginBottom: 5,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		label: {
			opacity: 0.6,
			fontWeight: 700,
		},
		processes: {
			maxWidth: '40%',
			alignItems: 'flex-start',
			padding: '16px'

		},
		root: {
			width: '70%',
			marginTop: 20,
			backgroundColor: theme.palette.background.paper,
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		titlePadding: {
			padding: '16px 0px'
		},
		closeButton: {
			position: 'absolute',
			top: 0,
			right: 0,
			marginTop: -28,
			marginRight: -15,
			cursor: 'pointer'
		},
		bgGreen: {
			color: '#08ad6c',
		}
	})
)

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);
export function Billings() {
	const stripe = useStripe();
	const elements = useElements();
	const classes = useStyle();
	const { t } = useTranslation();

	const [billingDetails, refetchBillingDetails] = useApi({
		apiRequest: getBillingDetails
	})

	const [paymentDetails, refetchPaymentDetails] = useApi({
		apiRequest: getPaymentDetails
	})
	const [invoicesList] = useApi({
		apiRequest: getInvoicesList
	})

	// Invoices Dropdown
	const [open, setOpen] = React.useState(false);
	const [customer, setCustomer] = React.useState<string>('');

	// Card Dialong
	const [openDialog, setOpenDialog] = React.useState(false);

	const handleClick = async () => {
		setOpen(!open);
	};
	const [alert, setAlert] = React.useState<AlertSettings>({ message: '', open: false, color: 'success' })
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ ...alert, open: false })
	}
	const [rerender, forceRerender] = React.useState(0)


	const nameRef = React.createRef<CustomInput>()
	const emailRef = React.createRef<CustomInput>()
	const phoneRef = React.createRef<CustomInput>()
	const cityRef = React.createRef<CustomInput>()
	const countryRef = React.createRef<CustomInput>()
	const lineOneRef = React.createRef<CustomInput>()
	const lineTwoRef = React.createRef<CustomInput>()
	const postalCodeRef = React.createRef<CustomInput>()
	const stateRef = React.createRef<CustomInput>()
	const companyIdRef = React.createRef<CustomInput>()
	const companyVATRef = React.createRef<CustomInput>()
	const fullNameRef = React.createRef<CustomInput>()

	React.useEffect(() => {
		if (billingDetails !== 'loading' && billingDetails != null && billingDetails.billing != null && billingDetails.billing.stripeCustomerId != null)
			setCustomer(billingDetails.billing.stripeCustomerId)
	}, [billingDetails])

	async function handleSaveCustomer() {
		if (emailRef.current!.value != null && nameRef.current!.value != null) {
			const address: StripeAddress = {
				city: cityRef.current!.value,
				country: countryRef.current!.value,
				line1: lineOneRef.current!.value,
				// line2: lineTwoRef.current!.value,
				postal_code: postalCodeRef.current!.value,
				state: '/',

			}
			const customerId = await createCustomer(
				emailRef.current!.value,
				nameRef.current!.value, address,
				{
					companyName: fullNameRef.current!.value,
					companyId: companyIdRef.current!.value,
					companyVAT: companyVATRef.current!.value,
					companyPhone: phoneRef.current!.value
				})
			if (customerId._type === 'data') {
				// Check if card is created
				refetchBillingDetails()
				setCustomer(customerId.data)
				setAlert({ open: true, message: t("Alert.Billing_Usr_Created"), color: 'success' })
			} else {
				setAlert({ open: true, message: t("Alert.Billing_Usr_Cre_Fail"), color: 'warning' })
				console.log("error creating user")
			}
		}

	}

	async function handleSaveCard() {
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		const cardNumberElement = elements.getElement(CardNumberElement)
		// const payment_details: PaymentDetails = {
		// 	name: nameRef.current!.value,
		// 	email: emailRef.current!.value,
		// 	address: {
		// 		city: cityRef.current!.value,
		// 		country: countryRef.current!.value,
		// 		line1: lineOneRef.current!.value,
		// 		// line2: lineTwoRef.current!.value,
		// 		postal_code: postalCodeRef.current!.value,
		// 		state: '/',

		// 	}
		// }
		if (cardNumberElement !== null) {
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardNumberElement,
				// billing_details: payment_details,
				metadata: {
					companyName: fullNameRef.current!.value,
					companyId: companyIdRef.current!.value,
					companyVAT: companyVATRef.current!.value

				}
			});
			console.log(paymentMethod?.id)
			if (error) {
				console.log('[error]', error);
			} else {

				const paymentDetails = await addPaymentMethod(customer, paymentMethod!.id)
				if (paymentDetails._type === 'data') {

				} else {
					console.log('error', paymentDetails)
				}
				refetchPaymentDetails()
				setOpenDialog(false)
				forceRerender(rerender + 1)
			}
		}

	}

	const handleClickOpen = () => {
		setOpenDialog(true)
	};

	const handleClose = () => {
		setOpenDialog(false)
		// setSelectedValue(value);
	};


	function renderInvoicesRow(invoicesList?: stripeInvoice[]) {
		if (invoicesList) {
			return invoicesList.map((value, index) => {
				return (
					< TableRow >
						<StyledTableCell align="center"></StyledTableCell>
						< StyledTableCell align="left" > {value.invoiceNr}</StyledTableCell >
						<StyledTableCell align="left">{new Date(value.created! * 1000).toString()}</StyledTableCell>
						<StyledTableCell align="left">{value.billing_reason}</StyledTableCell>
						<StyledTableCell align="left">{value.amount_paid}</StyledTableCell>
						<StyledTableCell align="center">	<a href={value.invoice_pdf!}>
							<CloudDownload />
						</a></StyledTableCell>
						<StyledTableCell align="center"></StyledTableCell>


					</TableRow >)
			})
		} else return null
	}

	function renderCardRow(paymentDetails: StripePaymentDetails[]) {
		let tmp = false
		return paymentDetails.map((e) => {
			if (billingDetails !== 'loading')
				console.log('This is it: ', billingDetails?.billing?.stripePaymentMethodId, ' ', e.id)
			return (<TableRow>
				<StyledTableCell align="center"><Checkbox name="checkbox1" checked={billingDetails !== 'loading' && billingDetails?.billing?.stripePaymentMethodId === e.id} /></StyledTableCell>
				<StyledTableCell align="left">{e.card.brand}</StyledTableCell>
				<StyledTableCell align="center">{e.card.last4}</StyledTableCell>
				{/* Missing iconButtonImplementation */}
				<StyledTableCell align="left">
					<Tooltip title={'Make Active Payment Method'}>
						<IconButton onClick={async () => {
							if (billingDetails !== 'loading' && billingDetails != null && billingDetails.billing != null && billingDetails.billing.stripeCustomerId != null) {
								const response = await updateDefoultPaymentMethot(billingDetails.billing.stripeCustomerId, e.id)
								if (response._type === 'generic_success') {
									await refetchPaymentDetails()
									// Alert here
									setAlert({ open: true, message: t("Alert.Payment"), color: 'success' })
									forceRerender(rerender + 1)
									// console.log('Succes updating default payment method')
								} else {
									setAlert({ open: true, message: t("Alert.Payment_Fail"), color: 'warning' })
									// console.log('Failed updating payment method')
								}
							}
						}}>
							<CheckCircleIcon className={`${(billingDetails !== 'loading' && billingDetails?.billing?.stripePaymentMethodId === e.id) ? classes.bgGreen : ''}`}></CheckCircleIcon>
						</IconButton>
					</Tooltip>
				</StyledTableCell>
				<StyledTableCell align="left">
					<Tooltip title={'Delete Payment Method'}>

						<IconButton onClick={async () => {
							if (billingDetails !== 'loading' && billingDetails != null && billingDetails.billing != null && billingDetails.billing.stripeCustomerId != null) {
								const response = await deletePaymentMethod(billingDetails.billing.stripeCustomerId, e.id)
								if (response._type === 'generic_success') {
									// Alert here
									setAlert({ open: true, message: t("Alert.Payment_Del"), color: 'success' })
									// console.log('Succes deleting default payment method')
								} else {
									// console.log('Error deleting default credit card')
									setAlert({ open: true, message: t("Alert.Payment_Del_Fail"), color: 'warning' })
									refetchPaymentDetails()
								}
							}
						}}>

							<DeleteOutlinedIcon />
						</IconButton>
					</Tooltip>
				</StyledTableCell>
			</TableRow>)
		})
	}
	if (billingDetails === 'loading' || paymentDetails === 'loading' || billingDetails == null || invoicesList === 'loading' || invoicesList === null) {
		return <CircularProgress></CircularProgress>
	} else
		return (
			<div>
				{/* <Form onSubmit={onSubmit}> */}
				<div className={classes.containerRow}>
					{/* <TextField
				label="Credit Card Number"
				name="ccnumber"
				variant="outlined"
				required
				fullWidth
				InputLabelProps={{ shrink: true }}
				InputProps={{
					inputComponent: StripeInput,
					inputProps: {
						component: CardNumberElement
					},
				}}
			/> */}
					<div className={`${classes.containerColumn} ${classes.itemLeft}`}>
						<Paper className={classes.innerItemLeft} style={{ marginBottom: '32px' }} >
							<Typography variant='h6' style={{ paddingBottom: '16px' }}>Billing Info</Typography>
							<CustomInput
								key="name"
								ref={nameRef}
								label="Company Name"
								errorText=""
								placeholder="Write your full name"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.metadata?.companyName}
							// startingValue={supplier?.value.compIco}
							/>
							<CustomInput
								key="lineOne"
								ref={lineOneRef}
								label="Street"
								errorText=""
								placeholder="Write your line"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.address?.line1}
							// startingValue={supplier?.value.compIco}
							/>
							<CustomInput
								key="postalCode"
								ref={postalCodeRef}
								label="Postal Code"
								errorText=""
								placeholder="Write your postal code"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.address?.postal_code}
							// startingValue={supplier?.value.compIco}
							/>
							<CustomInput
								key="city"
								ref={cityRef}
								label="City"
								errorText=""
								placeholder="Write your city"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.address?.city}
							// startingValue={supplier?.value.compIco}
							/>
							{/* This is actually state, not country */}
							<CustomInput
								key="country"
								ref={countryRef}
								label="Country"
								errorText=""
								placeholder="Write your country"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.address?.country}
							/>
							<CustomInput
								key="companyID"
								ref={companyIdRef}
								label="Company ID"
								errorText=""
								placeholder="Write your Company ID"
								type="text"
								required={false}
								startingValue={billingDetails?.billing?.metadata?.companyId}

							/>
							<CustomInput
								key="companyVAT"
								ref={companyVATRef}
								label="Company VAT"
								errorText=""
								placeholder="Write your Company VAT"
								type="text"
								required={false}
								startingValue={billingDetails?.billing?.metadata?.companyVAT}
							/>

							{/* <CustomInput
								key="lineTwo"
								ref={lineTwoRef}
								label="Line 2"
								errorText=""
								placeholder="Write your line"
								required={false}
								type="text"
								startingValue={paymentDetails?.billingDetails.address.line2}
							// startingValue={supplier?.value.compIco}
							/> */}

						</Paper>
						<Paper className={classes.innerItemLeft} >
							<Typography style={{ paddingBottom: '16px' }} variant='h6'>Company Contact</Typography>
							<CustomInput
								key="fullName"
								ref={fullNameRef}
								label="Full Name"
								errorText=""
								placeholder="Write your Full Name"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.fullName}
							// startingValue={supplier?.value.compIco}
							/>
							<CustomInput
								key="tst"
								ref={emailRef}
								label="Email"
								errorText=""
								placeholder="Write your email"
								required={true}
								type="email"
								regex={regexPatterns.email}
								startingValue={billingDetails?.billing?.billingEmail}
							// startingValue={supplier?.value.compIco}
							/>
							<CustomInput
								key="phone"
								ref={phoneRef}
								label="Phone"
								errorText=""
								placeholder="Write your state"
								required={true}
								type="text"
								startingValue={billingDetails?.billing?.metadata?.companyPhone}
							// startingValue={supplier?.value.compIco}
							/>
							<Button variant="contained" color='primary' onClick={handleSaveCustomer} style={{ alignSelf: 'center', minWidth: '200px' }} >Save</Button>
						</Paper>
					</div>

					<div className={classes.containerColumn}>
						<Paper className={classes.containerRow} style={{
							marginBottom: '32px',
							flexGrow: 1,
							width: '100%',
						}}>
							<div className={classes.containerColumn}>
								<div className={classes.containerRow} style={{ justifyContent: 'space-between', width: '100%' }}>
									<Typography style={{ alignSelf: 'start', padding: '16px 16px' }} variant='h6'>Payment Methods</Typography>
								</div>
								{<TableContainer>
									{/* className={classes.table} */}
									<Table aria-label="customized table">
										<TableHead>
											<TableRow>
												{/* className={classes.usernameCell} */}
												<StyledTableCell align="center">Active</StyledTableCell>
												<StyledTableCell align="left">Card Name</StyledTableCell>
												<StyledTableCell align="left">Card Ending</StyledTableCell>
												<StyledTableCell align="left"></StyledTableCell>
												<StyledTableCell align="left"></StyledTableCell>

											</TableRow>
										</TableHead>
										<TableBody>
											{paymentDetails != null && renderCardRow(paymentDetails)}
											{/* {renderMembers(data)} */}



										</TableBody>
									</Table>
								</TableContainer>}

								<Dialog open={openDialog} style={{ maxHeight: '700px' }}
								>
									{/* Correction here */}

									<div style={{ display: 'flex', flexDirection: 'column', padding: '32px 16px 0px 16px', width: 400, height: 318 }}>
										<div style={{ position: 'relative' }}><CloseIcon className={classes.closeButton} onClick={() => setOpenDialog(!openDialog)} /></div>


										<TextField
											className={classes.stripeInput}
											label="Credit Card Number"
											name="ccnumber"
											variant="outlined"
											required
											fullWidth
											InputLabelProps={{ shrink: true }}
											InputProps={{
												inputComponent: StripeInput as any,
												inputProps: {
													component: CardNumberElement
												},
											}}
										/>
										<TextField
											className={classes.stripeInput}
											label="CardExpiryElement"
											name="CardExpiryElement"
											variant="outlined"
											required
											fullWidth
											InputLabelProps={{ shrink: true }}
											InputProps={{
												inputComponent: StripeInput as any,
												inputProps: {
													component: CardExpiryElement
												},
											}}
										/>
										<TextField
											className={classes.stripeInput}
											label="CardCvcElement"
											name="CardCvcElement"
											variant="outlined"
											required
											fullWidth
											InputLabelProps={{ shrink: true }}
											InputProps={{
												inputComponent: StripeInput as any,
												inputProps: {
													component: CardCvcElement
												},
											}}

										/>
										<Button onClick={handleSaveCard} variant='contained' color='primary' style={{ alignSelf: 'center', minWidth: '200px' }}>Save</Button>
									</div>
								</Dialog>
								{/* <MemberModal ref={memberRef} /> */}

								{/* <SubmitButton >Save</SubmitButton> */}
								{/* <IconButton onClick={handleClickOpen}>
									<InfoIcon />
								</IconButton> */}

							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>

								<img style={{ marginRight: '-24px', width: '500px', height: '375px' }} src="/CreditCardPlaceHolder2.png" alt="logo" />
								<IconButton onClick={() => { setOpenDialog(!openDialog) }} style={{ marginLeft: 'auto', marginBottom: 'auto' }}>
									<AddCircleIcon style={{ color: '#08ad6c', }} />
								</IconButton>
							</div>

						</Paper>
						<Paper style={{ width: '100%' }}>
							<Typography style={{ padding: '16px' }} variant='h6'>Payment History</Typography>
							<TableContainer style={{ overflowX: 'auto', maxHeight: '376px' }}>
								{/* className={classes.table} */}
								<Table aria-label="customized table">
									<TableHead>
										<TableRow>
											{/* className={classes.usernameCell} */}
											<StyledTableCell align="left"></StyledTableCell>
											<StyledTableCell align="left" >Invoice Number</StyledTableCell>
											<StyledTableCell align="left">Date</StyledTableCell>
											<StyledTableCell align="center">Amount</StyledTableCell>
											<StyledTableCell align="left">Subscription</StyledTableCell>
											<StyledTableCell align="center">Download</StyledTableCell>
											<StyledTableCell align="left"></StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody style={{ overflow: 'scroll' }}>
										{/* {renderMembers(data)} */}
										{renderInvoicesRow(invoicesList)}

									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
						<Snackbar open={alert.open} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
							<Alert onClose={handleCloseAlert} severity={alert.color}>
								{alert.message}
							</Alert>
						</Snackbar>
					</div>

				</div>
				{/* </Form> */}

			</div >
		)
}