import { FormGroup, makeStyles } from '@material-ui/core'
import * as React from 'react'

export type FormContextType = {
	isLoading: boolean
}

type Props = {
	onSubmit: () => (Promise<void> | void)
	className?: string
	pure?: boolean
}

const useStyles = makeStyles({
	formGroup: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	fullWidth: {
		width: '100%'
	}
})

export const FormContext = React.createContext<FormContextType>(undefined as any)

export function Form({ onSubmit, children, pure, className = "" }: React.PropsWithChildren<Props>) {
	const [isLoading, setIsLoading] = React.useState(false)
	const classes = useStyles()

	const submit = async (e: React.FormEvent) => {
		e.preventDefault()
		try {
			setIsLoading(true)
			await onSubmit()
		} catch (e) {
			// Error handling
		} finally {
			setIsLoading(false)
		}
	}

	const renderInner = (children: React.ReactNode) => {
		if (pure) {
			return <form onSubmit={submit}>
				{children}
			</form>
		} else {
			return <FormGroup className={`${classes.formGroup} ${className}`}>
				<form onSubmit={submit} className={classes.fullWidth}>
					{children}
				</form>
			</FormGroup>
		}
	}

	return (
		<FormContext.Provider value={{
			isLoading
		}}>
			{renderInner(children)}
		</FormContext.Provider>
	)
}
