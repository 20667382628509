import { Button, makeStyles, Popover, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import { TagType } from 'api/api-types'
import { CustomInput } from 'elements/form/CustomInput'
import { UserContext } from 'main/AppWithUser'
import * as React from 'react'

const useStyles = makeStyles({
	popover: {
		padding: 10,
		width: 200,
	},
	labelContainer: {
		position: 'relative'
	},
	closeIcon: {
		fontSize: 14,
		position: 'absolute',
		top: '25%',
		right: 5,
		color: '#FF3D57'
	},
	title: {
		paddingBottom: 10,
	}
})

type Params = {
	step: Step
	element: HTMLElement
	id: string
	tags: TagType[]
	setSelected: (tag: TagType) => void
	addNew: (tag: TagType) => void
	remove: (tag: TagType) => void
}

type Step = "choose" | "create"

export type TagsPopover = {
	show: (params: Omit<Params, "step">) => void
}

export const TagsPopover = React.forwardRef((props: {}, ref: React.Ref<TagsPopover>) => {
	const user = React.useContext(UserContext)
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | null>(null)

	const aliasRef = React.useRef<CustomInput>(null)
	const codeRef = React.useRef<CustomInput>(null)

	const classes = useStyles()

	function handleClose() {
		setIsOpen(false)
		setParams(null)
	}

	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			setIsOpen(true)
			setParams({
				...params,
				step: 'choose'
			})
		}
	}))

	if (!params) {
		return null
	}

	const addNew = async () => {
		const aliasErrors = aliasRef.current?.checkErrors()
		const codeErrors = codeRef.current?.checkErrors()
		if (aliasErrors || codeErrors) {
			return
		} else {
			params.addNew({
				code: codeRef.current!.value,
				alias: aliasRef.current!.value
			})
			setParams({
				...params,
				step: 'choose',
				tags: [...params.tags, {
					code: codeRef.current!.value,
					alias: aliasRef.current!.value
				}]
			})
		}
	}

	const removeTag = async (tag: TagType) => {
		const index = params.tags.findIndex(e => e.alias === tag.alias && e.code === tag.code)
		params.remove(tag)
		setParams({
			...params,
			tags: [...params.tags.slice(0, index), ...params.tags.slice(index + 1)]
		})
	}

	const renderTags = () => {
		const tags = params.tags
			.map(e => (
				<div className={`bgGreen status-bgGreen label ${classes.labelContainer}`} onClick={() => {
					params.setSelected(e)
					handleClose()
				}}>
					<span>{e.alias}</span>
					<CloseIcon className={classes.closeIcon} onClick={(ev) => {
						ev.stopPropagation()
						removeTag(e)
					}} />
				</div>
			))

		return [
			...tags,
			(<span className="bgGrey status-bgGrey label" onClick={() => {
				setParams({
					...params,
					step: "create"
				})
			}}><AddIcon /></span>)
		]
	}

	const renderAddNew = () => {
		return <>
			<CustomInput
				ref={codeRef}
				required={true}
				type="text"
				errorText=""
				label="Code"
				placeholder=""
			/>
			<CustomInput
				ref={aliasRef}
				required={true}
				type="text"
				errorText=""
				label="Alias"
				placeholder=""
			/>
			{/* change to themes success button */}
			<Button variant='contained' color='primary' fullWidth onClick={addNew}>Save</Button>
		</>
	}

	if (params.step === "choose") {
		return <Popover
			open={isOpen}
			anchorEl={params.element}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<div className={classes.popover}>
				<Typography variant='body2' className={classes.title}>Add New Label</Typography>
				{renderTags()}
			</div>
		</Popover>
	} else {
		return <Popover
			open={isOpen}
			anchorEl={params.element}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<div className={classes.popover}>
				<Typography variant='body2' className={classes.title}>Add New Label</Typography>
				{renderAddNew()}
			</div>
		</Popover>
	}
})