import { DocStatusEnum, ExtractionStatus, Item, Label, OriginEnum } from "api/api-types"
import * as _ from 'lodash'
import * as React from 'react'
import { formatDateFromEpoch } from "utils/DateTime"
import { formatMoneyCS } from "utils/Money"
import { upcFirst } from "utils/TextFormating"

import { Column, columnOrdinal } from "./ItemTableColumns"

export type RowValue<T> = T | "Missing"

export function parseValue<T extends Row>(value: T | null | undefined): RowValue<T> {
	if (value == null || value === "" || value == -1) {
		return "Missing"
	} else {
		if (typeof value === "object") {
			if ((value as string[]).length > 0) {
				return value!
			} else {
				return "Missing"
			}
		} else {
			return value
		}
	}
}

type Row = string[] | string | number

const documentationStatus = (docStatus: DocStatusEnum, values: string[]) => {
	switch (docStatus) {
		case DocStatusEnum.SUCCESS:
			return values
		default: return docStatus
	}
}

const extractionStatus = (status: ExtractionStatus, confidenceLevel: number) => {
	if (status === ExtractionStatus.READY && confidenceLevel === 100) {
		return ExtractionStatus.CORRECT
	} else if (status === ExtractionStatus.DUPLICATE) {
		return ExtractionStatus.DUPLICATE
	} else if (status === ExtractionStatus.AI_ERROR) {
		return ExtractionStatus.AI_ERROR
	} else if (status === ExtractionStatus.CLASSIFIED) {
		return ExtractionStatus.CLASSIFIED
	}
	else {
		return ExtractionStatus.ATTENTION
	}
}

function checkOriginForDates(label: Label<number | null>): JSX.Element | 'Missing' {
	if (label.value == null)
		return 'Missing'
	if (label && label.origin === OriginEnum.RECOMMENDATION) {
		// return { value: parseValue(label.value), foultyExtraction: true }

		return <span style={{ position: 'relative' }}>
			{formatDateFromEpoch(label.value, "dd/MM/yyy")}
			<span style={{
				position: 'absolute',
				verticalAlign: 'middle',
				lineHeight: '1.43',
				height: '4px',
				width: '4px',
				marginBottom: '4px',
				marginLeft: '2px',
				// lineHeight: '50px',
				backgroundColor: '#1976d2',
				borderRadius: '50%',
				display: 'inline-block'
			}}></span></span>
	} else {
		return <div>{formatDateFromEpoch(label.value, "dd/MM/yyy")}</div>
	}
}

function checkOrigin(label: Label<number | null>): JSX.Element | 'Missing' {
	if (label.value == null)
		return 'Missing'
	if (label && label.origin === OriginEnum.RECOMMENDATION) {
		// return { value: parseValue(label.value), foultyExtraction: true }
		return <span style={{ position: 'relative' }}>
			{formatMoneyCS(label.value)}
			<span style={{
				position: 'absolute',
				verticalAlign: 'middle',
				lineHeight: '1.43',
				height: '4px',
				width: '4px',
				marginBottom: '4px',
				marginLeft: '2px',
				// lineHeight: '50px',
				backgroundColor: '#1976d2',
				borderRadius: '50%',
				display: 'inline-block'
			}}></span></span>
	} else {
		return <div>{formatMoneyCS(label.value)}</div>
	}
}

export function getItemTableRows(
	items: Item[],
	actionButton: (invoice: Item, index: number) => JSX.Element
) {
	return items
		.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// console.log(c, 'c.labels.supplierGroup.companyName.value: ', c.labels.supplierGroup.companyName != null ? parseValue(c.labels.supplierGroup.companyName.value) : 'Missing')
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					["Supplier", c.labels.supplierGroup.companyName != null ? parseValue(c.labels.supplierGroup.companyName.value) : 'Missing'],
					["Invoice ID", c.itemId],
					["Invoice #", c.labels.documentationGroup.documentNumber != null ? parseValue(c.labels.documentationGroup.documentNumber.value) : 'Missing'],
					["Requisition #", (c.labels.documentationGroup.requestIdStatus != null && c.labels.documentationGroup.requestId) ? parseValue(documentationStatus(c.labels.documentationGroup.requestIdStatus.value, c.labels.documentationGroup.requestId.map(e => e.value!))) : 'Missing'],
					["Purchase Order #", (c.labels.documentationGroup.purchaseOrderIdStatus && c.labels.documentationGroup.purchaseOrderId) ? parseValue(documentationStatus(c.labels.documentationGroup.purchaseOrderIdStatus.value, c.labels.documentationGroup.purchaseOrderId.map(e => e.value!))) : 'Missing'],
					// Ask Jorge about confidence lvl
					["Extraction", extractionStatus(c.extractionStatus, 100)],
					["Approval", c.approvalStatus],
					["Billing", "Missing"],
					["Booking", "Missing"],
					["Archive", "Missing"],
					["Document Format", parseValue(c.labels.inputGroup.documentFormat.value)],
					["Document", c.labels.inputGroup.documentType ? upcFirst(c.labels.inputGroup.documentType.value) : 'Missing'],
					["Name", parseValue(c.originalName)],
					["Amount", c.values.invoiceAmount ? checkOrigin(c.values.invoiceAmount) : 'Missing'],
					["IBAN", c.labels.supplierGroup.iban.length > 0 ? parseValue(c.labels.supplierGroup.iban.map(e => parseValue(e.value))) : 'Missing'],
					["Order #", c.labels.documentationGroup.orderNumber ? parseValue(c.labels.documentationGroup.orderNumber.map(e => parseValue(e.value))) : 'Missing'],
					// Here i need to check origin of labels and show red dot...
					// ["Date of Issue", parseValue(c.labels.datesGroup.dateOfIssue.value)],
					["Date of Issue", c.labels.datesGroup.dateOfIssue ? checkOriginForDates(c.labels.datesGroup.dateOfIssue) : 'Missing'],
					["Delivery Date", c.labels.datesGroup.deliveryDate ? checkOriginForDates(c.labels.datesGroup.deliveryDate) : 'Missing'],
					["Due Date", c.labels.datesGroup.dueDate ? checkOriginForDates(c.labels.datesGroup.dueDate) : 'Missing'],
					["ICO", c.labels.supplierGroup.ico ? parseValue(c.labels.supplierGroup.ico.value) : 'Missing'],
					// Check for Extraction time if this is the right time if not what to do?
					["Extraction Time", c.labels.datesGroup.extractedAt ? checkOriginForDates(c.labels.datesGroup.extractedAt) : 'Missing'],
					["Sender Email", parseValue(c.labels.inputGroup.senderEmail ? c.labels.inputGroup.senderEmail.value : '')],
					["Language", parseValue(c.labels.localizationGroup.language?.value)],
					["Created At", parseValue(c.createdAt)],
					["Tracking #", parseValue(c.trackingNumber ? c.trackingNumber : null)]
				]

			const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]

			p.push(rowWithDefaults)
			return p
		}, [[]])
		.filter(e => e.length > 0)
}