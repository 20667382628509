import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SecurityIcon from '@material-ui/icons/Security';
import { useTranslation } from 'react-i18next';
import GavelIcon from '@material-ui/icons/Gavel';




export function AccountDrawer(props: RouteComponentProps) {
	const drawerClasses = useDrawerStyle()
	const { t } = useTranslation();

	return (
		<>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/overview")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/settings/overview'}>
				<ListItemIcon className={drawerClasses.itemIcon}><PersonIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/overview' && drawerClasses.active}`} primary={t('Account.Account')} />
			</ListItem>

			{/* <ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/billings")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/settings/billings'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><AccountBalanceWalletIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/billings' && drawerClasses.active}`} primary={t('Account.Billings')} />
			</ListItem>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/subscription")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/settings/subscription'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><GroupIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/subscription' && drawerClasses.active}`} primary={t('Account.Subscription')} />
			</ListItem> */}

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/company-information")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/settings/company-information'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><ReceiptIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/company-information' && drawerClasses.active}`} primary={'Company Information'} />
			</ListItem>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/process-protocols")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/settings/procces-protocols'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><ReceiptIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/procces-protocols' && drawerClasses.active}`} primary={t('Account.Process_Protocols')} />
			</ListItem>

			{/* 
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/account/approval-groups")
			}}>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/account/approval-groups' && drawerClasses.active}`} primary="Approval Groups" />
			</ListItem> */}

			{/* <ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/account/departments")
			}}>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/account/departments' && drawerClasses.active}`} primary="Departments" />
			</ListItem> */}

			{/* <ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/account/suppliers")
			}}>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/account/suppliers' && drawerClasses.active}`} primary="Verified Suppliers" />
			</ListItem> */}

			{/* <ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/account/contractual-suppliers")
			}}>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/account/contractual-suppliers' && drawerClasses.active}`} primary="Contractual Suppliers" />
			</ListItem> */}

			{/* <ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/account/settings")
			}}>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/account/settings' && drawerClasses.active}`} primary="Settings" />
			</ListItem> */}



			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/privacy")

			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/settings/privacy'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><SecurityIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/privacy' && drawerClasses.active}`} primary={t('Account.Privacy')} />
			</ListItem>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/settings/license")

			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/settings/license'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><GavelIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/settings/license' && drawerClasses.active}`} primary={t('Account.License')} />
			</ListItem>

		</>
	)
}