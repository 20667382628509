import { createStyles, fade, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, withStyles } from '@material-ui/core';
import { ApprovalGroupType, Member, ApprovaGroupApprover } from 'api/api-types';
import { getApprovalGroups, getMembers, } from 'api/ApiServices';
import { useApi } from 'api/useApi';
import * as _ from 'lodash';
import * as React from 'react';
import { AuthContext } from 'utils/Auth';
import { AddNewGroupModal } from './AddNewGroupModal';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useModalStyles } from 'styles/useModalStyle'
import { formatDateFromEpoch } from 'utils/DateTime';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import { defaultCellStyle, defaultHeaderStyle } from 'elements/ItemTable/ItemTableColumns';
import { parseValue, RowValue } from 'elements/ItemTable/ItemTableRows';
import { useItemTableStyles } from 'elements/ItemTable/useItemTableStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MakeshiftDrawer from './MakeShiftDrawer';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ActionName, ItemActionPopover } from 'feed/ItemActionPopover';
import { useTranslation } from 'react-i18next';

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.common.black,
			fontWeight: 700,
		},
		body: {
			fontSize: 14,
		}
	}),
)(TableCell);

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		pageContent: {
			width: '100%',
			minHeight: 835,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column'
		},
		flexRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 20,
			paddingLeft: '5%',
			paddingRight: '5%'
		},
		topFlexRow: {
			borderBottom: '1px solid #ededed',
			paddingBottom: 20
		},
		//change the name to succes button
		addNewButton: {
			marginLeft: 'auto',
			marginRight: 15,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.1),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.01),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			border: '1px solid #e1e3e1'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit'
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		table: {
			marginTop: 15,
			fontSize: 14,
			// borderTop: '1px solid rgba(224, 224, 224, 1)',
		},
		noBorder: {
			border: 'none',
			marginTop: 0,
			paddingTop: 0

		},
		tableTitle: {
			fontSize: 15,
			fontWeight: 700,
			marginLeft: 25,
			opacity: 0.5
		},
		tableWrapper: {
			marginTop: 20,
			marginBottom: 10,
			width: '100%',
		},
		manageIcons: {
			height: 25,
			cursor: 'pointer',
			marginRight: 25
		},
		tableHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%'
		},
		addIcon: {
			color: '#08ad6c'
		},
		userIcon: {
			width: 22,
			height: 22,
			color: 'rgba(0, 0, 0, 0.54)',
		},
	})
)

export function ApprovalGroups() {
	const classes = useStyle()
	const tableClasses = useItemTableStyles()
	const modalClasses = useModalStyles()
	const [members] = useApi({
		apiRequest: getMembers
	})

	const [approvalGroups, refetchApprovalGroups] = useApi({
		apiRequest: getApprovalGroups
	})


	const [rowIndex, setRowIndex] = React.useState<number>()
	const [tableSideBarOpen, setTableSideBarOpen] = React.useState<{ open: boolean, approvers: ApprovaGroupApprover[], masterApprovers: ApprovaGroupApprover[] }>({ open: false, approvers: [], masterApprovers: [] })
	// Should also fetch departments here
	// const departments = _.toArray(OrgDepartment)
	const addGroupRef = React.useRef<AddNewGroupModal>(null)
	const popoverRef = React.useRef<ItemActionPopover>(null)

	const authContext = React.useContext(AuthContext)
	const { t } = useTranslation();

	function renderMembers(approvalGroup: ApprovalGroupType) {
		let approvers
		// if (approvalGroup.masterApprovers) {
		// 	approvers = approvalGroup.conditionalApprovers.concat(approvalGroup.masterApprovers) as string[]
		// } else {
		// 	approvers = approvalGroup.conditionalApprovers as string[]
		// }
		approvers = approvalGroup.conditionalApprovers
		// let tmpMembers: Member[]
		if (members !== 'loading' && members !== null && approvalGroups !== 'loading' && approvalGroups !== null) {
			// tmpMembers = members.filter(e => approvers.includes(e.username))
			// console.log("members after mapping", tmpMembers)
			return < ApprovalGroupX
				members={approvalGroup.conditionalApprovers}
				refetch={refetchApprovalGroups}
			/>
		} else {
			return null
		}
	}
	// This is obsolete
	let membersX: Member[] = []
	let content: ApprovalGroupType[] | null = []
	if (members === 'loading' || !members) {
		content = null
	} else if (approvalGroups === 'loading' || !approvalGroups) {
		content = null
	} else {
		// Why are we doing this filtering??
		membersX = members
		content = approvalGroups
	}

	function renderApprovaGroup(approvalGroups: ApprovalGroupType[]) {
		return approvalGroups.map((approvalGroup, i) => {
			return <><TableRow id={`${approvalGroup.departmentCode} 1stRow`} onClick={() => {
				if (rowIndex !== i) {
					setRowIndex(i)
				}
				else {
					setRowIndex(undefined)
				}

			}}>
				<StyledTableCell align="center">{approvalGroup.departmentCode}</StyledTableCell>
				<StyledTableCell align="center">{approvalGroup.departmentName}</StyledTableCell>
				<StyledTableCell align="center">{approvalGroup.departmentAlias}</StyledTableCell>
				<StyledTableCell align="center">{approvalGroup.documentationRequired}</StyledTableCell>
				<StyledTableCell align="center">{approvalGroup.suppliers.map(e => e.contractNumber)}</StyledTableCell>
				<StyledTableCell align="center">{formatDateFromEpoch(approvalGroup.createdAt, "dd/MM/yyy")}</StyledTableCell>
				<StyledTableCell align="left">{rowIndex !== i ? <ExpandMore /> : <ExpandLess />}</StyledTableCell>
			</TableRow>
				{rowIndex === i && <TableRow id={`${approvalGroup.departmentCode} 2ndRow`}>
					<StyledTableCell colSpan={5} align="left">{renderMembers(approvalGroup)}</StyledTableCell>
				</TableRow>}
			</>

		})
	}




	type Column = '' | 'Department Code' | 'Department Name' | 'Department Alias' | 'Documentation Required' | 'Contracts' | 'Created'
	type Row = string[] | string | number

	function constructColumn(
		// section: Section,
		column: Column,
		options: MUIDataTableColumnOptions
	): MUIDataTableColumn {
		return {
			name: column,
			label: column,
			// options: { ...columnOptions(section, column, options) },
			options: { ...options }
		}
	}

	function renderColumns() {
		const columns: MUIDataTableColumn[] = [
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',
						}
					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),
						style: {
							paddingRight: 0,
							width: '20px',

						}
					})
				}
			),
			constructColumn(
				'Department Name',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('AG.Dep_Name')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Department Alias',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('AG.Dep_Alias')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Department Code',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('AG.Dep_Code')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left"),

					}),
					setCellProps: () => ({
						...defaultCellStyle("left"),

					})
				}
			),
			constructColumn(
				'Documentation Required',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('AG.Doc_Req')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Contracts',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('AG.Contract_Num')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'Created',
				{
					customHeadLabelRender: (dataIndex: number, rowIndex: number) => {
						return t('AG.Created')
					},
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("left")
					}),
					setCellProps: () => ({
						...defaultCellStyle("left")
					})
				}
			),
			constructColumn(
				'',
				{
					setCellHeaderProps: () => ({
						...defaultHeaderStyle("right")
					}),
					setCellProps: () => ({
						...defaultCellStyle("right")
					})
				}
			),



		]
		return columns
	}
	function renderApprovalGroupsRow(
		approvalGroups: ApprovalGroupType[],
		actionButton: (approvalGroup: ApprovalGroupType, index: number) => JSX.Element
	) {

		return approvalGroups.reduce<[(RowValue<Row> | JSX.Element)[]]>((p, c, i) => {
			// Check with Jorge how to propperly use reduce and why is first showing []
			if (i === 0)
				p.shift()
			const row: [Column, (RowValue<Row> | JSX.Element)][] =
				[
					['', <GroupWorkIcon className={classes.userIcon}></GroupWorkIcon>],
					['Department Name', parseValue(c.departmentName)],
					['Department Alias', parseValue(c.departmentAlias)],
					['Department Code', parseValue(c.departmentCode)],
					['Documentation Required', parseValue(c.documentationRequired.map((e, i) => c.documentationRequired.length === i + 1 ? `${e}` : `${e}, `))],
					['Contracts', parseValue(c.suppliers.map((e, i) => c.suppliers.length === i + 1 ? `${e.contractNumber}` : `${e.contractNumber}, `))],
					['Created', parseValue(formatDateFromEpoch(c.createdAt, "dd/MM/yyy"))],
					['', actionButton(c, i)]
					// Edit to be added
					// ['', <GroupWorkIcon className={classes.userIcon}></GroupWorkIcon>],


				]
			// const ordered = _.orderBy(row, e => columnOrdinal(e[0]), ['asc']).map(e => e[1])
			// const rowWithDefaults = [...ordered, actionButton(c, i), c.itemId]
			const tmp = row.map(e => e[1])
			p.push(tmp)
			return p
		}, [[]])

	}



	if (membersX && content !== null)
		return (
			<>
				<div className={tableClasses.tableWrapper}>
					<div style={{ display: 'flex' }}>
						<MUIDataTable

							title='Approval Groups'
							data={renderApprovalGroupsRow(
								content!,
								(approvalGroup, index) => {
									return <MoreVertIcon style={{ height: 20, width: 20, cursor: 'pointer', zIndex: 999, marginRight: '16px' }} onClick={e => {
										e.stopPropagation()
										popoverRef.current?.show({
											anchorElement: e.currentTarget,
											actions: [
												{
													actionName: ActionName.EDIT,
													action: () => {
														addGroupRef.current?.show({
															members: membersX,
															approvalGroup: approvalGroup
														})
													}
												},
												// {
												// 	actionName: ActionName.REMOVE,
												// 	action: () => {
												// 		removeMember(member.username)
												// 	}
												// }
											]
										})
										// setSelectedItem(item.itemId, index)
									}} />
								}
							)}
							columns={renderColumns()}
							options={{

								elevation: 0,
								selectableRows: 'none',
								customToolbar: () => {
									return <div className={tableClasses.toolbarButtonsContainer}>
										{/* <div style={{ display: 'flex' }}> */}
										<IconButton onClick={() => {
											addGroupRef.current?.show({
												members: membersX,
											})
										}}>

											<AddCircleIcon className={classes.addIcon} />
										</IconButton>
										{/* </div> */}
									</div>
								},
								onRowClick: (row, i) => {
									let tmp = content as ApprovalGroupType[]
									setTableSideBarOpen({
										open: true,
										approvers: tmp[i.rowIndex].conditionalApprovers.sort((a, b) => {

											if (a.lastName < b.lastName) { return -1; }
											if (a.lastName > b.lastName) { return 1; }
											return 0;
										}),
										masterApprovers: tmp[i.rowIndex].masterApprovers.sort((a, b) => {

											if (a.lastName < b.lastName) { return -1; }
											if (a.lastName > b.lastName) { return 1; }
											return 0;
										})
									})
									setRowIndex(i.rowIndex)
									// handleOpenTableSidebar(content[rowIndex])
									// console.log(row as ApprovalGroupType)
								},
								setRowProps: (row, i) => ({

									style: {
										backgroundColor: rowIndex === i && "rgba(0, 0, 0, 0.04)"
									}
								})
							}}

						>

						</MUIDataTable>
						<MakeshiftDrawer open={tableSideBarOpen} setOpen={setTableSideBarOpen} />

					</div>

					{/* <div className={`${classes.flexRow} ${classes.topFlexRow}`}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
						<Button variant='contained' color='primary' className={`${classes.addNewButton}`} onClick={() => {
							addGroupRef.current?.show({
								members: membersX,
							})
						}}>Create New<AddIcon /></Button>
					</div>
					<TableContainer className={classes.table}>
						<Table>
							<TableHead>
								<TableRow>
									<StyledTableCell align="center">Department Code</StyledTableCell>
									<StyledTableCell align="center">Department Name</StyledTableCell>
									<StyledTableCell align="center">Department Alias</StyledTableCell>
									<StyledTableCell align="center">Documentation Requiered</StyledTableCell>
									<StyledTableCell align="center">Contracts</StyledTableCell>
									<StyledTableCell align="center">Created At</StyledTableCell>
									<StyledTableCell align="left"></StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{renderApprovaGroup(content)}
							</TableBody>
						</Table>
					</TableContainer> */}

				</div>
				<AddNewGroupModal refetch={refetchApprovalGroups} ref={addGroupRef} />
				<ItemActionPopover ref={popoverRef} />
			</>
		)
	return null
}

type Props = {
	// approvers: string[]
	members: ApprovaGroupApprover[]
	refetch: () => void
}

function ApprovalGroupX(props: Props) {
	const classes = useStyle()
	function renderMemberRow(members: ApprovaGroupApprover[]) {
		return members.map(member => {
			// if (props.approvers.includes(member.username))
			return (
				<TableRow key={member.username}>
					<StyledTableCell align="center">{member.username}</StyledTableCell>
					<StyledTableCell align="center">{member.firstName}</StyledTableCell>
					<StyledTableCell align="center">{member.lastName}</StyledTableCell>
					{/* <StyledTableCell align="center">{member.departments.map(e => (<div key={e}>{e}</div>))}</StyledTableCell>
					<StyledTableCell align="center">NA</StyledTableCell>
					<StyledTableCell align="center">NA</StyledTableCell> */}
				</TableRow>
			)
		})
	}

	return (
		<div className={classes.tableWrapper}>
			<div className={classes.tableHeader}>
				{/* <div className={classes.tableTitle}>{props.title}</div> */}
				{/* <RemoveIcon className={`${classes.manageIcons}`} onClick={async () => {
					await removeApprovalGroup(props.id)
					props.refetch()
				}} /> */}
			</div>
			<TableContainer className={`${classes.table} ${classes.noBorder}`}>
				<Table>
					<TableHead>
						<Typography variant="h6" align='left'>Approvers</Typography>
						<TableRow>
							<StyledTableCell align="center">Username</StyledTableCell>
							<StyledTableCell align="center">First Name</StyledTableCell>
							<StyledTableCell align="center">Last Name</StyledTableCell>
							{/*<StyledTableCell align="center">Title</StyledTableCell>
							<StyledTableCell align="center">Company</StyledTableCell>
							<StyledTableCell align="center">Department</StyledTableCell>
							<StyledTableCell align="center">Stand In</StyledTableCell>
							<StyledTableCell align="center">Condition</StyledTableCell> */}
						</TableRow>
					</TableHead>
					<TableBody>
						{renderMemberRow(props.members)}
					</TableBody>
				</Table>
			</TableContainer>
		</div >
	)
}
