import { makeStyles } from "@material-ui/core";

export function useItemTableStyles() {
	return makeStyles({
		tableWrapper: {
			width: '100%',
			height: '100%',
			position: 'relative'
		},
		toolbarContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: 10
		},
		submitButton: {
			backgroundColor: "#08ad6c",
			borderRadius: 0,
			marginLeft: 10,
			padding: 4,
			paddingLeft: 15,
			paddingRight: 15,
			'&:focus': {
				outline: "none"
			},
			'&:hover': {
				backgroundColor: "#4bc72c",
			},
			'&:disabled': {
				backgroundColor: 'grey'
			},
			color: 'white'
		},
		revertButton: {
			backgroundColor: "#f74531",
			'&:hover': {
				backgroundColor: "#c94e40",
			},
		},
		ibanContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		loadingContainer: {
			backgroundColor: "#cfcfcf",
			opacity: 0.5,
			width: '100%',
			height: '100%',
			position: 'absolute',
			zIndex: 100,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		customHeaderColumn: {
			'& span': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'red',
			}
		},
		customHeaderColumnRight: {
			'& span': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				backgroundColor: 'red',
			}
		},
		customHeaderColumnLeft: {
			'& span': {
				justifyContent: 'flex-start'
			}
		},
		titleContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		titleText: {
			marginRight: 20,
			fontWeight: 500
		},
		toolbarButtonsContainer: {
			display: 'inline-block',
			flexDirection: 'row',
		},
		addIcon: {
			color: '#08ad6c'
		}
	})()
}