import { Close } from "@material-ui/icons";
import * as React from 'react';
import "../index.css";
import { makeStyles } from "@material-ui/core/styles";
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const useStyles = makeStyles({
	documentWrapper: {
		// width: 500,
		// minHeight: 800,
		// height: 500,
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		alignSelf: 'stretch',
		zIndex: 99
	},
	document: {
		width: '100%',
		height: '100%',
		flexGrow: 1
	},
	button: {
		backgroundColor: "#08ad6c",
		'& :focus': {
			outline: "none"
		},
		'& :hover': {
			backgroundColor: "#4bc72c",
		},
		color: 'white'
	}
})


type Props = {
	setFile: (file: File | null) => void,
	file: File | null
}

export function UploadBox({ file, setFile }: Props) {
	const [dragIn, setDragIn] = React.useState<boolean>(false)
	// const [fileBase64, setFileBase64] = React.useState<string | ArrayBuffer | null>(null)
	const classes = useStyles()


	const inputRef = React.useRef<HTMLInputElement | null>(null)
	const dropZoneRef = React.useRef<HTMLDivElement>(null)

	function getBase64(file: File, cb: (result: string | ArrayBuffer | null) => void) {
		console.log('inside', file)
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			console.log('Will call cb with reader.result = ', reader.result)
			if (reader.result) {
				// let blob = new Blob([reader.result], { type: 'application/pdf' })
				// const url = URL.createObjectURL(blob);
				cb(reader.result)
			}
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	// const fileBase64 = React.useRef<string | ArrayBuffer>('')
	// let fileBase64: string | ArrayBuffer = '1'
	const [fileBase64, setFileBase64] = React.useState<string | ArrayBuffer>()


	// if (file) {
	// 	getBase64(file, (result) => {
	// 		if (result)
	// 			fileBase64 = result
	// 	});
	// }
	if (file)
		getBase64(file, (result) => {
			if (result) {
				console.log('will enter', result)
				setFileBase64(result)
			}

		});

	React.useEffect(() => {
		// if (file) {
		// 	getBase64(file, (result) => {
		// 		if (result) {
		// 			console.log('will enter', result)
		// 			fileBase64.current = result
		// 		}

		// 	});
		// }

	}, [file])

	function renderInner(file: File | null, fileBase64?: string | ArrayBuffer) {
		console.log('These are params for fetch inner', file, fileBase64)
		if (!file && (fileBase64 === '' || fileBase64 == null)) {
			return <div>Drag and drop PDF File...</div>
		} else {

			console.log('Beofre return', fileBase64)
			return (
				<>
					<div className={classes.documentWrapper}>
						<object className={classes.document} id='demoDoc' key={'demoDoc123'} data={`${fileBase64}`} aria-label="tsts12312" />
					</div>
					<Close className="x-button" onClick={() => {
						setDragIn(false)
						setFile(null)
						setFileBase64(undefined)
						// Hack, not liking this but needed to remove file form list
						// otherwise we cannot upload same file twice in a row
						// let input = document.getElementsByName("dragableInput").item(0)
						inputRef.current = null
					}}>X</Close>
				</>
			)
		}
	}

	const renderBoxContent = () => {
		return (
			<>
				<div className={`area ${dragIn || file ? "drag-in" : ""}`}>
					{fileBase64 ? renderInner(file, fileBase64) : <div className="dropZone" ref={dropZoneRef}>
						<div>Drag and drop a PDF file...</div>

						<input
							type="file"
							name="dragableInput"
							className="drag-drop-input"
							ref={inputRef}
							onDragOver={() => {
								setDragIn(true)
							}}
							onDragLeave={() => {
								setDragIn(false)
							}}
							onChange={e => {
								if (e.currentTarget.files) {
									// console.log('This is what we have from events', e.currentTarget.files.)
									const file = e.currentTarget.files[0]
									if (file) {
										setFile(file)
									}
								}
							}}
						/>


					</div>
					}
				</div>
			</>
		)
	}

	return (
		<div className="upload-box">
			{renderBoxContent()}
		</div>
	)
}
