import { makeStyles } from '@material-ui/core'
import * as React from 'react'

type Props = {
	count: number
	className?: string
}

const useStyles = makeStyles({
	invoiceCounter: {
		color: 'white',
		width: '20px',
		height: '20px',
		display: 'flex',
		/* padding: 3px; */
		/* font-size: 0.875rem; */
		fontSize: '12px',
		/* box-shadow: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20); */
		alignItems: 'center',
		fontWeight: 400,
		borderRadius: '300px',
		justifyContent: 'center',
		backgroundColor: '#FFAF38!important',
		lineHeight: '12px',
		fontFamily: 'arial',
	}
})

export function InvoiceCounter(props: Props) {
	const classes = useStyles()

	return (
		<div className={`${classes.invoiceCounter} ${props.className}`}>
			<span>{props.count}</span>
		</div>
	)
}