import { DateTimeFormatter, Instant, LocalDate } from "@js-joda/core"

export function parseDateFromEpoch(epoch: number, dash?: boolean) {
	function padZero(num: number) {
		return num < 10 ? `0${num}` : num.toString()
	}
	const date = new Date(epoch)
	if (dash) {
		return `${date.getUTCFullYear()}-${padZero(date.getUTCMonth() + 1)}-${padZero(date.getUTCDate())}`
	} else {
		return `${padZero(date.getUTCMonth() + 1)}/${padZero(date.getUTCDate())}/${date.getUTCFullYear()}`
	}
}

export function parseDateFrom(date: string | null | undefined) {
	if (!date) {
		return null
	} else {
		return Date.parse(date)
	}
}

export function fromDateToEpoch(date: Date) {
	return Math.floor(date.getTime() / 1000 / 1000)
}

export function formatDateFromEpoch(epochMilis: number | null, pattern: string) {
	if (epochMilis != null) {
		return DateTimeFormatter.ofPattern(pattern).format(LocalDate.ofInstant(Instant.ofEpochMilli(epochMilis)))
	} else {
		return null
	}
}

export function formatDateFromDateString(date: string, pattern: string) {
	return DateTimeFormatter.ofPattern(pattern).format(Instant.parse(date))
}

export function archivingPeriod(): { month: number, year: number } {
	if (new Date().getMonth() === 0) {
		return {
			month: 12,
			year: new Date().getFullYear() - 1,
		}
	} else {
		return {
			month: new Date().getMonth(),
			year: new Date().getFullYear(),
		}
	}
}
