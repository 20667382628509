import { Checkbox, createStyles, FormControl, FormControlLabel, FormHelperText, makeStyles, Theme } from '@material-ui/core'
// import { DepartmentsModal } from 'settings/departments/DepartmentsModal'
import { DepartmentType, JobRole, Member } from 'api/api-types'
import { inviteMember, updateMember } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX, ModalXHeader } from 'elements/ModalX'
import * as _ from 'lodash'
import { LOG } from 'logger/Log'
import { UserContext } from 'main/AppWithUser'
import * as React from 'react'
import Select from 'react-select'
import { ModalBody } from 'reactstrap'
import { regexPatterns } from 'utils/Validation'
import { jobRoleLabel } from './Members'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			padding: 30,
			maxWidth: 600
		},
		formControl: {
			marginBottom: 10,
		},
		selectLabel: {
			color: 'rgba(0, 0, 0, 0.54)'
		},
		selectError: {
			color: 'red'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
		}
	}))

type Params = {
	departments: DepartmentType[],
	roles: JobRole[]
	member?: Member
	refetch?: () => Promise<void>
}

export type MemberModal = {
	show: (params?: Params) => void
}

export const MemberModal = React.forwardRef((props: {}, ref: React.Ref<MemberModal>) => {
	const classes = useStyles()
	const user = React.useContext(UserContext)
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | undefined>()
	const [jobRoleError, setJobRoleError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [jobRoles, setJobRoles] = React.useState<{ value: JobRole, label: string }[]>([])
	const [departments, setDepartments] = React.useState<{ value: string, label: string }>()
	const [departmentOptions, setDepartmentOptions] = React.useState<{ value: string, label: string }[]>([])
	const [departmentError, setDepartmentError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const [isDepartmentOwner, setIsDepartmentOwner] = React.useState(false)
	const [isDepartmentOwnerError, setIsDepartmentOwnerError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [isMasterApprover, setIsMasterApprover] = React.useState(false)
	const [isMasterApproverError, setIsMasterApproverError] = React.useState({
		error: false,
		errorMessage: ""
	})

	const companyRef = React.useRef<CustomInput>(null)
	const firstNameRef = React.useRef<CustomInput>(null)
	const lastNameRef = React.useRef<CustomInput>(null)
	const titleRef = React.useRef<CustomInput>(null)
	const userNameRef = React.useRef<CustomInput>(null)
	const serialNumRef = React.useRef<CustomInput>(null)


	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			if (params) {
				setDepartmentOptions(params.departments.map(e => { return { value: e.code, label: e.name } }))
				// This will trigger edit mode
				if (params.member) {
					setParams(params)
					const { member } = params
					if (member.jobRoles.length > 0) {
						setJobRoles(member.jobRoles.map(e => {
							return {
								value: e,
								label: jobRoleLabel(e)
							}
						}))
					}
					if (member.departmentName && member.departmentCode)
						setDepartments({ value: member.departmentCode, label: member.departmentName })

					setIsDepartmentOwner(member.departmentOwner)
					setIsMasterApprover(member.masterApprover)
				}
			}
			setIsOpen(true)
		}
	}))

	async function onSubmit() {
		const companyError = companyRef.current?.checkErrors()
		const firstNameError = firstNameRef.current?.checkErrors()
		const lastNameError = lastNameRef.current?.checkErrors()
		const titleError = titleRef.current?.checkErrors()
		const usernameError = userNameRef.current?.checkErrors()

		// console.log("Params for submit", {
		// 	company: companyRef.current!.value,
		// 	username: userNameRef.current!.value,
		// 	firstName: firstNameRef.current!.value,
		// 	lastName: lastNameRef.current!.value,
		// 	title: titleRef.current!.value,
		// 	jobRoles: jobRoles.map(e => e.value),
		// 	departmentCode: departments[0].value,
		// 	departmentName: departments[0].label,
		// 	serialNumber: serialNumRef.current!.value
		// })
		// console.log("checking for Errors")
		if (jobRoles.length <= 0) {
			setJobRoleError({
				error: true,
				errorMessage: "Field is required"
			})
		}

		// if (departments.length <= 0) {
		// 	setDepartmentError({
		// 		error: true,
		// 		errorMessage: "Field is required"
		// 	})
		// 	return
		// }

		if (companyError || firstNameError || lastNameError || titleError || usernameError || jobRoleError.error || departmentError.error) {
			return
		} else {
			// This is editing, if there are params it means that edit mode is active
			// We make sure that that is the case
			if (params?.member) {
				console.log("entered edit")


				// This if to check if admin, might be redundant
				if (params.roles.indexOf(JobRole.ADMIN) === -1) {
					// Replace console.log by notif
					LOG.dev('You cannot update unless you are admin')
					handleClose()
				} else {
					// Edit member
					const response = await updateMember({
						company: companyRef.current!.value,
						username: userNameRef.current!.value,
						firstName: firstNameRef.current!.value,
						lastName: lastNameRef.current!.value,
						title: titleRef.current!.value,
						jobRoles: jobRoles.map(e => e.value),
						// masterApprover: isMasterApprover,
						departmentCode: departments?.value,
						departmentName: departments?.label,
						serialNumber: serialNumRef.current!.value
					})

					if (response._type === 'data' || response._type === 'generic_success') {
						handleClose()
					} else {
						// handle errors
					}
					// 	// departmentOwner: isDepartmentOwner
					// })
					// if (response._type === 'data' || response._type === 'generic_success') {
					// 	handleClose()
					// } else {
					// 	// handle errors
					// }
				}
			} else {
				const response = await inviteMember({
					company: companyRef.current!.value,
					username: userNameRef.current!.value,
					firstName: firstNameRef.current!.value,
					lastName: lastNameRef.current!.value,
					title: titleRef.current!.value,
					jobRoles: jobRoles.map(e => e.value),
					departmentCode: departments?.value,
					departmentName: departments?.label,
					serialNumber: serialNumRef.current!.value
					// departmentOwner: isDepartmentOwner
				})
				if (response._type === 'data' || response._type === 'generic_success') {
					handleClose()
				} else {
					// handle errors
				}
			}
		}
	}

	function jobRoleOptions() {
		return _.toArray(JobRole).map(e => ({ value: e, label: jobRoleLabel(e) }))
	}

	// function departmentOptions() {
	// 	return params?.departments.map(e => ({ value: e.code, label: e.name }))
	// }

	function handleClose() {
		if (params) {
			setParams(undefined)
		}
		setJobRoleError({
			error: false,
			errorMessage: ""
		})
		setJobRoles([])
		setDepartments(undefined)
		setDepartmentError({
			error: false,
			errorMessage: ""
		})
		setIsOpen(false)
		setIsMasterApprover(false)
		setIsMasterApproverError({
			error: false,
			errorMessage: ""
		})
		setIsDepartmentOwner(false)
		setIsDepartmentOwnerError({
			error: false,
			errorMessage: ""
		})
	}

	function renderCheckBox(
		checked: boolean,
		label: string,
		error: boolean,
		errorText: String,
		setValue: () => void,
	) {
		return (
			<FormControl>
				<FormControlLabel
					control={<Checkbox
						checked={checked}
						onChange={setValue}
						name={label}
					/>}
					label={label}
				/>
				<FormControl error={error}>
					<FormHelperText>{errorText}</FormHelperText>
				</FormControl>
			</FormControl>
		)
	}

	return (
		<ModalX isOpen={isOpen} onClose={handleClose}>
			<ModalXHeader>{params ? "Update Member" : "Invite Member"}</ModalXHeader>
			<ModalBody>
				<Form onSubmit={onSubmit} className={classes.form}>
					<CustomInput
						ref={companyRef}
						label={t("Hr.Company")}
						errorText=""
						placeholder="Type in company name"
						required={true}
						type="text"
						startingValue={params?.member?.company || user.organisation.name}
					/>
					<CustomInput
						ref={firstNameRef}
						label={t("Login.Name")}
						errorText=""
						placeholder="Type in member name"
						required={true}
						type="text"
						startingValue={params?.member?.firstName}
					/>
					<CustomInput
						ref={lastNameRef}
						label={t("Login.Surname")}
						errorText=""
						placeholder="Type in member name"
						required={true}
						type="text"
						startingValue={params?.member?.lastName}
					/>
					<CustomInput
						ref={titleRef}
						label={t("Modal.Job_Title")}
						errorText=""
						placeholder="Type in job title name"
						required={false}
						type="text"
						startingValue={params?.member?.title}
					/>
					<CustomInput
						ref={userNameRef}
						label={t("Login.Email")}
						errorText="Enter correct email format"
						placeholder="Type in email"
						required={true}
						type="text"
						regex={regexPatterns.email}
						startingValue={params?.member?.username}
					/>
					<CustomInput
						ref={serialNumRef}
						label={t("Modal.Id_Number")}
						errorText="Enter Correct Identity Number"
						placeholder="Type in Identity Number"
						required={false}
						type="text"
						startingValue={params?.member?.serialNumber}
					/>
					<FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>{t("Hr.Departments")}</p>
						<Select
							name="Departments"

							options={departmentOptions}
							value={departments}
							onChange={selected => {
								if (selected != null) {
									if (departmentError.error) {
										console.log('ERRRORR')
										setDepartmentError({ error: false, errorMessage: "" })
									}
									setDepartments(selected as { value: string, label: string })
								} else {
									setDepartments(undefined)
								}
							}}
							className={classes.select}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								})
							}}
						/>
						{departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>}
					</FormControl>
					<FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>{t("Hr.Job_Roles")}</p>
						<Select
							isMulti
							name="Job Roles"
							options={jobRoleOptions()}
							value={jobRoles}
							onChange={selected => {
								if (selected != null) {
									if (jobRoleError.error) {
										setJobRoleError({ error: false, errorMessage: "" })
									}
									setJobRoles(selected as { value: JobRole, label: string }[])
								} else {
									setJobRoles([])
								}
							}}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								})
							}}
							className={classes.select}
						/>
						{jobRoleError.error && <FormHelperText className={classes.selectError}>{jobRoleError.errorMessage}</FormHelperText>}
					</FormControl>
					{/* {renderCheckBox(
						isMasterApprover,
						"Master Approver",
						isMasterApproverError.error,
						isMasterApproverError.errorMessage,
						() => setIsMasterApprover(!isMasterApprover)
					)} */}
					{/* {renderCheckBox(
						isDepartmentOwner,
						"Department Owner",
						isDepartmentOwnerError.error,
						isDepartmentOwnerError.errorMessage,
						() => setIsDepartmentOwner(!isDepartmentOwner)
					)} */}
					<SubmitButton>{params ? "Update" : "Invite"}</SubmitButton>
				</Form>
			</ModalBody>
		</ModalX>
	)
})