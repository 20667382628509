import { Button, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { FormContext } from './Form'

type Props = {
  className?: string
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    submitButton: {
      backgroundColor: "#08ad6c",
      boxShadow: theme.shadows[2],
      padding: 8,
      paddingLeft: 15,
      paddingRight: 15,
      '&:focus': {
        outline: "none"
      },
      '&:hover': {
        backgroundColor: "#006C40",
      },
      color: 'white',
      width: '100%',
      maxWidth: 300,
    },
    circularProgress: {
      marginLeft: '10px',
      color: '#b5b5b5'
    }
  })
)

export function SubmitButton(props: React.PropsWithChildren<Props>) {
  const classes = useStyles()
  const context = React.useContext(FormContext)
  return (<div className={classes.buttonContainer}>
    <Button
      className={`${classes.submitButton} ${props.className}`}
      disabled={props.disabled || context.isLoading}
      type="submit"
    >
      {props.children} {context.isLoading && <CircularProgress className={classes.circularProgress} size={16} thickness={6} />}
    </Button>
  </div>
  )
}
