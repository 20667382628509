import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@material-ui/icons/Description';
import ListAltIcon from '@material-ui/icons/ListAlt';




export function InvoicingDrawer(props: RouteComponentProps) {
	const { t } = useTranslation();

	const drawerClasses = useDrawerStyle()
	return (
		<>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/invoicing/invoice")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/invoicing/invoice'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><DescriptionIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/invoicing/invoice' && drawerClasses.active}`} primary={'Invoice'} />
			</ListItem>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/invoicing/proforma-invoice")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/invoicing/proforma-invoice'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><DescriptionIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/invoicing/proforma-invoice' && drawerClasses.active}`} primary={'Proforma Invoice'} />
			</ListItem>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/invoicing/credit-note")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/invoicing/credit-note'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><DescriptionIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/invoicing/credit-note' && drawerClasses.active}`} primary={'Credit  note'} />
			</ListItem>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/invoicing/order")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/invoicing/order'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><DescriptionIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/invoicing/order' && drawerClasses.active}`} primary={'Order'} />
			</ListItem>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/invoicing/delivery-list")
			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/invoicing/order'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><ListAltIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/invoicing/delivery-list' && drawerClasses.active}`} primary={'Delivery list'} />
			</ListItem>



		</>
	)
}