import { createStyles, Hidden, IconButton, List, makeStyles, Theme, useTheme } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DrawerContext } from './AppWithDrawer'
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu'


export const DRAWER_WIDTH = 265
export const DRAWER_WIDTH_SM = 69


type Props = {
	mobileOpen: boolean,
	setMobileOpen: () => void
	leftDrawerOpen: boolean
	setLeftDrawerOpen: () => void
	routeProps: RouteComponentProps
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			color: "white",
			paddingTop: '12px',
		},
		drawer: {
			// width: DRAWER_WIDTH,
			zIndex: 10,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: DRAWER_WIDTH,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: DRAWER_WIDTH_SM,
			},
		},
		// drawer: {
		// 	[theme.breakpoints.up('sm')]: {
		// 		// width: DRAWER_WIDTH,
		// 		flexShrink: 1,
		// 	}
		// },
		drawerPaper: {
			// width: DRAWER_WIDTH,
			backgroundColor: "rgba(34, 42, 69, 0.96)",
		},
		toolbar: {
			...theme.mixins.toolbar,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingLeft: '16px',
			minHeight: '56px',
		},
		subHeader: {
			fontSize: 12,
			color: 'white',
			fontWeight: 500,
			marginTop: 10,
			opacity: 0.8
		},
		logoAnim: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen + 500,
			}),
		},
		logoPadding: {
			paddingBottom: '4px',
			paddingLeft: 10,
		},
		iconPadding: {
			height: 28,
			paddingLeft: 4,
		},
		menuButton: {
			marginRight: theme.spacing(2),
			// [theme.breakpoints.up('sm')]: {
			// 	display: 'none',
			// },
			color: 'white',
			letterSpacing: '20px'
		},
	}),
)

export function MainDrawer({ mobileOpen, setMobileOpen, routeProps, leftDrawerOpen, setLeftDrawerOpen }: Props) {
	const theme = useTheme()
	const classes = useStyles(theme)

	const drawerContext = React.useContext(DrawerContext)

	function renderInner() {
		return (
			<>
				<div className={classes.toolbar}>
					{leftDrawerOpen === true ?
						<a href="https://mygide.com/">
							<img src="/AiBERT_white2.png" alt="logo" id="logo" className={`${classes.logoAnim} ${classes.logoPadding}`} />
						</a>
						:
						<img src="/aibert-Icon.png" alt="logo" className={`${classes.logoAnim} ${classes.iconPadding}`} onClick={(e) => {
							e.preventDefault()
							setLeftDrawerOpen()
						}} />
					}
					{leftDrawerOpen && <IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={setLeftDrawerOpen}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					}
				</div>
				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
					className={classes.root}
					disablePadding
				>
					{drawerContext.renderContent}
				</List>
			</>
		)
	}

	return (
		<nav className={classes.drawer}>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			{/* <Hidden smUp implementation="css">
				<Drawer
					variant="persistent"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					onClose={setMobileOpen}
					open={leftDrawerOpen}
					classes={{
						paper: classes.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{renderInner()}
				</Drawer>
			</Hidden> */}
			{/* <Hidden xsDown implementation="css"> */}
			<Drawer

				className={clsx(classes.drawer, {
					[classes.drawerOpen]: leftDrawerOpen,
					[classes.drawerClose]: !leftDrawerOpen,
				})}
				classes={{
					paper: clsx(classes.drawerPaper, {
						[classes.drawerOpen]: leftDrawerOpen,
						[classes.drawerClose]: !leftDrawerOpen,
					}),
				}}
				variant="permanent"
				open={leftDrawerOpen}
			>
				{renderInner()}
			</Drawer>
			{/* </Hidden> */}
		</nav>
	)
}
