import { Popover } from '@material-ui/core'
import { TFunction } from 'i18next';
import * as _ from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next';

export enum ActionName {
	EDIT = "edit",
	REMOVE = "delete",
	SEND_BACK = "send-back",
	ON_HOLD = "on-hold",
	SUBMIT_DOCUMENTATION = "submit-documentation",
	VIEW = "view",
	PRINT = "print",
	SEND_FOR_APPROVAL = "send-for-approval",
	SEND_TO_ATTENTION = "send-to-attention"
}

export type Action = {
	actionName: ActionName
	action: () => void
}

export function createAction(
	actionName: ActionName,
	action: () => void
) {
	return {
		actionName,
		action
	}
}

function forActionName(actionName: ActionName, t: TFunction) {
	switch (actionName) {
		case ActionName.EDIT: return t('Common.Edit')
		case ActionName.ON_HOLD: return t('Common.On_Hold')
		case ActionName.REMOVE: return t('Common.Remove')
		case ActionName.SEND_BACK: return t('Common.Send_Back')
		case ActionName.SUBMIT_DOCUMENTATION: return t('Common.Submit_Doc')
		case ActionName.VIEW: return t('Common.View')
		case ActionName.PRINT: return t('Common.Print')
		case ActionName.SEND_FOR_APPROVAL: return t('Common.Ready_Approval')
		case ActionName.SEND_TO_ATTENTION: return t('Common.Back_To_Attention')
		default: throw Error()
	}
}

type Params = {
	anchorElement: Element
	actions: Action[]
}

export type ItemActionPopover = {
	show: (params: Params) => void
}

export const ItemActionPopover = React.forwardRef((props: {}, ref: React.Ref<ItemActionPopover>) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | null>(null)

	const { t } = useTranslation();

	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			setIsOpen(true)
			setParams(params)
		}
	}))

	function handleClose() {
		setIsOpen(false)
		setParams(null)
	}

	if (params) {
		const optionNames = _.toArray(params.actions.map(e => e.actionName))
		return (
			<Popover
				anchorEl={params.anchorElement}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={handleClose}
				open={isOpen}
			>
				{optionNames.map((a, i) => (
					<div
						style={{
							padding: '10px 20px 10px 20px',
							cursor: "pointer",
							minWidth: 70,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						key={a}
						onClick={() => {
							params.actions[i].action()
							handleClose()
						}}>{forActionName(a, t)}</div>
				))}
			</Popover>
		)
	} else {
		return null
	}
})