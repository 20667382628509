import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles({
	content: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'start',
		width: '100%',
		borderRadius: 4
	},
	header: {
		cursor: 'pointer',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	icon: {

	},
	childrenContainer: {
		marginTop: 10,
		padding: '10px 10px 0 10px',
		width: '100%'
	},
	childrenContainerSecondary: {
		padding: '5px 10px 0 10px',
		width: '100%'
	}

})

type Props = {
	className?: string,
	style?: React.CSSProperties,
	startExpanded?: boolean,
	renderHeader: () => JSX.Element
	childrenContainer?: boolean,
}

export function ExpandableSection({ children, className, style, startExpanded, renderHeader, childrenContainer }: React.PropsWithChildren<Props>) {
	const classes = useStyles()
	const [isExpanded, setIsExpanded] = React.useState(false)

	React.useEffect(() => {
		if (startExpanded) {
			setIsExpanded(true)
		}
	}, [])

	const renderExpandIcon = () => {
		if (isExpanded) {
			return <ExpandMoreIcon className={classes.icon} />
		} else {
			return <ExpandLessIcon className={classes.icon} />
		}
	}

	return (
		<div
			className={`${classes.content} ${className}`}
			style={style}
		>
			<div
				className={classes.header}
				onClick={() => setIsExpanded(!isExpanded)}>
				<div className={classes.content}>
					{renderHeader && renderHeader()}
				</div>
				{renderExpandIcon()}
			</div>
			{isExpanded && <div className={childrenContainer == true ? classes.childrenContainerSecondary : classes.childrenContainer}>{children}</div>}
		</div>
	)
}