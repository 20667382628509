import { makeStyles, Theme, createStyles } from '@material-ui/core'

type Props = {
	modalHeight?: number | string,
	modalWidth?: number | string,
	maxModalHeight?: number | string,
	maxModalWidth?: number | string
}

export function useModalStyles(props?: Props) {
	return makeStyles((theme: Theme) =>
		createStyles({
			paper: {
				position: "absolute",
				outline: 0,
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[5],
				maxHeight: props?.modalHeight,

			},
			paperCentered: {
				top: "50%",
				left: "50%",
				transform: 'translate(-50%, -50%)',
				display: 'flex'
			},
			modal: {
				left: "50%",
				transform: 'translate(-50%, 0)',
				position: 'relative',
				width: props?.modalWidth || 'auto',
				borderRadius: 0,
				display: 'inline-block'
			},
			modalHeader: {
				width: '100%',
				textAlign: 'center',
				padding: '16px',
				paddingBottom: '0px',
				fontSize: 20,
				// borderBottom: '1px solid #e3e3e3',
				display: 'flex',
			},
			modalBody: {
				position: 'relative',
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				overflow: 'hidden'
			},
			modalFooter: {
				width: '100%',
				display: 'flex',
				padding: 10
			},
			closeButton: {
				position: 'absolute',
				top: 0,
				right: 0,
				marginTop: 15,
				marginRight: 15,
				cursor: 'pointer'
			},
			sidebar: {
				paddingTop: 20,
				width: 200,
				backgroundColor: '#f4f5f7',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				alignSelf: 'stretch',
				alignItems: 'center',
				justifyContent: 'space-between'
			},
			sidebarHeader: {
				fontSize: 16,
				fontWeight: 500
			},
			sidebarContent: {
				padding: 10,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'start',
				height: '100%',
				width: '100%'
			}
		})
	)()
}