import { Checkbox, createStyles, FormControl, FormControlLabel, FormHelperText, makeStyles, Theme } from '@material-ui/core'
import { DepartmentType, Supplier } from 'api/api-types'
import { getDepartments, } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX, ModalXHeader } from 'elements/ModalX'
import * as _ from 'lodash'
import { LOG } from 'logger/Log'
import * as React from 'react'
import Select, { OptionsType } from 'react-select'
import { ModalBody } from 'reactstrap'
import { regexPatterns } from 'utils/Validation'
// import { jobRoleLabel } from './Members'

// Change type of membersOption to {value: member, label: member}[], it will be cleaner, having less to typecast and less code, maybe even faster
//Then for then when setting setMemberOption() i will need to create {value: member, label: member}[] from array<member>
//Introduce selectFormatType
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			padding: 30,
			maxWidth: 600
		},
		formControl: {
			marginBottom: 10,
		},
		selectLabel: {
			color: 'rgba(0, 0, 0, 0.54)'
		},
		selectError: {
			color: 'red'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
		}
	}))

type Params = {
	suppliers: Supplier[]
	supplier?: Supplier,
	refetch?: () => void,
}

export type SuppliersModal = {
	show: (params: Params) => void
}

export const SuppliersModal = React.forwardRef((props: {}, ref: React.Ref<SuppliersModal>) => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<Params | null>(null)


	// const [jobRoleError, setJobRoleError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })
	// const [jobRoles, setJobRoles] = React.useState<{ value: JobRole, label: string }[]>([])
	const [departments, setDepartments] = React.useState<{ value: DepartmentType, label: string }[]>([])
	const [department, setDepartment] = React.useState<{ value: DepartmentType, label: string }>()


	// const [departmentError, setDepartmentError] = React.useState({
	// 	error: false,
	// 	errorMessage: ""
	// })
	const [suppliers, setSuppliers] = React.useState<{ value: Supplier, label: string }[]>([])
	const [supplier, setSupplier] = React.useState<{ value: Supplier, label: string } | null>(null)
	const icoRef = React.useRef<CustomInput>(null)






	// const nameRef = React.useRef<CustomInput>(null)
	// const aliasRef = React.useRef<CustomInput>(null)

	// function findMemberByEmail(manageUsername: string) {
	// 	//check for this if it can be undefined or null at any time
	// 	return membersOptions.find(member => member.value.username === manageUsername)!
	// }
	// function findMembersByEmail(manageUsernames: string[]) {
	// 	//check for this if it can be undefined or null at any time
	// 	return membersOptions.filter(member => !manageUsernames.includes(member.value.username))
	// }

	// function toSelectFormat(members: Member[]) {
	// 	const selectFormat = [] as { value: Member, label: string }[]
	// 	members.forEach(member => selectFormat.push({ value: member, label: `${member.firstName} ${member.lastName}` }))
	// 	return selectFormat
	// }

	const fetchDepartments = async () => {
		const data = await getDepartments()
		if (data._type === 'data') {
			setDepartments(data.data.map(e => {
				return {
					value: e,
					label: e.name
				}
			}))
		}
	}
	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			setParams(params)

			setSuppliers(params.suppliers.map(e => {
				return {
					value: e,
					label: e.companyName
				}
			}))
			//This will trigger on edit or if something selected
			if (params.supplier) {
				setSupplier({ value: params.supplier, label: params.supplier.companyName })

			}
			fetchDepartments()
			// }
			setIsOpen(true)
		}
	}))

	//pitati stefani da li ovo treba u  show mozda


	async function onSubmit() {
		// const companyError = companyRef.current?.checkErrors()
		// const firstNameError = firstNameRef.current?.checkErrors()
		// const lastNameError = lastNameRef.current?.checkErrors()
		// const titleError = titleRef.current?.checkErrors()
		// const usernameError = userNameRef.current?.checkErrors()

		// if (jobRoles.length <= 0) {
		// 	setJobRoleError({
		// 		error: true,
		// 		errorMessage: "Field is required"
		// 	})
		// }

		// if (departments.length <= 0) {
		// 	setDepartmentError({
		// 		error: true,
		// 		errorMessage: "Field is required"
		// 	})
		// 	return
		// }


		//Handle errors here
		// if (companyError || firstNameError || lastNameError || titleError || usernameError || jobRoleError.error || departmentError.error) {
		// 	return
		// } else {


		// This is editing, if there are params it means that edit mode is active
		// We make sure that that is the case
		// Maybe we shouldnt make difference between edit mode and normal
		// if (params) {
		//I should get authContext(or userContext) here or pass it thrue props
		//
		// This if to check if admin, might be redundant
		// if (params.member.jobRoles.indexOf(JobRole.ADMIN) === -1) {
		// 	// Replace console.log by notif
		// 	LOG.dev('You cannot update unless you are admin')
		// 	handleClose()
		// } else {

		// console.log('Enter submit')
		// console.log('Params for req', {
		// 	companyName: supplier?.value.companyName,
		// 	companyIco: icoRef.current!.value,
		// 	departmentCode: departments[0].value.code,
		// 	departmentName: departments[0].value.name,
		// 	departmentAlias: departments[0].value.alias,

		// })

		// We aren't doing this for now

		// if (supplier?.value.companyName && icoRef.current != null && departments) {
		// 	const response = await createCompanyContract({
		// 		companyName: supplier?.value.companyName,
		// 		ico: icoRef.current.value,
		// 		departmentCode: departments[0].value.code,
		// 		departmentName: departments[0].value.name,
		// 		departmentAlias: departments[0].value.alias,
		// 	}
		// 	)
		// 	console.log('finishedResonse')
		// 	console.log(response)

		// 	if (response._type === 'data' || response._type === 'generic_success') {
		// 		handleClose()
		// 	} else {
		// 		// handle errors
		// 		console.error(response)
		// 	}
	}
	// }
	// else {
	// console.log('Entered u CreateDepartment')
	// console.log('code', codeRef.current!.value)
	// const response = await postDepartments([{
	// 	code: codeRef.current!.value,
	// 	alias: aliasRef.current?.value || null,
	// 	name: nameRef.current!.value,
	// 	//manager and parent state doesn't have to be array
	// 	manager: manager ? manager?.value.username : null,
	// 	hasEmployees: employees.map((e) => e.value.username),
	// 	parent: parent ? parent.value : null
	// }])
	// if (response._type === 'data' || response._type === 'generic_success') {
	// 	handleClose()
	// } else {
	// 	// handle errors
	// 	console.error(response)
	// }

	// // }
	// }

	// function departmentOptions() {
	// 	return _.toArray(OrgDepartment).map(e => ({ value: e, label: e }))
	// }

	function handleClose() {
		if (params) {
			setParams(null)
		}
		//Hanndle arrors later
		// setJobRoleError({
		// 	error: false,
		// 	errorMessage: ""
		// })
		// setJobRoles([])
		// setDepartments([])
		// setDepartmentError({
		// 	error: false,
		// 	errorMessage: ""
		// })

		setSuppliers([])
		setSupplier(null)
		setIsOpen(false)
		// setIsMasterApprover(false)
		// setIsMasterApproverError({
		// 	error: false,
		// 	errorMessage: ""
		// })
		// setIsDepartmentOwner(false)
		// setIsDepartmentOwnerError({
		// 	error: false,
		// 	errorMessage: ""
		// })
	}

	function renderCheckBox(
		checked: boolean,
		label: string,
		error: boolean,
		errorText: String,
		setValue: () => void,
	) {
		return (
			<FormControl>
				<FormControlLabel
					control={<Checkbox
						checked={checked}
						onChange={setValue}
						name={label}
					/>}
					label={label}
				/>
				<FormControl error={error}>
					<FormHelperText>{errorText}</FormHelperText>
				</FormControl>
			</FormControl>
		)
	}

	return (
		<ModalX isOpen={isOpen} onClose={handleClose}>
			<ModalXHeader>{params ? "Update Supplier" : "Create Supplier"}</ModalXHeader>
			<ModalBody>
				<Form onSubmit={onSubmit} className={classes.form}>
					<FormControl className={classes.formControl}>
						<p className={classes.selectLabel}>Supplier</p>
						<Select
							key="tst4"
							name="Supplier"
							options={suppliers}
							value={supplier}
							onChange={selected => {
								// if (selected != null) {
								// 	if (departmentError.error) {
								// 		setDepartmentError({ error: false, errorMessage: "" })
								// 	}
								// 	setDepartments(selected as { value: OrgDepartment, label: string }[])
								// } else {
								// setDepartments([])
								setSupplier(selected as { value: Supplier, label: string })
								// }
							}}
							className={classes.select}
							styles={{
								control: (base, props) => ({
									...base,
									border: 'none',
									boxShadow: 'none'
								}),
								menu: provided => ({ ...provided, zIndex: 10 })
							}}
						/>
						{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
					</FormControl>
					<CustomInput
						key="tst"
						ref={icoRef}
						label="ICO"
						errorText=""
						placeholder="Department ICO"
						required={true}
						type="text"
						startingValue={supplier?.value.compIco}
						disabled={true}
					/>
					<SubmitButton>{params ? "Update" : "Create"}</SubmitButton>
				</Form>
			</ModalBody>
		</ModalX>
	)

})