import { DrawerContext } from "drawer/AppWithDrawer"
import { Container, Page } from "elements/Container"
import * as React from "react"
import "styles/feed.css"
// import { Page } from "react-pdf"
import { RouteComponentProps } from "react-router-dom"
import { HRDrawer } from "./HRDrawer"
import { Members } from "./members/Members"
import { Departments } from "./departments/Departments"
import { AccountX } from "account/Account"
import { ApprovalGroups } from "./approvals/ApprovalGroups"
// import { ProcurementDrawer } from "./ProcurementDrawer"

type RouteParams = {
	sub: "overview" | "employees" | "departments" | "approval-groups"
}

export function HRPage(props: RouteComponentProps<RouteParams>) {
	const drawerContext = React.useContext(DrawerContext)
	React.useEffect(() => {
		drawerContext.setRenderContent(() => (
			<HRDrawer {...props} />
		))
	}, [props.location])
	let content
	if (props.match.params.sub === "employees") {
		content = <Members />
	} else if (props.match.params.sub === "departments") {
		content = <Departments />
	} else if (props.match.params.sub === "approval-groups") {
		content = <ApprovalGroups />
	} else {
		content = <AccountX />
	}

	return (
		<Container page={Page.HR} title="Procurement">
			{content}
		</Container>
	)

}

