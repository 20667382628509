import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { getItemDocument } from 'api/ApiServices';
import * as React from 'react';

const useStyles = makeStyles({
	documentWrapper: {
		width: '100%',
		minHeight: 800,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		alignSelf: 'stretch'
	},
	document: {
		width: '100%',
		height: '100%',
		flexGrow: 1
	},
	button: {
		backgroundColor: "#08ad6c",
		'& :focus': {
			outline: "none"
		},
		'& :hover': {
			backgroundColor: "#4bc72c",
		},
		color: 'white'
	}
})

type Props = {
	itemId: string
}

export const PdfDocument = (props: Props) => {
	const classes = useStyles()
	const [data, setData] = React.useState<string | "loading" | null>("loading")

	const fetchData = async () => {
		setData("loading")
		const response = await getItemDocument(props.itemId)
		if (response._type === "data") {
			setData(response.data)
		} else {
			setData(null)
		}
	}

	React.useEffect(() => {
		fetchData()
	}, [props.itemId])

	let content
	if (data === 'loading') {
		content = <CircularProgress />
	} else if (!data) {
		content = <>
			<p>Ooops something went wrong, please try again</p>
			<Button style={{ width: '100%', backgroundColor: "" }} onClick={fetchData}>Reload</Button>
		</>
	} else {
		content = <object className={classes.document} data={`data:application/pdf;base64,${data}`} />
	}

	return <div className={classes.documentWrapper}>
		{content}
	</div>
}