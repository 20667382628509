import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import GroupIcon from '@material-ui/icons/Group';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

export function HRDrawer(props: RouteComponentProps) {
	const drawerClasses = useDrawerStyle()
	const { t } = useTranslation();

	return (
		<>
			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/yellow-pages/employees")

			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/yellow-pages/employees'}

			>
				<ListItemIcon className={drawerClasses.itemIcon}><AccountCircleIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/yellow-pages/employees' && drawerClasses.active}`} primary={t('Hr.Employees')} />
			</ListItem>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/yellow-pages/departments")

			}}
				classes={{
					selected: drawerClasses.selected,
				}}
				selected={props.location.pathname === '/yellow-pages/departments'}

			>
				<ListItemIcon className={drawerClasses.itemIcon}><GroupIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/yellow-pages/departments' && drawerClasses.active}`} primary={t('Hr.Departments')} />
			</ListItem>

			<ListItem button className={drawerClasses.listItem} onClick={_ => {
				props.history.push("/yellow-pages/approval-groups")
			}} classes={{
				selected: drawerClasses.selected,
			}}
				selected={props.location.pathname === '/yellow-pages/approval-groups'}
			>
				<ListItemIcon className={drawerClasses.itemIcon}><GroupWorkIcon /></ListItemIcon>
				<ListItemText className={`${drawerClasses.listItemText} ${props.location.pathname === '/yellow-pages/approval-groups' && drawerClasses.active}`} primary={t('Account.Approval_Groups')} />
			</ListItem>



		</>
	)
}