//For now needs to stay, maybe deleted in future or just upgraded
import * as React from 'react'

export type FeedFilter = "all" | "incomplete_documentation" | "submision"
	| "duplicates" | "approved" | "rejected" | "attention" | "approving_progress"
	| "on_hold" | "returned" | "classified" | "conflicting"

export interface FilterContext {
	activeFilter: FeedFilter
	setActiveFilter: (filter: FeedFilter) => void
}

export const FilterContext = React.createContext<FilterContext>(undefined as any)

export function AppWithFilters(props: React.PropsWithChildren<{}>) {
	const [activeFilter, setActiveFilter] = React.useState<FeedFilter>("all")

	//Ask Zex about loading 
	// React.useEffect(() => {
	// 	if (filters !== 'loading' && filters !== null) {
	// 		setCustomFilters(filters)
	// 	}
	// }, [filters])



	const filterContextValue: FilterContext = {
		activeFilter: activeFilter,
		setActiveFilter: (filter) => {
			setActiveFilter(filter)
		}
	}


	return (
		<FilterContext.Provider value={filterContextValue}>
			{props.children}
		</FilterContext.Provider>
	)
}