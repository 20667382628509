import { createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// type RouteParams = {
// 	sub: "feed" | "procurement" | "inventory" | "yellow-pages" | "settings"
// }

// const useStyle = makeStyles((theme: Theme) =>
// 	createStyles({
// 		centered: {
// 			textAlign: 'center'
// 		},

// 		paddingBottom: {
// 			paddingBottom: '16px'
// 		},
// 		paddingLeft: {
// 			paddingLeft: '27px'
// 		}
// 	})
// )

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		padding: {
			padding: 8
		},
		paddingBottom: {
			paddingBottom: 8
		},
		paddingTop: {
			paddingTop: 8
		}
	})
)

export function Help() {
	const { t, i18n } = useTranslation();
	const classes = useStyle()

	const lang = i18n.language

	// let content
	return (<div>
		<Typography variant='h6' className={classes.padding}>{t("Help.Intro.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Intro.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}><Link to={lang == 'en' ? "Pdfs/AiBERTHELPEN.pdf" : "Pdfs/AiBERTHELPSK.pdf"} target="_blank" download>{t("Help.Intro.Part_2_Link")} </Link>
			{t("Help.Intro.Part_2")}</Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Instance.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Instance")}</Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Service.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Service.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Service.Part_2")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Service.Part_3")}</Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Department.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Department.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Department.Part_2")} <a href="mailto:customerservice@mygide.uk">customerservice@mygide.uk</a></Typography>
		<Typography variant='body2' className={classes.padding}>
			<ol>
				<li className={classes.paddingBottom}>{t("Help.Department.li_1")}</li>
				<li >{t("Help.Department.li_2")}</li>
			</ol>
		</Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Member.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_2")} </Typography>
		<Typography variant='body2' className={classes.padding}>
			<ol>
				<li className={classes.paddingBottom}>{t("Help.Member.li_1")}</li>
				<li className={classes.paddingBottom}>{t("Help.Member.li_2")} <a href="mailto:customerservice@mygide.uk">customerservice@mygide.uk</a></li>
				<li >{t("Help.Member.li_3")}</li>
			</ol>
		</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_3")} </Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_4")} </Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_5")} </Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_6")} </Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Member.Part_7")} </Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.AG.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.AG.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.AG.Part_2")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.AG.Part_3")}</Typography>
		<Typography variant='body2' className={classes.padding}>
			<ol>
				<li className={classes.paddingBottom}>{t("Help.AG.li_1")}</li>
				<li className={classes.paddingBottom}>{t("Help.AG.li_2")} <a href="mailto:customerservice@mygide.uk">customerservice@mygide.uk</a></li>
				<li className={classes.paddingBottom}>{t("Help.AG.li_3")}</li>
				<li >{t("Help.AG.li_4")}</li>
			</ol>
		</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.AG.Part_4")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.AG.Part_5")} <a href="mailto:customerservice@mygide.uk">customerservice@mygide.uk</a></Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Listener.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Listener.Part_1_1")} <a href="mailto:AIS@mygide.uk">AIS@mygide.uk</a> {t("Help.Listener.Part_1_2")} <a href="mailto:customerservice@mygide.uk">customerservice@mygide.uk</a></Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Drag.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Drag.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Drag.Part_2_1")} <CloudUploadIcon /> {t("Help.Drag.Part_2_2")}</Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Process.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Process.Part_1")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Process.Part_2")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Process.Part_3")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Process.Part_4")}</Typography>
		<Typography variant='body2' className={classes.padding}>
			<ol>
				<li>{t("Help.Process.li_1")}
					<ul>
						<li className={`${classes.paddingBottom} ${classes.paddingTop}`}>{t("Help.Process.l1_1_1")}</li>
						<li className={classes.paddingBottom}>{t("Help.Process.li_1_2")}</li>
						<li className={classes.paddingBottom}>{t("Help.Process.li_1_3")}</li>
					</ul>
				</li>
				<li>{t("Help.Process.li_2")}
					<ul>
						<li className={`${classes.paddingBottom} ${classes.paddingTop}`}>{t("Help.Process.li_2_1")}</li>
						<li className={classes.paddingBottom}>{t("Help.Process.li_2_2")}</li>
					</ul>
				</li>
				<li className={classes.paddingBottom}>{t("Help.Process.li_3")} <a href="mailto:customerservice@mygide.uk">customerservice@mygide.uk</a></li>
				<li>{t("Help.Process.li_4")}
					<ul>
						<li className={`${classes.paddingBottom} ${classes.paddingTop}`}>{t("Help.Process.li_4_1")}</li>
						<li className={classes.paddingBottom}>{t("Help.Process.li_4_2")}</li>
						<li >{t("Help.Process.li_4_3")}</li>
					</ul>
				</li>
			</ol>
		</Typography>
		<Divider />

		<Typography variant='h6' className={classes.padding}>{t("Help.Editing.Heading")}</Typography>
		<Typography variant='body2' className={classes.padding}>{t("Help.Editing.Part_1")}</Typography>





	</div>)

}