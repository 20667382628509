
import { DrawerContext } from "drawer/AppWithDrawer"
import { Container, Page } from "elements/Container"
import * as React from "react"
import "styles/feed.css"
// import { Page } from "react-pdf"
import { RouteComponentProps } from "react-router-dom"
import { InvoicingDrawer } from "./InvoicingDrawer"
import { Invoicer } from "./Invoicer"
import { DocTypesEnum } from "api/api-types"
// import { ProcurementDrawer } from "./ProcurementDrawer"

type RouteParams = {
	sub: "invoice" | "proforma-invoice" | "credit-note" | "order" | "delivery-list"
}

export function InvoicingPage(props: RouteComponentProps<RouteParams>) {
	const drawerContext = React.useContext(DrawerContext)
	React.useEffect(() => {
		drawerContext.setRenderContent(() => (
			<InvoicingDrawer {...props} />
		))
	}, [props.location])
	let content
	if (props.match.params.sub === "invoice") {
		content = <Invoicer type={DocTypesEnum.INVOICE} />
	} else if (props.match.params.sub === "proforma-invoice") {
		content = <Invoicer type={DocTypesEnum.PROFORMA_INVOICE} />
	} else if (props.match.params.sub === "credit-note") {
		content = <Invoicer type={DocTypesEnum.CREDIT_NOTE} />
	} else if (props.match.params.sub === "order") {
		content = <Invoicer type={DocTypesEnum.ORDER} />
	} else if (props.match.params.sub === "delivery-list") {
		content = <Invoicer type={DocTypesEnum.DELIVERY_LIST} />
	}
	else {
		content = null
	}

	return (
		<Container page={Page.PROCUREMENT} title="Procurement">
			{content}
		</Container>
	)

}

