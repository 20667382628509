import { Organisation } from 'api/api-types';
import { getOrganisation } from 'api/ApiServices';
import { useApi } from 'api/useApi';
import { AppWithDrawer } from 'drawer/AppWithDrawer';
import { getPackedSettings } from 'http2';
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';

export interface UserContext {
	organisation: Organisation
	refetch: () => Promise<void>
}
export const UserContext = React.createContext<UserContext>(undefined as any)

export function AppWithUser(props: RouteComponentProps) {
	const [organization, refetch] = useApi({
		apiRequest: getOrganisation
	})

	if (organization === "loading" || organization === null)  {
		return null
	
	} else {
		return <UserContext.Provider
			value={{
				organisation: organization,
				refetch: refetch
			}}>
			<AppWithDrawer {...props} />
		</UserContext.Provider>
	}
}