import { List, ListItem, ListItemText } from '@material-ui/core'
import { AggregationNode, AggregationParams } from 'api/api-types'
import { ExpandableListItem } from 'elements/ExpandableListItem'
import * as _ from 'lodash'
import { useDrawerStyle } from 'main/useDrawerStyle'
import * as React from 'react'
import { CHUNK_SIZE } from './AnalyticsPage'

type Props = {
	aggregationNode: AggregationNode
	aggregationNodeChildren: AggregationNode[]
	aggregationValueListId: number
	depth?: number
	setParams: React.Dispatch<React.SetStateAction<AggregationParams | null>>
}

export function AggregationTreeNode(props: Props) {
	const drawerClasses = useDrawerStyle()
	const { aggregationNode, aggregationNodeChildren, aggregationValueListId, depth, setParams } = props

	var lvl = depth
	if (depth === undefined) {
		lvl = 1
	} else {
		lvl = depth + 1
	}

	if (aggregationNodeChildren.length > 0) {
		return <ExpandableListItem
			key={`aggregation-node-${aggregationNode.node_caption}`}
			className={`${drawerClasses.listItem}`}
			text={aggregationNode.node_caption}
			onClick={async () => {
				const params = {
					value_list_id: aggregationValueListId,
					node_id: aggregationNode.node_id,
					chunk_offset: 0,
					chunk_size: CHUNK_SIZE
				}
				setParams(params)
			}}
			style={{ paddingLeft: lvl * 20 }}
		>
			<List component="div" disablePadding>
				{aggregationNodeChildren.map(e => (
					<AggregationTreeNode
						aggregationNode={e}
						aggregationNodeChildren={e.children}
						aggregationValueListId={aggregationValueListId}
						setParams={setParams}
						depth={depth}
					/>
				))}
			</List>
		</ExpandableListItem>
	} else {
		return <ListItem
			key={`${aggregationNode.node_caption}`}
			button
			className={`${drawerClasses.nested} ${drawerClasses.listItem}`}
			onClick={async () => {
				const params = {
					value_list_id: aggregationValueListId,
					node_id: aggregationNode.node_id,
					chunk_offset: 0,
					chunk_size: CHUNK_SIZE
				}
				setParams(params)
			}}
			style={{ paddingLeft: lvl * 20 }}
		>
			<ListItemText className={`${drawerClasses.listItemText}`}>{aggregationNode.node_caption}</ListItemText>
		</ListItem>
	}
}