import { Button, Checkbox, createStyles, FormControl, FormControlLabel, makeStyles, MenuItem, Modal, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// import { Suppliers } from 'account/suppliers/Suppliers'
import { VerifiedSuppliers, DepartmentType, DocumentationEnum, JobRole, Member, Supplier, CompanyContract, ApprovalGroupType, ApprovaGroupApprover } from 'api/api-types'
import { createApprovalGroup, getCompanyContracts, getDepartments, getSuppliers } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { SelectInput } from 'elements/form/SelectInput'
import * as _ from 'lodash'
import { LOG } from 'logger/Log'
import * as React from 'react'
import Select from 'react-select'
import { useModalStyles } from 'styles/useModalStyle'
import { Form } from 'elements/form/Form'
import { values } from 'lodash'


const MODAL_WIDTH = 600

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			minWidth: '100%',
			marginTop: '4px',
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		modal: {
			left: "50%",
			transform: 'translate(-50%, 0)',
			position: 'relative',
			width: MODAL_WIDTH || 'auto',
			borderRadius: 0,
			display: 'flex',
			flexDirection: 'column'
		},
		modalContent: {
			// marginTop: 50,
			marginBottom: 'auto',
			// paddingBottom: 20,
			// width: MODAL_WIDTH - 100,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			flexWrap: 'wrap'
		},
		//change the name to succesButton
		addNewButton: {
			alignSelf: 'center',
			width: '100%',
			maxWidth: 300,
			marginTop: '4px',
			marginBottom: '32px',
			// minWidth: MODAL_WIDTH - 100,
		},
		members: {
			marginTop: 20,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			maxHeight: 300,
			width: '100%',
			overflow: 'scroll'
		},
		memberRow: {
			padding: 10,
			display: 'flex',
			flexDirection: 'row',
			minWidth: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: '1px solid rgba(224, 224, 224, 1)',
		},
		memberCheckbox: {
			marginLeft: 'auto',
			justifySelf: 'flex-end'
		},
		memberColumn: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			minWidth: '100%',
		},
		memberusername: {
			width: MODAL_WIDTH * 0.4,
			textAlign: 'center'
		},
		memberTitle: {
			width: MODAL_WIDTH * 0.2,
			textAlign: 'center'
		},
		selectLabel: {
			marginBottom: '8px',
			color: 'rgba(0, 0, 0, 0.54)'
		},
		selectError: {
			color: 'red'
		},
		select: {
			border: '2px solid rgb(237, 237, 237)',
			borderRadius: 0,
			// width: '55%'
		},
		form: {
			padding: 30,
			maxWidth: 600
		},
		formControlPadding: {
			paddingTop: '16px'
		}
	})
)

export type Props = {
	refetch: () => void
}

export type Params = {
	members: Member[],
	approvalGroup?: ApprovalGroupType

}

export type AddNewGroupModal = {
	show: (params: Params) => void
}

type DataState = {
	departments: DepartmentType[],
	companyContracts: CompanyContract[]
}

type extendedSupplier = {

}
export const AddNewGroupModal = React.forwardRef((props: Props, ref: React.Ref<AddNewGroupModal>) => {
	const classes = useStyles()
	const modalClasses = useModalStyles({ modalWidth: MODAL_WIDTH })
	// const [department, setDepartment] = React.useState<DepartmentType | null>(null)
	const [departmentError, setDepartmentError] = React.useState({
		error: false,
		errorMessage: ""
	})
	const [isOpen, setIsOpen] = React.useState(true)
	const [params, setParams] = React.useState<Params | null>(null)
	const [data, setData] = React.useState<DataState | null>()

	const [docRequiered, setDocRequiered] = React.useState<{ value: DocumentationEnum, label: string }[]>([])
	const [approvers, setApprovers] = React.useState<{ value: ApprovaGroupApprover, label: string }[]>([])
	const [masterApprovers, setMasterApprovers] = React.useState<{ value: ApprovaGroupApprover, label: string }[]>([])
	const [department, setDepartment] = React.useState<{ value: string, label: string }>()
	const [suppliers, setSuppliers] = React.useState<{ value: Omit<CompanyContract, 'updatedAt'>, label: string }[]>([])
	const [hasSupplier, setHasSupplier] = React.useState(true)

	// const [suppliersInfo, setSuppliersInfo] = React.useState<VerifiedSuppliers & { contractNumber: string }[]>()



	// const checkedMembers = React.useRef<Map<string, Member>>(new Map())
	// const nameRef = React.useRef<CustomInput>(null)


	const fetchData = async () => {
		const departments = await getDepartments()
		const suppliers = await getCompanyContracts()
		if (suppliers._type === 'data' && departments._type === 'data') {
			//Set ParamsState here
			setData({ departments: departments.data, companyContracts: suppliers.data })
		}
	}
	React.useImperativeHandle(ref, () => ({
		show: async (params) => {
			await fetchData()
			setParams(params)
			setIsOpen(true)
			// Edit mode
			if (params.approvalGroup) {
				if (params.approvalGroup.suppliers.length > 0) {
					setHasSupplier(true)
					setSuppliers(params.approvalGroup.suppliers.map((e) => { return { value: e, label: `${e.companyName} <${e.contractNumber}>` } }))
				} else {
					setHasSupplier(false)
					setSuppliers([])
				}
				setDepartment({ value: params.approvalGroup.departmentCode, label: params.approvalGroup.departmentName })
				setDocRequiered(params.approvalGroup.documentationRequired.map((e) => { return { value: e, label: e } }))
				setMasterApprovers(params.approvalGroup.masterApprovers.map((e) => { return { value: e, label: `${e.lastName} ${e.firstName}` } }))
				setApprovers(params.approvalGroup.conditionalApprovers.map((e) => { return { value: e, label: `${e.lastName} ${e.firstName}` } }))
			}
			// setParams(params)
		}
	}))

	function handleClose() {
		setIsOpen(false)
		// setParams(null)
		setDocRequiered([])
		setApprovers([])
		setMasterApprovers([])
		setDepartment(undefined)
		setSuppliers([])
		setHasSupplier(true)
	}

	async function onSubmit() {
		// const nameError = nameRef.current!.checkErrors()
		// if (checkedMembers.current.size <= 0 || nameError || !department) {
		// 	return
		// } else {
		// 	const approvers: Member[] = []
		// 	checkedMembers.current.forEach(v => {
		// 		approvers.push(v)
		// 	})
		// 	const response = await createApprovalGroup({
		// 		approvers: approvers,
		// 		department: department as DepartmentType,
		// 		name: nameRef.current!.value
		// 	})
		// 	if (response._type === 'data') {
		// 		props.refetch()
		// 		handleClose()
		// 	} else {
		// 		LOG.dev("New approval group error", response)
		// 	}
		// }
		// console.log({
		// 	documentationRequired: docRequiered?.map(e => e.label),
		// 	conditionalApprovers: approvers.map(e => { return e.label }),
		// 	departmentCode: department?.label!,
		// 	masterApprovers: masterApprovers.map(e => e.label),
		// 	suppliers: suppliers.map(e => { return { contractNumber: e.value.contractNumber, ico: e.value.ico, companyName: e.value.companyName } }) as Omit<CompanyContract, 'updatedAt'>[]
		// })
		const response = await createApprovalGroup({
			documentationRequired: docRequiered?.map(e => e.label),
			conditionalApprovers: approvers.map(e => {
				return {
					username: e.value.username,
					firstName: e.value.firstName,
					lastName: e.value.lastName
				}
			}),
			departmentCode: department?.value!,
			masterApprovers: masterApprovers.map(e => {
				return {
					username: e.value.username,
					firstName: e.value.firstName,
					lastName: e.value.lastName
				}
			}),
			suppliers: suppliers.map(e => { return { contractNumber: e.value.contractNumber, ico: e.value.ico, companyName: e.value.companyName } })

		})
		if (response._type === 'generic_success') {
			props.refetch()
			handleClose()
		} else {
			LOG.dev("New approval group error", response)
		}

	}

	function renderDepartments(departments: string[]) {
		return departments.map(e => {
			return <MenuItem key={e} value={e}>{e}</MenuItem>
		})
	}

	// function departmentOptions() {
	// 	return _.toArray(OrgDepartment).map(e => ({ value: e, label: e }))
	// }

	function documentationRequiredOptions() {
		return _.toArray(DocumentationEnum).map(e => ({ value: e, label: e.toString() }))
	}

	// function renderMembers(members: Member[], department: DepartmentType | null) {
	// 	if (members.length <= 0) {
	// 		return <div>Organisation don't have any members</div>
	// 	} else {
	// 		const membersByDepartment = members.filter(e => _.some(e.departmentCode, d => d === department?.code))
	// 		if (membersByDepartment.length > 0) {
	// 			return membersByDepartment.map(e => (
	// 				<div key={e.username} >
	// 					<div className={classes.memberusername}>{e.username}</div>
	// 					<div className={classes.memberTitle}>{e.title}</div>
	// 					<div className={classes.memberTitle}>
	// 						<Checkbox name={e.username} onChange={event => {
	// 							if (event.target.name === e.username) {
	// 								if (checkedMembers.current.has(e.username)) {
	// 									checkedMembers.current.delete(e.username)
	// 								} else {
	// 									checkedMembers.current.set(e.username, e)
	// 								}
	// 							}
	// 						}} />
	// 					</div>
	// 				</div>
	// 			))
	// 		} else {
	// 			return <div>There are no members in this department</div>
	// 		}
	// 	}
	// }
	// function renderSuppliersList(suppliers: { value: Supplier, label: string }[]) {
	// 	if (suppliers) {
	// 		return suppliers.map(e => {
	// 			// let current = suppliersInfo.f
	// 			// setSuppliersInfoP({ ...suppliersInfo, suppliersInfo })
	// 			return <span>
	// 				<p className={classes.selectLabel}>{e.value.companyName}</p>
	// 				<CustomInput
	// 					ref={nameRef}
	// 					errorText=""
	// 					label="Contract Number"
	// 					placeholder="Enter Contract Number"
	// 					required={true}
	// 					type="text"
	// 					style={{
	// 						width: '100%',
	// 						alignSelf: 'flex-start'
	// 					}}
	// 				// startingValue={(() => {

	// 				// 	return suppliersInfo?.contractNumber
	// 				// })()
	// 				// }
	// 				/>
	// 			</span>
	// 		})
	// 	}
	// }
	if (params) {
		return <div>
			<Modal open={isOpen} onClose={handleClose}>
				<div className={`${modalClasses.paper} ${classes.modal} `}>
					<div className={modalClasses.modalHeader}>New Approval Group</div>
					<div className={classes.modalContent}>
						<Form onSubmit={onSubmit} className={classes.form}>
							<FormControl className={classes.formControl}>
								<p className={classes.selectLabel}>Department</p>
								<Select
									name="Department"
									// Should show last name as well
									options={data!.departments.map(e => {
										return { value: e.code, label: e.name }

									})}
									value={department}
									onChange={selected => {
										if (selected != null) {
											// if (departmentError.error) {
											// 	setDepartmentError({ error: false, errorMessage: "" })
											// }
											setDepartment(selected as { value: string, label: string })
										} else {
											setDepartment(undefined)
										}
									}}
									className={classes.select}
									styles={{
										control: (base, props) => ({
											...base,
											border: 'none',
											boxShadow: 'none'
										})
									}}
								/>
								{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
							</FormControl>
							<FormControl>
								<FormControlLabel
									style={{ marginTop: '13px' }}
									control={<Checkbox
										checked={hasSupplier}
										onChange={() => {
											setHasSupplier(!hasSupplier)
											if (hasSupplier) {
												const tmp = [{ value: DocumentationEnum.PURCHASE_ORDER, label: DocumentationEnum.PURCHASE_ORDER.toString() },
												{ value: DocumentationEnum.PURCHASE_REQUEST, label: DocumentationEnum.PURCHASE_REQUEST.toString() }]
												setDocRequiered(tmp)
												setSuppliers([])
											} else {
												setDocRequiered([])
											}

										}}
										name={'hasSupplier'}
									/>}
									label={'Contract Based Procurement'}
								/>
								{/* <FormControl error={error}>
									<FormHelperText>{errorText}</FormHelperText>
								</FormControl> */}
							</FormControl>
							{hasSupplier && <FormControl className={classes.formControl}>
								<p className={classes.selectLabel}>Verified Suppliers</p>
								<Select
									isMulti
									name="Suppliers"
									// Should show last name as well
									options={data!.companyContracts.map((e) => {
										// Omiting updatetAt
										let tmp = {
											contractNumber: e.contractNumber,
											ico: e.ico,
											companyName: e.companyName
										}
										return { value: tmp, label: `${e.companyName} <${e.contractNumber}>` }

									})}
									value={suppliers}
									onChange={selected => {
										if (selected != null) {
											// if (departmentError.error) {
											// 	setDepartmentError({ error: false, errorMessage: "" })
											// }
											setSuppliers(selected as { value: Omit<CompanyContract, 'updatedAt'>, label: string }[])
										} else {
											setSuppliers([])
										}
									}}
									className={classes.select}
									styles={{
										control: (base, props) => ({
											...base,
											border: 'none',
											boxShadow: 'none'
										})
									}}
								/>
								{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
							</FormControl>}
							<FormControl className={`${classes.formControl} ${classes.formControlPadding}`}>
								<p className={classes.selectLabel}>Required Documentation</p>
								<Select
									isMulti
									name="RequieredDocumentation"
									options={documentationRequiredOptions()}
									value={docRequiered}
									onChange={selected => {
										if (selected != null) {
											// if (departmentError.error) {
											// 	setDepartmentError({ error: false, errorMessage: "" })
											// }
											setDocRequiered(selected as { value: DocumentationEnum, label: string }[])
										} else {
											setDocRequiered([])
										}
									}}
									className={classes.select}
									styles={{
										control: (base, props) => ({
											...base,
											border: 'none',
											boxShadow: 'none'
										})
									}}
									isDisabled={!hasSupplier}
								/>
								{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
							</FormControl>
							<FormControl className={`${classes.formControl} ${classes.formControlPadding}`}>
								<p className={classes.selectLabel}>Master Approvers</p>
								<Select
									isMulti
									name="MasterApprovers"
									// Should show last name as well
									options={params.members.filter(e => e.jobRoles.includes(JobRole.APPROVER)).map(e => {
										return {
											value: {
												username: e.username,
												firstName: e.firstName,
												lastName: e.lastName,
											},
											label: `${e.lastName} ${e.firstName}`
										}
									})}
									value={masterApprovers}
									onChange={selected => {
										if (selected != null) {
											// if (departmentError.error) {
											// 	setDepartmentError({ error: false, errorMessage: "" })
											// }
											setMasterApprovers(selected as { value: ApprovaGroupApprover, label: string }[])
										} else {
											setMasterApprovers([])
										}
									}}
									className={classes.select}
									styles={{
										control: (base, props) => ({
											...base,
											border: 'none',
											boxShadow: 'none'
										})
									}}
								/>
								{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
							</FormControl>
							<FormControl className={`${classes.formControl} ${classes.formControlPadding}`}>
								<p className={classes.selectLabel}>Specialist Approvers</p>
								<Select
									isMulti
									name="AdditionalApprovers"
									// Should show last name as well
									options={params.members.map(e => ({
										value: {
											username: e.username,
											firstName: e.firstName,
											lastName: e.lastName,
										},
										label: `${e.lastName} ${e.firstName}`
									}))}
									value={approvers}
									onChange={selected => {
										if (selected != null) {
											// if (departmentError.error) {
											// 	setDepartmentError({ error: false, errorMessage: "" })
											// }
											setApprovers(selected as { value: ApprovaGroupApprover, label: string }[])
										} else {
											setApprovers([])
										}
									}}
									className={classes.select}
									styles={{
										control: (base, props) => ({
											...base,
											border: 'none',
											boxShadow: 'none'
										})
									}}
								/>
								{/* {departmentError.error && <FormHelperText className={classes.selectError}>{departmentError.errorMessage}</FormHelperText>} */}
							</FormControl>



							{/* <CustomInput
							ref={nameRef}
							errorText=""
							label="Group Name"
							placeholder="Enter new group name"
							required={true}
							type="text"
							style={{
								width: '100%',
								alignSelf: 'flex-start'
							}}
							/>
							<SelectInput
							children={renderDepartments(params!.departments)}
							errorText=""
							value={department}
							handleChange={(value) => setDepartment(value)}
							hasError={false}
							label="Department"
							className={classes.formControl}
							/>
							{department && <div className={classes.members}>
							{renderMembers(params.members, department)}
							</div>} */}
						</Form>
					</div>
					<Button variant='contained' color='primary' className={`${classes.addNewButton}`} onClick={onSubmit}>Add</Button>
					<CloseIcon className={modalClasses.closeButton} onClick={() => {
						setIsOpen(false)
					}} />
				</div>
			</Modal>
		</div>
	} else {
		return null
	}
})