import { FormHelperTextProps, InputAdornment, InputLabelProps, InputProps, TextField } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { EEXIST } from 'constants'
import * as React from 'react'
import { ChangeEventHandler } from 'react'

type Props = {
	label: string
	placeholder: string
	errorText: string
	required: boolean
	type: string
	showRequiredOnMount?: boolean
	shrink?: boolean
	regex?: RegExp
	startingValue?: string | null
	otherErrorCondition?: () => boolean
	style?: React.CSSProperties
	disabled?: boolean
	disableUnderline?: boolean
	className?: string
	startAndorment?: React.ReactNode
	endAndorment?: React.ReactNode
	multilne?: boolean
	classes?: Partial<ClassNameMap<"root">>,
	customOnBlur?: () => (Promise<void> | void)
	customOnChange?: (e: any) => void

}

export type CustomInput = {
	removeErrors: () => void
	checkErrors: () => boolean
	hasError: boolean
	value: string

}

export const InputCommon = {
	inputLabelProp: <T extends InputLabelProps>(props?: T) => ({
		...props,
		shrink: props?.shrink || true,
		style: {
			padding: 5,
			backgroundColor: 'white'
		}
	}),
	inputProps: <T extends InputProps>(props?: T) => ({
		...props,
		style: {
			border: '2px solid #ededed',
			borderRadius: 0,
			width: props?.multiline ? '400px' : '',
			height: props?.multiline ? '200px' : '',


		}
	}),
	helperTextProps: <T extends FormHelperTextProps>(props?: T) => ({
		...props,
		style: {

		}
	})
}

export const CustomInput = React.forwardRef((props: Props, ref: React.Ref<CustomInput>) => {
	const [value, setValue] = React.useState<string>("")
	const [error, setError] = React.useState({
		hasError: false,
		errorMessage: ""
	})

	const setIsRequiredError = () => {
		setError({
			hasError: true,
			errorMessage: "Field is required"
		})
	}

	const resetErrors = () => {
		setError({
			hasError: false,
			errorMessage: ""
		})
	}

	function handleErrors() {
		if (props.required) {
			if (value === '' || value == null) {
				setIsRequiredError()
				return true
			}
		}
		if (props.regex) {
			setError({
				hasError: !props.regex.test(value),
				errorMessage: props.errorText
			})
			return !props.regex.test(value)
		}
		if (props.otherErrorCondition) {
			setError({
				hasError: props.otherErrorCondition(),
				errorMessage: props.errorText
			})
			return props.otherErrorCondition()
		}
		resetErrors()
		return false
	}

	React.useImperativeHandle(ref, () => ({
		removeErrors: resetErrors,
		checkErrors: handleErrors,
		hasError: error.hasError,
		value: value
	}))

	React.useEffect(() => {
		if (props.startingValue) {
			setValue(props.startingValue)
		} else {
			setValue("")
			if (props.showRequiredOnMount && props.required && !props.startingValue) {
				setIsRequiredError()
			}
		}
	}, [props.startingValue, !props.startingValue])

	function renderErrorText() {
		if (error.hasError) {
			return error.errorMessage
		} else {
			return <>&nbsp;</>
		}
	}
	const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target instanceof HTMLInputElement && event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0]
			const base64 = await convertBase64(file)
			console.log(base64)
		}
	}

	const convertBase64 = (file: File) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				if (fileReader.result) {
					// fileReader.
					// console.log('This file will be set to', fileReader.result.toString())
					setValue(fileReader.result.toString())
				}
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}

	return <TextField
		variant="outlined"
		error={error.hasError}
		InputProps={InputCommon.inputProps<InputProps>({
			startAdornment: <InputAdornment position="start">{props.startAndorment || ""}</InputAdornment>,
			endAdornment: <InputAdornment position="end">{props.endAndorment || ""}</InputAdornment>,
			multiline: props.multilne,
			style: { height: '100%' }
		})}
		InputLabelProps={InputCommon.inputLabelProp({ shrink: props.shrink })}
		style={props.style}
		label={props.label}
		type={props.type}
		disabled={props.disabled}
		required={props.required}
		value={value}
		onChange={(e) => {
			// if (e.target instanceof HTMLInputElement) {
			// handleFileRead(e)
			// 	console.log('did enter')
			// 	console.log(e.currentTarget)
			// 	if (e.target.files && e.target.files.length > 0) {
			// 		// setValue(URL.createObjectURL(e.target.files[0]))
			// 		console.log('Object creation', URL.createObjectURL(e.target.files[0]))

			// 		// console.log('Object creation', URL.createObjectURL(e.target.files[0]))
			// 	}

			// 	console.log(e.currentTarget.type)
			// 	console.log(e.target)
			// 	console.log(e.target.type)

			// 	// if(e.currentTarget.type === '')
			// 	// let img = e.target.files[0]
			// 	// console.log('image set to', URL.createObjectURL(img))

			// }
			// if (props.type === 'file') {
			// 	// setValue(URL.createObjectURL(img))
			// } else {
			setValue(e.target.value)
			props.customOnChange?.(e)
			// }
		}
		}
		onBlur={_ => {
			if (!value && props.required) {
				setError({
					hasError: true,
					errorMessage: "Field is required"
				})
			} else if (props.regex && typeof value === "string") {
				setError({
					hasError: !props.regex.test(value),
					errorMessage: props.errorText
				})
			} else if (props.otherErrorCondition) {
				setError({
					hasError: props.otherErrorCondition(),
					errorMessage: props.errorText
				})
			} else {
				setError({
					...error,
					hasError: false
				})
			}

			// if (value.length > 3) {
			props.customOnBlur?.()
			// }
		}}
		FormHelperTextProps={InputCommon.helperTextProps({})}
		helperText={renderErrorText()}
		multiline={props.multilne}
	// classes={{root:{min}}}
	// classes={props.classes}
	//Test change for rebuild
	/>
})

export type InputState<T = string> = {
	error: boolean
	errorMessage: string
	value: T
}
