import { ApprovalStatus, ApproversType, DocStatusEnum, ExtractionStatus, Item, Label, LabelGroupsType, OriginEnum, ValuesType } from "api/api-types"
import { CustomInput } from "elements/form/CustomInput"
import * as React from "react"
import * as _ from 'lodash'
import { PdfDocument } from "elements/PdfDocument"
import { ArrowBack, ArrowForward } from "@material-ui/icons"
import { Section } from "elements/ItemTable/ItemTable"
import { Translation } from 'react-i18next';
import { PaginationParams, ViewParams } from "./useFetchItems"
import { setItemIsRead } from "api/ApiServices"

export type State = {
	labels: LabelGroupsType
	values: ValuesType
	itemId: string
	docName: string
	// isNotEditable: boolean
	extractionStatus: ExtractionStatus
	confidenceLevel: number
	approvalStatus: ApprovalStatus
	pagination: {
		hasNext: boolean,
		hasPrev: boolean
	}
	rejectedReason?: string,
	approvers?: ApproversType[]
	approvedByMe?: boolean
	setIsLoading?: (isLoading: boolean) => void
}


export type ItemModalParams = {
	item: Item
	currentIndex: number
	size: number
	showApprovalInfo?: boolean
	forSection?: Section
}

type ShowFunction = (params: ItemModalParams) => void

export type ItemModal = {
	show: ShowFunction
}

export type PaginateFunction = (items: Item[], id: string, forSection: Section) => void
export type PaginateFactory = (show: ShowFunction, close: () => void) => PaginateFunction

export type Props = {
	refetchInvoices: (viewP?: ViewParams, paginationP?: PaginationParams) => void
	refetchCounters: () => void
	paginate: (itemId: string, paginate: PaginateFunction) => void,
	expandabSectionCondition?: { supplier?: boolean, invoice?: boolean, booking?: boolean, payment?: boolean }
}

export type ArrayInputs<T> = {
	label: Label<T>
	ref: React.RefObject<CustomInput>
}[]

const paginateNext: PaginateFactory = (show: ShowFunction, close) => (items, id, forSection) => {
	const index = _.findIndex(items, e => e.itemId === id)! + 1
	close()
	// if(items[index].extractionStatus !== ExtractionStatus.DUPLICATE)
	if (index < items.length) {
		show({
			currentIndex: index,
			item: items[index],
			size: items.length,
			forSection: forSection
		})
	}

}

const paginatePrev: PaginateFactory = (show: ShowFunction, close) => (items, id, forSection) => {
	const index = _.findIndex(items, e => e.itemId === id)! - 1
	close()
	if (index < items.length) {
		show({
			currentIndex: index,
			item: items[index],
			size: items.length,
			forSection: forSection
		})
	}
}

const renderDocumentationStatusLabel = (status: DocStatusEnum, prefix: string) => {
	switch (status) {
		case DocStatusEnum.PENDING: return <Translation>{(t) => <span className='bgOrange status-bgOrange modal-header-label'>{prefix} {t('Modal.Pending')}</span>}</Translation>
		case DocStatusEnum.REJECTED: return <Translation>{(t) => <span className='bgRed status-bgRed modal-header-label'>{prefix} {t('Modal.Rejected')}</span>}</Translation>
		case DocStatusEnum.UNAVAILABLE: return <Translation>{(t) => <span className='bgGrey status-bgGrey modal-header-label'>{prefix} {t('Modal.Open')}</span>}</Translation>
		case DocStatusEnum.NOT_APPLICABLE: return <Translation>{(t) => <span className='bgGreen status-bgGreen modal-header-label'>{prefix} {t('Modal.NA')}</span>}</Translation>
		case DocStatusEnum.SUCCESS: return <Translation>{(t) => <span className='bgGreen status-bgGreen modal-header-label'>{prefix} {t('Modal.Approved')}</span>}</Translation>
	}
}

const renderExtractionStatusLabel = (status: ExtractionStatus, confidenceLevel: number) => {
	if (status === ExtractionStatus.READY) {
		//	Check what's going with confidence lvl and are we still using it	
		if (confidenceLevel === 100) {
			return <Translation>{(t) => <span className='bgGreen status-bgGreen modal-header-label'>{t('Modal.Extraction_Correct')}</span>}</Translation>
		} else {
			return <Translation>{(t) => <span className='bgBlue status-bgBlue modal-header-label'>{t('Modal.Extraction_Attention')}</span>}</Translation>
		}
	} else if (status === ExtractionStatus.DUPLICATE) {
		return <Translation>{(t) => <span className='bgOrange status-bgOrange modal-header-label'>{t('Modal.Extraction_Duplicate')}</span>}</Translation>
	} else if (status === ExtractionStatus.ATTENTION) {
		return <Translation>{(t) => <span className='bgBlue status-bgBlue modal-header-label'>{t('Modal.Extraction_Attention')}</span>}</Translation>
	}
	else {
		throw Error("Not supported label")
	}
}

const renderApprovalStatusLabel = (status: ApprovalStatus) => {
	switch (status) {
		case ApprovalStatus.READY_FOR_SUMBISION: return <Translation>{(t) => <span className='bgGreen status-bgGreen modal-header-label'>{t('Modal.Approval_Ready')}</span>}</Translation>
		case ApprovalStatus.APPROVED: return <Translation>{(t) => <span className='bgGreen status-bgGreen modal-header-label'>{t('Modal.Approval_Approved')}</span>}</Translation>
		case ApprovalStatus.REJECTED: return <Translation>{(t) => <span className='bgRed status-bgRed modal-header-label'>{t('Modal.Approval_Rejected')}</span>}</Translation>
		case ApprovalStatus.REVERTED: return <Translation>{(t) => <span className='bgRed status-bgRed modal-header-label'>{t('Modal.Approval_Reverted')}</span>}</Translation>
		case ApprovalStatus.RETURNED: return <Translation>{(t) => <span className='bgRed status-bgRed modal-header-label'>{t('Modal.Approval_Returned')}</span>}</Translation>
		case ApprovalStatus.ON_HOLD: return <Translation>{(t) => <span className='bgOrange status-bgOrange modal-header-label'>{t('Modal.Approval_OnHold')}</span>}</Translation>
		case ApprovalStatus.PENDING_APPROVAL: return <Translation>{(t) => <span className='bgOrange status-bgOrange modal-header-label'>{t('Modal.Approval_Pending')}</span>}</Translation>
		case ApprovalStatus.OPEN: return <Translation>{(t) => <span className='bgGrey status-bgGrey modal-header-label'>{t('Modal.Approval_Open')}</span>}</Translation>
	}
}

export const ItemModalCommon = {
	paginateNext: paginateNext,
	paginatePrev: paginatePrev,
	renderDocument: (state: State | null, className: string) => {
		if (state) {
			return <div className={className}>
				<PdfDocument itemId={state.itemId} />
			</div>
		} else {
			return null
		}
	},
	renderPagination: (
		state: State,
		props: Props,
		paginatePrev: PaginateFunction,
		paginateNext: PaginateFunction,
		containerClassName: string,
		paginateButtonClassName: string
	) => {
		let content = []
		if (state.pagination.hasPrev) {
			content.push(
				<Translation>{(t) => <div
					key="pagination-prev"
					className={paginateButtonClassName}
					onClick={() => props.paginate(state!.itemId, paginatePrev)}
				>
					<ArrowBack style={{ marginRight: 5 }} />
					<span>{t('Common.Previous')}</span>
				</div>}</Translation>
			)
		}

		if (state.pagination.hasNext) {
			content.push(
				<>
					<div />
					<Translation>{(t) => <div
						key="pagination-next"
						className={paginateButtonClassName}
						onClick={() => props.paginate(state!.itemId, paginateNext)}
					>
						<span style={{ marginRight: 5 }}>{t('Common.Next')}</span>
						<ArrowForward />
					</div>}</Translation>
				</>
			)
		}
		return (
			<div className={containerClassName}>
				{content}
			</div>
		)
	},
	renderHeaderLabels: (
		approvalStatus: ApprovalStatus,
		extractionStatus: ExtractionStatus,
		confidenceLevel: number,
		requestOrderStatus?: DocStatusEnum,
		purchaseOrderStatus?: DocStatusEnum
	) => {
		return <>
			{renderApprovalStatusLabel(approvalStatus)}
			{renderExtractionStatusLabel(extractionStatus, confidenceLevel)}
			{requestOrderStatus && renderDocumentationStatusLabel(requestOrderStatus, "Order Id")}
		</>
	},
	// AI=BLUE, SYSTEM=BLACK, USER=RED, CONSOLIDATED=GREEN
	getLabelColor: <T extends {}>(label: Label<T | string | null | undefined>) => {
		switch (label.origin) {
			case OriginEnum.AI:
				return 'bgBlue status-bgBlue modal-header-label'
			case OriginEnum.SYSTEM:
				return 'bgBlue status-bgBlue modal-header-label'
			case OriginEnum.USER_INPUT:
				return 'bgRed status-bgRed modal-header-label'
			case OriginEnum.USER_MODIFIED:
				return 'bgRed status-bgRed modal-header-label'
			case OriginEnum.CONSOLIDATED:
				return 'bgGreen status-bgGreen modal-header-label'
		}
	}

}