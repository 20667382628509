import * as React from 'react'
import { Button, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, createStyles, Snackbar, Typography } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertSettings } from 'elements/Alerts'
import { DropzoneArea } from 'material-ui-dropzone'
import { getUploadUrl, uploadFile } from 'api/ApiServices';
import { useTranslation } from 'react-i18next';

type Props = {
	setStart: (start: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// title: {
		// 	flexGrow: 1,
		// },
		// hide: {
		// 	display: 'none',
		// },
		// drawer: {
		// 	// width: DRAWER_WIDTH,
		// 	flexShrink: 0,
		// },
		// drawerPaper: {
		// 	width: DRAWER_WIDTH,
		// },
		// drawerHeader: {
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	padding: theme.spacing(0, 1),
		// 	// necessary for content to be below app bar
		// 	...theme.mixins.toolbar,
		// 	justifyContent: 'flex-end',
		// },
		// content: {
		// 	flexGrow: 1,
		// 	padding: theme.spacing(3),
		// 	transition: theme.transitions.create('margin', {
		// 		easing: theme.transitions.easing.sharp,
		// 		duration: theme.transitions.duration.leavingScreen,
		// 	}),
		// 	marginRight: -DRAWER_WIDTH,
		// },
		// contentShift: {
		// 	transition: theme.transitions.create('margin', {
		// 		easing: theme.transitions.easing.easeOut,
		// 		duration: theme.transitions.duration.enteringScreen,
		// 	}),
		// 	marginRight: 0,
		// },
		// To be splited in separate component
		MuiDropzonePreviewList: {
			image: {

			}
		},
		dropZoneText: {
			fontSize: '1rem',
			padding: '0px 16px'
		},

		buttonSection: {
			display: 'flex',
			height: '32%',
			width: '100%',
			maxWidth: '200px',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			padding: '0px 10px',
			alignSelf: 'center',


		},
		approveButton: {
			marginBottom: 16,
			padding: '10px 0px',
			color: '#08ad6c',
			border: '2px solid #08ad6c',
		},
		rejectButton: {
			justifySelf: 'flex-end',
			marginBottom: 16,
			padding: '10px 0px',
			color: '#FF3D57',
			border: '2px solid #FF3D57',

		},
		dropZoneGirdContainer: {

		},
		dropZoneGirdItem: {

		},
		dropZoneGirdImage: {
			color: 'rgba(0, 0, 0, 0.87)',
			width: 'initial',
			height: '30px',
			opacity: 1,
			zIndex: 5,
			maxWidth: '100%',
			boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;',
			boxSizing: 'border-box',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
			borderRadius: '4px',
		},
		list: {
			overflowY: 'auto',
			height: '100%'
		},
		listItem: {
			paddingBottom: '2px',
			paddingTop: '2px',
		},
		itemIcon: {
			minWidth: '0px',
			paddingRight: '10px'
		},
		icon: {
			color: 'rgba(34, 42, 69, 0.96)',
		},
		link: {
			color: '#1976d2'
		}

	}),
)

export const DocucmentUpload = (props: Props) => {
	const [filesUpload, setFilesUpload] = React.useState<File[]>([])
	const classes = useStyles()
	const { t } = useTranslation();
	const [alert, setAlert] = React.useState<AlertSettings>({ open: false, message: '', color: 'success' });
	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ open: false, message: '', color: 'success' })
		// setOpenAlert(false)
	}

	const handleChange = (files: File[]) => {
		setFilesUpload(files)
	}

	async function handleSave(files: File[]) {
		//Saving files to state for further use and closing Modal.
		if (alert.open !== true) {
			setAlert({ open: true, message: t("Alert.Aiber_Working"), color: 'success' })
		}
		// Exit recursive execution once there are no more files
		if (files.length === 0) {
			props.setStart(true)

			return
		}

		const urlResponse = await getUploadUrl(files[0].name)
		if (urlResponse._type === 'data') {
			const uploadRespone = await uploadFile(urlResponse.data, files[0])
			if (['data', 'generic_success'].indexOf(uploadRespone._type) > -1) {
				files.shift()
				// setFilesUpload(files)
				handleSave(files)
			} else {
				setAlert({ open: true, message: 'Error with s3 storage', color: 'error' })
			}
		} else {
			setAlert({ open: true, message: 'Error requesting signed URL for upload', color: 'error' })
			// console.log("")
		}
	}

	const renderPreviewList = () => {
		return filesUpload?.map((e) => {
			return (<>
				<ListItem key={e.name} className={classes.listItem}>
					<ListItemIcon className={classes.itemIcon}>
						<AttachFileIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText>{e.name}</ListItemText>
					<ListItemIcon className={classes.itemIcon}>
						<DeleteIcon className={classes.icon} onClick={() => setFilesUpload(filesUpload.filter(
							it => it.name !== e.name
						))} />
					</ListItemIcon>
				</ListItem>
			</>)
		})
	}

	return (<>
		<DropzoneArea
			acceptedFiles={['application/pdf']}
			dropzoneText={"Drag and drop pdf documents here or click to browse"}
			maxFileSize={5000000}
			filesLimit={25}
			showAlerts={false}
			showPreviewsInDropzone={false}
			showFileNamesInPreview={true}
			showPreviews={false}
			clearOnUnmount={true}
			onDrop={(files) => setFilesUpload(files.concat(filesUpload!))}
			dropzoneParagraphClass={classes.dropZoneText}
			previewGridClasses={{ image: classes.dropZoneGirdImage }}
		// Icon={renderDropZoneIcon()}
		/>
		<Typography variant='body2' style={{ padding: 16 }}>{t("Alert.Upload_Support")}</Typography>
		<List className={classes.list}>
			{renderPreviewList()}
		</List>
		<div className={classes.buttonSection}>
			<Button className={classes.approveButton} onClick={() => {
				if (filesUpload) {
					handleSave(filesUpload)
				}
				setFilesUpload([])
			}
			}>
				UPLOAD
			</Button>
			<Button className={classes.rejectButton} onClick={() => setFilesUpload([])}>
				CANCEL
			</Button>

		</div>
		<Snackbar open={alert.open} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} style={{ marginBottom: '122px', marginRight: '14px' }}>
			<Alert onClose={handleCloseAlert} severity={alert.color}>
				<Typography variant='body2' style={{ color: 'white' }}>{alert.message}</Typography>
			</Alert>
		</Snackbar>
	</>
	)

}