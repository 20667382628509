import * as React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

const theme = createMuiTheme({
	typography: {
		body1: {
			fontSize: '0.8125rem'
		},
		h6: {
			fontSize: '1rem',
			color: 'rgba(34, 42, 69, 0.96)'
		},
		h4: {
			color: 'rgba(34, 42, 69, 0.96)'
		},
		h5: {
			color: 'rgba(34, 42, 69, 0.96)'
		},
		subtitle1: {
			fontSize: '0.75rem',
			fontWeight: 700,

		},
		body2: {
			fontSize: '0.75rem',
		}

	},
	palette: {
	},
	overrides: {
		MuiButton: {
			root: {

			},
			// SuccesButton
			containedPrimary: {
				maxWidth: '300px',
				backgroundColor: "#08ad6c",
				boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px - 2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
				padding: 8,
				paddingLeft: 15,
				paddingRight: 15,
				'&:focus': {
					outline: "none"
				},
				'&:hover': {
					backgroundColor: "#006C40",
				},
				color: 'white'
			},
			containedSecondary: {
				maxWidth: '300px',
				backgroundColor: "#1976d2",
				boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px - 2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
				padding: 8,
				paddingLeft: 15,
				paddingRight: 15,
				'&:focus': {
					outline: "none"
				},
				'&:hover': {
					backgroundColor: "#1c4e99",
				},
				color: 'white'
			}

		},
		MuiListItem: {
			selected: {
			}
		},
		MuiListItemText: {
			root: {
				flex: '1 1 auto',
				minWidth: 0,
				marginTop: '4px',
				marginBottom: '4px',
			}
		},
		MuiIconButton: {
			root: {
				'&:focus': {
					outline: "none"
				},
			}
		},
		MuiSvgIcon: {
			root: {
				fontSize: '1.2rem',
				'&:focus': {
					outline: "none"
				},
			}

		},
		MuiListItemIcon: {
			root: {
				color: 'white',
			}
		},
		MUIDataTableToolbar: {
			icon: {
				'&:hover': {
					color: '#457EC1'
				},
				'& span': {
					'& svg': {
						fontSize: '1.5rem'
					}
				}
			},
			actions: {
				alignSelf: 'center'
			}
		},
		MUIDataTableHeadCell: {
			fixedHeader: {

			}
		},
		MuiInputBase: {
			input: {
				font: 'inherit',
				color: 'currentColor',
				width: '100%',
				border: 0,
				height: '1.1876em',
				margin: 0,
				display: 'block',
				padding: '8px 0 8px',
				minWidth: 0,
				background: 'none',
				boxSizing: 'content-box',
				animationName: ' mui-auto-fill-cancel',
				letterSpacing: 'inherit',
				animationDuration: '10ms',
				'-webkit-tap-highlight-color': 'transparent',
			}
		},
		MuiInputAdornment: {
			positionEnd: {
				marginLeft: '-6px'
			}
		},
		MuiTableCell: {
			root: {
				display: 'table-cell',
				textAlign: 'left',
				paddingRight: '0px',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans- serif',
				fontWeight: 400,
				lineHeight: 1.43,
				borderBottom: '1px solid rgba(224, 224, 224, 1)',
				letterSpacing: '0.01071em',
				verticalAlign: 'inherit',
				whiteSpace: 'nowrap',
				paddingBottom: '8px',
				paddingTop: '8px',

			},
			head: {
				color: '#222A45',
				fontWeight: 500,
				lineHeight: '1.5rem'
			}
		},
		MUIDataTable: {
			paper: {
				width: '100%'
			}
		},
		MuiFormControl: {
			root: {
				width: '100% !important'
			}
		},
		MuiOutlinedInput: {
			input: {
				padding: '12px 14px'
			}
		},

		//@ts-ignore
		MuiDropzonePreviewList: {
			image: {
				color: 'rgba(0, 0, 0, 0.87)',
				width: 'initial',
				height: '40px',
				opacity: 1,
				zIndex: 5,
				maxWidth: '100%',
				boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;',
				boxSizing: 'border-box',
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
				borderRadius: '4px',
			}
		},

		MuiCheckbox: {
			root: {
				"&$checked": {
					"color": "rgba(0, 0, 0, 0.87)"
				}
			}
		},
		//@ts-ignore
		MUIDataTableToolbarSelect: {
			root: {
				boxShadow: 'none',
				height: '64px'
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: 'rgba(34, 42, 69, 0.96)'
			}

		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: 'rgba(34, 42, 69, 0.96)'
			}
		},
		MuiToolbar: {
			regular: {
				['@media (min-width:600px)']: { // eslint-disable-line no-useless-computed-key
					minHeight: '52px',
				}
			}
		}


	}
})

export function AppWithTheme(props: React.PropsWithChildren<{}>) {
	return <ThemeProvider theme={theme}>
		{props.children}
	</ThemeProvider>
}