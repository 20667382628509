import { makeStyles } from '@material-ui/core'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { Column } from 'elements/ItemTable/ItemTableColumns'
import { ModalX } from 'elements/ModalX'
import * as React from 'react'
const MODAL_WIDTH = 500
const MODAL_HEIGHT = 400

const useStyles = makeStyles({
	formGroup: {
		padding: '30px 50px'
	}
})

export type UploadDocumentationModalParams = {
	columnName: Column
}

export type UploadDocumentationModal = {
	show: (params: UploadDocumentationModalParams) => void
}

export const UploadDocumentationModal = React.forwardRef((props: {}, ref: React.Ref<UploadDocumentationModal>) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const [params, setParams] = React.useState<UploadDocumentationModalParams | undefined>(undefined)
	const classes = useStyles()

	const requestRef = React.useRef<CustomInput>(null)
	const orderRef = React.useRef<CustomInput>(null)

	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			setParams(params)
			setIsOpen(true)
		}
	}))

	if (params) {
		return (
			<ModalX
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				modalHeight={MODAL_HEIGHT}
				modalWidth={MODAL_WIDTH}
			>
				<Form className={classes.formGroup} onSubmit={() => setIsOpen(false)}>
					{params.columnName === "Requisition #" && <CustomInput
						label="Requisition Number"
						errorText=""
						placeholder="Input Requisition Number"
						required={true}
						type="text"
						ref={requestRef}
					/>}
					{params.columnName === "Purchase Order #" && <CustomInput
						label="Purchase Order Number"
						errorText=""
						placeholder="Input Purchase Order Number"
						required={true}
						type="text"
						ref={orderRef}
					/>}
					<SubmitButton>Submit</SubmitButton>
				</Form>
			</ModalX>
		)
	} else {
		return null
	}
})