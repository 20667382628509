import { RegisterMember } from 'hr/members/RegisterMember'
import { Demo } from 'demo/Demo'
import { AppWithDrawer } from 'drawer/AppWithDrawer'
import "index.css"
import { AppWithErrors } from 'main/AppWithErrors'
import { AppWithUser } from 'main/AppWithUser'
import { Login } from 'main/Login'
import { Register } from 'main/Register'
import { VerifyRegistration } from 'main/VerifyRegistration'
import * as React from 'react'
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { Auth, AuthContext, useAuth } from 'utils/Auth'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';





function AppX(props: RouteComponentProps) {
	const { authState, setAuthState } = useAuth()

	const authContextValue: AuthContext = {
		logout: () => {
			setAuthState(Auth.logout())
		},
		login: (token: string) => {
			setAuthState(Auth.login(token))
		},
		getUser: () => {
			if (authState.step === 'logedIn') {
				return authState.token
			} else {
				return null
			}
		}
	}

	if (authState.step === 'loading') {
		return null
	} else {
		return (
			<AuthContext.Provider value={authContextValue}>
				<AppWithErrors>
					<Switch>
						<Route path="/registration/verify-member/:token" render={(props) => {
							if (authState.step === "logedOut") {
								const token = props.match.params.token as string
								return <RegisterMember token={token} />
							} else {
								return <Redirect to="/" />
							}
						}} />
						<Route path="/registration/verify/:token" render={(props) => {
							if (authState.step === "logedOut") {
								const token = props.match.params.token as string
								return <VerifyRegistration token={token} />
							} else {
								return <Redirect to="/" />
							}
						}} />
						<Route path="/login" render={() => {
							if (authState.step === 'logedOut') {
								return <Login />
							} else {
								return <Redirect to="/" />
							}
						}} />
						<Route path="/register" render={() => {
							if (authState.step === 'logedOut') {
								return <Register />
							} else {
								return <Redirect to="/" />
							}
						}} />
						{/* <Route path="/demo" component={Demo} /> */}
						<Route path="/demo" render={() => {
								return <Demo />
						}} />
						<Route render={(props) => {
							if (authState.step === 'logedIn') {
								return <AppWithUser {...props} />
							} else {
								return <Redirect to="/login" />
							}
						}} />
					</Switch>
				</AppWithErrors>
			</AuthContext.Provider>
		)
	}
}

const AppWithRouter = withRouter(AppX)

export function App() {
	const stripePromise = loadStripe('pk_test_rMNymgPxMg6timhBJIlIPqWZ00jTsNteyP');
	return (
		<BrowserRouter>
			<Elements stripe={stripePromise}>
				<AppWithRouter />
			</Elements>
		</BrowserRouter>
	);
}
