import { StripeProductEnum, StripePlansEnum, InvoiceMonthlyAllowance, StripeProduct, StripeProductName, ServEnum } from "api/api-types";



export const stripeProducts: StripeProduct[] = [
	{
		name: StripeProductName.AIS_APPROVAL,
		stripePriceId: 'price_1JRYSlKgM6Q7jDnM21iPP7nx',
		stripeProductId: 'prod_K37SBtBIh58pQw',
		service: ServEnum.APPROVAL,
		currency: 'eur'
	},
	{
		name: StripeProductName.AIS_ARCHIVE,
		stripePriceId: 'price_1JRYW2KgM6Q7jDnMjWdSLhtU',
		stripeProductId: 'prod_K37VqhpK6eECow',
		service: ServEnum.ARCHIVE,
		currency: 'eur'
	},
	{
		name: StripeProductName.AIS_BOOKING,
		stripePriceId: 'price_1JRYUXKgM6Q7jDnMDEljewG2',
		stripeProductId: 'prod_K37U9miLRxP8As',
		service: ServEnum.BOOKING,
		currency: 'eur'
	},
	{
		name: StripeProductName.AIS_CONNECTOR,
		stripePriceId: 'price_1JMU4NKgM6Q7jDnM6aV51oyk',
		stripeProductId: 'prod_K0V4FUpVl7UvcW',
		service: ServEnum.DOCUMENTATION_PAIRING,
		currency: 'eur'
	},
	{
		name: StripeProductName.AIS_EXTRACTOR,
		stripePriceId: 'price_1JMTt8KgM6Q7jDnM91hpI2S5',
		stripeProductId: 'prod_K0UsdBuSctnfY7',
		service: ServEnum.EXTRACTION,
		currency: 'eur'
	}
]

export const stripePricing = [
	{ price: 0.25, numberOfDocs: 50, discount: 0 },
	{ price: 0.225, numberOfDocs: 250, discount: 8 },
	{ price: 0.2, numberOfDocs: 500, discount: 14 },
	{ price: 0.175, numberOfDocs: 750, discount: 20 },
	{ price: 0.15, numberOfDocs: 1000, discount: 25 },
	{ price: 0.125, numberOfDocs: 1250, discount: 30 },
	{ price: 0.1, numberOfDocs: 1500, discount: 35 },
	{ price: 0.075, numberOfDocs: 1750, discount: 40 },
	{ price: 0.050, numberOfDocs: 2000, discount: 45 }
]