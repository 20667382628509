import { makeStyles } from '@material-ui/core'
import { submitRejected } from 'api/ApiServices'
import { CustomInput } from 'elements/form/CustomInput'
import { Form } from 'elements/form/Form'
import { SubmitButton } from 'elements/form/SubmitButton'
import { ModalX } from 'elements/ModalX'
import { LOG } from 'logger/Log'
import * as React from 'react'
import { Translation } from 'react-i18next';

// FormGroup class should be moved to its own file
const useStyles = makeStyles({
	form: {
		padding: 30
	}
})

type Params = {
	rejectedId: string
	onReject: () => void
}

export type RejectedReasonModal = {
	show: (params: Params) => void
}

export const RejectedReasonModal = React.forwardRef((props: {}, ref: React.Ref<RejectedReasonModal>) => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = React.useState(false)
	const reasonRef = React.useRef<CustomInput>(null)
	const [state, setState] = React.useState<Params | null>(null)

	async function onReject() {
		const reasonError = reasonRef.current?.checkErrors()
		if (reasonError) {
			return
		}

		try {
			const response = await submitRejected(state!.rejectedId, reasonRef.current!!.value)
			if (response._type === 'generic_success') {
				state!.onReject()
			}
		} catch (e) {
			LOG.dev("Error in rejecting", e)
		} finally {
			handleClose()
		}
	}

	React.useImperativeHandle(ref, () => ({
		show: (params) => {
			setIsOpen(true)
			setState(params)
		}
	}))

	function handleClose() {
		setIsOpen(false)
		setState(null)
	}

	return (
		<ModalX
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
		>
			<Form className={classes.form} onSubmit={onReject}>
				<CustomInput
					style={{ width: '400px', height: '200px' }}
					ref={reasonRef}
					required={true}
					label="Reason"
					placeholder="Type in you reason for rejecting"
					type="text"
					errorText=""
					multilne={true}
				// classes={{}}

				/>
				<Translation>{(t) => <SubmitButton>{t("Button.Reject")}</SubmitButton>}</Translation>
			</Form>
		</ModalX>
	)
})